import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .boxtype2-content {
    transition: height 0.5s ease-out;
    overflow: hidden;
    width: 100%;
    //height: auto;
  }

  .boxtype2-content.collapsed {
    height: 170px;
  }
`;

const BoxType2 = ({ title, children, extras1, extras2, collapsible }) => {
  const [collapsed, setCollapsed] = useState(collapsible ? true : false);

  return (
    <StyledDiv className='bg-white rounded-2xl shadow-md border border-solid border-zinc-300 dark:bg-zinc-900 dark:border-zinc-700 h-full'>
      <div className='p-4'>
        <div className='flex flex-row mb-4 items-center'>
          <div className='flex flex-row gap-4 items-center'>
            <div className='text-2xl'>{title}</div>
            <div>{extras1}</div>
          </div>
          <div className='flex-auto flex flex-row items-center justify-end'>{extras2}</div>
        </div>
        <div className='flex'>
          <div className={`p-1 overflow-hidden boxtype2-content ${collapsed ? 'collapsed' : ''}`}>{children}</div>
        </div>
      </div>
      {collapsible && (
        <div
          style={{ borderRadius: '0 0 0.375rem 0.375rem' }}
          className='h-10 cursor-pointer flex flex-row items-center justify-center bg-sky-500 text-white  dark:text-black'
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? <FaChevronDown className='text-xl' /> : <FaChevronUp className='text-xl' />}
        </div>
      )}
    </StyledDiv>
  );
};

BoxType2.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  extras1: PropTypes.node,
  extras2: PropTypes.node,
  collapsible: PropTypes.string,
};

export default BoxType2;

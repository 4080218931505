import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import DataNexusContext from 'src/context/DataNexusContext';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getServiceHostings, deleteServiceHosting } from 'src/api/datanexus';
import { notEmptyString, sleepMs, truncateText } from 'src/misc/Misc';
import dayjs from 'dayjs';
import Na from 'src/components/layout/Na';
import Highlighter from 'react-highlight-words';
import useTheme from 'src/hooks/useTheme';
import ServiceHostingEditor from './ServiceHostingEditor';
import { CREATE_NEW_ID } from '../../Misc/misc';
import { Button, Tag } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';

const ServiceHostingData = ({ filters, editable, hideId, onRowSelect }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { adminServiceHostingAddNew, setAdminServiceHostingAddNew, adminServiceHostingSorter, setAdminServiceHostingSorter, adminServiceHostingExport, setAdminServiceHostingExport } = useContext(DataNexusContext);

  const { theme } = useTheme();

  const handleRowClick = (record) => {
    if (onRowSelect) {
      onRowSelect(record);
    }
    // Any other click handling logic here
  };

  const columns = [
    {
      responsive: ['xl'],
      title: 'ID',
      key: 'id',
      align: 'center',
      width: 90,
      render: (record) => (
        <>
          <div>{record.id}</div>
          {record.justCreated && (
            <div>
              <Tag color='blue'>just created</Tag>
            </div>
          )}
        </>
      ),
      sorter: true,
    },
    {
      title: 'Hosting Region',
      key: 'region',
      align: 'center',
      render: (record) =>
        record?.region ? (
          <div>
            <div style={{ lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.region_display_name ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.region ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      title: 'Feature',
      key: 'feature',
      align: 'center',
      render: (record) =>
        record?.feature ? (
          <div>
            <div style={{ lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.feature_display_name ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.feature ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      title: 'Type',
      key: 'type',
      align: 'center',
      render: (record) =>
        record?.type ? (
          <div>
            <div style={{ lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.type_display_name ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.type ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      title: 'Name',
      key: 'name',
      align: 'center',
      render: (record) =>
        record?.name ? (
          <div>
            <div style={{ lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.name_display_name ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.name ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      responsive: ['xl'],
      title: 'AWS Region',
      key: 'use_other_region',
      align: 'center',
      render: (record) =>
        record?.use_other_region ? (
          <div>
            <div style={{ lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.use_other_region_display_name ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.use_other_region ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
          </div>
        ) : (
          <div>
            <div style={{ lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.region_display_name ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.region ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
          </div>
        ),
      sorter: true,
    },
    {
      responsive: ['xl'],
      title: '3rd Party Region',
      key: 'third_party_region',
      align: 'center',
      width: '300px',
      render: (record) => (notEmptyString(record?.third_party_region) ? <Highlighter textToHighlight={truncateText(record?.third_party_region, 90) ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} className='font-light opacity-80' /> : <Na />),
    },
    {
      responsive: ['xxl'],
      title: 'Comments',
      key: 'comments',
      align: 'center',
      width: '300px',
      render: (record) => (notEmptyString(record?.comments) ? <Highlighter textToHighlight={truncateText(record?.comments, 90) ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} className='font-light opacity-80' /> : <Na />),
    },
    {
      responsive: ['xxl'],
      title: 'Created At',
      key: 'created_at',
      align: 'center',
      render: (record) =>
        record.created_at ? (
          <>
            <div style={{ lineHeight: '1rem' }}>{dayjs(record.created_at).fromNow()}</div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              {dayjs(record.created_at).format('LLL')}
            </div>
          </>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      responsive: ['xxl'],
      title: 'Updated At',
      key: 'updated_at',
      align: 'center',
      render: (record) =>
        record.updated_at ? (
          <>
            <div style={{ lineHeight: '1rem' }}>{dayjs(record.updated_at).fromNow()}</div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              {dayjs(record.updated_at).format('LLL')}
            </div>
          </>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      responsive: ['xxl'],
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: '70px',
      render: (record) => (
        <div onClick={(e) => e.stopPropagation()}>
          <YesNoDialog onYesClick={() => handleDelete(record.id)} title='Delete' body='Do you want to delete this item?' iconYes={<DeleteOutlined />} labelYes='Yes, delete it'>
            <Button type='text' size='small'>
              <DeleteOutlined />
            </Button>
          </YesNoDialog>
        </div>
      ),
    },
  ].filter((x) => {
    if (editable) {
      if (hideId && x.key === 'id') return false;
      return true;
    }
    if (hideId && x.key === 'id') return false;
    return !['actions'].includes(x.key);
  });

  const pageSizeOptions = [10, 25, 50, 100];

  const [initiated, setInitiated] = useState(false);
  const [entities, setEntities] = useState(null);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    loadData();
    executeAsyncProcess(async () => {
      await sleepMs(1000);
      setInitiated(true);
    });
  }, []);

  useEffect(() => {
    if (!initiated) return;
    loadData();
  }, [page]);

  useEffect(() => {
    if (!initiated) return;
    loadData();
    setPage(1);
    setTotal(0);
    setEntities(null);
    loadData();
  }, [pageSize, filters]);

  useEffect(() => {
    if (!adminServiceHostingAddNew) return;
    setSelectedItem(CREATE_NEW_ID);
    setAdminServiceHostingAddNew(false);
  }, [adminServiceHostingAddNew]);

  useEffect(() => {
    console.log('adminServiceHostingSorter', adminServiceHostingSorter);
    if (!adminServiceHostingSorter) return;
    loadData();
  }, [adminServiceHostingSorter]);

  useEffect(() => {
    if (!adminServiceHostingExport) return;
    setAdminServiceHostingExport(false);
    exportData();
  }, [adminServiceHostingExport]);

  const loadData = () =>
    executeAsyncProcess(async () => {
      try {
        const { regions, useOtherRegions, features, types, names, query } = filters ?? {};
        //console.log({ page, pageSize, regions, useOtherRegions, features, types, names, query });
        const resp = await getServiceHostings(page, pageSize, sortingFieldsMapper(adminServiceHostingSorter?.columnKey) ?? null, adminServiceHostingSorter?.order ?? null, regions, useOtherRegions, features, types, names, query);
        //console.log(resp);
        setEntities(resp.entities);
        setTotal(resp.total);
      } catch (error) {
        console.error(error);
      }
    });

  const handleOnSorterChange = (sorter) => {
    console.log('handleOnSorterChange:', sorter);
    setAdminServiceHostingSorter(sorter);
  };

  const handleServiceHostingEditorClose = (values) => {
    console.log('handleServiceHostingEditorClose', values);
    if (values) {
      if (selectedItem === CREATE_NEW_ID) {
        let e = [...entities];
        e.unshift({ ...values, justCreated: true });
        e.slice(0, 10);
        setEntities(e);
        // CREATE_NEW_ID means add new
        // TODO: handle adding new item
      } else {
        let e = [...entities];
        const i = e.findIndex((x) => x.id === values.id);
        if (i >= 0) {
          e[i] = { ...e[i], ...values };
          setEntities(e);
        }
      }
    }
    setSelectedItem(null);
  };

  const handleDelete = (id) => {
    console.log('handleDelete', id);
    executeAsyncProcess(async () => {
      try {
        await deleteServiceHosting(id);
        setEntities(entities.filter((x) => x.id !== id));
      } catch (error) {
        console.error(error);
      }
    });
  };

  const sortingFieldsMapper = (field) => {
    switch (field) {
      case 'region':
        return 'region_display_name';
      case 'use_other_region':
        return 'use_other_region_display_name';
      case 'feature':
        return 'feature_display_name';
      case 'type':
        return 'type_display_name';
      case 'name':
        return 'name_display_name';
      default:
        return field;
    }
  };

  const exportData = () => {
    console.log('exportData');
    executeAsyncProcess(async () => {
      try {
        const { regions, useOtherRegions, features, types, names, query } = filters ?? {};
        const resp = await getServiceHostings(1, 1000000, sortingFieldsMapper(adminServiceHostingSorter?.columnKey) ?? null, adminServiceHostingSorter?.order ?? null, regions, useOtherRegions, features, types, names, query);

        // Get exportable columns (excluding actions column)
        const exportableColumns = columns.filter((col) => col.key !== 'actions');

        // Get headers from columns definition
        const headers = exportableColumns.map((col) => col.title);

        // Map the data using column definitions
        const csvData = resp.entities.map((record) =>
          exportableColumns.map((col) => {
            // If the column has a "_display_name" field, use that
            if (record[`${col.key}_display_name`]) {
              return record[`${col.key}_display_name`];
            }
            // For other fields, use the direct value
            return record[col.key] || '';
          })
        );

        // Create CSV content
        const csvContent = [headers.join(','), ...csvData.map((row) => row.map((cell) => `"${cell || ''}"`).join(','))].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'service_hosting_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <>
      <ServiceHostingEditor id={selectedItem} onClose={handleServiceHostingEditorClose} />

      <CondensedTableLegacy
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions,
          size: 'small',
          onShowSizeChange: (current, size) => {
            console.log('onShowSizeChange', current, size);
            setPageSize(size);
          },
          current: page,
          pageSize,
          total,
          onChange: (page) => setPage(page),
        }}
        dataSource={entities}
        rowKey='id'
        onRow={(record) => {
          const rowProps = {
            style: { cursor: 'pointer' },
            onClick: () => {
              handleRowClick(record);

              // Also handle the existing edit functionality if in edit mode
              if (editable) {
                setSelectedItem(record.id);
              }
            },
          };

          // Add background styling for selected item in edit mode
          if (editable && record.id === selectedItem) {
            rowProps.style.background = theme.backgroundLight;
          }

          return rowProps;
        }}
        onChange={(_, __, sorter) => {
          handleOnSorterChange(sorter);
        }}
      />
    </>
  );
};

ServiceHostingData.propTypes = {
  filters: PropTypes.object,
  editable: PropTypes.bool,
  hideId: PropTypes.bool,
  onRowSelect: PropTypes.func,
};

export default ServiceHostingData;

import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import useTheme from 'src/hooks/useTheme';
import { Button, Space, Popover, Tooltip, Checkbox } from 'antd';
import TicketListWithGrouping from 'src/components/pages/Teacket/Components/TicketListWithGrouping';
import { getTickets } from 'src/api/teacket';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import Highlighter from 'react-highlight-words';
import UserImage from 'src/components/layout/UserImage';
import BarLoader from 'react-bar-loader';
import TicketStatusColor from '../../Components/TicketStatusColor';
import TicketPriorityColor from '../../Components/TicketPriorityColor';
import { FaTableColumns } from 'react-icons/fa6';
import TicketTypeTag from '../../Components/TicketTypeTag';
import CmdBtn from 'src/components/layout/CmdBtn';
import TicketDrawer from '../../Components/TicketDrawer';
import { isRequestCancelled, sorterMockup } from 'src/misc/Misc';
import TicketStage from '../../Components/TicketStage';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

// If a user id is passed, local state will be used instead of the global context
const TicketListControl = ({ userId, accountId, opportunityId, showRequesterAndUserTickets = true, allowSorting = false, onlyActive }) => {
  const { theme } = useTheme();
  const context = useContext(TeacketContext);
  const { ticketListRefresh, setTicketListRefresh, ticketListSorter } = context;

  const navigate = useNavigate();

  // Use the local state if any of these vars are set
  const useLocalState = !!(userId || accountId || opportunityId);

  const [isLoading, setIsLoading] = useState(true);

  // Local state variables
  const [localTicketList, setLocalTicketList] = useState([]);
  const [localTicketListStartDate, setLocalTicketListStartDate] = useState(dayjs(context.dashboardStartDate).format());
  const [localTicketListEndDate, setLocalTicketListEndDate] = useState(dayjs(context.dashboardEndDate).format());
  const [localTicketListQuerying, setLocalTicketListQuerying] = useState(false);
  const [localTicketListCurrentPage, setLocalTicketListCurrentPage] = useState(1);
  const [localTicketListTotal, setLocalTicketListTotal] = useState(0);

  const [pageSize] = useState(parseInt(25));
  const [isInitialUserSetupDone, setIsInitialUserSetupDone] = useState(!userId);

  // Add refs to track previous values
  const prevFiltersRef = useRef({
    query: context.ticketListQuery,
    users: context.ticketListUsers,
    services: context.ticketListServices,
    types: context.ticketListTypes,
    statuses: context.ticketListStatuses,
    priorities: context.ticketListPriorities,
    from: useLocalState ? localTicketListStartDate : context.dashboardStartDate,
    to: useLocalState ? localTicketListEndDate : context.dashboardEndDate,
  });

  useEffect(() => {
    if (useLocalState) {
      setIsInitialUserSetupDone(true);
      setLocalTicketListTotal(0);
    }
    loadData();
  }, []);

  useEffect(() => {
    if (!ticketListRefresh) return;
    setTicketListRefresh(false);
    loadData();
  }, [ticketListRefresh]);

  useEffect(() => {
    // console.log('ticketListSorter:', ticketListSorter);
    loadData();
  }, [ticketListSorter]);

  useEffect(() => {
    if (useLocalState) {
      setLocalTicketListStartDate(dayjs(context.dashboardStartDate).format());
      setLocalTicketListEndDate(dayjs(context.dashboardEndDate).format());
    }
  }, [context.dashboardStartDate, context.dashboardEndDate]);

  useEffect(() => {
    if (useLocalState && !isInitialUserSetupDone) return;

    loadData();
  }, [localTicketListCurrentPage]);

  // Make sure all dependencies are listed below to reload data when they change
  useEffect(() => {
    if (useLocalState && !isInitialUserSetupDone) return;

    // Only reset page when filters change, not when page changes
    const isFilterChange =
      prevFiltersRef.current.query !== context.ticketListQuery || prevFiltersRef.current.users !== context.ticketListUsers || prevFiltersRef.current.services !== context.ticketListServices || prevFiltersRef.current.types !== context.ticketListTypes || prevFiltersRef.current.statuses !== context.ticketListStatuses || prevFiltersRef.current.priorities !== context.ticketListPriorities || prevFiltersRef.current.startDate !== useLocalState
        ? localTicketListStartDate
        : context.dashboardStartDate || prevFiltersRef.current.endDate !== useLocalState
        ? localTicketListEndDate
        : context.dashboardEndDate;

    if (isFilterChange) {
      setLocalTicketListCurrentPage(1);
      context.setTicketListCurrentPage(1);
    }

    // Update refs with current values
    prevFiltersRef.current = {
      query: context.ticketListQuery,
      users: context.ticketListUsers,
      services: context.ticketListServices,
      types: context.ticketListTypes,
      statuses: context.ticketListStatuses,
      priorities: context.ticketListPriorities,
      from: useLocalState ? localTicketListStartDate : context.dashboardStartDate,
      to: useLocalState ? localTicketListEndDate : context.dashboardEndDate,
    };

    loadData();
  }, [context.ticketListQuery, context.ticketListUsers, context.ticketListServices, context.ticketListTypes, context.ticketListStatuses, context.ticketListPriorities, context.ticketListStatuses, context.dashboardListServices, localTicketListStartDate, context.dashboardStartDate, context.ticketListStartDate, localTicketListEndDate, context.dashboardEndDate, context.ticketListEndDate, isInitialUserSetupDone]);

  useEffect(() => {
    if (useLocalState && !isInitialUserSetupDone) return;
    loadData();
  }, [localTicketListCurrentPage, context.ticketListCurrentPage]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      setLocalTicketListQuerying(true);
      setLocalTicketList([]);

      context.setTicketList([]);
      context.setTicketListTotal(0);

      const currentPage = useLocalState ? localTicketListCurrentPage : context.ticketListCurrentPage;

      let resp;

      // console.log('ticketListSorter 2', ticketListSorter);

      if (useLocalState) {
        // Use local state
        if (userId) {
          resp = await getTickets(currentPage, pageSize, ticketListSorter?.field ?? null, ticketListSorter?.order ?? null, null, context.dashboardListServices, null, context.dashboardRequestStatuses, [userId], localTicketListStartDate, localTicketListEndDate, context.dashboardRequestTypes, null, null, null, null, onlyActive);
        } else if (accountId) {
          if (opportunityId) {
            resp = await getTickets(currentPage, pageSize, ticketListSorter?.field ?? null, ticketListSorter?.order ?? null, null, context.dashboardListServices, null, context.dashboardRequestStatuses, null, null, null, context.dashboardRequestTypes, null, null, accountId, opportunityId, onlyActive);
          } else {
            resp = await getTickets(currentPage, pageSize, ticketListSorter?.field ?? null, ticketListSorter?.order ?? null, null, context.dashboardListServices, null, context.dashboardRequestStatuses, null, null, null, context.dashboardRequestTypes, null, null, accountId, onlyActive);
          }
        }

        // Do not show tickets if user is not a requester or user?
        // if (!showRequesterAndUserTickets) {
        //   resp.entities = resp.entities.filter((x) => !x.teacket_ticket_members.find((ttm) => ttm.user_id === userId && (ttm.role === 'Requester' || ttm.role === 'User' || ttm.role === 'Assigned SC')));
        // }
        // v2

        if (!showRequesterAndUserTickets) {
          resp.entities = resp.entities.filter((x) => {
            const userRoles = x.teacket_ticket_members.filter((ttm) => ttm.user_id === userId).map((ttm) => ttm.role);
            const hasOnlySpecifiedRoles = userRoles.every((role) => ['Requester', 'User', 'Assigned SC'].includes(role));
            return !hasOnlySpecifiedRoles;
          });
        }

        setLocalTicketList(resp?.entities ?? []);
        setLocalTicketListTotal(resp?.total ?? 0);
      } else {
        // Use context
        resp = await getTickets(currentPage, pageSize, ticketListSorter?.field ?? null, ticketListSorter?.order ?? null, context.ticketListQuery, context.ticketListServices, context.ticketListPriorities, context.ticketListStatuses, context.ticketListUsers, context.ticketListStartDate, context.ticketListEndDate, context.ticketListTypes, null, null, onlyActive);

        context.setTicketList(resp?.entities ?? []);
        context.setTicketListTotal(resp?.total ?? 0);
      }
      setLocalTicketListQuerying(false);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
      setLocalTicketListQuerying(false);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: 'Id',
      showInGroupBy: false,
      key: 'id',
      dataIndex: 'id',
      width: 140,
      sorter: () => sorterMockup(),
      render: (text, record) => (
        <Space>
          <CmdBtn
            type='link'
            style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis' }}
            onClick={async () => {
              context.setTicketListSelectedTicket(text);
            }}
            onClickCommandShiftPressed={() => {
              navigate(`/teacket/tickets/${record.id}`);
            }}
            onClickCommandPressed={() => {
              window.open(`/teacket/tickets/${record.id}`, '_blank');
            }}>
            <strong>{record.id}</strong>
          </CmdBtn>
          {record?.type && <TicketTypeTag type={record.type} styling='-ml-4 -mr-4' />}
        </Space>
      ),
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      width: 420,
      sorter: () => sorterMockup(),
      render: (text) => (
        <div style={{ maxWidth: '400px', wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </div>
      ),
    },
    {
      title: 'Account',
      showInGroupBy: true,
      key: 'account',
      dataIndex: 'acc_name',
      sorter: () => sorterMockup(),
      render: (text) => (
        <div>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </div>
      ),
    },
    {
      title: 'Members',
      key: 'members',
      dataIndex: 'teacket_ticket_members',
      align: 'left',
      width: 220,
      showInGroupBy: false,
      render: (text) => (
        <div className='flex flex-col gap-1 m-2 flex-wrap'>
          {text?.map((x, i) => {
            return (
              <div key={i} className='flex flex-row gap-2 items-center'>
                <UserImage image={x?.user?.user_pic} size='25px' />
                <div style={{ lineHeight: '0.5rem' }}>
                  <div className='text-sm'>{x?.user?.name}</div>
                  <div className='text-[0.6rem] font-light opacity-60'>{x?.role}</div>
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: 'Category',
      showInGroupBy: true,
      key: 'category',
      dataIndex: 'category',
      align: 'center',
      sorter: () => sorterMockup(),
      render: (text, record) => (
        <div style={{ wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          <div className='text-xs font-light opacity-60'>
            <Highlighter textToHighlight={record?.sub_category ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        </div>
      ),
    },
    {
      title: 'Priority',
      showInGroupBy: true,
      key: 'priority',
      dataIndex: 'priority',
      align: 'center',
      sorter: () => sorterMockup(),
      render: (text, record) => (
        <div className='flex flex-row gap-2 items-center justify-center'>
          <TicketPriorityColor serviceId={record?.service_id} priority={text} />
          {text}
        </div>
      ),
    },
    {
      title: 'Status',
      showInGroupBy: true,
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      sorter: () => sorterMockup(),
      render: (text, record) => {
        return (
          <>
            <div className='flex flex-row gap-2 items-center justify-center'>
              <TicketStatusColor serviceId={record?.service_id} status={text} />
              {text}
            </div>
            <TicketStage serviceId={record?.service_id} status={text} />
          </>
        );
      },
    },
    // {
    //   title: 'Stage',
    //   showInGroupBy: true,
    //   key: 'status',
    //   dataIndex: 'status',
    //   align: 'center',
    //   render: (text, record) => <div className='flex flex-row gap-2 items-center justify-center'>{stageForStatus(record?.service_id, record?.status)}</div>,
    // },
    {
      title: 'Created',
      showInGroupBy: false,
      key: 'created_at',
      dataIndex: 'created_at',
      align: 'center',
      sorter: () => sorterMockup(),
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(text).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
    {
      title: 'Closed',
      showInGroupBy: false,
      key: 'closed_at',
      dataIndex: 'closed_at',
      align: 'center',
      sorter: () => sorterMockup(),
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(text).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
    {
      title: 'Need Completed By',
      showInGroupBy: false,
      key: 'need_completed_by',
      dataIndex: 'need_completed_by',
      align: 'center',
      sorter: true,
      render: (text, record) => {
        const needCompletedBy = record.teacket_tickets_values?.find((x) => x.field_name === 'Need completed by');
        if (needCompletedBy) {
          return (
            <>
              <div>{dayjs(needCompletedBy.valueDateTime).fromNow()}</div>
              <div className='font-thin text-xs'>{dayjs(needCompletedBy.valueDateTime).format('LL')}</div>
            </>
          );
        } else {
          return <>n/a</>;
        }
      },
    },
    {
      title: 'Forecasted',
      showInGroupBy: false,
      key: 'forecasted_date',
      dataIndex: 'forecasted_date',
      align: 'center',
      sorter: (a, b) => new Date(a.forecasted_date) - new Date(b.forecasted_date),
      render: (text, record) => {
        const forecastedDate = record.teacket_tickets_values?.find((x) => x.field_name === 'Forecasted date');
        if (forecastedDate) {
          return (
            <>
              <div>{dayjs(forecastedDate.valueDateTime).fromNow()}</div>
              <div className='font-thin text-xs'>{dayjs(forecastedDate.valueDateTime).format('LL')}</div>
            </>
          );
        } else {
          return <>n/a</>;
        }
      },
    },
  ].map((col) => ({ ...col, sorter: allowSorting ? col.sorter : null }));

  const columnsContent = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {context.columnOrder.map((key) => {
        const column = columns.find((col) => col.key === key);
        if (!column) return <></>;
        return (
          <div key={column.key} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox checked={context.visibleColumns[column.key]} onChange={() => context.toggleColumnVisibility(column.key)}>
              {column.title}
            </Checkbox>
          </div>
        );
      })}
    </div>
  );

  const filteredColumns = columns.filter((col) => context.columnOrder.includes(col.key) && context.visibleColumns[col.key]).sort((a, b) => context.columnOrder.indexOf(a.key) - context.columnOrder.indexOf(b.key));

  useEffect(() => {
    //console.log('filteredColumns:', filteredColumns);
  }, [filteredColumns]);

  return (
    <>
      <TicketDrawer />
      <div className='relative'>
        <div className='absolute animate-appear2 top-12 right-4 z-10 opacity-40 hover:opacity-100'>
          <Tooltip title='Show/Hide columns'>
            <Popover content={columnsContent} title='Show/Hide Columns' trigger='click'>
              <Button type='primary' shape='circle' size='small' className='w-8 h-8'>
                <FaTableColumns />
              </Button>
            </Popover>
          </Tooltip>
        </div>

        <div className={`relative ${localTicketListQuerying ? 'disabled' : null}`}>
          {!localTicketList ? (
            <SkeletonTable />
          ) : (
            <>
              {localTicketListQuerying ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '2px' }} />}
              <TicketListWithGrouping
                loading={isLoading}
                pagination={{
                  current: useLocalState ? localTicketListCurrentPage : context.ticketListCurrentPage,
                  showSizeChanger: false,
                  pageSize,
                  size: 'small',
                  total: useLocalState ? localTicketListTotal : context.ticketListTotal,
                  onChange: (page) => {
                    if (useLocalState) {
                      setLocalTicketListCurrentPage(page);
                    } else {
                      context.setTicketListCurrentPage(page);
                    }
                  },
                  position: ['topRight', 'bottomRight'],
                }}
                columns={filteredColumns}
                dataSource={useLocalState ? localTicketList : context.ticketList}
                rowKey='id'
                groupBy={context.ticketListGroupBy}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

TicketListControl.propTypes = {
  userId: PropTypes.string,
  column: PropTypes.object,
  index: PropTypes.number,
  moveColumn: PropTypes.func,
  tickets: PropTypes.array,
  showRequesterAndUserTickets: PropTypes.bool,
  accountId: PropTypes.string,
  opportunityId: PropTypes.string,
  allowSorting: PropTypes.bool,
  onlyActive: PropTypes.bool,
};

export default TicketListControl;

import React, { useContext } from 'react';
import DataNexusContext from 'src/context/DataNexusContext';
import { notArrayOrEmpty, selectFilterValueAndLabel } from 'src/misc/Misc';
import Na from 'src/components/layout/Na';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const PickerTypes = ({ width = '100%', onPick, placeholder = 'select a type...', initialValue, mode, allowClear }) => {
  const { dictionaries } = useContext(DataNexusContext);
  const { types } = dictionaries || {};

  const getOptions = () =>
    types.map((d) => {
      return { label: d.display_name, value: d.name };
    });

  const handleOnChange = (v) => onPick && onPick(v);

  const handleOnClear = () => onPick && onPick(null);

  if (true === notArrayOrEmpty(types)) return <Na />;

  const optionRender = (v) => {
    return (
      <div>
        <div>{v.label ?? v.value}</div>
        <div className='text-xs font-light opacity-60'>{v.value}</div>
      </div>
    );
  };

  return <Select defaultValue={initialValue} mode={mode} options={getOptions()} placeholder={placeholder} onChange={handleOnChange} onClear={handleOnClear} style={{ width }} autoComplete='off' allowClear={allowClear} optionRender={optionRender} showSearch filterOption={selectFilterValueAndLabel} />;
};

PickerTypes.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
  placeholder: PropTypes.string,
  initialValue: PropTypes.any,
  mode: PropTypes.string,
  allowClear: PropTypes.bool,
};

export default PickerTypes;

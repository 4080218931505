import React, { useState, useContext, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tag, Tooltip, Input, Space, Button, message } from 'antd';
import UserImage from 'src/components/layout/UserImage';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { patchTicketConversation } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import EditItemModal from './ConversationHistoryItemEdit';
import { PiShootingStarDuotone } from 'react-icons/pi';
import { uuid } from 'short-uuid';
import useUserInfo from 'src/hooks/useUserInfo';
import { MdEdit } from 'react-icons/md';
import useTheme from 'src/hooks/useTheme';
import Space2 from 'src/components/layout/Space2';
import SecondsCountDown from 'src/components/controls/SecondsCountDown/SecondsCountDown';
import { FaRegClock } from 'react-icons/fa6';
import { notEmptyString } from 'src/misc/Misc';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const StylesWrapper = styled.div`
  overflow-wrap: anywhere;

  .ant-input {
    line-height: 1rem !important;
    padding: 8px 16px !important;
  }

  .conv-history-left-image {
    width: 9px;
    height: 9px;
    color: #6466f1;
  }

  .conv-history-right-image {
    width: 9px;
    height: 9px;
    color: #6466f1;
  }

  .conv-history-item-left {
    border-radius: 0 0.8rem 0.8rem 0.8rem;
  }

  .conv-history-item-right {
    border-radius: 0.8rem 0 0.8rem 0.8rem;
  }

  .conv-history-item-posting {
    filter: blur(1px);
    opacity: 0.6;
  }

  .conv-history-item-new {
    opacity: 1;
    animation-name: item-new-animation;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }

  .conv-history-item-new-left {
    transform-origin: left;
  }

  .conv-history-item-new-right {
    transform-origin: right;
  }

  .conv-history-edited-msg-left {
    font-size: 0.7rem;
    font-style: italic;
    opacity: 0.8;
    font-weight: 200;
  }

  .conv-history-edited-msg-right {
    font-size: 0.7rem;
    font-style: italic;
    opacity: 0.8;
    font-weight: 200;
    text-align: right;
  }

  .conv-history-deleted-msg {
    font-size: 0.7rem;
    font-style: italic;
    opacity: 0.8;
    font-weight: 200;
  }

  @keyframes item-new-animation {
    50% {
      transform: scale(1.2);
    }
  }
`;

const ConversationHistoryItem = ({ side, item }) => {
  const { id: currentUserId } = useUserInfo();
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { currentTicket, currentTicketConversation, setCurrentTicketConversation, getServiceSettingForServiceId } = useContext(TeacketContext);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [busyModal, setBusyModal] = useState(false);
  const [editingAllowed, setEditingAllowed] = useState(false);
  const [editingSeconds, setEditingSeconds] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(null);

  useEffect(() => {
    const secs = parseInt(getServiceSettingForServiceId(currentTicket?.service_id, 'AllowEditingConversationSecs') ?? 0);
    setEditingSeconds(secs > 0 ? secs : null);
  }, [currentTicket]);

  const handleEditClick = () => {
    setEditModalVisible(true);
  };

  const handleModalClose = () => {
    setEditModalVisible(false);
  };

  const handleOnSave = (text, visibility) => {
    console.log('handleOnSave', text, visibility);
    executeAsyncProcess(async () => {
      try {
        setBusyModal(true);
        const copy = [...currentTicketConversation];
        const index = copy.findIndex((conv) => conv.id === item.id);
        if (index !== -1) {
          copy[index] = { ...currentTicketConversation[index], isPosting: true };
          setCurrentTicketConversation(copy);
        }

        await patchTicketConversation(item.ticket_id, item.id, { text, visibility });

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Conversation updated',
          duration: 3,
        });
        setEditModalVisible(false);
      } catch (error) {
        console.error(error);
      } finally {
        setBusyModal(false);
      }
    });
  };

  //#region "HANDLERS - EDITING"

  const handleOnMouseOver = () => {
    if (currentUserId !== item.user_id) return;
    if (!editingSeconds || editingSeconds < 1) return;
    if (isEditing) return;
    const diff = dayjs().diff(dayjs(item.created_at), 'seconds');
    if (diff > editingSeconds) return;
    setEditingAllowed(true);
  };

  const handleOnMouseLeave = () => {
    setEditingAllowed(false);
  };

  const handleOnEditClick = () => {
    setNewValue(item?.text?.replace(/<br \/>/g, '\n') ?? '');
    setIsEditing(true);
    setEditingAllowed(false);
  };

  const handleOnCancelClick = () => {
    setIsEditing(false);
  };

  const handleOnSaveClick = () =>
    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        setIsEditing(false);
        const copy = [...currentTicketConversation];
        const index = copy.findIndex((conv) => conv.id === item.id);
        if (index !== -1) {
          copy[index] = { ...currentTicketConversation[index], isPosting: true };
          setCurrentTicketConversation(copy);
        }
        messageApi.open({
          key,
          type: 'loading',
          content: 'Saving...',
          duration: 3,
        });
        await patchTicketConversation(item.ticket_id, item.id, { text: newValue });
        messageApi.open({
          key,
          type: 'success',
          content: 'Done!',
          duration: 3,
        });
        setEditModalVisible(false);
      } catch (error) {
        console.error(error);
        messageApi.destroy(key);
      }
    });

  const handleNewValueOnChange = (e) => {
    setNewValue(e.target.value);
  };

  const handleOnTimeout = () => {
    setIsEditing(false);
  };

  const isEdited = dayjs(item.updated_at).diff(dayjs(item.created_at), 'seconds') > 1;

  const isDeleted = isEdited && false === notEmptyString(item.text);

  //#endregion

  const applyStyleForMsg = (item, side) => {
    let style = '';
    if (item?.props?.is_rejectedMsg) {
      style = 'text-white bg-red-600 dark:border-red-600 opacity-80';
    } else if (item?.props?.is_reviewMsg) {
      style = 'text-white bg-yellow-600 dark:border-yellow-600 opacity-90';
    } else if (item.visibility === 'internal') {
      style = 'text-white bg-gray-500 dark:border-gray-500';
    } else {
      style = 'text-white bg-blue-600 dark:border-blue-700';
    }
    // if (style === '') {
    //   if (side === 'right') style = 'text-white bg-blue-600 dark:border-blue-700';
    //   else style = 'text-black  bg-gray-100 border-gray-200 dark:text-white dark:bg-zinc-800 dark:border-zinc-800';
    // }
    return style;
  };

  const replaceMentionsWithStrong = (text) => text.replace(/\[@([^\]]+)\]/g, '<strong>@$1</strong>');

  return (
    <StylesWrapper>
      {contextHolder}
      {editModalVisible && <EditItemModal title='Ticket summary generated by AI' item={item} isVisible={editModalVisible} onClose={handleModalClose} onSave={handleOnSave} busy={busyModal} />}
      <div className={`m-4 ${item.isPosting ? 'conv-history-item-posting animate-pulse' : item.isNew ? `conv-history-item-new conv-history-item-new-${side}` : null}`}>
        <div className='px-11 pb-1'>
          <div className={`flex gap-2 items-center text-xs ${'right' === side ? 'flex-row-reverse' : 'flex-row'}`}>
            <div>
              <span className='opacity-60 font-light'>{dayjs(item?.created_at).fromNow()}</span> <span className='opacity-80'>by {item?.user_name ?? 'Unknown user'}:</span>
            </div>
            {item?.props?.is_summary &&
              (item.visibility === 'internal' ? (
                <Tooltip title='Click to edit'>
                  <Tag className='bg-teal-600' onClick={handleEditClick}>
                    <PiShootingStarDuotone className='pt-1 scale-125' /> Summary
                  </Tag>
                </Tooltip>
              ) : (
                <Tag className='bg-teal-600 text-left'>
                  <PiShootingStarDuotone className='pt-1 scale-125' /> Summary
                </Tag>
              ))}
            {item.visibility === 'internal' && (
              <Tag color='orange' style={{ margin: 0, fontSize: '0.8rem' }}>
                internal
              </Tag>
            )}
          </div>
        </div>
        <div className={`flex  ${'right' === side ? 'flex-row-reverse' : 'flex-row'} gap-2`}>
          <div className='w-9 text-center'>
            <UserImage image={item?.user_pic} size={'32px'} />
          </div>
          <div className={`relative ${isEditing ? 'w-[85%]' : 'max-w-[85%]'}`} onMouseOver={handleOnMouseOver} onMouseLeave={handleOnMouseLeave}>
            {editingAllowed && !isEditing && (
              <Tooltip title='Click to edit'>
                <div className='cursor-pointer opacity-80 hover:opacity-100 absolute top-[-8px] right-[-8px]' style={{ padding: '2px', backgroundColor: theme.backgroundMedium, borderRadius: '0.25rem', color: theme.textBase }} onClick={handleOnEditClick}>
                  <MdEdit />
                </div>
              </Tooltip>
            )}
            {isEditing ? (
              <div className='w-full max-w-full'>
                <div>
                  <Input.TextArea className='py-8 px-4' value={newValue} onChange={handleNewValueOnChange} autoSize={{ minRows: 1, maxRows: 15 }} maxLength={5000} showCount />
                </div>
                <Space className='my-2'>
                  <Button size='small' type='primary' onClick={handleOnSaveClick}>
                    <Space2>
                      Save
                      <div className='text-xs opacity-60 font-light'>
                        <Space2 className='gap-1'>
                          <FaRegClock />
                          <SecondsCountDown timeoutAt={dayjs(item.created_at).add(editingSeconds, 'second')} onTimeout={() => handleOnTimeout()} />
                          <span>seconds left</span>
                        </Space2>
                      </div>
                    </Space2>
                  </Button>
                  <Button size='small' onClick={handleOnCancelClick}>
                    Cancel
                  </Button>
                </Space>
              </div>
            ) : (
              <div className={`${applyStyleForMsg(item, side)} py-2 px-4 conv-history-item-${side} max-w-full border border-solid content-center`} dangerouslySetInnerHTML={{ __html: !isDeleted ? replaceMentionsWithStrong(item?.text) : '<span class="conv-history-deleted-msg">this message was deleted</span>' }} />
            )}
            {isEdited && !isDeleted && <div className={`conv-history-edited-msg-${side}`}>this message was edited</div>}
          </div>
        </div>
      </div>
    </StylesWrapper>
  );
};

ConversationHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  side: PropTypes.string.isRequired,
};

ConversationHistoryItem.defaultProps = {
  side: 'left',
};

export default ConversationHistoryItem;

import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import useUserInfo from 'src/hooks/useUserInfo';
import { getTicket, getServicesIsMaster, getTicketMembers } from 'src/api/teacket';
import PropTypes from 'prop-types';
import { useInterval } from 'react-interval-hook';
import { subscribe } from 'src/api/subscriptions';
import { notEmptyArray, notArrayOrEmpty } from 'src/misc/Misc';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import { message } from 'antd';
import { uuid } from 'short-uuid';
import { getCurrentTicketReadOnlyWidgetList } from 'src/components/pages/Teacket/Misc/ticketLayout';
import dayjs from 'dayjs';

const LoaderCurrentTicket = ({ ticketId, children }) => {
  const { resetCurrentTicket, currentTicket, updateCurrentTicket, setCurrentTicket, setActiveViewers, currentTicketMembers, setCurrentTicketMembers, setCurrentTicketReadOnly, getCurrentTicketService, setCurrentTicketOppDetails, getWidgetListForCurrentTicket, setCurrentTicketReadOnlyWidgetList, servicesForTenant } = useContext(TeacketContext);
  const navigate = useNavigate();
  const { id: userId, permissions: userPermissions } = useUserInfo();
  const [messageApi, contextHolder] = message.useMessage();
  const [currentTicketService, setCurrentTicketService] = useState(null);

  const [showNewTicketMsg, setShowNewTicketMsg] = useState(false);

  useEffect(() => {
    if (!ticketId) return;
    // if (!currentTicket) loadData();
    loadData();
    return () => {
      resetCurrentTicket();
    };
  }, [ticketId]);

  //#region "useEffect - READ ONLY WIDGETS"
  useEffect(() => {
    if (!currentTicket?.service_id) return;

    const readOnlyWidgetList = getCurrentTicketReadOnlyWidgetList(userPermissions, getWidgetListForCurrentTicket(), currentTicket?.service_id, currentTicket?.status, servicesForTenant, userId, currentTicketMembers);
    console.log('readOnlyWidgetList:', readOnlyWidgetList);
    setCurrentTicketReadOnlyWidgetList(readOnlyWidgetList);
  }, [currentTicket?.service_id, currentTicket?.status, currentTicketMembers]);
  //#endregion

  //#region "useEffect - WHOLE TICKET READ ONLY"
  useEffect(() => {
    //console.log('currentTicket:', currentTicket);
    if (!currentTicket?.status || !currentTicketMembers) return;

    if (notEmptyArray(userPermissions) && userPermissions.includes(MASTER_ADMIN_PERMISSION)) {
      setCurrentTicketReadOnly(false);
      return;
    }

    if (notEmptyArray(userPermissions) && userPermissions.includes('apps.teacket.list.view')) {
      // superuser / supervisor
      setCurrentTicketReadOnly(false);
      return;
    }

    if (true === isCurrentTicketClosed()) {
      setCurrentTicketReadOnly(true);
      return;
    }

    if (true === notArrayOrEmpty(currentTicketMembers)) {
      setCurrentTicketReadOnly(true);
      return;
    }

    if (!currentTicketMembers.some((x) => x.user_id === userId)) {
      setCurrentTicketReadOnly(true);
      return;
    }

    setCurrentTicketReadOnly(false);
  }, [currentTicket?.status, currentTicketMembers, currentTicketService]);
  //#endregion

  useEffect(() => {
    if (!currentTicket?.created_at) return;
    if (dayjs().diff(currentTicket.created_at, 'second') < 20) {
      setShowNewTicketMsg(true);
    }
  }, [currentTicket?.created_at]);

  useEffect(() => {
    if (!showNewTicketMsg) return;
    setShowNewTicketMsg(false);
    messageApi.success({
      content: 'Thank you for submitting the ticket. We will get back to you shortly.',
      duration: 10,
    });
  }, [showNewTicketMsg]);

  useInterval(async () => {
    await subscribeToTicket();
  }, 10000);

  const subscribeToTicket = async () => {
    const viewers = await subscribe('ticket', ticketId);
    setActiveViewers(viewers);
  };

  const isCurrentTicketClosed = () => {
    if (!currentTicket) return false;
    if (!currentTicketService) return false;
    const { statusList } = currentTicketService?.props ?? {};
    const status = statusList?.find((x) => x.status === currentTicket.status);
    console.log('status:', status, status?.isClosed === true);
    return status?.isClosed === true;
  };

  const loadData = async () => {
    try {
      const ticket = await getTicket(ticketId);
      console.log('ticket:', ticket);

      const { isMaster } = await getServicesIsMaster(ticket.service_id);
      console.log('isMaster:', isMaster);

      setCurrentTicket(ticket, isMaster);

      const result2 = await getTicketMembers(ticketId);
      setCurrentTicketMembers(result2);

      await subscribe('ticket', ticketId);

      setCurrentTicketService(getCurrentTicketService());

      // Check if there are Stakeholder details to save
      if (ticket.opp_id) {
        setCurrentTicketOppDetails(ticket?.props);
      }
    } catch (error) {
      console.error(error);
      if (error?.toString()?.includes('404')) {
        navigate('/404');
      }
    }
  };

  // <TODO: move this handler to "more global" place - maybe to LoaderCurrentTicket or create a dedicated component? - this component is responsible for displaying part of the ticket data only>
  const handleOnWsMessageReceived = async (message) => {
    try {
      if (message?.data?.ticket_id !== currentTicket?.id) return;
      console.log('[TicketProperties] TICKET_UPDATED:', message);

      if (!message?.data?.details) return;
      message.data.details.updated_at = new Date();

      console.log('New Opp Id (if any)', message.data.details.opp_id);
      console.log('Current Opp Id', currentTicket.opp_id);

      // Check if there are Stakeholder details to save
      if (message.data.details.opp_id && message.data.details.props && 'OPPORTUNITY_AGENTS' in message.data.details.props) {
        // Check at least if the Opportunity URL is present - usually it is the case
        console.log('Saving New Stakeholder details:', message.data.details.props);

        setCurrentTicketOppDetails(message.data.details.props);
      } else if (!message.data.details.opp_id && message.data.details.acc_id) {
        console.log('Clearing Stakeholder details');
        setCurrentTicketOppDetails(null);
      }

      updateCurrentTicket(message.data.details);

      let msg = `${Object.keys(message.data.details)[0]}`;
      msg = msg.charAt(0).toUpperCase() + msg.slice(1);
      messageApi.open({
        key: uuid(),
        type: 'success',
        content: `${msg} updated.`,
        duration: 3,
      });
    } catch (error) {
      console.error(error);
    }
  };
  // </TODO: move this handler to "more global" place - maybe to LoaderCurrentTicket or create a dedicated component?>

  if (!currentTicket)
    return (
      <>
        <FullScreenSpin title='Please wait' subtitle='Loading ticket' />;
      </>
    );

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_UPDATED']}></GlobalWsMessenger>
      {contextHolder}
      {children}
    </>
  );
};

LoaderCurrentTicket.propTypes = {
  ticketId: PropTypes.string,
  children: PropTypes.node,
};

export default LoaderCurrentTicket;

import React, { useState } from 'react';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import Space2 from 'src/components/layout/Space2';
import StyledButton from 'src/components/layout/StyledButton';
import { GiEntryDoor } from 'react-icons/gi';
import { GrDocument } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
// import { ReactComponent as GenesysLogo } from 'src/components/svg/genesys-logo.svg';
import useTheme from 'src/hooks/useTheme';
import { FaTimes } from 'react-icons/fa';
//import { FaStar } from 'react-icons/fa6';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { ReactComponent as SpcWelcome2 } from 'src/components/svg/spc-welcome-2.svg';
import CorporateColors from 'src/misc/CorporateColors';

const SectionWelcome = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [showWelcome, setShowWelcome] = useState(localStorage.getItem(LOCAL_STORAGE_KEYS.showWelcome) !== 'false');

  const toggleShowWelcome = () => {
    const newShowWelcome = !showWelcome;
    setShowWelcome(newShowWelcome);
    localStorage.setItem(LOCAL_STORAGE_KEYS.showWelcome, newShowWelcome ? 'true' : 'false');
  };

  if (!showWelcome)
    return (
      <div className='relative rounded-2xl shadow-md cursor-pointer' onClick={toggleShowWelcome}>
        <div className={'relative safari-backdrop-blur bg-white/70 dark:bg-zinc-900/70 rounded-2xl border border-solid border-zinc-300 dark:border-zinc-700'}>
          <div className='flex flex-row gap-4 p-4 lg:p-4'>
            <WhenDesktop>
              <div className='ml-4'>
                <SpcWelcome2 className='h-[42px] transition-transform duration-300 hover:scale-105' style={{ filter: `drop-shadow(0px 0px 8px ${CorporateColors.orange + '77'})` }} />
                {/* <GenesysLogo style={{ height: '42px', width: '27px' }} className='text-genesys-orange-base ml-4' /> */}
              </div>
            </WhenDesktop>
            <div className='text-4xl flex flex-col justify-center'>
              <div>
                <span className='font-thin opacity-60'>Welcome to the</span> <span className='font-bold font-roboto'>SPC</span> <span className='opacity-60 text-2xl font-roboto'>Portal</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className='relative rounded-2xl shadow-md'>
      <div className='absolute top-0 left-0 right-0 bottom-0 z-0 overflow-hidden flex flex-col items-center justify-center rounded-2xl'>
        <img src='/images/misc/threads-4.png' className='w-full z-0' alt='Threads' />
      </div>
      <div className={'relative safari-backdrop-blur bg-white/70 dark:dark:bg-zinc-900/70  rounded-2xl border border-solid border-zinc-300 dark:border-zinc-700'}>
        <FaTimes className='absolute right-4 top-4 text-2xl cursor-pointer opacity-60 hover:opacity-100 hover:scale-105 transition-all' onClick={toggleShowWelcome} />
        <div className='flex flex-row gap-8 p-4 lg:p-6'>
          <WhenDesktop>
            <div>
              <SpcWelcome2 className='h-[140px] transition-transform duration-300 hover:scale-105' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.orange + '77'})` }} />
              {/* <GenesysLogo style={{ height: '84px', width: '54px' }} className='text-genesys-orange-base ml-4' /> */}
            </div>
          </WhenDesktop>
          <div className='flex flex-col justify-center '>
            <div>
              <div className='text-4xl mb-4'>
                <span className='font-thin opacity-60'>Welcome to the</span> <span className='font-bold font-roboto'>SPC</span> <span className='opacity-60 text-2xl font-roboto'>Portal</span>
              </div>

              <p className='text-lg mb-2'>A gateway to Genesys Cloud security, privacy, and compliance experience.</p>
              <p className='text-lg mb-2'>Created by the Genesys COE Security, Privacy & Compliance team, our portal is designed to empower you with the resources and tools you need to navigate the complex landscape of security and compliance with confidence.</p>
              <p className='text-lg mb-2'>Here, you'll find a comprehensive collection of documents and cutting-edge tools tailored to enhance your Genesys Cloud journey.</p>
              <PermissionsProvider requiredPermissions={['apps.securityadvisor.view']} isControl>
                <p className='text-lg mb-2'>
                  Whether you're leveraging the <strong className='text-genesys-navy-base dark:text-genesys-orange-200'>Security Advisor</strong> to proactively scan your organization for potential security vulnerabilities, our portal is your trusted partner in safeguarding your digital environment.
                </p>
              </PermissionsProvider>
              <PermissionsProvider requiredPermissions={['apps.auditviewer.view', 'apps.datasubjectrequests.view']} isControl>
                <p className='text-lg mb-2'>
                  Explore the <strong className='text-genesys-navy-base dark:text-genesys-orange-200'>Audit Viewer</strong> to gain insights from your audit logs, or manage GDPR data subject requests seamlessly with our intuitive <strong className='text-genesys-navy-base dark:text-genesys-orange-200'>Data Subject Requests</strong> interface.
                </p>
              </PermissionsProvider>
              <PermissionsProvider requiredPermissions={['apps.securityadvisor.view', 'apps.auditviewer.view', 'apps.datasubjectrequests.view']} isControl>
                <p className='text-lg mb-2'>Our suite of tools is crafted to support your compliance needs, ensuring you stay ahead in a rapidly evolving digital world. We are thrilled to have you on board and are committed to providing you with a modern, user-friendly experience that reflects the innovative spirit of a startup.</p>
              </PermissionsProvider>
              <p className='text-lg'>Welcome to the future of security, privacy, and compliance with Genesys Cloud. Scroll down to find out more.</p>

              <Space2>
                <PermissionsProvider requiredPermissions={['admin.tenant']} isControl>
                  <StyledButton color={theme.textBase} size='large' onClick={() => window.open('/Products/getting-started', '_blank')}>
                    <Space2>
                      <GrDocument />
                      <WhenDesktop>Getting Started</WhenDesktop>
                    </Space2>
                  </StyledButton>
                </PermissionsProvider>
                <PermissionsProvider requiredPermissions={['admin.tenant']} isControl>
                  <StyledButton color={theme.textBase} size='large' onClick={() => navigate('/master-admin/invitation-links?invite=true')}>
                    <Space2>
                      <GiEntryDoor />
                      <WhenDesktop>Invite co-workers</WhenDesktop>
                    </Space2>
                  </StyledButton>
                </PermissionsProvider>
                {/* <PermissionsProvider requiredPermissions={['apps.teacket.spcportal.categories.requestfullaccess']} isControl>
                  <StyledButton color={theme.textBase} size='large' onClick={() => navigate('/teacket/full-access-request-form')}>
                    <Space2>
                      <FaStar className='animate-pulse' style={{ color: CorporateColors.orange }} />
                      <WhenDesktop>
                        <span>
                          <span className='font-bold'>Full Access</span> <span className='font-light'>Request</span>
                        </span>
                      </WhenDesktop>
                    </Space2>
                  </StyledButton>
                </PermissionsProvider> */}
              </Space2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionWelcome.propTypes = {};

export default SectionWelcome;

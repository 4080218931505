import React from 'react';
import PropTypes from 'prop-types';
import { DrawIoEmbed } from 'react-drawio';

const DrawIoViewer = ({ xml }) => {
  return (
    <DrawIoEmbed
      xml={xml}
      urlParameters={{
        lightbox: true,
      }}
      // configuration={{}}
    />
  );
};

DrawIoViewer.propTypes = {
  xml: PropTypes.string,
};

export default DrawIoViewer;

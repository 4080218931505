import { DatePicker, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const { RangePicker } = DatePicker;

const RangePickerDayJs = ({ size, showDropdown, startDate, endDate, disabled, onChange, predefinedToday, predefinedYesterday, predefinedQuarter, predefinedMonth, predefined30days, predefinedWeek, predefinedWeekBefore, predefined7days, width = '310px' }) => {
  const [browserTimeZone, setBrowserTimeZone] = useState('UTC');

  useEffect(() => {
    const tz = getBrowserTimeZone();
    setBrowserTimeZone(tz);
  }, []);

  const predefinedIntervalListOnClick = (e) => {
    if (!e || !e.key) {
      return;
    }

    const interval = getDatesForPredefinedInterval(e.key, browserTimeZone);
    if (onChange) onChange({ startDate: dayjs(interval[0]).startOf('day'), endDate: dayjs(interval[1]).endOf('day'), predefinedInterval: e.key }, false);
  };

  const menuItems = [
    predefinedToday && {
      label: 'Today',
      key: 'today',
    },
    predefinedYesterday && {
      label: 'Yesterday',
      key: 'yesterday',
    },
    predefined7days && {
      label: 'Past 7 Days',
      key: 'past-7-days',
    },
    predefinedWeek && {
      label: 'This week to date',
      key: 'week-to-date',
    },
    predefinedWeek && {
      label: 'Last Week',
      key: 'last-week',
    },
    predefinedWeekBefore && {
      label: '1 week before last',
      key: 'last-week-before-1',
    },
    predefinedWeekBefore && {
      label: '2 weeks before last',
      key: 'last-week-before-2',
    },
    predefinedWeekBefore && {
      label: '3 weeks before last',
      key: 'last-week-before-3',
    },
    predefined30days && {
      label: 'Past 30 Days',
      key: 'past-30-days',
    },
    predefinedMonth && {
      label: 'This month to date',
      key: 'month-to-date',
    },
    predefinedMonth && {
      label: 'Last month',
      key: 'last-month',
    },
    predefinedQuarter && {
      label: 'This quarter to date',
      key: 'quarter-to-date',
    },
    predefinedQuarter && {
      label: 'Last quarter',
      key: 'last-quarter',
    },
  ].filter(Boolean); // Remove falsy values

  const menuProps = {
    items: menuItems,
    onClick: predefinedIntervalListOnClick,
  };

  const getDatesForPredefinedInterval = (predefinedInterval, timezone) => {
    let interval = null;
    const currentDate = dayjs().tz(timezone);

    // Genesys quarters start in February, May, August, November
    const getAdjustedQuarterStart = (date) => date.subtract(1, 'months').startOf('quarter').add(1, 'months');
    const getAdjustedQuarterEnd = (date) => date.subtract(1, 'months').endOf('quarter').add(1, 'months');

    switch (predefinedInterval) {
      case 'today':
        interval = [currentDate.startOf('day').format(), currentDate.endOf('day').format()];
        break;
      case 'yesterday':
        interval = [currentDate.subtract(1, 'days').startOf('day').format(), currentDate.subtract(1, 'days').endOf('day').format()];
        break;
      case 'past-7-days':
        interval = [currentDate.subtract(7, 'days').startOf('day').format(), currentDate.subtract(1, 'days').endOf('day').format()];
        break;
      case 'week-to-date':
        interval = [currentDate.startOf('isoWeek').format(), currentDate.endOf('day').format()];
        break;
      case 'last-week':
        interval = [currentDate.subtract(1, 'weeks').startOf('isoWeek').format(), currentDate.subtract(1, 'weeks').endOf('isoWeek').format()];
        break;
      case 'last-week-before-1':
        interval = [currentDate.subtract(2, 'weeks').startOf('isoWeek').format(), currentDate.subtract(2, 'weeks').endOf('isoWeek').format()];
        break;
      case 'last-week-before-2':
        interval = [currentDate.subtract(3, 'weeks').startOf('isoWeek').format(), currentDate.subtract(3, 'weeks').endOf('isoWeek').format()];
        break;
      case 'last-week-before-3':
        interval = [currentDate.subtract(4, 'weeks').startOf('isoWeek').format(), currentDate.subtract(4, 'weeks').endOf('isoWeek').format()];
        break;
      case 'past-30-days':
        interval = [currentDate.subtract(30, 'days').startOf('day').format(), currentDate.endOf('day').format()];
        break;
      case 'month-to-date':
        interval = [currentDate.startOf('month').format(), currentDate.endOf('day').format()];
        break;
      case 'last-month':
        interval = [currentDate.subtract(1, 'months').startOf('month').format(), currentDate.subtract(1, 'months').endOf('month').format()];
        break;
      case 'quarter-to-date':
        if (currentDate.isBefore(getAdjustedQuarterEnd(currentDate))) {
          interval = [getAdjustedQuarterStart(currentDate).add(1, 'days').format(), currentDate.endOf('day').format()];
        } else {
          interval = [getAdjustedQuarterStart(currentDate.subtract(1, 'quarters')).format(), getAdjustedQuarterEnd(currentDate.subtract(1, 'quarters')).format()];
        }
        break;
      case 'last-quarter':
        interval = [getAdjustedQuarterStart(currentDate.subtract(1, 'quarters')).format(), getAdjustedQuarterEnd(currentDate.subtract(1, 'quarters')).format()];
        break;
      default:
        throw new Error(`Unknown interval: ${predefinedInterval}`);
    }
    return interval;
  };

  const disabledDate = (current) => {
    // Disable dates before 2 years ago from today and future dates
    return current && (current < dayjs().subtract(2, 'years').startOf('day') || current > dayjs().endOf('day'));
  };

  return (
    <div style={{ width }} className='flex flex-row items-center overflow-hidden'>
      <div className='flex-grow'>
        <RangePicker
          style={{ width: '100%' }}
          disabled={disabled}
          value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
          onChange={(e) => {
            if (!Array.isArray(e) || e.length !== 2) {
              if (onChange) onChange({ startDate: null, endDate: null });
              return;
            }
            if (onChange) onChange({ startDate: e[0].startOf('day'), endDate: e[1].endOf('day') }, true);
          }}
          size={size}
          disabledDate={disabledDate}
        />
      </div>
      {showDropdown && (
        <Dropdown disabled={disabled} menu={menuProps} trigger={['click']}>
          <Button type='link' className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

RangePickerDayJs.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  predefinedToday: PropTypes.bool,
  predefinedYesterday: PropTypes.bool,
  predefinedQuarter: PropTypes.bool,
  predefinedMonth: PropTypes.bool,
  predefined30days: PropTypes.bool,
  predefinedWeek: PropTypes.bool,
  predefinedWeekBefore: PropTypes.bool,
  predefined7days: PropTypes.bool,
  showDropdown: PropTypes.bool,
  size: PropTypes.string,
  width: PropTypes.string,
};

RangePickerDayJs.defaultProps = {
  startDate: null,
  endDate: null,
  disabled: false,
  onChange: () => {
    console.warn('OnChange() function not provided');
  },
  predefinedQuarter: true,
  predefinedMonth: true,
  predefinedToday: true,
  predefinedYesterday: true,
  predefined30days: true,
  predefinedWeek: true,
  predefinedWeekBefore: false,
  predefined7days: true,
  showDropdown: true,
};

export default RangePickerDayJs;

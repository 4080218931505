import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

export const getAll = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postSubscriber = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections/${id}/subscribers`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const deleteSubscriber = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections/${id}/subscribers`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getSections = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getSection = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections/${id}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postSection = async (body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const pachSection = async (id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const deleteSection = async (id, body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections/${id}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postItem = async (id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections/${id}/items`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchItem = async (id, itemId, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections/${id}/items/${itemId}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const deleteItem = async (id, itemId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/sections/${id}/items/${itemId}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getHistory = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/download/history`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal: AbortSignal.timeout(30000),
    })
  );
};

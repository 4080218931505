import React from 'react';
import PropTypes from 'prop-types';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { ReactComponent as SpcGuidelines } from 'src/components/svg/spc-guidelines.svg';
import CorporateColors from 'src/misc/CorporateColors';

const BoxType2 = ({ children }) => {
  return (
    <div className='relative rounded-2xl shadow-md'>
      <div className='absolute top-0 left-0 right-0 bottom-0 z-0 overflow-hidden flex flex-col items-center justify-center rounded-2xl'>
        <img src='/images/misc/threads-4.png' className='w-full z-0' alt='Threads' />
      </div>
      <div className={'relative safari-backdrop-blur bg-white/70 dark:dark:bg-zinc-900/70  rounded-2xl border border-solid border-zinc-300 dark:border-zinc-700'}>
        <div className='flex flex-row gap-8 p-4 lg:p-6'>
          <WhenDesktop>
            <div>
              <SpcGuidelines className='h-[140px] transition-transform duration-300 hover:scale-105' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.orange + '77'})` }} />
              {/* <GenesysLogo style={{ height: '84px', width: '54px' }} className='text-genesys-orange-base ml-4' /> */}
            </div>
          </WhenDesktop>
          <div className='flex flex-col justify-center '>{children}</div>
        </div>
      </div>
    </div>
  );
};

BoxType2.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  extras1: PropTypes.node,
  extras2: PropTypes.node,
  collapsible: PropTypes.string,
};

export default BoxType2;

import React, { useContext, useEffect, useState, useRef } from 'react';
//import PropTypes from 'prop-types';
import { Space, Input, Button, Form, Switch } from 'antd';
import { postTicketConversation } from 'src/api/teacket';
import useUserInfo from 'src/hooks/useUserInfo';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TeacketContext from 'src/context/TeacketContext';
//import { hasMasterAdminRights, hasTicketAdminRights, hasSupervisorRights } from '../Misc/misc';
import ConversationPickMember from './ConversationPickMember';
import { FaWindowClose } from 'react-icons/fa';
import { sleepMs } from 'src/misc/Misc';

//import PropTypes from 'prop-types';

const ConversationNewMessage = () => {
  const [postMessageForm] = Form.useForm();
  const ref = useRef(null);
  const { executeAsyncProcess } = useAsyncProcesses();
  const userInfo = useUserInfo();
  const { currentTicket, currentTicketMembers, addCurrentTicketConversationMessage, currentTicketIsMasterTenant, determineIsWidgetReadOnly, hideInternalMessageButton } = useContext(TeacketContext);

  const [showInternalMsgButton, setShowInternalMsgButton] = useState(false);
  const [showPickeMember, setShowPickeMember] = useState(false);

  const messageValue = Form.useWatch('message', postMessageForm);

  useEffect(() => {
    if (!currentTicket || !currentTicketMembers) {
      return;
    }

    if (currentTicketIsMasterTenant) {
      const hideStatus = hideInternalMessageButton(userInfo);
      setShowInternalMsgButton(!hideStatus);
    } else {
      setShowInternalMsgButton(false);
    }
  }, [currentTicketMembers]);

  const handleFormOnFinish = async (values) => {
    console.log('Success:', values);

    executeAsyncProcess(async () => {
      try {
        const userId = userInfo.id;
        const text = values.message.replace(/\n/g, '<br />');
        const visibility = values.isPublic ? 'external' : 'internal';
        let newMessage = { userId, text, visibility, is_master_tenant: true === currentTicketIsMasterTenant };
        addCurrentTicketConversationMessage({ ...newMessage, isPosting: true, user_name: userInfo.name, user_pic: userInfo.user_pic, user_id: userInfo.id });
        postMessageForm.resetFields();
        delete newMessage.owner;
        let confirmedMessage = await postTicketConversation(currentTicket.id, newMessage);
        addCurrentTicketConversationMessage({ ...confirmedMessage, isNew: true, user_name: userInfo.name, user_pic: userInfo.user_pic, is_master_tenant: true === currentTicketIsMasterTenant });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleFormOnFailure = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOnKeyPress = async (e) => {
    await sleepMs(10);
    if (e.charCode === 64) setShowPickeMember(true);
  };

  const handleOnClose = async () => {
    setShowPickeMember(false);
    await sleepMs(10);
    ref.current && ref.current.focus();
  };

  const handleOnPick = async (value) => {
    setShowPickeMember(false);
    await sleepMs(10);

    if (ref.current) {
      const textarea = ref.current.resizableTextArea.textArea;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const textBefore = textarea.value.substring(0, startPos);
      const textAfter = textarea.value.substring(endPos, textarea.value.length);
      const textToAdd = `[@${value}] `;
      const newValue = textBefore.slice(0, -1) + textToAdd + textAfter;
      postMessageForm.setFieldsValue({ message: newValue });
      textarea.focus();
      await sleepMs(10);
      const newCursorPos = startPos + textToAdd.length - 1;
      textarea.setSelectionRange(newCursorPos, newCursorPos);
    }
  };

  return (
    <div className='relative mt-4 z-0'>
      {showPickeMember && (
        <div className='absolute top-8 left-4 right-4 z-10'>
          <div className='absolute top-[-18px] right-0'>
            <FaWindowClose className='text-red-500 opacity-60 hover:opacity-100 cursor-pointer' onClick={handleOnClose} />
          </div>
          <div className='relative'>
            <ConversationPickMember onCancel={handleOnClose} onPick={handleOnPick} />
          </div>
        </div>
      )}

      <Form form={postMessageForm} name='conversation-new-message' initialValues={{ remember: true }} onFinish={handleFormOnFinish} onFinishFailed={handleFormOnFailure} autoComplete='off' layout='vertical'>
        <Form.Item label='New message' name='message' rules={[{ required: true, message: "Message can't be empty" }]} initialValue={''} onKeyPress={handleOnKeyPress}>
          <Input.TextArea placeholder='Enter a message...' rows={4} maxLength={5000} showCount disabled={true === determineIsWidgetReadOnly('Conversation') || showPickeMember} ref={ref} />
        </Form.Item>
        <div className='flex flex-row gap-2'>
          <div>
            <Form.Item>
              <Space>
                <Button htmlType='reset' disabled={true === determineIsWidgetReadOnly('Conversation') || !messageValue}>
                  Clear Text
                </Button>
                <Button type='primary' htmlType='submit' disabled={true === determineIsWidgetReadOnly('Conversation')}>
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </div>
          <div className={`${true === determineIsWidgetReadOnly('Conversation') || showInternalMsgButton !== true ? 'hidden' : ''}`}>
            <Form.Item name='isPublic' valuePropName='checked' initialValue={true}>
              <Switch unCheckedChildren='Internal' checkedChildren='Public' disabled={true === determineIsWidgetReadOnly('Conversation') || showInternalMsgButton !== true} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

//ConversationNewMessage.propTypes = {};

export default ConversationNewMessage;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import './GridLayout.css';
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import useTheme from 'src/hooks/useTheme';
import { Tooltip } from 'antd';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayout = (props) => {
  const { theme } = useTheme();

  //#region Use States

  const [layouts, setLayouts] = useState(null);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const [compactType, setCompactType] = useState('vertical');
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState({
    lg: [],
  });
  const [previousHiddenIds, setPreviousHiddenIds] = useState([]);

  //#endregion

  //#region Use Effects

  useEffect(() => {
    if (props.forceDefaultLayout) {
      const initialLayouts = generateInitialLayouts();
      console.log('Forcing default layouts:', initialLayouts);
      setLayouts(initialLayouts);
      return;
    }

    // Load layouts from localStorage on mount
    const savedLayouts = loadLayouts();
    if (savedLayouts && Object.keys(savedLayouts).length > 0) {
      console.log('Setting layouts from localStorage:', savedLayouts);
      setLayouts(savedLayouts);
    } else {
      // Initialize with default layouts if none saved
      const initialLayouts = generateInitialLayouts();
      console.log('Setting initial layouts:', initialLayouts);
      setLayouts(initialLayouts);
    }

    setMounted(true);
  }, []);

  // Detect newly shown components
  useEffect(() => {
    if (!mounted || !layouts) return;

    const currentHiddenIds = props.hiddenComponentIds || [];
    const newlyShownIds = previousHiddenIds.filter((id) => !currentHiddenIds.includes(id));

    if (newlyShownIds.length > 0) {
      console.log('Newly shown components:', newlyShownIds);
      // Use existing layouts - no need to recreate them as they contain the positions of both visible and hidden components
    }

    setPreviousHiddenIds(currentHiddenIds);
  }, [props.hiddenComponentIds, mounted, layouts]);

  //#endregion

  //#region Local Storage

  const loadLayouts = () => {
    try {
      const savedLayouts = localStorage.getItem(LOCAL_STORAGE_KEYS.layoutDataNexusHome);
      const parsedLayouts = savedLayouts ? JSON.parse(savedLayouts) : {};
      console.log('Layouts loaded from localStorage', parsedLayouts);
      return parsedLayouts;
    } catch (err) {
      console.error('Failed to load layouts from localStorage', err);
      return {};
    }
  };

  const saveLayouts = (layouts) => {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEYS.layoutDataNexusHome, JSON.stringify(layouts));
      console.log('Layouts saved to localStorage', layouts);
    } catch (err) {
      console.error('Failed to save layouts to localStorage', err);
    }
  };

  //#endregion

  //#region Handlers

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onCompactTypeChange = () => {
    const oldCompactType = compactType;
    const newCompactType = oldCompactType === 'horizontal' ? 'vertical' : oldCompactType === 'vertical' ? null : 'horizontal';
    setCompactType(newCompactType);
  };

  const onLayoutChange = (layout, layouts) => {
    // Create a merged layout that preserves hidden components' positions
    const mergedLayouts = {};

    // Get all breakpoints
    const breakpoints = Object.keys(layouts);

    // For each breakpoint
    breakpoints.forEach((bp) => {
      // Get existing items in the current layout
      const currentItems = layouts[bp];
      const currentItemsIds = currentItems.map((item) => item.i);

      // Start with current visible items
      mergedLayouts[bp] = [...currentItems];

      // For hidden components, if they existed in previous layouts, preserve their position
      if (props.hiddenComponentIds?.length > 0) {
        // Get the previous saved layouts
        const previousLayouts = loadLayouts();
        const previousLayoutForBreakpoint = previousLayouts[bp] || [];

        // Find hidden items in previous layout and add them to merged layout
        const hiddenItemsFromPrevious = previousLayoutForBreakpoint.filter((item) => props.hiddenComponentIds.includes(item.i) && !currentItemsIds.includes(item.i));

        mergedLayouts[bp] = [...mergedLayouts[bp], ...hiddenItemsFromPrevious];
      }
    });

    setLayouts(mergedLayouts);
    saveLayouts(mergedLayouts);
  };

  //#endregion

  // Generate initial layouts based on props.components
  // Ensure ALL components (visible and hidden) have layouts
  const generateInitialLayouts = () => {
    const breakpoints = ['lg', 'md', 'sm', 'xs', 'xxs'];
    const initialLayouts = {};

    breakpoints.forEach((bp) => {
      initialLayouts[bp] = props.components.map((component) => ({
        ...component.layout,
        i: component.id,
      }));
    });

    return initialLayouts;
  };

  const generateDOM = () => {
    return _.map(props.components, function (l) {
      if (props.hiddenComponentIds?.includes(l.id)) {
        return null;
      }

      // CARD CONTENT
      return (
        <div key={l.id} className={` ${l.static ? 'border-2 border-red-500 dark:border-red-600' : ''} flex flex-col h-full px-2 py-4 gap-4 shadow-lg`} style={{ backgroundColor: theme.backgroundBase, color: theme.textBase, borderRadius: '1rem 1rem 0 1rem' }}>
          {/* DRAG AREA & TOP SECTION */}
          <div className='relative w-full flex flex-row items-center justify-center min-h-[24px]'>
            {/* Align icon to the left */}
            <div className={'grid-item-handle cursor-move absolute left-1 top-1'}>
              <Tooltip title='Move this widget'>
                <PiDotsSixVerticalBold className='text-lg' />
              </Tooltip>
            </div>

            {/* Center content */}
            <div className='flex flex-col items-center gap-1'>
              {l.title && (
                <div className='text-lg font-bold' style={{ lineHeight: '1rem' }}>
                  {l.title}
                </div>
              )}
              {l.subtitle && (
                <div className='text-sm font-light opacity-60' style={{ lineHeight: '1rem' }}>
                  {l.subtitle}
                </div>
              )}
            </div>
          </div>

          <div className='w-full overflow-auto'>{l.component}</div>
        </div>
      );
    });
  };

  // Only render the grid when layouts are available and component is mounted
  if (!layouts || !mounted) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className='m-2'>
        <ResponsiveReactGridLayout
          key={props.components
            .filter((c) => !props.hiddenComponentIds?.includes(c.id))
            .map((c) => c.id)
            .join('-')}
          {...props}
          layouts={layouts}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          measureBeforeMount={false}
          useCSSTransforms={false}
          compactType={compactType}
          preventCollision={!compactType}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          onCompactTypeChange={onCompactTypeChange}
          draggableHandle='.grid-item-handle'
        >
          {generateDOM()}
        </ResponsiveReactGridLayout>
      </div>
    </>
  );
};

GridLayout.propTypes = {
  components: PropTypes.array,
  className: PropTypes.string,
  rowHeight: PropTypes.number,
  onLayoutChange: PropTypes.func,
  cols: PropTypes.object,
  breakpoints: PropTypes.object,
  containerPadding: PropTypes.array,
  forceDefaultLayout: PropTypes.bool,
  hiddenComponentIds: PropTypes.array,
};

GridLayout.defaultProps = {
  className: 'layout',
  rowHeight: 30,
  cols: { lg: 14, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1400, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [0, 0],
};

export default GridLayout;

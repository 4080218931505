import React, { useContext, useEffect } from 'react';
//import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { getTicketWidgetsValues } from 'src/api/teacket';
import CustomFieldDateTime from './TicketCustomProps/CustomFieldDateTime';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import NoData from 'src/components/layout/NoData';
import { message } from 'antd';
import { uuid } from 'short-uuid';
import CustomFieldString from './TicketCustomProps/CustomFieldString';

const TicketCompletionDates = () => {
  const WIDGET_ID = 'Completion Dates';
  const { currentTicket, currentTicketCompletionDates, setCurrentTicketCompletionDates } = useContext(TeacketContext);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!currentTicket) return;
    loadData();
  }, [currentTicket?.id]);

  const loadData = async () => {
    try {
      const result = await getTicketWidgetsValues(currentTicket.id, WIDGET_ID);
      console.log('[TicketCompletionDates] loadData :', result);
      setCurrentTicketCompletionDates(result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCustomFieldOnUpdate = (e) => {
    console.log('handleCustomFieldOnUpdate()', e);
    const key = uuid();
    try {
      let cf = [...currentTicketCompletionDates];
      let index = cf.findIndex((x) => x.field === e.field.field);
      cf[index] = e.result;
      setCurrentTicketCompletionDates(cf);
      messageApi.open({
        key,
        type: 'success',
        content: `[${e.field.field}] updated`,
        duration: 3,
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (!currentTicketCompletionDates) return <SkeletonTable />;
  if (currentTicketCompletionDates.length === 0) return <NoData />;

  return (
    <div className='flex flex-col gap-2'>
      {contextHolder}
      {currentTicketCompletionDates?.some((x) => x.field === 'Need completed by') && <CustomFieldDateTime ticketId={currentTicket.id} showTime={false} widgetId={WIDGET_ID} field={{ ...currentTicketCompletionDates?.find((x) => x.field === 'Need completed by') }} onUpdate={handleCustomFieldOnUpdate} alwaysReadOnly={true} />}
      {currentTicketCompletionDates?.some((x) => x.field === 'Justification' && x.value) && <CustomFieldString ticketId={currentTicket.id} showTime={false} widgetId={WIDGET_ID} field={{ ...currentTicketCompletionDates?.find((x) => x.field === 'Justification') }} onUpdate={handleCustomFieldOnUpdate} alwaysReadOnly={true} />}
      {currentTicketCompletionDates?.some((x) => x.field === 'Forecasted date') && <CustomFieldDateTime ticketId={currentTicket.id} showTime={false} widgetId={WIDGET_ID} field={{ ...currentTicketCompletionDates?.find((x) => x.field === 'Forecasted date'), isRequired: true }} onUpdate={handleCustomFieldOnUpdate} />}
    </div>
  );
};

// TicketCompletionDates.propTypes = {};

export default TicketCompletionDates;

import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import PropTypes from 'prop-types';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import { getTimeTrackingDashboardAccounts } from 'src/api/teacket';
import Space2 from 'src/components/layout/Space2';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import DashboardDataAccountsUsers from './DashboardDataAccountsUsers';
import Na from 'src/components/layout/Na';
import { MdAccountBalance } from 'react-icons/md';

const DashboardDataAccounts = (props) => {
  const { from, to, services, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers } = props;
  console.log('DashboardDataAccounts:', props);
  const { commandPressed } = useContext(GlobalContext);

  const columns = [
    {
      title: 'Account',
      key: 'account',
      render: (record) => {
        if (!record.acc_id || !record.acc_name)
          return (
            <Space2>
              <MdAccountBalance className='opacity-40' />
              <Na />
              {commandPressed && <span className='font-light text-xs text-genesys-orange-base'>no account</span>}
            </Space2>
          );
        return (
          <Space2>
            <MdAccountBalance className='opacity-40' />
            <span className='font-bold text-sm'>{record?.acc_name}</span>
            {commandPressed && <span className='font-light text-xs text-genesys-orange-base'>{record?.acc_id}</span>}
          </Space2>
        );
      },
    },
    {
      title: 'Hours',
      key: 'hours',
      align: 'right',
      width: '80px',
      render: (record) => <span className='font-bold text-sm'>{record?.hours}</span>,
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log('from/to:', from, to);
    if (!from || !to) return;
    loadData();
  }, [from, to]);

  const loadData = async () => {
    try {
      console.log('loadData');
      setLoading(true);

      const resp = await getTimeTrackingDashboardAccounts(services, from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'), projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers);
      setData(resp);
    } catch {
      console.error('Error loading data');
      setData(null);
    }
    setLoading(false);
  };

  if (loading) return <SkeletonTable />;

  return (
    <CondensedTableLegacy
      showHeader={false}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={'acc_id'}
      expandable={{
        //TODO: Add extra parameters (users) here
        expandedRowRender: (record) => (
          <p style={{ margin: 0 }} className='pl-10'>
            <DashboardDataAccountsUsers from={from} to={to} services={services} accountId={record?.acc_id} projectManagers={projectManagers} primaryTeamMembers={primaryTeamMembers} subjectMatterExperts={subjectMatterExperts} teamMembers={teamMembers} />
          </p>
        ),
        // rowExpandable: () => true,
      }}
    />
  );
};

DashboardDataAccounts.propTypes = {
  selectedFilters: PropTypes.object,
  from: PropTypes.object,
  to: PropTypes.object,
  services: PropTypes.array,
  projectManagers: PropTypes.array,
  primaryTeamMembers: PropTypes.array,
  subjectMatterExperts: PropTypes.array,
  teamMembers: PropTypes.array,
};

export default DashboardDataAccounts;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-arrowheads';
import { FaCloud, FaServer, FaDatabase, FaNetworkWired } from 'react-icons/fa';
import { renderToStaticMarkup } from 'react-dom/server';
import GeoJSONLayer from './GeoJSONLayer';
import './LeafletMap.css';

// Fix Leaflet's icon paths (common webpack issue)
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Component to handle map resize events
function MapResizer() {
  const map = useMap();

  useEffect(() => {
    // Small delay to ensure container is fully rendered
    setTimeout(() => {
      map.invalidateSize();
    }, 300);

    const resizeObserver = new ResizeObserver(() => {
      map.invalidateSize();
    });

    resizeObserver.observe(map.getContainer());

    return () => {
      resizeObserver.disconnect();
    };
  }, [map]);

  return null;
}

// Function to create a custom icon based on service type/feature
const getCustomIcon = (type) => {
  let icon;

  if (type?.toLowerCase().includes('database')) {
    icon = <FaDatabase size={24} color='#0078d4' />;
  } else if (type?.toLowerCase().includes('server')) {
    icon = <FaServer size={24} color='#0078d4' />;
  } else if (type?.toLowerCase().includes('network')) {
    icon = <FaNetworkWired size={24} color='#0078d4' />;
  } else {
    icon = <FaCloud size={24} color='#0078d4' />;
  }

  const iconMarkup = renderToStaticMarkup(icon);

  return L.divIcon({
    html: iconMarkup,
    className: 'custom-service-icon',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  });
};

// Component to handle region connections with arrows
function RegionConnection({ sourceRegion, targetRegion, regionCoordinates, serviceType }) {
  const map = useMap();

  useEffect(() => {
    if (!sourceRegion || !targetRegion || !regionCoordinates) return;

    // Get source and target coordinates
    const sourceCoords = regionCoordinates[sourceRegion];
    const targetCoords = regionCoordinates[targetRegion];

    if (!sourceCoords || !targetCoords) {
      console.error('Missing coordinates for regions:', sourceRegion, targetRegion);
      return;
    }

    // Create array of points for the line
    const points = [
      [sourceCoords[0], sourceCoords[1]],
      [targetCoords[0], targetCoords[1]],
    ];

    // Draw arrow line
    const polyline = L.polyline(points, {
      color: '#0078d4',
      weight: 3,
      opacity: 0.8,
      dashArray: '5, 8',
      smoothFactor: 1,
    }).addTo(map);

    // Add arrowheads
    polyline.arrowheads({
      frequency: '1',
      size: '12px',
      fill: true,
    });

    // Add service icon at source location
    const icon = getCustomIcon(serviceType);
    const marker = L.marker([sourceCoords[0], sourceCoords[1]], {
      icon: icon,
    }).addTo(map);

    // Ensure both regions are visible with proper padding around them
    const bounds = L.latLngBounds(points);

    // Add padding based on distance between points
    setTimeout(() => {
      // Small delay to ensure GeoJSON layers are loaded
      map.fitBounds(bounds, {
        padding: [40, 40], // Fixed padding in pixels
        maxZoom: 6, // Limit max zoom to show context
        animate: true, // Smooth animation
      });
    }, 300);

    return () => {
      map.removeLayer(polyline);
      map.removeLayer(marker);
    };
  }, [sourceRegion, targetRegion, regionCoordinates, serviceType, map]);

  return null;
}

RegionConnection.propTypes = {
  sourceRegion: PropTypes.string,
  targetRegion: PropTypes.string,
  regionCoordinates: PropTypes.object,
  serviceType: PropTypes.string,
};

// Component to handle map view changes when selectedRegion changes
function MapViewHandler({ selectedRegion, targetRegion, regionCoordinates }) {
  const map = useMap();

  useEffect(() => {
    // If both regions are selected, let the RegionConnection component handle the view
    if (selectedRegion && targetRegion) return;

    // Otherwise, center on the selected region
    if (selectedRegion && regionCoordinates && regionCoordinates[selectedRegion]) {
      const region = regionCoordinates[selectedRegion];
      const coordinates = [region[0], region[1]];
      const zoomLevel = region.zoomLevel || 5;

      // Add a small delay to ensure the map is ready
      setTimeout(() => {
        map.setView(coordinates, zoomLevel, { animate: true });
      }, 200);
    }
  }, [selectedRegion, targetRegion, regionCoordinates, map]);

  return null;
}

MapViewHandler.propTypes = {
  selectedRegion: PropTypes.string,
  targetRegion: PropTypes.string,
  regionCoordinates: PropTypes.object,
};

const LeafletMap = ({ selectedRegion = null, targetRegion = null, regionCoordinates = null, defaultPosition = [48.16319480610542, -1.5722126698612073], defaultZoom = 4, serviceType = null }) => {
  const [mounted, setMounted] = useState(false);

  // Use selected region's coordinates if available, otherwise use default
  const position = selectedRegion && regionCoordinates && regionCoordinates[selectedRegion] ? [regionCoordinates[selectedRegion][0], regionCoordinates[selectedRegion][1]] : defaultPosition;

  // Get the zoom level for the selected region or use default
  const zoom = selectedRegion && regionCoordinates && regionCoordinates[selectedRegion] && regionCoordinates[selectedRegion].zoomLevel ? regionCoordinates[selectedRegion].zoomLevel : defaultZoom;

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!mounted) {
    return (
      <div className='leaflet-container-placeholder' style={{ height: '100%', minHeight: '300px', background: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Loading map...
      </div>
    );
  }

  return (
    <div className='leaflet-container'>
      <MapContainer center={position} zoom={zoom} scrollWheelZoom={true} style={{ height: '100%', width: '100%', minHeight: '350px' }}>
        <MapResizer />

        {/* Handle view changes when region selection changes */}
        <MapViewHandler selectedRegion={selectedRegion} targetRegion={targetRegion} regionCoordinates={regionCoordinates} />

        {/* Base map tiles */}
        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />

        {/* Source region GeoJSON */}
        {selectedRegion && (
          <GeoJSONLayer
            region={selectedRegion}
            style={{
              color: '#0078d4',
              weight: 2,
              opacity: 0.7,
              fillColor: '#0078d4',
              fillOpacity: 0.2,
            }}
          />
        )}

        {/* Target region GeoJSON (if different from source) */}
        {targetRegion && targetRegion !== selectedRegion && (
          <GeoJSONLayer
            region={targetRegion}
            style={{
              color: '#e74c3c',
              weight: 2,
              opacity: 0.7,
              fillColor: '#e74c3c',
              fillOpacity: 0.2,
            }}
          />
        )}

        {/* Draw connection line with arrow if we have source and target */}
        {selectedRegion && targetRegion && regionCoordinates && <RegionConnection sourceRegion={selectedRegion} targetRegion={targetRegion} regionCoordinates={regionCoordinates} serviceType={serviceType} />}

        {/* Only show marker for the selected region if not showing a connection */}
        {selectedRegion && !targetRegion && (
          <Marker position={position}>
            <Popup>{`Region: ${selectedRegion}`}</Popup>
          </Marker>
        )}
      </MapContainer>
    </div>
  );
};

LeafletMap.propTypes = {
  selectedRegion: PropTypes.string,
  targetRegion: PropTypes.string,
  regionCoordinates: PropTypes.object,
  defaultPosition: PropTypes.array,
  defaultZoom: PropTypes.number,
  serviceType: PropTypes.string,
};

export default LeafletMap;

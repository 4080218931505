import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';

const StyledSelect1 = styled(Select)`
  .ant-select-selector {
    border-color: ${(props) => props.borderColor ?? 'transparent'} !important;
    background-color: ${(props) => props.backgroundColor ?? 'transparent'} !important;
    color: ${(props) => props.color ?? 'black'} !important;
  }

  .ant-select-selection-placeholder {
    color: ${(props) => props.color ?? 'black'} !important;
    opacity: 0.4 !important;
  }

  .ant-select-arrow {
    color: ${(props) => props.color ?? 'black'} !important;
  }
`;

const StyledSelect = (props) => {
  return <StyledSelect1 {...props}></StyledSelect1>;
};

export default StyledSelect;

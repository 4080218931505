import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoxType1 from './BoxType1';
import { Space } from 'antd';
import { BsRobot } from 'react-icons/bs';
import { ReactComponent as CopilotSvg } from 'src/components/svg/copilot.svg';
import CorporateColors from 'src/misc/CorporateColors';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import StyledButton from 'src/components/layout/StyledButton';
import useTheme from 'src/hooks/useTheme';

const SectionTrust = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();

  return (
    <PermissionsProvider requiredPermissions={['apps.copilot.view']} isControl>
      <BoxType1>
        <div className='flex flex-row gap-8'>
          <WhenDesktop>
            <div>
              <CopilotSvg className='h-[140px]' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.orange + '77'})` }} />
            </div>
          </WhenDesktop>
          <div>
            <div className='text-2xl'>
              <Space className='btn'>
                <BsRobot />
                Your AI Assistant<span className='font-thin opacity-60'> | Ask Anything!</span>
              </Space>
            </div>
            <p>COE AI Assistant is designed to be your virtual advisor, providing real-time assistance, insights, and expert guidance tailored specifically to the intricacies of Genesys Cloud product, security, privacy and Compliance. Whether you're a seasoned professional seeking advanced strategies or a newcomer looking to understand the fundamentals, COE AI Assistant is here to assist you every step of the way with most up to date information coming from managed data sources.</p>
            <p>This tool will not only provide you with answers to your questions but will also help to build tables & diagram from actual texts using AWS Bedrock within Genesys COE team managed environment.</p>
            <div className='mt-6'>
              <StyledButton
                color={theme.textBase}
                size='large'
                type='primary'
                onClick={() => {
                  navigate('/COEAIAssistant');
                }}
              >
                <Space className='btn'>
                  Open
                  <span className='font-bold'>COE AI Assistant</span>
                  <BsRobot />
                </Space>
              </StyledButton>
            </div>
          </div>
        </div>
      </BoxType1>
    </PermissionsProvider>
  );
};

SectionTrust.propTypes = {};

export default SectionTrust;

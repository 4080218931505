import React, { useContext, useState, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getTicketTimeTracking } from 'src/api/teacket';
import CondensedTable from 'src/components/layout/CondensedTableLegacy';
import { groupBy, notArrayOrEmpty } from 'src/misc/Misc';
import UserImage from 'src/components/layout/UserImage';
import Na from 'src/components/layout/Na';
import dayjs from 'dayjs';
import { Table, Tooltip } from 'antd';
import { TbSum, TbHash } from 'react-icons/tb';
import { FaMinusCircle } from 'react-icons/fa';
import Space2 from 'src/components/layout/Space2';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import WhenMeOrAdmin from 'src/components/layout/WhenMeOrAdmin';
import { deleteTicketTimeTracking } from 'src/api/teacket';
import { message } from 'antd';
import { uuid } from 'short-uuid';
//import PropTypes from 'prop-types';

const TicketTimeTrackingEntries = () => {
  const { currentTicket, determineIsWidgetReadOnly } = useContext(TeacketContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'User',
      key: 'user_name',

      render: (record) => {
        return (
          <div className='flex flex-row gap-1 items-center my-[2px]'>
            <UserImage image={record.user_pic} size='24px' />
            <span style={{ fontStretch: 'semi-condensed', lineHeight: '1rem' }}>{record.user_name}</span>
          </div>
        );
      },
    },
    {
      title: (
        <Space2 className='gap-[2px] btn'>
          <TbHash />
          Entries
        </Space2>
      ),
      key: 'EntriesCount',
      width: 75,
      align: 'center',
      render: (record) => {
        return <>{record?.items?.length ?? <Na />} </>;
      },
    },
    {
      title: (
        <Space2 className='gap-[2px] btn'>
          <TbSum />
          Hours
        </Space2>
      ),
      key: 'TotalHours',
      width: 75,
      align: 'center',
      render: (record) => {
        const total = record?.items?.reduce((n, { hours }) => n + hours, 0) ?? <Na />;
        return <>{total}</>;
      },
    },
  ];

  const columns1 = [
    {
      title: <span className='text-xs'>Date</span>,
      key: 'created_at',
      render: (record) => {
        return (
          <Space2>
            {false === determineIsWidgetReadOnly('Time Tracking') && (
              <WhenMeOrAdmin userId={record.user_id}>
                <div className='text-xs opacity-40 hover:opacity-100 cursor-pointer flex flex-row items-center'>
                  <Tooltip title={'Click to delete'} mouseEnterDelay={1}>
                    <FaMinusCircle onClick={() => handleEntryDelete(record.user_id, record.id)} />
                  </Tooltip>
                </div>
              </WhenMeOrAdmin>
            )}
            <div className='text-xs'>
              <Space2>
                <span>{dayjs(record.date).utc(true).format('MMM DD')}</span>
                <span className='font-light opacity-60'>{dayjs(record.date).utc(true).format('ddd')}</span>
              </Space2>
            </div>
          </Space2>
        );
      },
    },
    {
      title: <span className='text-xs'>Hours</span>,
      key: 'hours',
      width: 75,
      align: 'center',
      render: (record) => {
        return <span className='text-xs'>{record.hours}</span>;
      },
    },
  ];

  const [entries, setEntries] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await getTicketTimeTracking(currentTicket.id);
      if (true === notArrayOrEmpty(resp)) {
        setEntries(null);
        setLoading(false);
        return;
      }
      setEntries(groupBy(resp, 'user_id', ['user_name', 'user_pic']));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getTotalEntries = () => {
    if (notArrayOrEmpty(entries)) return null;
    return entries.reduce((n, { items }) => n + items.length, 0);
  };

  const getTotalHours = () => {
    if (notArrayOrEmpty(entries)) return null;
    return entries.reduce((n, { items }) => n + items.reduce((n, { hours }) => n + hours, 0), 0);
  };

  const handleEntryDelete = (user_id, tracking_id) => {
    console.log('handleEntryDelete()', user_id, tracking_id);
    executeAsyncProcess(async () => {
      try {
        const { id: ticket_id } = currentTicket;
        const key = uuid();
        messageApi.open({
          key,
          type: 'loading',
          content: 'Deleting entry...',
          duration: 0,
        });
        await deleteTicketTimeTracking(ticket_id, user_id, tracking_id);
        messageApi.open({
          key,
          type: 'success',
          content: 'Entry deleted',
          duration: 3,
        });
        let entriesCopy = [...entries];
        entriesCopy = entriesCopy.map((entry) => {
          if (entry.user_id === user_id) {
            entry.items = entry.items.filter((item) => item.id !== tracking_id);
          }
          return entry;
        });
        setEntries(entriesCopy);
      } catch (error) {
        console.error(error);
      }
    });
  };

  if (loading) return <SkeletonTable />;

  return (
    <>
      {contextHolder}
      <CondensedTable
        columns={columns}
        dataSource={entries}
        pagination={{
          pageSize: 6,
          size: 'small',
        }}
        rowKey='user_id'
        summary={() => {
          if (notArrayOrEmpty(entries)) return null;
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1} align='right'>
                  <span className='font-bold'>Totals:</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align='center'>
                  <span className='font-bold'>{getTotalEntries()}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align='center'>
                  <span className='font-bold'>{getTotalHours()}</span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        expandable={{
          columnWidth: 20,
          expandedRowRender: (record) => (
            <div className='ml-5'>
              <CondensedTable
                columns={columns1}
                dataSource={record?.items}
                pagination={{
                  pageSize: 10,
                  size: 'small',
                }}
              />
            </div>
          ),
        }}
      />
    </>
  );
};

TicketTimeTrackingEntries.propTypes = {};

export default TicketTimeTrackingEntries;

import React, { useEffect, useState, useContext, cloneElement } from 'react';
import styled from 'styled-components';
import GlobalContext from 'src/context/GlobalContext';
import PropTypes from 'prop-types';
import { getMonths, getWeeks } from 'src/misc/Misc';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import Space2 from 'src/components/layout/Space2';
import { getTimeTrackingDashboardPeriods } from 'src/api/teacket';
import * as dayjs from 'dayjs';
import { numberSorter } from 'src/misc/Misc';
import { BsCalendar3 } from 'react-icons/bs';

const StyledDiv = styled.div`
  .ant-table {
    border-radius: 0 !important; // remove border-radius for first rows when headers are hidden
  }

  .ant-table-cell {
    padding: 1px 0px !important;
  }
`;

const DashboardDataPeriods = ({ selectedFilters, children }) => {
  const { commandPressed } = useContext(GlobalContext);
  const { selectedPeriod, periodType, services, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers } = selectedFilters ?? {};

  const columns = [
    {
      title: 'Period',
      key: 'period',
      render: (record) => {
        let label = null;
        switch (periodType) {
          case 'week':
            label = (
              <Space2>
                <BsCalendar3 className='opacity-40' />
                <span className={`font-bold text-base ${record?.hours < 1 ? 'opacity-40' : null}`}>Week of {record?.from.format('MMM DD')}</span>
                {commandPressed && (
                  <span className='font-light text-xs text-genesys-orange-base'>
                    {record.from.format('YYYY-MM-DD')} - {record.to.format('YYYY-MM-DD')}
                  </span>
                )}
              </Space2>
            );
            break;
          case 'month':
            label = (
              <Space2>
                <BsCalendar3 className='opacity-40' />
                <span className={`font-bold text-base ${record?.hours < 1 ? 'opacity-40' : null}`}>{record?.from.format('MMM YYYY')}</span>
                {commandPressed && (
                  <span className='font-light text-xs text-genesys-orange-base'>
                    {record.from.format('YYYY-MM-DD')} - {record.to.format('YYYY-MM-DD')}
                  </span>
                )}
              </Space2>
            );
            break;
          default:
            label = 'Unknown period: ' + periodType;
            break;
        }
        return label;
      },
    },
    {
      title: 'Hours',
      key: 'hours',
      align: 'right',
      width: '80px',
      render: (record) => <span className={`font-bold text-base ${record?.hours < 1 ? 'opacity-60' : null}`}>{record?.hours}</span>,
      sorter: (a, b) => numberSorter(a, b, 'hours'),
    },
  ];

  const [periods, setPeriods] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log('Selected Filters:', selectedFilters);
    if (!selectedPeriod) return;
    if (!periodType) return;

    console.log('selectedPeriod:', selectedPeriod);
    switch (periodType) {
      case 'week':
        setPeriods(getWeeks(selectedPeriod[0], selectedPeriod[1]));
        break;
      case 'month':
        setPeriods(getMonths(selectedPeriod[0], selectedPeriod[1]));
        break;
      default:
        setPeriods(null);
        break;
    }
  }, [selectedFilters]);

  useEffect(() => {
    console.log('periods:', periods);
    if (!periods) return;
    loadData();
  }, [periods]);

  const loadData = async () => {
    setData(null);
    try {
      const resp = await getTimeTrackingDashboardPeriods(services, periodType, selectedPeriod[0].format('YYYY-MM-DD'), selectedPeriod[1].format('YYYY-MM-DD'), projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers);
      const p = periods.map((period) => {
        const { hours } = resp.find((record) => dayjs(record.from).utc().format('YYYY-MM-DD') === period.from.format('YYYY-MM-DD')) ?? { hours: 0 };

        return { ...period, ...{ hours } };
      });
      setData(p);
      // setPeriods(p);
      // setData(resp);
    } catch {
      console.error('Error loading data');
      //setData(null);
    }
  };

  // if (!data) return <SkeletonTable />;

  return (
    <StyledDiv>
      <CondensedTableLegacy
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={'from'}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }} className='pl-10'>
              {cloneElement(children, { from: record.from, to: record.to, services: selectedFilters?.services, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers })}
            </p>
          ),
          rowExpandable: (record) => record?.hours > 0,
        }}
      />
    </StyledDiv>
  );
};

DashboardDataPeriods.propTypes = {
  selectedFilters: PropTypes.object,
  children: PropTypes.node,
};

export default DashboardDataPeriods;

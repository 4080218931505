import React, { useEffect, useState, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import { message, Space, Tag, Tooltip, Card, Table, Spin, Checkbox, Popover } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import { pageList } from '../Misc/misc';
import PageSwitch from 'src/components/layout/PageSwitch';
import TicketListControl from '../TicketList/components/TicketListControl';
import DashboardFilters from '../Dashboard/components/DashboardFilters/DashboardFilters';
import { getTopOpportunities } from 'src/api/snowflake';
import { getTicketCounts, postAdminDashboardExtrasQuery, putAdminDashboardExtras } from 'src/api/teacket';
import useExport from 'src/hooks/useExport';
import { FaDownload } from 'react-icons/fa';
// import styled from 'styled-components';
// import DataCard from '../Components/DataCard';
import { Statistic } from 'antd';
import CorporateColors from 'src/misc/CorporateColors';
import NoData from 'src/components/layout/NoData';
import { notEmptyArray, notArrayOrEmpty, notEmptyString } from 'src/misc/Misc';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import StyledButton from 'src/components/layout/StyledButton';
import useTheme from 'src/hooks/useTheme';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import Space2 from 'src/components/layout/Space2';
import { IoChatboxOutline, IoChatboxEllipsesOutline } from 'react-icons/io5';
import { uuid } from 'short-uuid';
import CommentEditor from './CommentEditor';

// const StyledDiv = styled.div`
//   .ant-table {
//     margin-block: 0 !important;
//     margin-inline: 0 !important;
//   }
// `;

const messageKey = 'counter';

const AdminDashboard = () => {
  const { theme } = useTheme();
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { exportToCSV } = useExport();

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    size: 'small',
    position: ['topRight', 'bottomRight'],
  });
  const [totalOpportunities, setTotalOpportunities] = useState(null);
  const [totalTickets, setTotalTickets] = useState(null);
  const [teamEngagement, setTeamEngagement] = useState(null);
  const [uniqueAccounts, setUniqueAccounts] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();

  const { dashboardListServices, dashboardRegions, dashboardSubRegions, dashboardQuarters, dashboardACVs, dashboardPlatforms, dashboardForecasts, dashboardStages, dashboardTypes, dashboardRequestTypes, dashboardRequestStatuses, dashboardRequestCategories, dashboardRequestSubCategories } = useContext(TeacketContext);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Dashboards',
      },
      {
        title: 'Admin Dashboard',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    if (true === notEmptyArray(data)) {
      console.log('Data:', data);
      setTotalOpportunities(data.length);
      setTotalTickets(data.reduce((acc, d) => acc + (d.ticketCounts || 0), 0));
      setTeamEngagement((data.reduce((acc, d) => acc + (d.ticketCounts || 0), 0) * 100) / data.length);
      setUniqueAccounts(new Set(data.map((d) => d['Acc 18 Digit ID'])).size);
    } else {
      setTotalOpportunities(null);
      setTotalTickets(null);
      setTeamEngagement(null);
      setUniqueAccounts(null);
    }
  }, [data]);

  const loadData = async () => {
    console.log('Loading data...');

    let hideLoadingSnowflakeMessage, hideCountMessage;
    try {
      setLoading(true);
      setData([]);

      let currentData = [];

      //#region Get top opportunities from Snowflake

      hideLoadingSnowflakeMessage = messageApi.open({
        key: messageKey,
        type: 'loading',
        content: 'Getting opportunities from Snowflake...',
        duration: 0,
      });

      const resp = await getTopOpportunities(dashboardRegions, dashboardSubRegions, dashboardQuarters, dashboardACVs, dashboardPlatforms, dashboardForecasts, dashboardStages, dashboardTypes);
      console.log('Top Opportunities response:', resp);

      if (!resp.presignedUrl) {
        message.error('Failed to get opportunities from Snowflake');
        return;
      }

      let currentTopOpportunities = await fetch(resp.presignedUrl).then((res) => res.json());
      if (!currentTopOpportunities) {
        message.error('Failed to download results');
        return;
      }
      currentTopOpportunities = JSON.parse(currentTopOpportunities);
      console.log('currentTopOpportunities:', currentTopOpportunities);

      currentData = currentTopOpportunities;
      if (hideLoadingSnowflakeMessage) hideLoadingSnowflakeMessage(); // Close the loading message

      //#endregion

      //#region Get ticket counts for each account & opportunity

      hideCountMessage = messageApi.open({
        key: messageKey,
        type: 'loading',
        content: 'Counting tickets...',
        duration: 0,
      });

      const sfData = currentData.map((item) => {
        return {
          accountId: item['Acc 18 Digit ID'],
          opportunityId: item['18 Digit ID'],
        };
      });

      const ticketCounts = await getAllTicketCounts(sfData);
      console.log('ticketCounts:', ticketCounts);

      const dataWithKeysAndCounts = currentData.map((item, index) => {
        if (item['Acc 18 Digit ID'] && item['18 Digit ID']) {
          // Both account and opportunity are set
          const ticketCount = ticketCounts.find((count) => count.acc_id === item['Acc 18 Digit ID'] && count.opp_id === item['18 Digit ID']);
          return {
            ...item,
            key: `${item.member}-${index}`,
            ticketCounts: ticketCount ? ticketCount.count : 0, // Add ticket count to the item
          };
        } else if (item['Acc 18 Digit ID']) {
          // Only account is set
          const ticketCount = ticketCounts.find((count) => count.acc_id === item['Acc 18 Digit ID']);
          return {
            ...item,
            key: `${item.member}-${index}`,
            ticketCounts: ticketCount ? ticketCount.count : 0, // Add ticket count to the item
          };
        } else {
          // Neither account nor opportunity is set
          return {
            ...item,
            key: `${item.member}-${index}`,
            ticketCounts: 0, // Add ticket count to the item
          };
        }
      });

      //#endregion

      setData(dataWithKeysAndCounts);
      console.log('dataWithKeysAndCounts:', dataWithKeysAndCounts);
      message.success('All opportunities loaded!'); // Show success message
    } catch (error) {
      console.error(error);
      if (error.message.indexOf('404') !== -1) {
        message.warning('No opportunities found');
      } else {
        message.error('Failed to load data');
      }
    } finally {
      if (hideLoadingSnowflakeMessage) hideLoadingSnowflakeMessage(); // Close the loading message
      if (hideCountMessage) hideCountMessage(); // Close the loading message
      setLoading(false);
    }
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const getAllTicketCounts = async (sfData) => {
    try {
      const chunkSize = 100; // Adjust the chunk size as needed to avoid hitting the API return data size limit
      const ticketCounts = [];

      const sfDataChunks = chunkArray(sfData, chunkSize);

      let index = 0;
      for (const chunk of sfDataChunks) {
        index++;
        messageApi.open({
          key: messageKey,
          type: 'loading',
          content: `Checking if opps have tickets (${sfData.length < chunkSize ? sfData.length : sfData.length < chunkSize * index ? sfData.length : chunkSize * index}/${sfData.length})...`,
          duration: 0,
        });

        const chunkTicketCounts = await getTicketCounts(chunk, dashboardListServices, dashboardRequestTypes, dashboardRequestStatuses, dashboardRequestCategories, dashboardRequestSubCategories);
        ticketCounts.push(...chunkTicketCounts);
      }

      return ticketCounts;
    } catch (error) {
      console.error('Failed to get ticket counts for accounts:', sfData, error);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  const formatCurrency = (value, currency) => {
    if (!value || !currency) return value;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(value);
  };

  const sortByQuarterAndYear = (a, b) => {
    const [quarterA, yearA] = a['Closing Fiscal Quarter'].split('-');
    const [quarterB, yearB] = b['Closing Fiscal Quarter'].split('-');

    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }

    return quarterA.localeCompare(quarterB);
  };

  const truncateText = (text, maxLength) => {
    if (!text) return text;
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: <>Reviewed</>,
      width: 76,
      key: 'reviewed',
      align: 'center',
      render: (record) => {
        if (false === Array.isArray(extras)) {
          return <Spin size='small' />;
        }
        const item = extras.find((item) => item.id === `${record['Acc 18 Digit ID']}_${record['18 Digit ID'] ? record['18 Digit ID'] : 'no_opportunity'}`);
        return (
          <div className='flex flex-row gap-2 justify-center items-center'>
            <Tooltip title='Is reviewed?' mouseEnterDelay={1}>
              <Checkbox
                checked={item?.reviewed ?? false}
                onClick={(e) => {
                  handleReviewedOnClick(record, e);
                }}
              />
            </Tooltip>
            <Popover
              placement='right'
              title={'Comment'}
              content={
                <div className='w-64'>
                  <CommentEditor
                    initialValue={item?.comment ?? ''}
                    onUpdate={async (v) => {
                      handleCommentOnUpdate(record, v);
                    }}
                  />
                </div>
              }
              trigger='click'
            >
              <Tooltip title='Comment' mouseEnterDelay={1}>
                {true === notEmptyString(item?.comment) ? <IoChatboxEllipsesOutline className='text-lg cursor-pointer hover:scale-110' /> : <IoChatboxOutline className='text-lg cursor-pointer hover:scale-110' />}
              </Tooltip>
            </Popover>
          </div>
        );
      },
    },
    {
      title: 'Tickets',
      dataIndex: 'ticketCounts',
      key: 'ticketCounts',
      align: 'center',
      sorter: (a, b) => {
        return a.ticketCounts - b.ticketCounts;
      },
      sortOrder: sortedInfo.columnKey === 'ticketCounts' && sortedInfo.order,
      render: (text) => <Tag className='px-3 py-1.5 rounded-full font-medium border-0 bg-genesys-azure-100 text-genesys-azure-800 dark:bg-genesys-azure-900 dark:text-genesys-azure-100'>{text}</Tag>,
    },
    {
      title: 'Account Info',
      children: [
        {
          title: 'Account',
          dataIndex: 'Account Name',
          key: 'Account Name',
          fixed: 'center',
          sorter: (a, b) => a['Account Name']?.localeCompare(b['Account Name']),
          sortOrder: sortedInfo.columnKey === 'Account Name' && sortedInfo.order,
          render: (text, record) => (
            <a href={`https://genesys.lightning.force.com/lightning/r/Account/${record['Acc 18 Digit ID']}/view`} target='_blank' rel='noopener noreferrer'>
              {text}
            </a>
          ),
        },
        {
          title: 'Opportunity',
          dataIndex: 'Opportunity Name',
          key: 'Opportunity Name',
          fixed: 'center',
          sorter: (a, b) => a['Opportunity Name']?.localeCompare(b['Opportunity Name']),
          sortOrder: sortedInfo.columnKey === 'Opportunity Name' && sortedInfo.order,
          render: (text, record) => (
            <Tooltip title={text}>
              <a href={`https://genesys.lightning.force.com/lightning/r/Opportunity/${record['18 Digit ID']}/view`} target='_blank' rel='noopener noreferrer'>
                {truncateText(text, 15)}
              </a>
            </Tooltip>
          ),
        },
      ],
    },
    {
      title: 'ACV',
      children: [
        {
          title: 'Local',
          dataIndex: 'Value',
          key: 'Value',
          fixed: 'center',
          sorter: (a, b) => a.Value - b.Value,
          sortOrder: sortedInfo.columnKey === 'Value' && sortedInfo.order,
          render: (text, record) => <div className='float-right'>{formatCurrency(text || 0, record.Currency)}</div>,
        },
        {
          title: 'Currency',
          hidden: true,
          dataIndex: 'Currency',
          key: 'Currency',
          fixed: 'center',
          sorter: (a, b) => a.Currency?.localeCompare(b.Currency),
          sortOrder: sortedInfo.columnKey === 'Currency' && sortedInfo.order,
        },
        {
          title: 'USD ($)',
          dataIndex: 'ValueUSD',
          key: 'ValueUSD',
          fixed: 'center',
          sorter: (a, b) => a.ValueUSD - b.ValueUSD,
          sortOrder: sortedInfo.columnKey === 'ValueUSD' && sortedInfo.order,
          render: (text) => <div className='float-right'>{formatCurrency(text || 0, 'USD')}</div>,
        },
      ],
    },
    {
      title: 'Closing Q.',
      dataIndex: 'Closing Fiscal Quarter',
      key: 'Closing Fiscal Quarter',
      fixed: 'center',
      sorter: sortByQuarterAndYear,
      sortOrder: sortedInfo.columnKey === 'Closing Fiscal Quarter' && sortedInfo.order,
    },
    {
      title: 'Forecast',
      dataIndex: 'Forecast Status',
      key: 'Forecast Status',
      fixed: 'center',
      sorter: (a, b) => a['Forecast Status']?.localeCompare(b['Forecast Status']),
      sortOrder: sortedInfo.columnKey === 'Forecast Status' && sortedInfo.order,
    },
    {
      title: 'Stage',
      dataIndex: 'Stage',
      key: 'Stage',
      fixed: 'center',
      sorter: (a, b) => a.Stage?.localeCompare(b.Stage),
      sortOrder: sortedInfo.columnKey === 'Stage' && sortedInfo.order,
    },
    {
      title: 'Agents',
      dataIndex: 'Agents',
      key: 'Agents',
      fixed: 'center',
      sorter: (a, b) => a.Agents?.localeCompare(b.Agents),
      sortOrder: sortedInfo.columnKey === 'Agents' && sortedInfo.order,
    },
    {
      title: 'Owner',
      dataIndex: 'Opportunity Owner',
      key: 'Opportunity Owner',
      fixed: 'center',
      sorter: (a, b) => a['Opportunity Owner']?.localeCompare(b['Opportunity Owner']),
      sortOrder: sortedInfo.columnKey === 'Opportunity Owner' && sortedInfo.order,
    },
    {
      title: 'Partner',
      dataIndex: 'Partner Name',
      key: 'Partner Name',
      fixed: 'center',
      sorter: (a, b) => a['Partner Name']?.localeCompare(b['Partner Name']),
      sortOrder: sortedInfo.columnKey === 'Partner Name' && sortedInfo.order,
      render: (text) => (
        <Tooltip title={text}>
          <div>{truncateText(text, 15)}</div>
        </Tooltip>
      ),
    },
    {
      title: 'Offering',
      dataIndex: 'Offering Detail',
      key: 'Offering Details',
      fixed: 'center',
      sorter: (a, b) => a['Offering Detail']?.localeCompare(b['Offering Detail']),
      sortOrder: sortedInfo.columnKey === 'Offering Detail' && sortedInfo.order,
    },
    {
      title: 'DSR',
      dataIndex: 'SC DSR Name',
      key: 'SC DSR Name',
      fixed: 'center',
      sorter: (a, b) => a['SC DSR Name']?.localeCompare(b['SC DSR Name']),
      sortOrder: sortedInfo.columnKey === 'SC DSR Name' && sortedInfo.order,
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      fixed: 'center',
      sorter: (a, b) => a.Type?.localeCompare(b.Type),
      sortOrder: sortedInfo.columnKey === 'Type' && sortedInfo.order,
    },
    {
      title: 'Lead Offer',
      dataIndex: 'Lead Offer',
      key: 'Lead Offer',
      fixed: 'center',
      sorter: (a, b) => a['Lead Offer']?.localeCompare(b['Lead Offer']),
      sortOrder: sortedInfo.columnKey === 'Lead Offer' && sortedInfo.order,
    },
    {
      title: 'Outgoing Platform',
      dataIndex: 'Outgoing Platform Migration',
      key: 'Outgoing Platform Migration',
      fixed: 'center',
      sorter: (a, b) => a['Outgoing Platform Migration']?.localeCompare(b['Outgoing Platform Migration']),
      sortOrder: sortedInfo.columnKey === 'Outgoing Platform Migration' && sortedInfo.order,
    },
    {
      title: 'Region',
      dataIndex: 'Owner Region',
      key: 'Owner Region',
      fixed: 'center',
      sorter: (a, b) => a['Owner Region']?.localeCompare(b['Owner Region']),
      sortOrder: sortedInfo.columnKey === 'Owner Region' && sortedInfo.order,
    },
    {
      title: 'Sub Region',
      dataIndex: 'Owner Sub Region',
      key: 'Owner Sub Region',
      fixed: 'center',
      sorter: (a, b) => a['Owner Sub Region']?.localeCompare(b['Owner Sub Region']),
      sortOrder: sortedInfo.columnKey === 'Owner Sub Region' && sortedInfo.order,
    },
    {
      title: 'Renewal',
      dataIndex: 'Renewal',
      key: 'Renewal',
      fixed: 'center',
      sorter: (a, b) => a.Renewal?.localeCompare(b.Renewal),
      sortOrder: sortedInfo.columnKey === 'Renewal' && sortedInfo.order,
    },
  ];

  const expandableConfig = {
    expandRowByClick: true,
    expandedRowKeys: expandedKeys,
    expandedRowRender: (record) => {
      if (!record['Account ID']) return null;

      return <TicketListControl accountId={record['Acc 18 Digit ID']} opportunityId={record['18 Digit ID']} />;
    },
    onExpand: async (expanded, record) => {
      setExpandedKeys((prevKeys) => {
        if (expanded) {
          return [...prevKeys, record.key];
        }
        return prevKeys.filter((key) => key !== record.key);
      });
    },
    rowExpandable: (record) => record.ticketCounts > 0,
  };

  //#region "EXPERIMENTAL WAY OF HANDLING CURRENT PAGE DATA"

  const { adminDbrd_currentPageData, setAdminDbrd_currentPageData } = useContext(TeacketContext);
  const [extras, setExtras] = useState([]);

  const handleTableFooter = (v) => {
    let a = true === notEmptyArray(adminDbrd_currentPageData) ? adminDbrd_currentPageData : [];
    let b = true === notEmptyArray(v) ? v : [];
    if (a.length !== b.length || a.some((item, index) => item.key !== b[index].key) || b.some((item, index) => item.key !== a[index].key)) {
      handleOnCurrentPageDataChange(v);
    }
    return null;
  };

  const handleOnCurrentPageDataChange = async (v) => {
    // console.log('handleOnCurrentPageDataChange()', v);
    setAdminDbrd_currentPageData(v);
  };

  useEffect(() => {
    console.log('adminDbrd_currentPageData:', adminDbrd_currentPageData);
    if (true === notArrayOrEmpty(adminDbrd_currentPageData)) {
      setExtras([]);
      return;
    }
    loadExtras();
  }, [adminDbrd_currentPageData]);

  const loadExtras = async () => {
    try {
      setExtras(null);
      const query = adminDbrd_currentPageData.map((item) => {
        return {
          acc_id: item['Acc 18 Digit ID'],
          opp_id: item['18 Digit ID'],
        };
      });
      const result = await postAdminDashboardExtrasQuery(query);
      console.log('result:', result);
      setExtras(result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReviewedOnClick = async (record, e) => {
    console.log('handleReviewedOnClick:', record, e);
    const key = uuid();
    try {
      //#region "UPDATE UI"
      const item = extras?.find((item) => item.id === `${record['Acc 18 Digit ID']}_${record['18 Digit ID'] ? record['18 Digit ID'] : 'no_opportunity'}`);
      if (item) {
        item.reviewed = e.target.checked;
        setExtras([...extras]);
      } else {
        setExtras([...extras, { id: `${record['Acc 18 Digit ID']}_${record['18 Digit ID'] ? record['18 Digit ID'] : 'no_opportunity'}`, reviewed: e.target.checked }]);
      }
      //#endregion

      //#region "UPDATE DB"
      messageApi.open({
        key,
        type: 'loading',
        content: 'Saving...',
        duration: 0,
      });
      const query = {
        acc_id: record['Acc 18 Digit ID'],
        opp_id: record['18 Digit ID'],
        reviewed: e.target.checked,
      };
      await putAdminDashboardExtras(query);
      messageApi.open({
        key,
        type: 'success',
        content: 'Saved',
        duration: 2,
      });
      //#endregion
    } catch (error) {
      console.error(error);
      messageApi.destroy(key);
    }
  };

  const handleCommentOnUpdate = async (record, v) => {
    console.log('handleCommentOnUpdate:', record, v);
    const key = uuid();
    try {
      //#region "UPDATE UI"
      const item = extras?.find((item) => item.id === `${record['Acc 18 Digit ID']}_${record['18 Digit ID'] ? record['18 Digit ID'] : 'no_opportunity'}`);
      if (item) {
        item.comment = v;
        setExtras([...extras]);
      } else {
        setExtras([...extras, { id: `${record['Acc 18 Digit ID']}_${record['18 Digit ID'] ? record['18 Digit ID'] : 'no_opportunity'}`, comment: v }]);
      }
      //#endregion

      //#region "UPDATE DB"
      messageApi.open({
        key,
        type: 'loading',
        content: 'Saving...',
        duration: 0,
      });
      const query = {
        acc_id: record['Acc 18 Digit ID'],
        opp_id: record['18 Digit ID'],
        comment: v,
      };
      await putAdminDashboardExtras(query);
      messageApi.open({
        key,
        type: 'success',
        content: 'Saved',
        duration: 2,
      });
      //#endregion
    } catch (error) {
      console.error(error);
      messageApi.destroy(key);
    }
  };

  const handleTableOnRow = (record) => {
    const reviewed = extras?.find((item) => item.id === `${record['Acc 18 Digit ID']}_${record['18 Digit ID'] ? record['18 Digit ID'] : 'no_opportunity'}`)?.reviewed ?? false;
    if (reviewed) return { style: { opacity: '0.7', fontWeight: '100' } };
    return { style: { opacity: '1', fontWeight: '500' } };
  };

  //#endregion

  return (
    // <StyledDiv>
    <>
      {contextHolder}
      <PermissionsProvider requiredPermissions={['apps.teacket.dashboards.admindashboard.view']}>
        <LoaderServicesForTenant>
          <TitleBar
            isLoading={loading}
            title={
              <Space className='btn'>
                <BsCupHotFill />
                <div>
                  <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
                </div>
              </Space>
            }
            afterTitleExtras={
              <div className='flex flex-row items-center gap-4'>
                <div>
                  <PageSwitch pageList={pageList} />
                </div>
                <div>
                  <StyledButton
                    size='small'
                    color={theme.textBase}
                    onClick={() => {
                      exportToCSV({ columns, data, fileName: 'GreenTeacketAdminDashboard.csv' });
                    }}
                  >
                    <Space2>
                      <FaDownload />
                      <WhenDesktop>Export to CSV</WhenDesktop>
                    </Space2>
                  </StyledButton>
                </div>
              </div>
            }
            bottomExtras={<DashboardFilters id='admin-dashboard' onSubmit={() => loadData()} includeDateRange={false} includeServices={true} includeRegions includeSubRegions includeQuarters includeACVs includePlatforms includeForecasts includeStages includeTypes includeTicketTypes includeRequestStatuses includeRequestCategories includeRequestSubCategories allowSave collapsible={false} />}
            defaultExpanded
          />

          {/* <div className='p-6'>
            <div className='grid grid-cols-3 gap-4'>
              <DataCard title='Total Items' data={totalOpportunities} />
              <DataCard title='Total Tickets related to accounts or opps' data={totalTickets} />
              <DataCard title='% Team Engagement' data={teamEngagement ? parseFloat(teamEngagement).toFixed(1) + ' %' : undefined} />
            </div>
            <div className='grid grid-cols-3 gap-4'>
              <DataCard title='Unique Accounts' data={uniqueAccounts} />
            </div>
          </div> */}

          <div className='p-4 flex flex-col gap-4'>
            {/* DASHBOARDS */}
            <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-4'>
              <Card title='Total Items' size='small' variant='borderless'>
                {null === totalOpportunities ? <NoData /> : <Statistic value={totalOpportunities} precision={0} valueStyle={{ color: CorporateColors.orange }} />}
              </Card>
              <Card title='Total Tickets related to accounts or opps' size='small' variant='borderless'>
                {null === totalTickets ? <NoData /> : <Statistic value={totalTickets} precision={0} valueStyle={{ color: CorporateColors.orange }} />}
              </Card>
              <Card title='Team Engagement' size='small' variant='borderless'>
                {null === teamEngagement ? <NoData /> : <Statistic value={parseFloat(teamEngagement).toFixed(1)} precision={2} suffix='%' valueStyle={{ color: CorporateColors.orange }} />}
              </Card>
              <Card title='Unique Accounts' size='small' variant='borderless'>
                {null === uniqueAccounts ? <NoData /> : <Statistic value={uniqueAccounts} precision={0} valueStyle={{ color: CorporateColors.orange }} />}
              </Card>
            </div>

            {/* TABLE */}
            <CondensedTableLegacy bordered loading={loading} columns={columns} dataSource={data} onChange={handleChange} pagination={pagination} size='small' expandable={expandableConfig} scroll={{ x: 'max-content' }} className='shadow-sm resizable-table rounded-lg overflow-hidden' footer={handleTableFooter} onRow={handleTableOnRow} />
          </div>
        </LoaderServicesForTenant>
      </PermissionsProvider>
    </>
    // </StyledDiv>
  );
};

export default AdminDashboard;

import React, { useEffect, useContext, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from '../../Misc/misc';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../../Loaders/LoaderServicesForTenant';
import Space2 from 'src/components/layout/Space2';
import NoData from 'src/components/layout/NoData';
import TeacketServiceEditorRouting from 'src/components/pages/MasterAdmin/TeacketServices/TeacketServiceEditor/TeacketServiceEditorRouting/TeacketServiceEditorRouting';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import StyledSelect from 'src/components/layout/StyledSelect';
import useTheme from 'src/hooks/useTheme';
import useScreen from 'src/hooks/useScreen';
//import PropTypes from 'prop-types';

const AdminRouting = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { supervisorFiltersServicesForTenant } = useContext(TeacketContext);
  const { theme } = useTheme();
  const { isDesktop } = useScreen();

  const [serviceId, setServiceId] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Admin',
      },
      {
        title: 'Routing',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.admin.routing']}>
      <LoaderServicesForTenant>
        <TitleBar
          title={
            <Space2>
              <BsCupHotFill />
              <div>
                <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
              </div>
            </Space2>
          }
          afterTitleExtras={
            <Space2>
              <PageSwitch pageList={pageList} />
              <StyledSelect
                size='small'
                color={theme.textBase}
                backgroundColor={theme.backgroundMedium}
                borderColor={theme.textBase}
                value={serviceId}
                onChange={(v) => setServiceId(v)}
                className={isDesktop ? 'w-72' : 'w-full'}
                options={supervisorFiltersServicesForTenant?.map((x) => {
                  return { value: x.id, label: x.name };
                })}
                placeholder='select a service...'
              />
            </Space2>
          }
        ></TitleBar>
        <div className='p-4 flex flex-col gap-4 w-full lg:w-[100%]'>
          <DraggableLayoutItem>
            {!serviceId ? (
              <div className='my-24'>
                <NoData label='No service selected' />
              </div>
            ) : (
              <TeacketServiceEditorRouting serviceId={serviceId} />
            )}
          </DraggableLayoutItem>
        </div>
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// AdminRouting.propTypes = {};

export default AdminRouting;

import { FaHome } from 'react-icons/fa';
import { BsTable } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { PiFolderUserBold } from 'react-icons/pi';
import { IoStatsChart } from 'react-icons/io5';
import { ImStatsDots } from 'react-icons/im';
import { FaRegClock /*, FaStar */ } from 'react-icons/fa6';
import { TbRouteAltLeft, TbReportMoney } from 'react-icons/tb';
import { RiTeamFill } from 'react-icons/ri';

import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';

export const pageList = [
  {
    path: '/teacket',
    label: 'Home',
    icon: <FaHome />,
    permissions: ['apps.teacket.view'],
  },
  // {
  //   path: '/teacket/full-access-request-form',
  //   label: (
  //     <span>
  //       <span className='font-bold'>Full Access</span> <span className='font-thin'>Request</span>
  //     </span>
  //   ),
  //   icon: <FaStar />,
  //   permissions: ['apps.teacket.ticket.create'],
  // },
  {
    path: '/teacket/create-new',
    label: 'Create new',
    icon: <MdAdd />,
    permissions: ['apps.teacket.ticket.create'],
  },
  {
    path: '/teacket/my-tickets',
    label: 'My Tickets',
    icon: <PiFolderUserBold />,
    permissions: ['apps.teacket.ticket.create', 'apps.teacket.ticket.edit'],
  },
  // {
  //   path: '/teacket/tickets',
  //   label: 'Ticket List',
  //   icon: <BsTable />,
  //   permissions: ['apps.teacket.list.view'],
  // },
  {
    path: '/teacket/tickets2',
    label: 'Ticket List',
    icon: <BsTable />,
    permissions: ['apps.teacket.list.view'],
  },
  {
    path: '/teacket/dashboards/general',
    label: 'Tickets',
    icon: <ImStatsDots />,
    permissions: ['apps.teacket.dashboards.tickets.view'],
    group: 'Dashboards',
  },
  {
    path: '/teacket/dashboards/time-tracking',
    label: 'Time Tracking',
    icon: <FaRegClock />,
    permissions: ['apps.teacket.dashboards.timetracking.view'],
    group: 'Dashboards',
  },
  {
    path: '/teacket/dashboards/team',
    label: 'Team Dashboard',
    icon: <RiTeamFill />,
    permissions: ['apps.teacket.dashboards.teamdashboard.view'],
    group: 'Dashboards',
  },
  {
    path: '/teacket/dashboards/admin',
    label: 'Admin Dashboard',
    icon: <IoStatsChart />,
    permissions: ['apps.teacket.dashboards.admindashboard.view'],
    group: 'Dashboards',
  },
  {
    path: '/teacket/dashboards/winloss',
    label: 'Win Loss Report',
    icon: <TbReportMoney />,
    permissions: ['apps.teacket.dashboards.winloss.view'],
    group: 'Dashboards',
  },
  {
    path: '/teacket/admin/routing',
    label: 'Routing',
    icon: <TbRouteAltLeft />,
    permissions: ['apps.teacket.admin.routing'],
    group: 'Admin',
  },
];

export const hasTickeCreate = (permissions) => permissions.includes('apps.teacket.ticket.create') || permissions.includes(MASTER_ADMIN_PERMISSION);
export const hasTicketEditRights = (permissions) => permissions.includes('apps.teacket.ticket.edit') || permissions.includes(MASTER_ADMIN_PERMISSION);
export const hasTicketAdminRights = (permissions) => permissions.includes('apps.teacket.dashboards.admindashboard.view') || permissions.includes(MASTER_ADMIN_PERMISSION);
export const hasSupervisorRights = (permissions) => permissions.includes('apps.teacket.dashboards.tickets.view') || permissions.includes(MASTER_ADMIN_PERMISSION);
export const hasMasterAdminRights = (permissions) => permissions.includes(MASTER_ADMIN_PERMISSION) || permissions.includes(MASTER_ADMIN_PERMISSION);

export const colors = {
  open: {
    light: '#2fd2b1',
    dark: '#0e937c',
  },
  closed: {
    light: '#f7ad00',
    dark: '#d18000',
  },
  remaining: {
    light: '#ff6537',
    dark: '#C2410C',
  },
  categories: [
    {
      light: '#1b55da',
      dark: '#bedeff',
    },
    {
      light: '#35576f',
      dark: '#cfdde8',
    },
    {
      light: '#18caa8',
      dark: '#107565',
    },
    {
      light: '#ffec86',
      dark: '#a65a02',
    },
    {
      light: '#c71407',
      dark: '#ffc4a8',
    },
    {
      light: '#9C27B0',
      dark: '#E1BEE7',
    },
    {
      light: '#00BCD4',
      dark: '#B2EBF2',
    },
    {
      light: '#FF5722',
      dark: '#FFCCBC',
    },
    {
      light: '#795548',
      dark: '#D7CCC8',
    },
    {
      light: '#607D8B',
      dark: '#CFD8DC',
    },
    {
      light: '#8BC34A',
      dark: '#DCEDC8',
    },
    {
      light: '#FFC107',
      dark: '#FFECB3',
    },
    {
      light: '#3F51B5',
      dark: '#C5CAE9',
    },
    {
      light: '#009688',
      dark: '#B2DFDB',
    },
    {
      light: '#F44336',
      dark: '#FFCDD2',
    },
  ],
};

export const TITLE_MAX_LENGTH = 250;
export const CONTENT_MAX_LENGTH = 5000;

export const needNumberOfQuestions = (ticket) => ticket?.category === 'Questionnaire/Assessment';

export const isLegalSupportSelected = (category) => 'legal support' === (category?.toLowerCase() ?? '');

export const determineRegion = (regionDictionary, regionString) => {
  console.log('determineRegion()', regionDictionary, regionString);
  if (!regionString) return null;
  if (!regionDictionary) return null;
  let region = null;
  region = regionDictionary.find((x) => x.key?.toLowerCase() === regionString.toLowerCase());
  if (!region) region = regionDictionary.find((x) => x.valueStr?.toLowerCase() === regionString.toLowerCase());
  if (!region) region = regionDictionary.find((x) => x.valueJson?.map((x) => x.toLowerCase())?.includes(regionString.toLowerCase()));
  return region?.key ?? null;
};

export const TIME_TRACKING_HOURS_PER_DAY = 8;

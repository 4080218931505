import React, { useState, useEffect } from 'react';
import useTheme from 'src/hooks/useTheme';
import { useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import { LuPlug } from 'react-icons/lu';
import CountUp from 'react-countup';
import Space2 from 'src/components/layout/Space2';
import Na from 'src/components/layout/Na';
import { getUsersSignedInNowCount } from 'src/api/users';
import { useInterval } from 'react-interval-hook';

//import PropTypes from 'prop-types';

const AdminStatsSignedIn = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();

  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const resp = await getUsersSignedInNowCount();
      if (resp?.count !== count) setCount(resp?.count ?? null);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useInterval(() => loadData(), 10000);

  return (
    <Card
      className='w-36 text-center cursor-pointer hover:scale-105'
      onClick={() => navigate('/dashboards/who-is-signed-in-now')}
      size='small'
      title={
        <Space2 style={{ color: theme.textBase }}>
          <LuPlug />
          Signed-in now
        </Space2>
      }
    >
      <div className={`text-center text-4xl ${loading ? 'animate-pulse' : ''}`}>{count === null ? <Na /> : <CountUp end={count ?? 0} separator=',' duration={2} />}</div>
    </Card>
  );
};

AdminStatsSignedIn.propTypes = {};

export default AdminStatsSignedIn;

import { FaHome, FaDatabase } from 'react-icons/fa';
import Space2 from 'src/components/layout/Space2';
import { FaCircleNodes } from 'react-icons/fa6';

export const pageList = [
  {
    path: '/datanexus',
    label: 'Home',
    icon: <FaHome />,
    permissions: ['apps.datanexus.view'],
  },

  {
    path: '/datanexus/admin/service-hosting',
    label: 'Service Hosting',
    icon: <FaDatabase />,
    permissions: ['apps.datanexus.admin'],
    group: 'Admin',
  },
  {
    path: '/datanexus/admin/subcontractors',
    label: 'Subcontractors',
    icon: <FaDatabase />,
    permissions: ['apps.datanexus.admin'],
    group: 'Admin',
  },
];

export const pageTitle = (
  <Space2>
    <FaCircleNodes />
    Data Nexus
  </Space2>
);

export const CREATE_NEW_ID = -1; // -1 means add new

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getSubcontractor, patchSubcontractor, postSubcontractor } from 'src/api/datanexus';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import { Button, Drawer, Form, Input, Tag, Switch } from 'antd';
import PickerSubcontractors from '../../Controls/PickerSubcontractors';
import { sleepMs, truncateText } from 'src/misc/Misc';
import Space2 from 'src/components/layout/Space2';

import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { CREATE_NEW_ID } from '../../Misc/misc';

const SubcontractorsEditor = ({ id, onClose }) => {
  const [item, setItem] = useState(null);

  const [editItemForm] = Form.useForm();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const creatingMode = CREATE_NEW_ID === id;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editRelations, setEditRelations] = useState(false);

  useEffect(() => {
    if (!id) return;
    if (creatingMode) setData({});
    else loadData();
  }, [id]);

  const loadData = async (newId) => {
    try {
      setLoading(true);
      const resp = await getSubcontractor(newId || id);
      console.log('@@', resp);
      setItem(resp);

      editItemForm.setFieldsValue(resp.sub_contractor);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setData = () => {
    const newItem = { id, name: null, address: null, country_of_registration: null, trade_register_number: null, lei: null, parent_company: null, ownership: null };
    setItem(newItem);
    editItemForm.setFieldsValue(newItem);
  };

  const close = async (values) => {
    onClose(values);
    await sleepMs(500); //allow to complete collapsing the drawer
    setItem(null);
  };

  const handleFormOnFinish = async (values) =>
    executeAsyncProcess(async () => {
      console.log('handleFormOnFinish', values);
      setSaving(true);
      try {
        const resp = creatingMode ? await postSubcontractor(values) : await patchSubcontractor(item?.sub_contractor?.id, { ...values, id: undefined });
        console.log(resp);
        close(resp);
      } catch (error) {
        console.error(error);
      } finally {
        setSaving(false);
      }
    });

  const handleFormOnFailure = async (errorInfo) => {
    console.log('handleFormOnFailure', errorInfo);
  };

  const onPick = async (x) => {
    console.log('@@ item', x);
    const resp = await getSubcontractor(item?.sub_contractor?.id);
    console.log('@@', resp);
    // update only the relations
    setItem({ ...item, relation_up: resp.relation_up, relation_down: resp.relation_down });
  };

  return (
    <Drawer
      open={null !== id}
      onClose={close}
      closable={false}
      maskClosable={true}
      width={500}
      title={creatingMode ? 'Create new' : 'Edit'}
      extra={
        <Space2>
          <Button type='primary' size='small' onClick={() => editItemForm.submit()} disabled={!item || isBusy}>
            Save
          </Button>
          <Button size='small' onClick={() => close()} disabled={!item || isBusy}>
            Cancel
          </Button>
        </Space2>
      }
      styles={{ header: { padding: '12px' }, body: { padding: '12px' } }}
    >
      {!item ? (
        <FullScreenSpin title='Please wait' subtitle='Loading' />
      ) : (
        <div className={`${loading ? 'opacity-50 blur-sm disabled' : ''}`}>
          {saving && <FullScreenSpin title='Please wait' subtitle='Saving...' />}
          <Form form={editItemForm} name='ticket-create-form' onFinish={handleFormOnFinish} onFinishFailed={handleFormOnFailure} autoComplete='off' layout='vertical'>
            {!creatingMode && (
              <Form.Item label='Id' name='id' hidden>
                <Input disabled />
              </Form.Item>
            )}
            <div className='flex flex-col'>
              <div className='w-full'>
                <Form.Item label='Name' name='name'>
                  <Input />
                </Form.Item>
              </div>
              <div className='text-right text-xs opacity-60 -mt-4'>id: {item.sub_contractor.id}</div>
            </div>

            <Form.Item label='Address' name='address'>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 8 }} maxLength={1000} />
            </Form.Item>
            <Form.Item label='Country of registration' name='country_of_registration'>
              <Input />
            </Form.Item>
            <Form.Item label='Trade register number' name='trade_register_number'>
              <Input />
            </Form.Item>
            <Form.Item label='LEI' name='lei'>
              <Input />
            </Form.Item>

            <Form.Item label='Service provision' name='service_provision'>
              <Input />
            </Form.Item>
            <Form.Item label='Country of Service provision' name='country_of_service_provision'>
              <Input />
            </Form.Item>

            <Form.Item label='Ownership' name='ownership'>
              <Input />
            </Form.Item>

            <Form.Item label='Parent company' name='parent_company'>
              <Input />
            </Form.Item>
          </Form>

          <div className='mt-5 pt-4 border-t border-gray-200'>
            <div className='flex flex-row items-center m-2'>
              <h3>Relationships</h3>

              <div className='flex ml-auto items-center'>
                <Tag color='orange' className='text-xs'>
                  {editRelations ? 'Edit' : 'View'} relationships
                </Tag>
                <Switch checked={editRelations} onChange={(v) => setEditRelations(v)} />
              </div>
            </div>
            <div className='flex flex-col items-center my-4 relative'>
              {!editRelations ? (
                <>
                  {/* Parent relationships */}
                  <div className='flex flex-wrap justify-center gap-2 w-full min-h-[60px] z-10 mb-1 pb-1'>
                    {item?.relation_up?.length > 0 ? (
                      item.relation_up.map((r, index) => (
                        <div key={index} className='items-center relative z-10 parent-item'>
                          <Tag color='blue' className='hover:scale-105 hover:shadow transition-transform cursor-pointer' onClick={() => loadData(r.parent_id)}>
                            {r.parents.name}
                          </Tag>
                          {r.service_provision_in_eu && <div className='flex flex-col text-xs opacity-60 ml-auto text-right scale-75 -mb-1'>service in EU</div>}
                        </div>
                      ))
                    ) : (
                      <div className='text-gray-400 italic p-2.5 z-10'>No parent relationships</div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className='flex flex-col items-center mb-2.5 w-full'>
                    <div className='text-xs opacity-60 mb-1'>Select parent company(s)</div>
                    <PickerSubcontractors allowSeacrh initialValue={item.relation_up.map((x) => x.parent_id)} relationDetails={item.relation_up} onPick={onPick} relationParent={true} mySubcontractor={item.sub_contractor?.id} busy={(x) => setLoading(x)} />
                  </div>
                </>
              )}

              {/* Arrow pointing to center */}
              <div className='relative w-0.5 h-4 bg-gray-500 mb-2.5 arrow-up'>
                <div className='absolute -bottom-2 left-1/2 w-0 h-0 border-l-[6px] border-r-[6px] border-t-[8px] border-transparent border-t-gray-300 -translate-x-1/2'></div>
              </div>

              {/* Main node */}
              <div className='my-1 z-10'>
                <Tag color='green' className='text-base px-4 py-1 rounded-full shadow'>
                  {truncateText(item?.sub_contractor?.name, 40)}
                </Tag>
              </div>

              {/* Arrow pointing down */}
              <div className='relative w-0.5 h-4 bg-gray-500 mt-2.5 arrow-down'>
                <div className='absolute -bottom-2 left-1/2 w-0 h-0 border-l-[6px] border-r-[6px] border-t-[8px] border-transparent border-t-gray-300 -translate-x-1/2'></div>
              </div>

              {!editRelations ? (
                <>
                  {/* Child relationships */}
                  <div className='flex flex-wrap justify-center gap-2 w-full min-h-[60px] z-10 mt-2 pt-1'>
                    {item?.relation_down?.length > 0 ? (
                      item.relation_down.map((r, index) => (
                        <div key={index} className='flex flex-col items-center relative z-10 child-item'>
                          <Tag color='blue' className='hover:scale-105 hover:shadow transition-transform cursor-pointer' onClick={() => loadData(r.child_id)}>
                            {r.children.name}
                          </Tag>

                          {r.service_provision_in_eu && <div className='text-xs opacity-60 mr-2 -mb-1 text-right ml-auto scale-75'>service in EU</div>}
                        </div>
                      ))
                    ) : (
                      <div className='text-gray-400 italic p-2.5 z-10'>No child relationships</div>
                    )}
                  </div>
                </>
              ) : (
                <div className='flex flex-col items-center mb-2.5 w-full'>
                  <div className='text-xs opacity-60 mb-1'>Select child company(s)</div>
                  <PickerSubcontractors allowSeacrh initialValue={item.relation_down.map((x) => x.child_id)} relationDetails={item.relation_down} onPick={onPick} relationParent={false} mySubcontractor={item.sub_contractor?.id} busy={(x) => setLoading(x)} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};

SubcontractorsEditor.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
};

export default SubcontractorsEditor;

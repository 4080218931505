import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import PropertyItem from 'src/components/layout/PropertyItem';
import { Button, Modal, Input } from 'antd';
import { putTicketWidgetsValues } from 'src/api/teacket';
import PropTypes from 'prop-types';
import { notEmptyString } from 'src/misc/Misc';

const CustomFieldUrl = ({ ticketId, field, onUpdate, alwaysReadOnly, widgetId = 'Additional Properties', maxLength = 2000, isLink = true }) => {
  const { determineIsWidgetReadOnly } = useContext(TeacketContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(null);
  const [errorState, setErrorState] = useState(null);

  useEffect(() => {
    setNewValue(field.value);
  }, [isEditing, field.value]);

  //#region "EVENT HANDLERS"

  const handlePropertyItemOnClick = () => {
    console.log('CustomFieldUrl:', field);
    setIsEditing(true);
  };

  const handleModalOnSave = async () => {
    setIsEditing(false);
    await executeAsyncProcess(async () => {
      try {
        const result = await putTicketWidgetsValues(ticketId, widgetId, field.field, newValue);
        if (onUpdate) onUpdate({ ticketId, field, result });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleModalOnCancel = () => {
    setIsEditing(false);
  };

  //#endregion

  //#region "MISC"

  const isSaveDisabled = () => newValue === field.value || errorState;

  const isCancelDisabled = () => false;

  const getLabelExtras = () => {
    if (true === field.isRequired && (!field.value || field.value === '')) {
      return <span className='text-red-500 animate-pulse'>is required!</span>;
    }
    return null;
  };

  //#endregion

  return (
    <>
      <Modal
        closable={false}
        open={isEditing}
        title={field.field}
        footer={[
          <Button key='Save' onClick={handleModalOnSave} type='primary' disabled={isSaveDisabled()}>
            Save
          </Button>,
          <Button key='Cancel' onClick={handleModalOnCancel} disabled={isCancelDisabled()}>
            Cancel
          </Button>,
        ]}
      >
        <Input
          status={errorState ? 'error' : null}
          placeholder='Enter a value'
          value={newValue}
          showCount
          onChange={(e) => {
            const v = e.target.value;
            setErrorState(true === notEmptyString(v) && !/^(ftp|ftps|sftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(v));
            setNewValue(v);
          }}
          maxLength={maxLength}
        />
      </Modal>
      <PropertyItem isValueLink={isLink} label={field.field} value={field.value} onClick={true !== alwaysReadOnly && true !== determineIsWidgetReadOnly(widgetId) ? handlePropertyItemOnClick : null} labelExtras={getLabelExtras()} isHighlighted={null !== getLabelExtras()} />
    </>
  );
};

CustomFieldUrl.propTypes = {
  ticketId: PropTypes.string,
  field: PropTypes.object,
  onUpdate: PropTypes.func,
  alwaysReadOnly: PropTypes.bool,
  widgetId: PropTypes.string,
  maxLength: PropTypes.number,
  isLink: PropTypes.bool,
};

export default CustomFieldUrl;

import React, { useState, useEffect, useContext } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getBreachNotificationMessages, postBreachNotificationMessage } from 'src/api/notifications';
import dayjs from 'dayjs';
import Highlighter from 'react-highlight-words';
import useTheme from 'src/hooks/useTheme';
import { Space, Tag } from 'antd';

//import PropTypes from 'prop-types';

const BreachNotificationsSelector = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const { breachNotificationsQuery, breachNotificationsSelectedId, setBreachNotificationsSelectedId, breachNotificationsList, setBreachNotificationsList, breachNotificationsNewCreated, setBreachNotificationsNewCreated } = useContext(MasterAdminContext);

  const pageSizeOptions = [20, 50, 100];

  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: 'Title / ID / Created at',
      key: 'title',
      render: (record) => {
        const NEW_ROW_PERIOD = 60 * 1000; // 1 minute in milliseconds
        const diff = dayjs().diff(dayjs(record.created_at));
        const newRow = diff <= NEW_ROW_PERIOD;
        return (
          <div>
            <div className='overflow-hidden' style={{ wordBreak: 'break-all' }}>
              <Space>
                <span className={record.id === breachNotificationsSelectedId ? 'font-bold' : null}>
                  <Highlighter textToHighlight={record?.title ?? ''} searchWords={[`${breachNotificationsQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
                </span>
                {newRow && (
                  <Tag color='blue' size='small'>
                    just created
                  </Tag>
                )}
              </Space>
            </div>
            <div className='flex flex-row gap-2'>
              <div className='w-1/2 text-[0.6rem] opacity-60'>
                <Highlighter textToHighlight={record?.id ?? ''} searchWords={[`${breachNotificationsQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
              </div>
              <div className='w-1/2 text-[0.6rem] opacity-60 text-right'>created {dayjs(record.created_at).fromNow()}</div>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setBreachNotificationsList(null);
    setBreachNotificationsSelectedId(null);
    loadData();
  }, [page]);

  useEffect(() => {
    setBreachNotificationsList(null);
    setPage(1);
    setBreachNotificationsSelectedId(null);
    loadData();
  }, [breachNotificationsQuery, pageSize]);

  useEffect(() => {
    if (!breachNotificationsNewCreated) return;
    CreateNew();
  }, [breachNotificationsNewCreated]);

  const loadData = async (forcePage = null) => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getBreachNotificationMessages(breachNotificationsQuery, forcePage ?? page, pageSize); // corrected the variable name
        setTotal(resp?.total ?? 0);
        setBreachNotificationsList(resp?.entities ?? null);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const CreateNew = () => {
    executeAsyncProcess(async () => {
      try {
        setBreachNotificationsList(null);
        setPage(1);
        setBreachNotificationsSelectedId(null);
        setBreachNotificationsNewCreated(false);
        const resp = await postBreachNotificationMessage({
          title: 'SPC Portal Breach Notification',
          description: `<br/>
          A new breach was detected in the Genesys Security & Privacy Central (SPC) Portal. Please review the details below and take necessary actions.<br/>
          <br/>
          <strong>Date & Time of Incident</strong><br/>
            <ENTER BREACH DATE HERE (e.g. February 21, 2025, at 22:05 (UTC))><br/>
          <br/>
          <strong>Incident Description</strong><br/>
            We are writing to inform you of a security breach detected in the Genesys Security & Privacy Central (SPC) Portal (https://spc.genesys.com). Our security monitoring systems have identified unauthorized access affecting specific user accounts.<br/>
          <br/>
          <strong>Affected Users</strong><br/>
            The following user accounts were involved in this security incident:<br/>
            <br/>
            {users}
            <br/>
            <br/>
          <strong>Next Steps</strong><br/>
            Our security team is actively investigating this incident and implementing necessary measures to prevent future occurrences. Affected users should:<br/>

            <ul>
              <li>Change their passwords immediately</li>
              <li>Enable two-factor authentication if not already active</li>
              <li>Review their recent account activity for any suspicious actions</li>
            </ul>
          <br/>
          <strong>Contact Information</strong><br/>
            If you have any questions or observe suspicious activity, please contact the SPC team:<br/>
            <br/>
            Email: shahzad.ahmad@genesys.com<br/>
            <br/>
            We take this incident seriously and are committed to maintaining the security and integrity of our systems.<br/>
          <br/>
          Regards,<br/>
          The SPC team
          `,
        });
        await loadData(1);
        setBreachNotificationsSelectedId(resp.id);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <>
      <CondensedTableLegacy
        pagination={{
          showSizeChanger: true,
          pageSizeOptions,
          size: 'small',
          onShowSizeChange: (current, size) => {
            console.log('onShowSizeChange', current, size);
            setPageSize(size);
          },
          current: page,
          pageSize,
          total,
          onChange: (page) => setPage(page),
        }}
        columns={columns}
        dataSource={breachNotificationsList}
        rowKey={'id'}
        onRow={(record) => {
          return {
            style: { cursor: 'pointer', background: record.id === breachNotificationsSelectedId ? theme.backgroundLight : null },
            onClick: async () => {
              setBreachNotificationsSelectedId(record.id);
            },
          };
        }}
      />
    </>
  );
};

BreachNotificationsSelector.propTypes = {};

export default BreachNotificationsSelector;

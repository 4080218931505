import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import DataNexusContext from 'src/context/DataNexusContext';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getSubcontractors, deleteSubcontractor, getSubcontractorsList } from 'src/api/datanexus';
import { sleepMs, truncateText } from 'src/misc/Misc';
import dayjs from 'dayjs';
import Na from 'src/components/layout/Na';
import Highlighter from 'react-highlight-words';
import useTheme from 'src/hooks/useTheme';
import SubcontractorsEditor from './SubcontractorsEditor';
import { CREATE_NEW_ID } from '../../Misc/misc';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';

const SubcontractorsData = ({ filters, editable }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { subcontractorsAddNew, setSubcontractorsAddNew, subcontractorsSorter, setSubcontractorsSorter, setSubcontractorsList } = useContext(DataNexusContext);

  const { theme } = useTheme();

  const columns = [
    {
      title: 'Name',
      key: 'name',
      align: 'left',
      render: (record) =>
        record?.name ? (
          <div>
            <div style={{ lineHeight: '1rem' }}>
              <Highlighter textToHighlight={record?.name ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              <Highlighter textToHighlight={truncateText(record?.ownership, 40) ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      responsive: ['xl'],
      title: 'Country of Registration',
      key: 'country_of_registration',
      align: 'left',
      render: (record) =>
        record?.country_of_registration ? (
          <div style={{ lineHeight: '1rem' }}>
            <Highlighter textToHighlight={record?.country_of_registration ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      responsive: ['xl'],
      title: 'Service provision',
      key: 'service_provision',
      align: 'left',
      render: (record) =>
        record?.service_provision ? (
          <div style={{ lineHeight: '1rem' }}>
            <Highlighter textToHighlight={record?.service_provision ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      title: 'Address',
      key: 'address',
      align: 'left',
      render: (record) =>
        record?.address ? (
          <div style={{ lineHeight: '1rem' }}>
            <Highlighter textToHighlight={record?.address ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      title: 'Trade Reg Number',
      key: 'trade_register_number',
      align: 'center',
      render: (record) =>
        record?.trade_register_number ? (
          <div style={{ lineHeight: '1rem' }}>
            <Highlighter textToHighlight={record?.trade_register_number ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      title: 'LEI',
      key: 'lei',
      align: 'center',
      render: (record) =>
        record?.lei ? (
          <div style={{ lineHeight: '1rem' }}>
            <Highlighter textToHighlight={record?.lei ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      title: 'Parent Company',
      key: 'parent_company',
      align: 'center',
      render: (record) =>
        record?.parent_company ? (
          <div style={{ lineHeight: '1rem' }}>
            <Highlighter textToHighlight={record?.parent_company ?? ''} searchWords={[`${filters?.query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        ) : (
          <Na />
        ),
      sorter: true,
    },

    {
      responsive: ['xxl'],
      title: 'Created At',
      key: 'created_at',
      align: 'center',
      render: (record) =>
        record.created_at ? (
          <>
            <div style={{ lineHeight: '1rem' }}>{dayjs(record.created_at).fromNow()}</div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              {dayjs(record.created_at).format('LLL')}
            </div>
          </>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      responsive: ['xxl'],
      title: 'Updated At',
      key: 'updated_at',
      align: 'center',
      render: (record) =>
        record.updated_at ? (
          <>
            <div style={{ lineHeight: '1rem' }}>{dayjs(record.updated_at).fromNow()}</div>
            <div className='font-light opacity-40' style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
              {dayjs(record.updated_at).format('LLL')}
            </div>
          </>
        ) : (
          <Na />
        ),
      sorter: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: '70px',
      render: (record) => (
        <div onClick={(e) => e.stopPropagation()}>
          <YesNoDialog onYesClick={() => handleDelete(record.id)} title='Delete' body='Do you want to delete this item?' iconYes={<DeleteOutlined />} labelYes='Yes, delete it'>
            <Button type='text' size='small'>
              <DeleteOutlined />
            </Button>
          </YesNoDialog>
        </div>
      ),
    },
  ].filter((x) => {
    if (editable) return true;
    return !['actions'].includes(x.key);
  });

  const pageSizeOptions = [10, 25, 50, 100];

  const [initiated, setInitiated] = useState(false);
  const [entities, setEntities] = useState(null);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[2]));
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    loadData();
    executeAsyncProcess(async () => {
      await sleepMs(1000);
      setInitiated(true);
    });
  }, []);

  useEffect(() => {
    if (!initiated) return;
    loadData();
  }, [page]);

  useEffect(() => {
    if (!initiated) return;
    loadData();
    setPage(1);
    setTotal(0);
    setEntities(null);
    loadData();
  }, [pageSize, filters]);

  useEffect(() => {
    if (!subcontractorsAddNew) return;
    setSelectedItem(CREATE_NEW_ID);
    setSubcontractorsAddNew(false);
  }, [subcontractorsAddNew]);

  useEffect(() => {
    console.log('subcontractorsSorter', subcontractorsSorter);
    if (!subcontractorsSorter) return;
    loadData();
  }, [subcontractorsSorter]);

  const loadData = () =>
    executeAsyncProcess(async () => {
      try {
        const { query } = filters ?? {};
        //console.log({ page, pageSize, regions, useOtherRegions, features, types, names, query });
        const resp = await getSubcontractors(page, pageSize, sortingFieldsMapper(subcontractorsSorter?.columnKey) ?? null, subcontractorsSorter?.order ?? null, query);
        //console.log(resp);
        setEntities(resp.entities);
        setTotal(resp.total);

        // Feed Dict
        const subList = await getSubcontractorsList();

        setSubcontractorsList(subList ?? []);
      } catch (error) {
        console.error(error);
      }
    });

  const handleOnSorterChange = (sorter) => {
    console.log('handleOnSorterChange:', sorter);
    setSubcontractorsSorter(sorter);
  };

  const handleEditorClose = (values) => {
    console.log('handleEditorClose', values);
    if (values) {
      if (selectedItem === CREATE_NEW_ID) {
        let e = [...entities];
        e.unshift({ ...values, justCreated: true });
        e.slice(0, 10);
        setEntities(e);
        // CREATE_NEW_ID means add new
        // TODO: handle adding new item
      } else {
        let e = [...entities];
        const i = e.findIndex((x) => x.id === values.id);
        if (i >= 0) {
          e[i] = { ...e[i], ...values };
          setEntities(e);
        }
      }
    }
    setSelectedItem(null);
  };

  const handleDelete = (id) => {
    console.log('handleDelete', id);
    executeAsyncProcess(async () => {
      try {
        await deleteSubcontractor(id);
        setEntities(entities.filter((x) => x.id !== id));
      } catch (error) {
        console.error(error);
      }
    });
  };

  const sortingFieldsMapper = (field) => {
    switch (field) {
      case 'name':
        return 'name';
      default:
        return field;
    }
  };

  return (
    <>
      <SubcontractorsEditor id={selectedItem} onClose={handleEditorClose} />

      <CondensedTableLegacy
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions,
          size: 'small',
          onShowSizeChange: (current, size) => {
            console.log('onShowSizeChange', current, size);
            setPageSize(size);
          },
          current: page,
          pageSize,
          total,
          onChange: (page) => setPage(page),
        }}
        dataSource={entities}
        rowKey='id'
        onRow={
          editable
            ? (record) => {
                return {
                  style: { cursor: 'pointer', background: record.id === selectedItem ? theme.backgroundLight : null },
                  onClick: () => {
                    setSelectedItem(record.id);
                  },
                };
              }
            : null
        }
        onChange={(_, __, sorter) => {
          handleOnSorterChange(sorter);
        }}
      />
    </>
  );
};

SubcontractorsData.propTypes = {
  filters: PropTypes.object,
  editable: PropTypes.bool,
};

export default SubcontractorsData;

import React, { useRef, useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import { getMyTicketsGraph } from 'src/api/teacket';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';

dayjs.extend(relativeTime);

const GraphMyOverdue = ({ hideLegend = false, hideFooter = false }) => {
  const { isDarkMode } = useTheme();
  const [graphDataStatus, setGraphDataStatus] = useState(undefined);

  const refContainer = useRef();

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const loadData = async () => {
    try {
      const resp = await getMyTicketsGraph('overdue');
      setGraphDataStatus(resp);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!graphDataStatus) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      <div ref={refContainer}>
        {!graphDataStatus ? (
          <div className='text-center mt-10'>
            <Spin />
          </div>
        ) : (
          <>
            {graphDataStatus && Array.isArray(graphDataStatus) && graphDataStatus.length > 0 && (
              <>
                <div className='flex flex-row justify-center'>
                  <PieChart
                    title='ByStatus'
                    colors={['#f28e2c', '#76b7b2']}
                    series={[
                      {
                        arcLabel: (item) => `${item.value}`,
                        data: graphDataStatus || [],
                        innerRadius: 20,
                        outerRadius: 70,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        cy: 100,
                        cx: 100,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fontWeight: 'bold',
                      },
                    }}
                    width={hideLegend ? 200 : 400} // Increase the width of the pie chart container
                    height={200} // Adjust height to give more space
                    slotProps={{
                      legend: {
                        hidden: hideLegend,
                        position: { vertical: 'middle', horizontal: 'right' },
                        layout: 'vertical', // Vertically stack items
                        maxWidth: 100, // Limit the width of the legend to prevent overlap
                        itemGap: 10, // Increase space between legend items
                      },
                    }}
                  />
                </div>
                {!hideFooter && <div className='text-xs font-thin text-center whitespace-break-spaces'>My active tickets</div>}
              </>
            )}
            {graphDataStatus && Array.isArray(graphDataStatus) && graphDataStatus.length === 0 && (
              <p className='flex items-center justify-center h-full' style={{ height: '200px' }}>
                No overdue Tickets.
              </p>
            )}
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

GraphMyOverdue.propTypes = {
  hideLegend: PropTypes.bool,
  hideFooter: PropTypes.bool,
};

export default GraphMyOverdue;

import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { getTenantRoles } from 'src/api/accesscontrol';
import PropTypes from 'prop-types';
import { DEFAULT_ROLE } from 'src/misc/Config';

const { Option } = Select;

const RolePicker = ({ width, onPick }) => {
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setItemList([]);
    setLoading(true);
    try {
      let resp = await getTenantRoles();
      setItemList(resp?.entities ?? []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleOnSelect = (v) => {
    console.log('RolePicker onSelect:', v);
    const role = itemList.find((u) => u.role === v);
    onPick(role);
  };

  return (
    <Select
      loading={loading}
      showSearch
      allowClear
      placeholder='select a role...'
      onSelect={handleOnSelect}
      style={{ width }}
      autoComplete='off'
      className={loading ? 'disabled' : null}
      optionRender={({ value }) => {
        const item = itemList.find((x) => x.role === value);
        if (!item) return null;
        return (
          <div className='flex flex-col'>
            <div className='font-bold'>{item.role}</div>
            <div className='text-xs font-light opacity-60' style={{ textWrap: 'pretty' }} dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        );
      }}
    >
      {itemList.map((d) => (
        <Option key={d.role} disabled={d.role === DEFAULT_ROLE}>
          {d.role}
        </Option>
      ))}
    </Select>
  );
};

RolePicker.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
};

RolePicker.defaultProps = {
  width: '300px',
};

export default RolePicker;

import React, { useState, useEffect, useContext } from 'react';
import { AiOutlinePlus, AiOutlineSetting, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BiLinkExternal } from 'react-icons/bi';
import { getConversations, createConversation, getConversation, deleteConversation } from 'src/api/securityqa';
import useUserInfo from 'src/hooks/useUserInfo';
import SQAConversationContext from 'src/context/SQAConversationContext';
import SidebarItem from './SidebarItem';
import SettingsModal from './SettingsModal';
import { Skeleton, message } from 'antd';
import dayjs from 'dayjs';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';

import { getBrowserTimeZone } from 'src/misc/Timezone';

import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';

var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const Sidebar = () => {
  const timezone = getBrowserTimeZone();
  const timeRangeList = {
    today: {
      label: 'Today',
      from: dayjs().tz(timezone).startOf('day').format(),
      to: dayjs().tz(timezone).endOf('day').format(),
      items: [],
    },
    yesterday: {
      label: 'Yesterday',
      from: dayjs().tz(timezone).subtract(1, 'day').startOf('day').format(),
      to: dayjs().tz(timezone).subtract(1, 'day').endOf('day').format(),
      items: [],
    },
    previous7days: {
      label: 'Previous 7 days',
      from: dayjs().tz(timezone).subtract(2, 'day').startOf('day').format(),
      to: dayjs().tz(timezone).subtract(9, 'day').endOf('day').format(),
      items: [],
    },
    previous30days: {
      label: 'Previous 30 days',
      from: dayjs().tz(timezone).subtract(10, 'day').startOf('day').format(),
      to: dayjs().tz(timezone).subtract(40, 'day').endOf('day').format(),
      items: [],
    },
    older: {
      label: 'Older',
      from: dayjs().tz(timezone).subtract(100, 'year').startOf('day').format(),
      to: dayjs().tz(timezone).subtract(41, 'day').endOf('day').format(),
      items: [],
    },
  };

  // console.log('timeRangeList:', timeRangeList);

  const userInfo = useUserInfo();
  const { conversation, setConversation, conversations, setConversations, setSwitchingConversation, availableModels, streaming, updateConversationSubject } = useContext(SQAConversationContext);

  const [deleting, setDeleting] = useState(null);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [creatingNewConversation, setCreatingNewConversation] = useState(false);
  const [loadingConversation, setLoadingConversation] = useState(undefined);
  const [loadingUserConversations, setLoadingUserConversations] = useState(false);
  const [conversationsTimeGrouped, setConversationsTimeGrouped] = useState(null);

  useEffect(() => {
    getUserConversations();
  }, []);

  useEffect(() => {
    // <if no conversations>
    if (true === notArrayOrEmpty(conversations)) {
      setConversationsTimeGrouped({ ...timeRangeList });
      return;
    }
    // </if no conversations>

    // <if conversations exist>
    let groupedConversations = { ...timeRangeList };
    groupedConversations.today.items = conversations.filter((x) => dayjs(x.updated_at ?? x.created_at ?? undefined).isBetween(timeRangeList.today.from, timeRangeList.today.to, 'second', '[]'));
    groupedConversations.yesterday.items = conversations.filter((x) => dayjs(x.updated_at ?? x.created_at ?? undefined).isBetween(timeRangeList.yesterday.from, timeRangeList.yesterday.to, 'second', '[]'));
    groupedConversations.previous7days.items = conversations.filter((x) => dayjs(x.updated_at ?? x.created_at ?? undefined).isBetween(timeRangeList.previous7days.from, timeRangeList.previous7days.to, 'second', '[]'));
    groupedConversations.previous30days.items = conversations.filter((x) => dayjs(x.updated_at ?? x.created_at ?? undefined).isBetween(timeRangeList.previous30days.from, timeRangeList.previous30days.to, 'second', '[]'));
    groupedConversations.older.items = conversations.filter((x) => dayjs(x.updated_at ?? x.created_at ?? undefined).isBetween(timeRangeList.older.from, timeRangeList.older.to, 'second', '[]'));
    setConversationsTimeGrouped(groupedConversations);
    // </if conversations exist>
  }, [conversations]);

  const getUserConversations = async () => {
    try {
      setLoadingUserConversations(true);
      const currentConversations = await getConversations(userInfo.id);
      currentConversations?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      console.log('Conversations:', currentConversations);
      setConversations(currentConversations);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUserConversations(false);
    }
  };

  const createNewConversation = async (e) => {
    console.log('createNewConversation');
    try {
      e.preventDefault();

      if (availableModels.length === 0) {
        message.error('No models available, please contact your administrator.');
        return;
      }

      setCreatingNewConversation(true);
      setSwitchingConversation(true);

      let modelIdForNewConversation;
      const savedModelId = localStorage.getItem(LOCAL_STORAGE_KEYS.appsCopilotDefaultModelId);
      if (savedModelId) {
        if (availableModels.filter((x) => x.id === savedModelId)[0]) modelIdForNewConversation = availableModels.filter((x) => x.id === savedModelId)[0].id;
        else {
          console.log('Saved Model is no longer available, use first available model');
          modelIdForNewConversation = availableModels[0].id;
          localStorage.setItem(LOCAL_STORAGE_KEYS.appsCopilotDefaultModelId, modelIdForNewConversation);
        }
      } else {
        modelIdForNewConversation = availableModels[0].id;
      }

      const newConversation = await createConversation(userInfo.id, {
        title: 'New Conversation',
        model_id: modelIdForNewConversation,
      });
      console.log('New Conversation:', newConversation);
      setConversations([newConversation, ...conversations]);
      setConversation(newConversation);
    } catch (error) {
      console.error(error);
    } finally {
      setCreatingNewConversation(false);
      setSwitchingConversation(false);
    }
  };

  const switchConversation = async (conversationId) => {
    if (conversationId === conversation?.id) return;
    try {
      setSwitchingConversation(true);
      setLoadingConversation(conversationId);
      console.log('Switching to conversation:', conversationId);
      const newConversation = conversations.find((conversation) => conversation.id === conversationId);
      const fullConversation = await getConversation(newConversation.id);
      console.log('Full conversation:', fullConversation);
      setConversation(fullConversation);
    } catch (error) {
      console.error(error);
    } finally {
      setSwitchingConversation(false);
      setLoadingConversation(undefined);
    }
  };

  const handleDeleteConversation = async (conversationId) => {
    setDeleting(conversationId);
    try {
      console.log('Deleting conversation:', conversationId);
      await deleteConversation(conversationId);
      const newConversations = conversations.filter((conversation) => conversation.id !== conversationId);
      setConversations(newConversations);
      // Check if deleted conversation is current conversation
      if (conversation?.id === conversationId) {
        setConversation(null);
      }
    } catch (error) {
      console.error(error);
    }
    setDeleting(null);
  };

  const handleModalClose = async () => {
    setSettingsModalOpen(false);
    await getUserConversations();
  };

  const handleOnWsMessageReceived = async (message) => {
    try {
      console.log('Message received:', message);
      updateConversationSubject(message?.data?.id, message?.data?.title);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['COPILOT_CONV_UPDATED']}></GlobalWsMessenger>
      <nav className={`flex h-full flex-col space-y-1 p-2 ${streaming ? 'disabled' : ''} '`}>
        <div className='flex-initial flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 cursor-pointer text-sm mb-1 flex-shrink-0 border border-white/20' onClick={createNewConversation}>
          {creatingNewConversation ? <AiOutlineLoading3Quarters className='animate-spin items-right' /> : <AiOutlinePlus className='h-4 w-4' />}
          New chat
        </div>
        <div className='flex-auto overflow-auto'>
          <div className='flex-col flex-1 border-b border-white/20'>
            {loadingUserConversations && conversations.length === 0 && (
              <div className='p-2 pt-1'>
                <Skeleton.Input active block className='mb-1' />
                <Skeleton.Input active block />
              </div>
            )}
            {/* TODAY */}
            {true === notEmptyArray(conversationsTimeGrouped?.today?.items) && (
              <>
                <div className='font-bold text-xs my-1 opacity-60'>Today</div>
                {conversationsTimeGrouped?.today?.items?.map((currentConversation, index) => (
                  <SidebarItem key={index} conversationId={currentConversation.id} title={currentConversation.title} isSelected={conversation?.id === currentConversation?.id} onSelect={switchConversation} onDelete={handleDeleteConversation} isDeleting={currentConversation?.id === deleting} animate={currentConversation?.animate} isLoading={loadingConversation} />
                ))}
              </>
            )}
            {/* YESTERDAY */}
            {true === notEmptyArray(conversationsTimeGrouped?.yesterday?.items) && (
              <>
                <div className='font-bold text-xs my-1 opacity-60'>Yesterday</div>
                {conversationsTimeGrouped?.yesterday?.items?.map((currentConversation, index) => (
                  <SidebarItem key={index} conversationId={currentConversation.id} title={currentConversation.title} isSelected={conversation?.id === currentConversation?.id} onSelect={switchConversation} onDelete={handleDeleteConversation} isDeleting={currentConversation?.id === deleting} animate={currentConversation?.animate} isLoading={loadingConversation} />
                ))}
              </>
            )}
            {/* PREVIOUS 7 DAYS */}
            {true === notEmptyArray(conversationsTimeGrouped?.previous7days?.items) && (
              <>
                <div className='font-bold text-xs my-1 opacity-60'>Previous 7 days</div>
                {conversationsTimeGrouped?.previous7days?.items?.map((currentConversation, index) => (
                  <SidebarItem key={index} conversationId={currentConversation.id} title={currentConversation.title} isSelected={conversation?.id === currentConversation?.id} onSelect={switchConversation} onDelete={handleDeleteConversation} isDeleting={currentConversation?.id === deleting} animate={currentConversation?.animate} isLoading={loadingConversation} />
                ))}
              </>
            )}
            {/* PREVIOUS 30 DAYS */}
            {true === notEmptyArray(conversationsTimeGrouped?.previous30days?.items) && (
              <>
                <div className='font-bold text-xs my-1 opacity-60'>Previous 30 days</div>
                {conversationsTimeGrouped?.previous30days?.items?.map((currentConversation, index) => (
                  <SidebarItem key={index} conversationId={currentConversation.id} title={currentConversation.title} isSelected={conversation?.id === currentConversation?.id} onSelect={switchConversation} onDelete={handleDeleteConversation} isDeleting={currentConversation?.id === deleting} animate={currentConversation?.animate} isLoading={loadingConversation} />
                ))}
              </>
            )}
            {/* OLDER */}
            {true === notEmptyArray(conversationsTimeGrouped?.older?.items) && (
              <>
                <div className='font-bold text-xs my-1 opacity-60'>Older</div>
                {conversationsTimeGrouped?.older?.items?.map((currentConversation, index) => (
                  <SidebarItem key={index} conversationId={currentConversation.id} title={currentConversation.title} isSelected={conversation?.id === currentConversation?.id} onSelect={switchConversation} onDelete={handleDeleteConversation} isDeleting={currentConversation?.id === deleting} animate={currentConversation?.animate} isLoading={loadingConversation} />
                ))}
              </>
            )}

            {/* <div className='font-'>today</div>
            {conversations
              ?.filter((x) => dayjs(x.updated_at ?? x.created_at ?? undefined).isBetween(timeRangeList.today.from, timeRangeList.today.to, 'second', '[]'))
              ?.map((currentConversation, index) => (
                <SidebarItem key={index} conversationId={currentConversation.id} title={currentConversation.title} isSelected={conversation?.id === currentConversation?.id} onSelect={switchConversation} onDelete={handleDeleteConversation} isDeleting={currentConversation?.id === deleting} animate={currentConversation?.animate} isLoading={loadingConversation} />
              ))} */}
          </div>
        </div>
        <div>
          <a target='_self' className='flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 cursor-pointer text-sm' href='#/' onClick={() => setSettingsModalOpen(true)}>
            <AiOutlineSetting className='h-4 w-4' />
            Settings
          </a>
          <a href='/products/COEAIAssistant' target='_blank' className='flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm' rel='noreferrer'>
            <BiLinkExternal className='h-4 w-4' />
            Get help
          </a>
        </div>
      </nav>
      {settingsModalOpen && <SettingsModal onClose={handleModalClose} />}
    </>
  );
};

export default Sidebar;

import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import useTheme from 'src/hooks/useTheme';
import { invertColor, notEmptyString } from 'src/misc/Misc';
import { FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Alert, Tooltip, Typography, Space, Tag, Select, message, InputNumber, Button, Modal } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { getDistinctRegions, getDistinctPlatforms, getDistinctMarketSegments, getDistinctDirectIndirects, getDistinctCustomerTypes } from 'src/api/snowflake';
import { getTicketMembers, getTicketProperties } from 'src/api/teacket';
import { uuid } from 'short-uuid';
import SnowflakeAccountPicker from 'src/components/controls/SnowflakeAccountPicker/SnowflakeAccountPicker';
import SnowflakeOpportunityPicker from 'src/components/controls/SnowflakeOpportunityPicker/SnowflakeOpportunityPicker';
import Journey from './Journey';
import PropertyItem from 'src/components/layout/PropertyItem';
import DeploymentPicker from './DeploymentPicker';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { GiJourney } from 'react-icons/gi';

const StakeholderDetails = ({ ticket, propertyColor, isHighlighted, onTicketChange, onPropertiesChange, noEdit = false }) => {
  const { theme, isDarkMode } = useTheme();
  const [messageApi, contextHolder] = message.useMessage();
  const { executeAsyncProcess } = useAsyncProcesses();

  const { getServiceSettingForServiceId, /*updateCurrentTicketProperties,*/ currentTicketMembers, setCurrentTicketMembers, currentTicket, determineIsWidgetReadOnly, currentTicketIsMasterTenant, setCurrentTicket } = useContext(TeacketContext);

  const [busy, setBusy] = useState({});

  //#region Use States

  const [regions, setRegions] = useState({});
  const [directIndirects, setDirectIndirects] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [marketSegments, setMarketSegments] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [numberOfSeats, setNumberOfSeats] = useState(0);
  const [numberOfSeatsChanged, setNumberOfSeatsChanged] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [opportunityModalOpen, setOpportunityModalOpen] = useState(false);
  const [opportunity, setOpportunity] = useState(null);
  const [isOkDisabled, setIsOkDisabled] = useState(true);
  const [journeyModalOpen, setJourneyModalOpen] = useState(false);
  const [showJourney, setShowJourney] = useState(false);
  const [snowflakeOpportunitySupport, setSnowflakeOpportunitySupport] = useState(false);
  const [gcDeploymentSupport, setGcDeploymentSupport] = useState(null);
  const [canUpdateDeployment, setCanUpdateDeployment] = useState(false);
  const [deploymentModalOpen, setDeploymentModalOpen] = useState(false);
  const [deployment, setDeployment] = useState(null);
  const [forceRerender, setForceRerender] = useState(false);

  //#endregion

  //#region Load Data

  const loadDirectIndirects = async () => {
    try {
      const result = await getDistinctDirectIndirects();
      setDirectIndirects(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading direct/indirect:', error);
        message.error('Error while loading direct/indirect');
      } else {
        console.log('No direct/indirect found');
        setDirectIndirects([]);
      }
    }
  };

  const loadRegions = async () => {
    try {
      const result = await getDistinctRegions();
      setRegions(result);
    } catch (error) {
      console.error('Error while loading regions:', error);
      message.error('Error while loading regions');
    }
  };

  const loadPlatforms = async () => {
    try {
      const result = await getDistinctPlatforms();
      setPlatforms(result);
    } catch (error) {
      console.error('Error while loading platforms:', error);
      message.error('Error while loading platforms');
    }
  };

  const loadMarketSegments = async () => {
    try {
      const result = await getDistinctMarketSegments();
      setMarketSegments(result);
    } catch (error) {
      console.error('Error while loading market segments:', error);
      message.error('Error while loading market segments');
    }
  };

  const loadCustomerTypes = async () => {
    try {
      const result = await getDistinctCustomerTypes();
      setCustomerTypes(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading customer types:', error);
        message.error('Error while loading customer types');
      } else {
        console.log('No customer types found');
        setCustomerTypes([]);
      }
    }
  };

  const loadPreliminaryData = async () => {
    await Promise.all([loadRegions(), loadDirectIndirects(), loadPlatforms(), loadMarketSegments(), loadCustomerTypes()]);
  };

  //#endregion

  const determineIfCanUpdateDeployment = () => {
    if (true !== determineIsWidgetReadOnly('Properties') && currentTicketIsMasterTenant) return true;
    return false;
  };

  const canvasRef = useRef(null);

  //#region Use Effects

  useEffect(() => {
    if (!forceRerender) return;
    setForceRerender(false);
  }, [forceRerender]);

  // useEffect(() => {
  //   const resizeObserver = new ResizeObserver((event) => {
  //     //console.log(canvasRef.current.offsetWidth);
  //     setMaxInlineSize(canvasRef?.current?.offsetWidth || 350 / 2);
  //   });

  //   resizeObserver.observe(document.getElementById('stakeholder-details'));
  // }, []);

  useEffect(() => {
    const sf = '1' === getServiceSettingForServiceId(ticket?.service_id, 'SnowflakeOpportunitySupport');
    setSnowflakeOpportunitySupport(sf);
    console.log('SnowflakeOpportunitySupport #2:', sf);

    const gc = '1' === getServiceSettingForServiceId(ticket?.service_id, 'GcDeploymentSupport');
    setGcDeploymentSupport(gc);

    const sj = '1' === getServiceSettingForServiceId(ticket?.service_id, 'ShowJourney');
    setShowJourney(sj);

    loadPreliminaryData();

    if (ticket?.opp_id) {
      reloadOppDetails();
    }

    setCanUpdateDeployment(determineIfCanUpdateDeployment());
  }, []);

  useEffect(() => {
    console.log('[StakeholderDetails] Ticket has been updated', ticket);
  }, [ticket]);

  useEffect(() => {
    setForceRerender(true);
  }, [ticket?.opp_name, ticket?.acc_name]);

  //#endregion

  //#region Handlers

  const handlePropertyChanged = async (propertyName, propertyData) => {
    const key = uuid();
    messageApi.open({
      key,
      type: 'loading',
      content: `Updating ${propertyName}...`,
      duration: 3,
    });

    if (onPropertiesChange) {
      try {
        setBusy({ [propertyName]: true });
        await onPropertiesChange(propertyData);

        messageApi.open({
          key,
          type: 'success',
          content: `${propertyName[0].toUpperCase() + propertyName.substr(1)} updated`,
          duration: 3,
        });
      } catch (error) {
        console.error(error);
        messageApi.open({
          key,
          type: 'error',
          content: 'An error occurred',
          duration: 3,
        });
      } finally {
        setBusy({ region: false });
      }
    }
  };

  //#region Number of Seats control handlers

  const handleNumberOfSeatsChanged = (v) => {
    setNumberOfSeats(v);
    setNumberOfSeatsChanged(true);
  };

  const handleSaveSeatsClick = () => {
    handlePropertyChanged('seats', { seats: numberOfSeats });
    setNumberOfSeatsChanged(false);
  };

  //#endregion

  const handleDeploymentModalOnSelect = (v) => {
    console.log('handleDeploymentModalOnSelect', v);
    setDeployment(v);
  };

  //#endregion

  //#region Modals

  const handleAccountModalOnOk = () => {
    console.log('handleAccountModalOnOk');

    executeAsyncProcess(async () => {
      try {
        console.log('=== handleAccountModalOnOk, account:', account);
        const { ACCOUNT_ID: acc_id, ACCOUNT_NAME: acc_name } = account;

        //TODO: Update common properties
        let props = Object.keys(ticket?.props ?? {})?.reduce((acc, key) => {
          acc[key] = ticket?.props[key];
          return acc;
        }, {});

        // Add all account properties
        props = { ...props, ...account };

        if (onTicketChange) {
          console.log('=== Call onTicketChange', { acc_id, acc_name, opp_id: null, opp_name: null, props: props });
          await onTicketChange({ acc_id, acc_name, opp_id: null, opp_name: null, props: props });
        }

        // Reload members
        const result = await getTicketMembers(ticket?.id);
        setCurrentTicketMembers(result);

        //setCurrentTicket(ticket);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Account updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });

    setAccountModalOpen(false);
  };

  const handleOpportunityModalOnOk = async () => {
    console.log('handleOpportunityModalOnOk', opportunity);
    try {
      if (onTicketChange) {
        await onTicketChange({ opp_id: opportunity?.OPPORTUNITY_ID, opp_name: opportunity?.OPPORTUNITY_NAME, acc_id: opportunity?.ACCOUNT_ID, acc_name: opportunity?.ACCOUNT_NAME, props: opportunity });
      }

      // Reload members
      const result = await getTicketMembers(ticket?.id);
      setCurrentTicketMembers(result);

      messageApi.open({
        key: uuid(),
        type: 'success',
        content: 'Opportunity updated',
        duration: 3,
      });
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to update opportunity',
        duration: 3,
      });
    }
    setOpportunityModalOpen(false);
  };

  const handleAccountModalOnSelect = (v) => {
    setAccount(v);
  };

  const handleOpportunityModalOnSelect = (v) => {
    setOpportunity(v);
  };

  const handleDeploymentModalOnOk = () => {
    console.log('handleDeploymentModalOnOk');
    executeAsyncProcess(async () => {
      try {
        const { value: dep_id, label: dep_name } = deployment;

        if (onTicketChange) {
          await onTicketChange({ ...ticket, dep_id, dep_name });
        }

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Deployment updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
    setDeploymentModalOpen(false);
  };

  //#endregion

  //#region Properties... properties

  const getPropertyColor = () => {
    if (!propertyColor) return null;
    if (isDarkMode) return invertColor(propertyColor);
    return propertyColor;
  };

  const getPropertyBorder = () => {
    if (isHighlighted) return '2px dashed #EF4444';
    if (propertyColor) return `1px solid ${isDarkMode ? invertColor(propertyColor) : propertyColor}`;
    return `1px solid ${theme.borderLight}`;
  };

  const formatCurrency = (value, currency) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  };

  //#endregion

  const assignedSCLabel = () => {
    const assignedSCUserNames = currentTicketMembers?.filter((sc) => sc.role === 'Assigned SC').map((sc) => sc.user_name) || [];
    const bWarning = !currentTicket ? false : !assignedSCUserNames.includes(ticket?.props?.sc_name);

    return (
      <>
        <label className='text-xs'>
          <Tooltip title={ticket?.props?.sc_email}>
            {ticket?.props?.sc_name || (
              <>
                <Space>
                  <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                  N/A
                </Space>
              </>
            )}
          </Tooltip>
          {bWarning && (
            <Tooltip title='Assigned SC do not match current ticket Members'>
              <span className='ml-2 text-orange-400'>
                <WarningFilled />
              </span>
            </Tooltip>
          )}
        </label>
      </>
    );
  };

  const reloadOppDetails = async () => {
    console.log('reloadOppDetails()');

    try {
      const newDetails = await getTicketProperties(currentTicket.id);
      console.log('newDetails:', newDetails);

      if (newDetails && Object.keys(newDetails).length > 0) {
        //updateCurrentTicketProperties(newDetails);
        setCurrentTicket({ ...currentTicket, props: { ...currentTicket.props, ...newDetails } });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {contextHolder}
      {showJourney && ticket?.acc_id && (
        <div className='flex justify-center mb-4'>
          <Button type='primary' size='small' onClick={() => setJourneyModalOpen(true)}>
            <GiJourney className='mr-2' />
            Show Journey
          </Button>
        </div>
      )}
      <div className='mt-2' id='stakeholder-details' ref={canvasRef}>
        {/* ACCOUNT */}
        <div className='mt-1 relative rounded-lg flex flex-row' style={{ border: getPropertyBorder() }}>
          <div className='absolute top-[-9px] left-[8px] z-10 flex flex-row flex-wrap gap-2 text-xs px-1' style={{ backgroundColor: theme.backgroundBase }}>
            <div className='font-semibold opacity-80' style={{ color: getPropertyColor() }}>
              Account
            </div>
          </div>
          <div className='mb-4 mt-4 w-full'>
            <div className='flex flex-row'>
              {ticket?.acc_id && (
                <div className='flex-1 ml-2 min-w-0'>
                  <Tooltip title={`https://genesys.lightning.force.com/lightning/r/Account/${ticket?.acc_id}/view`}>
                    <Link className='block truncate' to={`https://genesys.lightning.force.com/lightning/r/Account/${ticket?.acc_id}/view`}>
                      {ticket?.acc_name}
                    </Link>
                  </Tooltip>
                  <div>
                    <span className='text-xs text-gray-500'>{ticket?.acc_id}</span>
                  </div>
                </div>
              )}

              <div className='flex flex-col items-center gap-1 mr-2'>
                {!forceRerender && (
                  <Typography.Text
                    className='text-xs text-left text-gray-500 scale-85'
                    copyable={
                      true === notEmptyString(ticket?.acc_name)
                        ? {
                            tooltips: [ticket?.acc_name, 'Copied'],
                            text: ticket?.acc_name,
                          }
                        : null
                    }
                  />
                )}
                {!noEdit && !determineIsWidgetReadOnly('Stakeholder Details') && (
                  <>
                    {ticket?.acc_id ? (
                      <Tooltip title='Edit Account'>
                        <FaEdit className='text-md text-genesys-azure-600' onClick={() => setAccountModalOpen(true)} />
                      </Tooltip>
                    ) : (
                      <div className='mb-4 mt-4 w-full flex flex-row'>
                        <div className='flex-1 ml-2 min-w-0 text-gray-500'>No account</div>
                        <div className='flex flex-col items-center gap-1 mr-2'>
                          <Tooltip title='Add an Account'>
                            <FaEdit className='text-md text-genesys-azure-600' onClick={() => setAccountModalOpen(true)} />
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <hr className='text-center opacity-10 w-[90%]' />
            <div className='flex flex-col gap-2 mt-2 text-gray-500 ml-2 mr-2'>
              <div className='flex justify-between'>
                <label className='text-xs font-semibold'>Region</label>
                <label className='text-xs'>
                  <Tooltip title={ticket?.props?.region}>
                    {ticket?.props?.region || (
                      <>
                        <Space>
                          <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                          N/A
                        </Space>
                      </>
                    )}
                  </Tooltip>
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* DEPLOYMENT */}
        {ticket.acc_id && true === gcDeploymentSupport && (
          <div className='mt-4'>
            <PropertyItem label='Deployment' value={ticket.dep_name} secondValue={ticket.dep_id} onClick={canUpdateDeployment ? () => setDeploymentModalOpen(true) : null} />
          </div>
        )}
        {/* OPPORTUNITY */}
        {(snowflakeOpportunitySupport || ticket?.opp_id) && (
          <div className='mt-4 relative rounded-lg flex flex-row' style={{ border: getPropertyBorder() }}>
            <div className='absolute top-[-9px] left-[8px] z-10 flex flex-row flex-wrap gap-2 text-xs px-1' style={{ backgroundColor: theme.backgroundBase }}>
              <div className='font-semibold opacity-80' style={{ color: getPropertyColor() }}>
                Opportunity
              </div>
            </div>
            {ticket?.opp_id ? (
              <div className='mb-4 mt-4 w-full'>
                {ticket?.opp_id && ticket?.props?.OPPORTUNITY_CRITICAL && <Alert message='Critical Opportunity' type='warning' size='small' showIcon className='scale-90 pt-1 pb-1 mb-2' />}
                {/* OPPORTUNITY ID & BUTTONS */}
                <div className='flex flex-row'>
                  {/* OPPORTUNITY ID */}
                  <div className='flex-1 ml-2 min-w-0'>
                    <Tooltip title={`https://genesys.lightning.force.com/lightning/r/Opportunity/${ticket?.opp_id}/view`}>
                      <Link className='block truncate' to={`https://genesys.lightning.force.com/lightning/r/Opportunity/${ticket?.opp_id}/view`}>
                        {ticket?.opp_name}
                      </Link>
                    </Tooltip>
                    <div>
                      <span className='text-xs text-gray-500'>{ticket?.opp_id}</span>
                    </div>
                  </div>
                  {/* COPY/EDIT BUTTONS */}
                  <div className='flex flex-col items-center gap-1 mr-2'>
                    {!forceRerender && (
                      <Typography.Text
                        className='text-xs text-left text-gray-500 scale-85'
                        copyable={{
                          tooltips: [ticket?.opp_name, 'Copied'],
                          text: ticket?.opp_name,
                        }}
                      />
                    )}
                    {ticket?.opp_id && !noEdit && !determineIsWidgetReadOnly('Stakeholder Details') && (
                      <Tooltip title='Edit Opportunity'>
                        <FaEdit className='text-md text-genesys-azure-600' onClick={() => setOpportunityModalOpen(true)} />
                      </Tooltip>
                    )}
                  </div>
                </div>
                <hr className='text-center opacity-10 w-[90%]' />
                {/* OPPORTUNITY PROPERTIES */}
                {ticket?.opp_id && (
                  <div className='flex flex-col gap-1 mt-1 text-gray-500 ml-2 mr-2'>
                    {/* ACV */}
                    <div className='flex flex-col items-center'>
                      <Tag color='magenta'>
                        ACV: {formatCurrency(ticket?.props?.gross_acv_booking || 0, ticket?.props?.currency_iso_code || 'USD')} {ticket?.props?.currency_iso_code === 'USD' ? '' : `(${formatCurrency(ticket?.props?.gross_acv_booking_usd?.toFixed(2) || 0, 'USD') || '$0'})`}
                      </Tag>
                      <div className='text-xs text-gray-500 mt-1'>
                        <span>(Gross ACV Booking Value)</span>
                      </div>
                    </div>
                    {/* OWNER */}
                    <div className='flex justify-between'>
                      <label className='text-xs font-semibold'>Owner</label>
                      <label className='text-xs'>
                        <Tooltip title={`${ticket?.props?.owner_email ? ticket?.props?.owner_email : ''}`}>
                          {ticket?.props?.owner_name || (
                            <>
                              <Space>
                                <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                                N/A
                              </Space>
                            </>
                          )}
                        </Tooltip>
                      </label>
                    </div>
                    {/* ASSIGNED SC */}
                    <div className='flex justify-between'>
                      <label className='text-xs font-semibold'>Assigned SC</label>
                      {assignedSCLabel()}
                    </div>
                    {/* STAGE */}
                    <div className='flex justify-between'>
                      <label className='text-xs font-semibold'>Stage</label>
                      <label className='text-xs'>
                        <Tooltip title={ticket?.props?.stage_name}>
                          {ticket?.props?.stage_name || (
                            <>
                              <Space>
                                <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                                N/A
                              </Space>
                            </>
                          )}
                        </Tooltip>
                      </label>
                    </div>
                    {/* # OF AGENTS */}
                    <div className='flex justify-between'>
                      <label className='text-xs font-semibold'># Agents</label>
                      <label className='text-xs'>
                        <Tooltip title={ticket?.props?.agents}>
                          {ticket?.props?.agents?.replace(/^\d+\.\s*/, '') || (
                            <>
                              <Space>
                                <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                                N/A
                              </Space>
                            </>
                          )}
                        </Tooltip>
                      </label>
                    </div>
                    {/* DSR */}
                    {ticket?.props?.dsr_url ? (
                      <div className='flex justify-between'>
                        <label className='text-xs font-semibold'>
                          <Link to={ticket?.props?.dsr_url}>
                            DSR <FaExternalLinkAlt />
                          </Link>
                        </label>
                        <label className='text-xs'>
                          {ticket?.props?.dsr_url ? (
                            <Typography.Text
                              className='text-xs text-left  text-gray-500  scale-85'
                              copyable={
                                true === notEmptyString(ticket?.acc_name)
                                  ? {
                                      tooltips: [ticket?.props?.dsr_url, 'Copied'],
                                      text: ticket?.props?.dsr_url,
                                    }
                                  : null
                              }
                            />
                          ) : (
                            <>
                              <Space>
                                <div className='bg-orange-400' style={{ height: 6, width: 6, borderRadius: 6, border: '0px', marginTop: '2px' }}></div>
                                N/A
                              </Space>
                            </>
                          )}
                        </label>
                      </div>
                    ) : (
                      <div className='flex justify-between mt-2'>
                        <label className='text-xs font-semibold'>(No DSR found)</label>
                      </div>
                    )}
                  </div>
                )}
                {/* DEPLOYMENT */}
                {ticket?.acc_id && true === gcDeploymentSupport && <PropertyItem label='Deployment' value={ticket?.dep_name} secondValue={ticket?.dep_id} onClick={canUpdateDeployment ? () => setDeploymentModalOpen(true) : null} />}
              </div>
            ) : (
              <div className='mb-4 mt-4 w-full'>
                <div className='flex flex-row'>
                  <div className='flex-1 ml-2 min-w-0 text-gray-500'>
                    No opportunity
                    <div>
                      <span className='text-xs text-gray-500'>{ticket?.opp_id}</span>
                    </div>
                  </div>
                  {!noEdit && !determineIsWidgetReadOnly('Stakeholder Details') && (
                    <div className='flex flex-col items-center gap-1 mr-2'>
                      <Tooltip title='Add an Opportunity'>
                        <FaEdit className='text-md text-genesys-azure-600' onClick={() => setOpportunityModalOpen(true)} />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {/* PROPERTIES */}
        {snowflakeOpportunitySupport && (
          <div className='flex flex-col gap-1 mt-4 text-gray-500 ml-2 mr-2'>
            {/* REGION */}
            <div className='flex justify-between flex-col 2xl:flex-row'>
              <div className='text-xs font-semibold overflow-hidden'>
                <span className='text-red-500 mr-1 leading-[1rem]'>*</span>Region
              </div>
              <Select
                className='w-full 2xl:w-2/3'
                loading={busy?.region}
                size='small'
                disabled={determineIsWidgetReadOnly('Stakeholder Details')}
                onChange={(v) => {
                  handlePropertyChanged('region', { region: v?.replace('NA', 'NORTH AMERICA'), sub_region: null });
                }}
                options={Object.keys(regions)?.map((x) => ({
                  value: x,
                  label: x,
                }))}
                value={ticket?.props?.region?.replace('NA', 'NORTH AMERICA')}
                popupMatchSelectWidth={false}
              />
            </div>
            {/* SUBREGION */}
            <div className='flex justify-between flex-col 2xl:flex-row'>
              <div className='text-xs font-semibold overflow-hidden'>Subregion</div>
              <Select
                className='w-full 2xl:w-2/3'
                loading={busy?.subRegion}
                size='small'
                disabled={determineIsWidgetReadOnly('Stakeholder Details')}
                onChange={(v) => {
                  handlePropertyChanged('subRegion', { sub_region: v });
                }}
                options={(regions[ticket?.props?.region?.replace('NA', 'NORTH AMERICA')] || []).map((subRegion) => ({
                  value: subRegion,
                  label: subRegion,
                }))}
                value={ticket?.props?.sub_region}
                popupMatchSelectWidth={false}
              />
            </div>
            {/* RELATIONSHIP */}
            <div className='flex justify-between flex-col 2xl:flex-row'>
              <div className='text-xs font-semibold overflow-hidden'>Relationship</div>
              <Select
                className='w-full 2xl:w-2/3 '
                loading={busy?.relationship}
                size='small'
                disabled={determineIsWidgetReadOnly('Stakeholder Details')}
                onChange={(v) => {
                  handlePropertyChanged('relationship', { direct_indirect_sale: v });
                }}
                options={directIndirects.map((x) => ({
                  value: x,
                  label: x,
                }))}
                value={ticket?.props?.direct_indirect_sale}
                popupMatchSelectWidth={false}
              />
            </div>
            {/* DEAL TYPE */}
            <div className='flex justify-between flex-col 2xl:flex-row'>
              <div className='text-xs font-semibold overflow-hidden'>Deal Type</div>
              <Select
                className='w-full 2xl:w-2/3'
                loading={busy?.dealType}
                size='small'
                disabled={determineIsWidgetReadOnly('Stakeholder Details')}
                onChange={(v) => {
                  // handlePropertyChanged('dealType', { OPPORTUNITY_DEAL_TYPE: v });
                  handlePropertyChanged('dealType', { deal_type: v });
                }}
                options={customerTypes.map((x) => ({
                  value: x,
                  label: x,
                }))}
                value={ticket?.props?.deal_type}
                popupMatchSelectWidth={false}
              />
            </div>
            {/* MARKET SEGMENT */}
            <div className='flex justify-between flex-col 2xl:flex-row'>
              <div className='text-xs font-semibold overflow-hidden'>Market Segment</div>
              <Select
                className='w-full 2xl:w-2/3'
                id='marketSegment'
                loading={busy?.marketSegment}
                size='small'
                disabled={determineIsWidgetReadOnly('Stakeholder Details')}
                onChange={(v) => {
                  // handlePropertyChanged('marketSegment', { OPPORTUNITY_SALES_SEGMENT: v });
                  handlePropertyChanged('marketSegment', { sales_segment: v });
                }}
                options={marketSegments.map((x) => ({
                  value: x,
                  label: x,
                }))}
                value={ticket?.props?.sales_segment}
                popupMatchSelectWidth={false}
              />
            </div>
            {/* PRODUCT */}
            <div className='flex justify-between flex-col 2xl:flex-row'>
              <div className='text-xs font-semibold overflow-hidden'>
                <span className='text-red-500 mr-1 leading-[1rem]'>*</span>Product
              </div>

              <Select
                className='w-full 2xl:w-2/3'
                loading={busy?.platform}
                size='small'
                disabled={determineIsWidgetReadOnly('Stakeholder Details')}
                onChange={(v) => {
                  // handlePropertyChanged('platform', { OPPORTUNITY_PRODUCT: v });
                  handlePropertyChanged('platform', { product: v });
                }}
                options={platforms.map((x) => ({
                  value: x,
                  label: x,
                }))}
                value={ticket?.props?.product}
                popupMatchSelectWidth={false}
              />
            </div>
            {/* # OF SEATS */}
            <div className='flex justify-between flex-col 2xl:flex-row'>
              <div className='text-xs font-semibold overflow-hidden'># Seats</div>
              <div className='w-full 2xl:w-2/3'>
                <InputNumber
                  className='w-full 2xl:w-2/3'
                  controls={false} // Hides up & down arrows
                  keyboard={false} // Disables arrow key increment/decrement
                  disabled={busy?.seats || determineIsWidgetReadOnly('Stakeholder Details')}
                  min={0}
                  max={100000}
                  onChange={handleNumberOfSeatsChanged}
                  size='small'
                  value={ticket?.props?.seats || 0}
                  addonAfter={
                    ticket && (
                      <Button disabled={!numberOfSeatsChanged} type='link' size='small' onClick={handleSaveSeatsClick} className='p-0'>
                        <span className='text-sm'>Save</span>
                      </Button>
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}

        {/* MODALS */}
        {accountModalOpen && (
          <Modal
            title={'Search for account'}
            open={true}
            onCancel={() => {
              setAccountModalOpen(false);
            }}
            onOk={handleAccountModalOnOk}
          >
            <SnowflakeAccountPicker onSelect={handleAccountModalOnSelect} />
          </Modal>
        )}
        {deploymentModalOpen && (
          <Modal
            title={'Select a deployment'}
            open={true}
            onCancel={() => {
              setDeploymentModalOpen(false);
            }}
            onOk={handleDeploymentModalOnOk}
          >
            <DeploymentPicker accId={ticket.acc_id} defaultValue={ticket.dep_id} onSelect={handleDeploymentModalOnSelect} />
          </Modal>
        )}
        {opportunityModalOpen && (
          <Modal
            title={
              <div className='flex flex-col items-center'>
                <span> Search for Opportunity</span>
                <p className='text-xs opacity-60 text-center'>
                  You can search by Name or paste full Opportuity URL like <i>https://genesys.lightning.force.com/lightning/r/Opportunity/0000T000.../view</i>
                </p>
              </div>
            }
            open={true}
            onCancel={() => {
              setOpportunityModalOpen(false);
            }}
            onOk={handleOpportunityModalOnOk}
            okButtonProps={{ disabled: isOkDisabled }}
          >
            <SnowflakeOpportunityPicker accountId={ticket?.acc_id} onSelect={handleOpportunityModalOnSelect} setBusy={setIsOkDisabled} />
          </Modal>
        )}
        {journeyModalOpen && (
          <Modal title='Journey' open={true} centered width={'75%'} onOk={() => setJourneyModalOpen(false)} autoFocusButton='ok' onCancel={() => setJourneyModalOpen(false)} cancelButtonProps={{ style: { display: 'none' } }}>
            <Journey ticketId={ticket?.id} ticketCreatedDate={ticket?.created_at} ticketClosedDate={ticket?.closed_at} accId={ticket?.acc_id} oppId={ticket?.opp_id} />
          </Modal>
        )}
      </div>
    </>
  );
};

StakeholderDetails.propTypes = {
  ticket: PropTypes.object.isRequired,
  propertyColor: PropTypes.string,
  isHighlighted: PropTypes.bool,
  onTicketChange: PropTypes.func,
  onPropertiesChange: PropTypes.func,
  noEdit: PropTypes.bool,
};

export default StakeholderDetails;

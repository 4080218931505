import React, { useState } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const CommentEditor = ({ initialValue, onUpdate }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <div className='w-64'>
      <Input.TextArea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder='Add a comment...'
        className='w-64 mb-4'
        autoSize={{ minRows: 2, maxRows: 6 }}
        onBlur={() => {
          if (value === initialValue) return;
          onUpdate(value);
        }}
        showCount
        maxLength={500}
      />
    </div>
  );
};

CommentEditor.propTypes = {
  initialValue: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default CommentEditor;

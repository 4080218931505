import React, { useEffect, useContext } from 'react';
import DataNexusContext from 'src/context/DataNexusContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getDictionariesAll } from 'src/api/datanexus';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import PropTypes from 'prop-types';

const DictLoader = ({ children }) => {
  const { dictionaries, setDictionaries } = useContext(DataNexusContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  useEffect(() => {
    if (null === dictionaries) loadData();
  }, []);

  useEffect(() => {
    console.log('dictionaries:', dictionaries);
  }, [dictionaries]);

  const loadData = () =>
    executeAsyncProcess(async () => {
      try {
        console.log('Loading data nexus dictionaries');
        const resp = await getDictionariesAll();
        console.log('resp:', resp);
        setDictionaries(resp);
      } catch (error) {
        console.error(error);
      }
    });

  if (!dictionaries) return <FullScreenSpin title='Please wait' subtitle='Loading dictionaries' />;
  return <>{children}</>;
};

DictLoader.propTypes = {
  children: PropTypes.node,
};

export default DictLoader;

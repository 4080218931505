import { getAccessHeaders, handleResponseLegacy } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';

//#region "SERVICE HOSTING"

var controller = null;

export const getServiceHostings = async (page, pageSize, orderBy, orderDirection, regions, useOtherRegions, features, types, names, query) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  if (orderDirection?.startsWith('asc')) orderDirection = 'asc';
  if (orderDirection?.startsWith('desc')) orderDirection = 'desc';
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/service-hosting${objToQueryStringParams({ page, pageSize, orderBy, orderDirection, regions, useOtherRegions, features, types, names, query })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getServiceHosting = async (id) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/service-hosting/${id}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const patchServiceHosting = async (id, changes) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/service-hosting/${id}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const postServiceHosting = async (item) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/service-hosting`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    })
  );
};

export const deleteServiceHosting = async (id) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/service-hosting/${id}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "SUBCONTRACTORS"

export const getSubcontractors = async (page, pageSize, orderBy, orderDirection, query) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  if (orderDirection?.startsWith('asc')) orderDirection = 'asc';
  if (orderDirection?.startsWith('desc')) orderDirection = 'desc';
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/subcontractors${objToQueryStringParams({ page, pageSize, orderBy, orderDirection, query })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getSubcontractorsList = async () => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/subcontractors-list`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getSubcontractor = async (id) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/subcontractors/${id}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const patchSubcontractor = async (id, changes) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/subcontractors/${id}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const postSubcontractor = async (item) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/subcontractors`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    })
  );
};

export const deleteSubcontractor = async (id) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/subcontractors/${id}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const patchServiceRelation = async (item) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/services`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    })
  );
};

export const postServiceRelation = async (item) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/services`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    })
  );
};

export const deleteServiceRelation = async (item) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/services`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    })
  );
};

//#endregion

//#region "DICTIONARIES"

export const getDictionariesAll = async () =>
  handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/dict`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

//#endregion

//#region Subscriptions

export const getSubscriptionStatus = async () => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/subscriptions/status`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postSubscriber = async () => {
  // const userId = getAccessHeaders().userId;
  console.log('getAccessHeaders:', getAccessHeaders());
  // return handleResponseLegacy(
  //   await fetch(`${ENV.apiUrl}/datanexus/subscriptions`, {
  //     method: 'POST',
  //     headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
  //     body: JSON.stringify({
  //       user_id: userId,

  //     }),
  //   })
  // );
};

export const deleteSubscriber = async (id) => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/datanexus/subscriptions`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

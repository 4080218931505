import React from 'react';
import { Alert } from 'antd';

//import PropTypes from 'prop-types';

const TenantNotFound = () => {
  return (
    <>
      <div className='mt-8'>
        <Alert message={<span className='font-bold text-lg'>Before Genesys Cloud users can sign in using your organization, an administrator needs to complete the initial portal setup.</span>} type='warning' showIcon />
        <div className='my-4 font-bold text-2xl'>What should you do?</div>
        <div>
          Please follow <a href='/Products/getting-started'>the Getting Started process</a> to complete the initial portal setup. If you have any questions, please contact <a href='mailto:spc.portal@genesys.com'>spc.portal@genesys.com</a>.
        </div>
      </div>
    </>
  );
};

TenantNotFound.propTypes = {};

export default TenantNotFound;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getAdminService, patchAdminServices } from 'src/api/teacket';
import { Typography } from 'antd';
import Na from 'src/components/layout/Na';
import { notEmptyString } from 'src/misc/Misc';

const TeacketServiceEditorSettings = ({ serviceId }) => {
  const { executeAsyncProcess } = useAsyncProcesses();

  const [service, setService] = useState(null);

  useEffect(() => {
    loadData();
  }, [serviceId]);

  const loadData = () =>
    executeAsyncProcess(async () => {
      try {
        const resp = await getAdminService(serviceId, ['description']);
        setService(resp);
      } catch (error) {
        console.log(error);
      }
    });

  const handleDescriptionOnChange = (description) =>
    executeAsyncProcess(async () => {
      console.log('handleDescriptionOnChange()', description);
      if (description === service?.description) return;
      try {
        const resp = await patchAdminServices(serviceId, { description });
        setService(resp);
      } catch (error) {
        console.log(error);
      }
    });

  return (
    <div className='px-4'>
      <div className='font-light text-xs opacity-60'>Description:</div>
      <div>
        <Typography.Paragraph
          editable={{
            onChange: handleDescriptionOnChange,
            text: service?.description ?? '',
          }}
        >
          {true === notEmptyString(service?.description) ? service?.description : <Na />}
        </Typography.Paragraph>
      </div>
    </div>
  );
};

TeacketServiceEditorSettings.propTypes = {
  serviceId: PropTypes.string,
};

export default TeacketServiceEditorSettings;

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
// import useTheme from 'src/hooks/useTheme';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const ConversationHistoryItemHistoryObj = ({ item }) => {
  //const { theme } = useTheme();
  return (
    // <div className={'my-4 px-16 mx-0 text-xs text-center w-full'} style={{ wordBreak: 'break-all', lineHeight: '0.9rem' }}>
    //   <div className='opacity-60 font-light'>{dayjs(item?.timestamp).fromNow()}</div> <div className='opacity-80'>{item?.message}</div>
    // </div>
    // <div className={'m-4'}>
    //   <div className={'flex text-xs justify-center font-light opacity-60 text-center w-full'}>
    //     {item?.message} {dayjs(item?.timestamp).fromNow()}
    //   </div>
    // </div>
    <div className={'my-4 mx-0 text-xs text-center w-full'} style={{ wordBreak: 'break-all', lineHeight: '0.9rem' }}>
      <div className='px-2 lg:px-16'>
        <span className='opacity-80'>{item?.message}</span> <span className='opacity-60 font-light'>{dayjs(item?.timestamp).fromNow()}</span>
      </div>
    </div>
  );
};

ConversationHistoryItemHistoryObj.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ConversationHistoryItemHistoryObj;

import React, { useContext, useEffect } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TeacketContext from 'src/context/TeacketContext';
import { getTicketWidgetsValues } from 'src/api/teacket';
import CustomFieldString from './CustomFieldString';
import CustomFieldInt from './CustomFieldInt';
import CustomFieldDateTime from './CustomFieldDateTime';
import CustomFieldNumber from './CustomFieldNumber';
import CustomFieldBool from './CustomFieldBool';
import CustomFieldSelect from './CustomFieldSelect';
import { Alert, message } from 'antd';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import NoData from 'src/components/layout/NoData';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import { uuid } from 'short-uuid';
import CustomFieldUrl from './CustomFieldUrl';

//import PropTypes from 'prop-types';

const TicketCustomProperties = () => {
  const { currentTicket, currentTicketCustomFields, setCurrentTicketCustomFields } = useContext(TeacketContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!currentTicket) return;
    loadData();
  }, [currentTicket?.id]);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const result = await getTicketWidgetsValues(currentTicket.id, 'Additional Properties');
        setCurrentTicketCustomFields(result);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleCustomFieldOnUpdate = (e) => {
    if (!e.ticketId && e?.data?.details?.ticket_id !== currentTicket?.id) return;
    console.log('handleCustomFieldOnUpdate()', e);
    const key = uuid();
    try {
      if (e?.action === 'TICKET_CF_UPDATED') e = { action: 'TICKET_CF_UPDATED', field: { field: e?.data?.details?.field }, result: e?.data?.details };

      let cf = [...currentTicketCustomFields];
      let index = cf.findIndex((x) => x.field === e.field.field);
      cf[index] = e.result;
      setCurrentTicketCustomFields(cf);

      messageApi.open({
        key,
        type: 'success',
        content: `${e.field.field} updated`,
        duration: 3,
      });
    } catch (error) {
      console.error(error);
      messageApi.open({
        key,
        type: 'error',
        content: 'Failed to update',
        duration: 3,
      });
    }
  };

  if (!currentTicketCustomFields) return <SkeletonTable />;
  if (currentTicketCustomFields.length === 0) return <NoData />;

  return (
    <div className='flex flex-col gap-2'>
      {contextHolder}
      <GlobalWsMessenger onMessageReceived={handleCustomFieldOnUpdate} actionList={['TICKET_CF_UPDATED']}></GlobalWsMessenger>
      {currentTicketCustomFields.map((field, i) => {
        switch (field.type) {
          case 'String':
            return <CustomFieldString key={i} ticketId={currentTicket.id} field={field} onUpdate={handleCustomFieldOnUpdate} />;
          case 'URL':
            return <CustomFieldUrl key={i} ticketId={currentTicket.id} field={field} onUpdate={handleCustomFieldOnUpdate} />;
          case 'Int':
            return <CustomFieldInt key={i} ticketId={currentTicket.id} field={field} onUpdate={handleCustomFieldOnUpdate} />;
          case 'DateTime':
            return <CustomFieldDateTime key={i} ticketId={currentTicket.id} field={field} onUpdate={handleCustomFieldOnUpdate} />;
          case 'Number':
            return <CustomFieldNumber key={i} ticketId={currentTicket.id} field={field} onUpdate={handleCustomFieldOnUpdate} />;
          case 'Bool':
            return <CustomFieldBool key={i} ticketId={currentTicket.id} field={field} onUpdate={handleCustomFieldOnUpdate} />;
          case 'Select':
            return <CustomFieldSelect key={i} ticketId={currentTicket.id} field={field} onUpdate={handleCustomFieldOnUpdate} />;
          default:
            return <Alert key={i} message={`Unsupported field type: ${field.type}`} type='error' showIcon />;
        }
      })}
    </div>
  );
};

TicketCustomProperties.propTypes = {};

export default TicketCustomProperties;

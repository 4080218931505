import React, { useEffect } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageTitle, pageList } from '../Misc/misc';
import Space2 from 'src/components/layout/Space2';
import LoaderDict from 'src/components/pages/DataNexus/Loaders/DictLoader';

const DataNexusHome = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Data Nexus',
      },
    ]);

    //loadSubscriptionStatus();

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.datanexus.view']}>
      <LoaderDict>
        <TitleBar
          title={pageTitle}
          afterTitleExtras={
            <Space2>
              <PageSwitch pageList={pageList} />
            </Space2>
          }></TitleBar>
        Hello
      </LoaderDict>
    </PermissionsProvider>
  );
};

export default DataNexusHome;

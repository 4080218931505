import React, { Component } from 'react';
import DataNexusContext from './DataNexusContext';

export default class DataNexusProvider extends Component {
  state = {
    // DICTIONARIES
    dictionaries: null,
    // ADMIN SERVICE HOSTING
    adminServiceHostingFilters: null,
    adminServiceHostingAddNew: false,
    adminServiceHostingSorter: false,
    adminServiceHostingExport: false,
    // SUBCONTRACTORS
    subcontractorsList: [],
    subcontractorsFilters: null,
    subcontractorsSorter: false,
    subcontractorsAddNew: false,

    // OTHERS
    // ...
  };

  render() {
    return (
      <DataNexusContext.Provider
        value={{
          ...this.state,
          // DICTIONARIES
          setDictionaries: (dictionaries) => {
            this.setState({ dictionaries });
          },
          setSubcontractorsList: (subcontractorsList) => {
            console.log('subcontractorsList', subcontractorsList);
            this.setState({ subcontractorsList });
          },
          // ADMIN SERVICE HOSTING
          setAdminServiceHostingFilters: (name, value) => {
            let { adminServiceHostingFilters } = this.state;
            if (!adminServiceHostingFilters) adminServiceHostingFilters = {};
            adminServiceHostingFilters = { ...adminServiceHostingFilters, [name]: value };
            this.setState({ adminServiceHostingFilters });
          },
          setHomeLayoutItemsSelector: (homeLayoutItemsSelector) => this.setState({ homeLayoutItemsSelector }),
          setAdminServiceHostingAddNew: (adminServiceHostingAddNew) => this.setState({ adminServiceHostingAddNew }),
          setSubcontractorsAddNew: (subcontractorsAddNew) => this.setState({ subcontractorsAddNew }),
          setAdminServiceHostingSorter: (adminServiceHostingSorter) => this.setState({ adminServiceHostingSorter }),
          setAdminServiceHostingExport: (adminServiceHostingExport) => this.setState({ adminServiceHostingExport }),
          // SUBCONTRACTORS
          setSubcontractorsFilters: (name, value) => {
            let { subcontractorsFilters } = this.state;
            if (!subcontractorsFilters) subcontractorsFilters = {};
            subcontractorsFilters = { ...subcontractorsFilters, [name]: value };
            this.setState({ subcontractorsFilters });
          },

          setSubcontractorsSorter: (subcontractorsSorter) => this.setState({ subcontractorsSorter }),
          // ...
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </DataNexusContext.Provider>
    );
  }
}

import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const FullScreenSpin = ({ title, subtitle, height = '100vh', size = 'large' }) => {
  return (
    <div className='flex flex-col justify-center items-center' style={{ height }}>
      <div>
        <Spin size={size} />
      </div>
      <div className='mt-4 text-lg font-semibold'>{title}</div>
      <div className='text-sm font-extralight'>{subtitle}</div>
    </div>
  );
};

FullScreenSpin.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.string,
};

export default FullScreenSpin;

import React, { useState, useEffect } from 'react';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import Chat from '../../controls/SecurityQAGPT/Chat';
import MobileSiderbar from '../../controls/SecurityQAGPT/MobileSidebar';
import Sidebar from '../../controls/SecurityQAGPT/Sidebar';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import FeatureAccess from 'src/components/controls/FeatruesAccess/FeaturesAccess';
import { theme } from 'antd';

const SecurityQAGPTHome = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  const [isComponentVisible, setIsComponentVisible] = useState(false);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'COE AI Assistant',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const toggleComponentVisibility = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <PermissionsProvider requiredPermissions={['apps.copilot.view']}>
      <FeatureAccess feature='ai_assistant' featureDisplayName='COE AI Assistant'>
        <div className='overflow-hidden w-full h-screen relative flex'>
          {isComponentVisible ? <MobileSiderbar toggleComponentVisibility={toggleComponentVisibility} /> : null}
          <div className='dark hidden flex-shrink-0 md:flex md:w-[260px] md:flex-col' style={{ background: colorBgContainer }}>
            <div className='flex h-full min-h-0 flex-col'>
              <Sidebar />
            </div>
          </div>
          <Chat toggleComponentVisibility={toggleComponentVisibility} />
        </div>
      </FeatureAccess>
    </PermissionsProvider>
  );
};

export default SecurityQAGPTHome;

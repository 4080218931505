import React, { useEffect, useState, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import { message, Space, Table, Tag, Tooltip, Button, Progress } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import { pageList } from '../Misc/misc';
import PageSwitch from 'src/components/layout/PageSwitch';
import TicketListControl from '../TicketList/components/TicketListControl';
import DashboardFilters from '../Dashboard/components/DashboardFilters/DashboardFilters';
import dayjs from 'dayjs';
import { getWinLossData } from 'src/api/snowflake';
import { getTicketCounts } from 'src/api/teacket';
import useExport from 'src/hooks/useExport';
import { FaDownload } from 'react-icons/fa';
import styled from 'styled-components';
import { dateTimeSorter } from 'src/misc/Misc';

const StyledDiv = styled.div`
  .ant-table {
    margin-block: 0 !important;
    margin-inline: 0 !important;
  }
`;

const messageKey = 'counter';

const WinLossReport = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { exportToCSV } = useExport();

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    size: 'small',
    position: ['bottomRight'],
  });

  const [messageApi, contextHolder] = message.useMessage();
  const [ticketCountProgress, setTicketCountProgress] = useState(0);
  const [mainTableDisabled, setMainTableDisabled] = useState(false);

  const { dashboardListServices, dashboardRegions, dashboardSubRegions, dashboardQuarters, dashboardTypes, dashboardRequestTypes, dashboardRequestStatuses, dashboardRequestCategories, dashboardRequestSubCategories } = useContext(TeacketContext);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Dashboards',
      },
      {
        title: 'Win Loss Report',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    console.log('Dashboard List Services:', dashboardListServices);
    setMainTableDisabled(true);
    setExpandedKeys([]);
  }, [dashboardListServices]);

  const loadData = async () => {
    console.log('Loading data...');
    setMainTableDisabled(false);
    if (!dashboardQuarters || dashboardQuarters.length === 0) {
      message.warning('Please select at least one quarter');
      return;
    }

    let hideLoadingSnowflakeMessage, hideCountMessage;
    try {
      setLoading(true);
      setData([]);
      setTicketCountProgress(0);
      let currentData = [];

      //#region Get top opportunities from Snowflake

      hideLoadingSnowflakeMessage = messageApi.open({
        key: messageKey,
        type: 'loading',
        content: 'Getting opportunities from Snowflake...',
        duration: 0,
      });

      const resp = await getWinLossData(dashboardRegions, dashboardSubRegions, dashboardQuarters, dashboardTypes);

      if (!resp.presignedUrl) {
        message.error('Failed to get opportunities from Snowflake');
        return;
      }

      let opportunities = await fetch(resp.presignedUrl).then((res) => res.json());
      if (!opportunities) {
        message.error('Failed to download results');
        return;
      }

      currentData = JSON.parse(opportunities);
      if (hideLoadingSnowflakeMessage) hideLoadingSnowflakeMessage(); // Close the loading message

      //#endregion

      //#region Get ticket counts for each account & opportunity

      hideCountMessage = messageApi.open({
        key: messageKey,
        type: 'loading',
        content: 'Analyzing tickets...',
        duration: 0,
      });

      const sfData = currentData.map((item) => {
        return {
          accountId: item['Acc 18 Digit ID'],
          opportunityId: item['18 Digit ID'],
        };
      });

      const ticketCounts = await getAllTicketCounts(sfData);
      console.log('ticketCounts:', ticketCounts);

      const dataWithKeysAndCounts = currentData.map((item, index) => {
        if (item['Acc 18 Digit ID'] && item['18 Digit ID']) {
          // Both account and opportunity are set
          const ticketCount = ticketCounts.find((count) => count.acc_id === item['Acc 18 Digit ID'] && count.opp_id === item['18 Digit ID']);
          return {
            ...item,
            key: `${item.member}-${index}`,
            ticketCounts: ticketCount ? ticketCount.count : 0, // Add ticket count to the item
          };
        } else if (item['Acc 18 Digit ID']) {
          // Only account is set
          const ticketCount = ticketCounts.find((count) => count.acc_id === item['Acc 18 Digit ID']);
          return {
            ...item,
            key: `${item.member}-${index}`,
            ticketCounts: ticketCount ? ticketCount.count : 0, // Add ticket count to the item
          };
        } else {
          // Neither account nor opportunity is set
          return {
            ...item,
            key: `${item.member}-${index}`,
            ticketCounts: 0, // Add ticket count to the item
          };
        }
      });

      //#endregion

      setData(dataWithKeysAndCounts);
      message.success('All data loaded!'); // Show success message
    } catch (error) {
      console.error(error);
      if (error.message.indexOf('404') !== -1) {
        message.warning('No opportunities found');
      } else {
        message.error('Failed to load data');
      }
    } finally {
      if (hideLoadingSnowflakeMessage) hideLoadingSnowflakeMessage(); // Close the loading message
      if (hideCountMessage) hideCountMessage(); // Close the loading message
      setLoading(false);
    }
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const getAllTicketCounts = async (sfData) => {
    try {
      const chunkSize = 100; // Adjust the chunk size as needed to avoid hitting the API return data size limit
      const ticketCounts = [];

      const sfDataChunks = chunkArray(sfData, chunkSize);

      let index = 0;
      for (const chunk of sfDataChunks) {
        index++;
        // Update progress based on the chunks processed:
        const progressPercent = Math.min(Math.round((index / sfDataChunks.length) * 100), 100);
        setTicketCountProgress(progressPercent);

        const chunkTicketCounts = await getTicketCounts(chunk, dashboardListServices, dashboardRequestTypes, dashboardRequestStatuses, dashboardRequestCategories, dashboardRequestSubCategories);
        ticketCounts.push(...chunkTicketCounts);
      }

      setTicketCountProgress(100);

      return ticketCounts;
    } catch (error) {
      console.error('Failed to get ticket counts for accounts:', sfData, error);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  const formatCurrency = (value, currency) => {
    if (!value || !currency) return value;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(value);
  };

  const sortByQuarterAndYear = (a, b) => {
    const [quarterA, yearA] = a['Closing Fiscal Quarter'].split('-');
    const [quarterB, yearB] = b['Closing Fiscal Quarter'].split('-');

    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }

    return quarterA.localeCompare(quarterB);
  };

  const truncateText = (text, maxLength) => {
    if (!text) return text;
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: 'Tickets',
      dataIndex: 'ticketCounts',
      key: 'ticketCounts',
      fixed: 'center',
      sorter: (a, b) => {
        return a.ticketCounts - b.ticketCounts;
      },
      sortOrder: sortedInfo.columnKey === 'ticketCounts' && sortedInfo.order,
      render: (text) => <Tag className='px-3 py-1.5 rounded-full font-medium border-0 bg-genesys-azure-100 text-genesys-azure-800 dark:bg-genesys-azure-900 dark:text-genesys-azure-100'>{text}</Tag>,
    },
    {
      title: 'Account Info',
      children: [
        {
          title: 'Account',
          dataIndex: 'Account Name',
          key: 'Account Name',
          fixed: 'center',
          sorter: (a, b) => a['Account Name']?.localeCompare(b['Account Name']),
          sortOrder: sortedInfo.columnKey === 'Account Name' && sortedInfo.order,
          render: (text, record) => (
            <a href={`https://genesys.lightning.force.com/lightning/r/Account/${record['Acc 18 Digit ID']}/view`} target='_blank' rel='noopener noreferrer'>
              {text}
            </a>
          ),
        },
        {
          title: 'Opportunity',
          dataIndex: 'Opportunity Name',
          key: 'Opportunity Name',
          fixed: 'center',
          sorter: (a, b) => a['Opportunity Name']?.localeCompare(b['Opportunity Name']),
          sortOrder: sortedInfo.columnKey === 'Opportunity Name' && sortedInfo.order,
          render: (text, record) => (
            <Tooltip title={text}>
              <a href={`https://genesys.lightning.force.com/lightning/r/Opportunity/${record['18 Digit ID']}/view`} target='_blank' rel='noopener noreferrer'>
                {truncateText(text, 15)}
              </a>
            </Tooltip>
          ),
        },
      ],
    },
    {
      title: 'ACV',
      children: [
        {
          title: 'Local',
          dataIndex: 'Gross ACV Booking',
          key: 'Gross ACV Booking',
          fixed: 'center',
          sorter: (a, b) => a.Value - b.Value,
          sortOrder: sortedInfo.columnKey === 'Gross ACV Booking' && sortedInfo.order,
          render: (text, record) => <div className='float-right'>{formatCurrency(text || 0, record['Currency ISO Code'])}</div>,
        },
        {
          title: 'Currency',
          hidden: true,
          dataIndex: 'Currency',
          key: 'Currency',
          fixed: 'center',
          sorter: (a, b) => a.Currency?.localeCompare(b.Currency),
          sortOrder: sortedInfo.columnKey === 'Currency' && sortedInfo.order,
        },
        {
          title: 'USD ($)',
          dataIndex: 'ValueUSD',
          key: 'ValueUSD',
          fixed: 'center',
          sorter: (a, b) => a.ValueUSD - b.ValueUSD,
          sortOrder: sortedInfo.columnKey === 'ValueUSD' && sortedInfo.order,
          render: (text) => <div className='float-right'>{formatCurrency(text || 0, 'USD')}</div>,
        },
      ],
    },
    {
      title: 'Closing Q.',
      dataIndex: 'Closing Fiscal Quarter',
      key: 'Closing Fiscal Quarter',
      fixed: 'center',
      sorter: sortByQuarterAndYear,
      sortOrder: sortedInfo.columnKey === 'Closing Fiscal Quarter' && sortedInfo.order,
    },
    {
      title: 'Territory L4',
      dataIndex: 'Territory L4',
      key: 'Territory L4',
      fixed: 'center',
      sorter: (a, b) => a['Territory L4']?.localeCompare(b['Territory L4']),
      sortOrder: sortedInfo.columnKey === 'Territory L4' && sortedInfo.order,
    },
    {
      title: 'Sub Region L5',
      dataIndex: 'Sub Region L5',
      key: 'Sub Region L5',
      fixed: 'center',
      sorter: (a, b) => a['Sub Region L5']?.localeCompare(b['Sub Region L5']),
      sortOrder: sortedInfo.columnKey === 'Sub Region L5' && sortedInfo.order,
    },
    {
      title: 'Sub Region',
      dataIndex: 'Owner Sub Region',
      key: 'Owner Sub Region',
      fixed: 'center',
      sorter: (a, b) => a['Owner Sub Region']?.localeCompare(b['Owner Sub Region']),
      sortOrder: sortedInfo.columnKey === 'Owner Sub Region' && sortedInfo.order,
    },
    {
      title: 'Owner',
      dataIndex: 'Opportunity Owner',
      key: 'Opportunity Owner',
      fixed: 'center',
      sorter: (a, b) => a['Opportunity Owner']?.localeCompare(b['Opportunity Owner']),
      sortOrder: sortedInfo.columnKey === 'Opportunity Owner' && sortedInfo.order,
    },
    {
      title: 'Owner Role',
      dataIndex: 'Owner Role',
      key: 'Owner Role',
      fixed: 'center',
      sorter: (a, b) => a['Owner Role']?.localeCompare(b['Owner Role']),
      sortOrder: sortedInfo.columnKey === 'Owner Role' && sortedInfo.order,
    },
    {
      title: 'Owner Sub Region',
      dataIndex: 'Owner Sub Region',
      key: 'Owner Sub Region',
      fixed: 'center',
      sorter: (a, b) => a['Owner Sub Region']?.localeCompare(b['Owner Sub Region']),
      sortOrder: sortedInfo.columnKey === 'Owner Sub Region' && sortedInfo.order,
    },
    {
      title: 'Lead Offer',
      dataIndex: 'Lead Offer',
      key: 'Lead Offer',
      fixed: 'center',
      sorter: (a, b) => a['Lead Offer']?.localeCompare(b['Lead Offer']),
      sortOrder: sortedInfo.columnKey === 'Lead Offer' && sortedInfo.order,
    },
    {
      title: 'Direct/Indirect Sale',
      dataIndex: 'Direct/Indirect Sale',
      key: 'Direct/Indirect Sale',
      fixed: 'center',
      sorter: (a, b) => a['Direct/Indirect Sale']?.localeCompare(b['Direct/Indirect Sale']),
      sortOrder: sortedInfo.columnKey === 'Direct/Indirect Sale' && sortedInfo.order,
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      fixed: 'center',
      sorter: (a, b) => a.Type?.localeCompare(b.Type),
      sortOrder: sortedInfo.columnKey === 'Type' && sortedInfo.order,
    },
    {
      title: 'Offering',
      dataIndex: 'Offering Detail',
      key: 'Offering Details',
      fixed: 'center',
      sorter: (a, b) => a['Offering Detail']?.localeCompare(b['Offering Detail']),
      sortOrder: sortedInfo.columnKey === 'Offering Detail' && sortedInfo.order,
    },
    {
      title: 'Number of Agents',
      dataIndex: 'Number of Agents',
      key: 'Number of Agents',
      fixed: 'center',
      sorter: (a, b) => a['Number of Agents']?.localeCompare(b['Number of Agents']),
      sortOrder: sortedInfo.columnKey === 'Number of Agents' && sortedInfo.order,
    },
    {
      title: 'Primary Competitor',
      dataIndex: 'Primary Competitor',
      key: 'Primary Competitor',
      fixed: 'center',
      sorter: (a, b) => a.Renewal?.localeCompare(b['Primary Competitor']),
      sortOrder: sortedInfo.columnKey === 'Primary Competitor' && sortedInfo.order,
    },
    {
      title: 'Stage',
      dataIndex: 'Stage',
      key: 'Stage',
      fixed: 'center',
      sorter: (a, b) => a.Stage?.localeCompare(b.Stage),
      sortOrder: sortedInfo.columnKey === 'Stage' && sortedInfo.order,
    },
    {
      title: 'Forecast',
      dataIndex: 'Forecast Status',
      key: 'Forecast Status',
      fixed: 'center',
      sorter: (a, b) => a['Forecast Status']?.localeCompare(b['Forecast Status']),
      sortOrder: sortedInfo.columnKey === 'Forecast Status' && sortedInfo.order,
    },
    {
      title: 'Sold To/Business Partner',
      dataIndex: 'Sold To/Business Partner',
      key: 'Sold To/Business Partner',
      fixed: 'center',
      sorter: (a, b) => a['Sold To/Business Partner']?.localeCompare(b['Sold To/Business Partner']),
      sortOrder: sortedInfo.columnKey === 'Sold To/Business Partner' && sortedInfo.order,
    },
    {
      title: 'Who won this Opportunity',
      dataIndex: 'Who won this Opportunity',
      key: 'Who won this Opportunity',
      fixed: 'center',
      sorter: (a, b) => a['Who won this Opportunity']?.localeCompare(b['Who won this Opportunity']),
      sortOrder: sortedInfo.columnKey === 'Who won this Opportunity' && sortedInfo.order,
    },
    {
      title: 'Primary Win/Loss Reason',
      dataIndex: 'Primary Win/Loss Reason',
      key: 'Primary Win/Loss Reason"',
      fixed: 'center',
      sorter: (a, b) => a['Primary Win/Loss Reason']?.localeCompare(b['Primary Win/Loss Reason']),
      sortOrder: sortedInfo.columnKey === 'Primary Win/Loss Reason' && sortedInfo.order,
    },

    {
      title: 'Close Date',
      dataIndex: 'Close Date',
      key: 'Close Date',
      fixed: 'center',
      sorter: (a, b) => dateTimeSorter(a, b, 'Close Date'),
      sortOrder: sortedInfo.columnKey === 'Close Date' && sortedInfo.order,
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(text).format('LL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
  ];

  const expandableConfig = {
    expandRowByClick: true,
    expandedRowKeys: expandedKeys,
    expandedRowRender: (record) => {
      if (!record['Account ID']) return null;

      return <TicketListControl accountId={record['Acc 18 Digit ID']} opportunityId={record['18 Digit ID']} />;
    },
    onExpand: async (expanded, record) => {
      setExpandedKeys((prevKeys) => {
        if (expanded) {
          return [...prevKeys, record.key];
        }
        return prevKeys.filter((key) => key !== record.key);
      });
    },
    rowExpandable: (record) => record.ticketCounts > 0,
  };

  return (
    <StyledDiv>
      {contextHolder}
      <PermissionsProvider requiredPermissions={['apps.teacket.dashboards.winloss.view']}>
        <LoaderServicesForTenant>
          <TitleBar
            isLoading={loading}
            title={
              <Space className='btn'>
                <BsCupHotFill />
                <div>
                  <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
                </div>
              </Space>
            }
            afterTitleExtras={
              <div className='flex flex-row items-center gap-4'>
                <div>
                  <PageSwitch pageList={pageList} />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      exportToCSV({ columns, data, fileName: 'GreenTeacketWinLossReport.csv' });
                    }}
                  >
                    <FaDownload />
                  </Button>
                </div>
              </div>
            }
            bottomExtras={<DashboardFilters id='winloss-report' onSubmit={() => loadData()} includeDateRange={false} includeServices includeRegions includeSubRegions includeQuarters includeTypes collapsible={false} additionalDescriptions={{ includeQuarters: 'It refers to the Closing Fiscal Quarter of the Opportunity (Snowflake)' }} />}
            defaultExpanded
          />
          <div className='p-6 flex flex-col gap-4'>
            {/* Render the progress bar while ticket counts are loading */}
            {mainTableDisabled && data?.length > 0 && <div className='text-sm text-amber-500 text-center mb-2'>Services Filter changed, you need to re-Apply filters.</div>}

            <Table
              bordered
              loading={{
                spinning: loading,
                indicator: (
                  <>
                    {loading && ticketCountProgress > 0 && ticketCountProgress < 100 && (
                      <div className='mb-4 text-center'>
                        <Progress percent={ticketCountProgress} status='active' type='dashboard' trailColor='rgba(0, 0, 0, 0.06)' steps={12} strokeWidth={10} />
                      </div>
                    )}
                  </>
                ),
              }}
              columns={columns}
              dataSource={data}
              onChange={handleChange}
              pagination={pagination}
              size='small'
              expandable={expandableConfig}
              scroll={{ x: 'max-content' }}
              className={`shadow-sm resizable-table rounded-lg overflow-hidden ${mainTableDisabled && data?.length > 0 && 'disabled blur-sm'} `}
            />
          </div>
        </LoaderServicesForTenant>
      </PermissionsProvider>
    </StyledDiv>
  );
};

export default WinLossReport;

import React, { useEffect, useContext, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import ConversationHistoryItem from './ConversationHistoryItem';
import ConversationHistoryItemHistoryObj from './ConversationHistoryItemHistoryObj';
import { getTicketConversation } from 'src/api/teacket';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import ScrollableSection from 'src/components/layout/ScrollableSection';
import { notEmptyArray } from 'src/misc/Misc';
import useTheme from 'src/hooks/useTheme';
import useUserInfo from 'src/hooks/useUserInfo';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import PropertySection from 'src/components/layout/PropertySection';
import * as dayjs from 'dayjs';

const ConversationHistory = () => {
  const { id: myUserId } = useUserInfo();
  const { theme } = useTheme();
  const { currentTicket, currentTicketConversation, setCurrentTicketConversation, addCurrentTicketConversationMessage, updateCurrentTicketConversationMessage } = useContext(TeacketContext);

  const [scrollDown, setScrollDown] = useState(true);
  const [isBusy, setIsBusy] = useState(false);

  const EVENT_TYPE = { TICKET_HISTORY_ITEM_ADDED: 'TICKET_HISTORY_ITEM_ADDED' };

  useEffect(() => {
    if (!currentTicket?.id) return;
    if (!currentTicketConversation) loadData();
  }, [currentTicket]);

  useEffect(() => {
    if (true === scrollDown) return;
    setScrollDown(true);
  }, [scrollDown]);

  useEffect(() => {
    setScrollDown(false);
  }, [currentTicketConversation]);

  const loadData = async () => {
    try {
      setIsBusy(true);
      const resp = await getTicketConversation(currentTicket.id);
      console.log('resp', resp);
      setCurrentTicketConversation(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setIsBusy(false);
    }
  };

  const handleOnWsMessageReceived = async (message) => {
    console.log('handleOnWsMessageReceived', message);
    try {
      if (message?.data?.ticket_id !== currentTicket.id) return;
      switch (message.action) {
        case 'CONVERSATION_CREATED':
          addCurrentTicketConversationMessage(message.data);
          break;

        case 'CONVERSATION_UPDATED':
          updateCurrentTicketConversationMessage(message.data);
          break;
        case 'TICKET_PII_PURGED':
          // Let's do it one-by-one instaead of reaload all. Nicer experience.
          message?.data?.messages?.forEach((m) => {
            updateCurrentTicketConversationMessage(m);
          });
          //loadData();
          break;
        case EVENT_TYPE.TICKET_HISTORY_ITEM_ADDED:
          if (!message?.data?.body) break;
          const body = JSON.parse(message?.data?.body);
          if (currentTicket.id !== body.ticket_id) break;
          const item = { id: message.messageId, timestamp: dayjs().format(), user_id: body.user_id, history: true, message: body.historyItemText };
          console.log('item', item);
          addCurrentTicketConversationMessage(item);
          break;

        default:
          console.error('Unknown action', message.action);
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['CONVERSATION_CREATED', 'CONVERSATION_UPDATED', 'TICKET_PII_PURGED', EVENT_TYPE.TICKET_HISTORY_ITEM_ADDED]}></GlobalWsMessenger>
      {isBusy ? (
        <PropertySection>
          <FullScreenSpin height='120px' subtitle='Loading conversation history' size='normal' />
        </PropertySection>
      ) : (
        true === notEmptyArray(currentTicketConversation) && (
          <ScrollableSection maxHeight='50vh' scrollDown={scrollDown} edgeGradientColor={theme.backgroundBase + 'ee'}>
            {currentTicketConversation.map((item, index) => (!item?.history ? <ConversationHistoryItem key={index} item={item} side={myUserId === item.user_id ? 'right' : 'left'} /> : <ConversationHistoryItemHistoryObj key={index} item={item} />))}
          </ScrollableSection>
        )
      )}
    </>
  );
};

export default ConversationHistory;

import React, { Component } from 'react';
import MasterAdminContext from './MasterAdminContext';

var teacketServicePropData1 = null;
export default class MasterAdminProvider extends Component {
  state = {
    adminStats: null,
    homePageFilter: '',
    questions: null,
    users: null,
    // TENANT
    tenant: null,
    // TEACKET
    teacketServicePropList: null,
    teacketServicePropData: null,
    teacketServicePropShowModal: null,
    // ADMIN MESSAGES
    adminMessagesList: null,
    adminMessagesQuery: null,
    adminMessagesSelectedId: null,
    adminMessagesSelected: null,
    adminMessagesTabsDisabled: false,
    adminMessagesNewCreated: false,
    // BREACH NOTIFICATIONS
    breachNotificationsList: null,
    breachNotificationsQuery: null,
    breachNotificationsSelectedId: null,
    breachNotificationsSelected: null,
    breachNotificationsTabsDisabled: false,
    breachNotificationsNewCreated: false,
    // GREEN TEACKET SERVICE ROUTING
    greenTeacketServiceRoutingQuery: null,
    // INVITATION LINKS
    tenantRolesCount: null,
  };

  render() {
    return (
      <MasterAdminContext.Provider
        value={{
          ...this.state,

          //#region Users
          setUsers: (users) => {
            this.setState({ users });
          },
          addNewCreatedUser: (user) => {
            console.log('addNewCreatedUser', user);
            const users = [{ ...user, newCreated: true }].concat(this.state.users);
            this.setState({ users });
          },
          updateUser: (user_id, props) => {
            console.log('updateUser', user_id, props);
            const users = this.state.users.map((u) => {
              if (user_id === u.user_id) {
                return { ...u, ...props };
              }
              return u;
            });
            this.setState({ users });
          },
          deleteUser: (user_id) => {
            console.log('deleteUser', user_id);
            const users = this.state.users.filter((u) => user_id !== u.user_id);
            this.setState({ users });
          },
          //#endregion

          //#region Questions
          setQuestions: (questions) => {
            this.setState({ questions });
          },
          addNewCreatedQuestion: (question) => {
            console.log('addNewCreatedQuestion', question);
            const questions = [{ ...question, newCreated: true }].concat(this.state.questions);
            this.setState({ questions });
          },
          updateQuestion: (questionId, props) => {
            console.log('updateQuestion', questionId, props);
            const questions = this.state.questions.map((q) => {
              if (questionId === q.id) {
                return { ...q, ...props };
              }
              return q;
            });
            this.setState({ questions });
          },
          deleteQuestion: (questionId) => {
            console.log('deleteQuestion', questionId);
            const questions = this.state.questions.filter((q) => questionId !== q.id);
            this.setState({ questions });
          },
          //#endregion

          //#region Admin Stats
          setAdminStats: (adminStats) => {
            this.setState({ adminStats });
          },
          //#endregion

          //#region Home Page Filter
          setHomePageFilter: (homePageFilter) => {
            this.setState({ homePageFilter });
          },
          //#endregion

          //#region "TENANT"
          setTenant: (tenant) => this.setState({ tenant }),
          //#endregion

          //#region "TEACKET"
          setTeacketServicePropList: (teacketServicePropList) => {
            this.setState({ teacketServicePropList });
          },

          setTeacketServicePropData: (propName, data) => {
            const teacketServicePropData = this.state.teacketServicePropData ?? {};
            teacketServicePropData[propName] = data;
            teacketServicePropData1 = teacketServicePropData;
            this.setState({ teacketServicePropData });
          },

          getTeacketServicePropData: (propName) => {
            return teacketServicePropData1?.[propName] ?? null;
          },

          resetTeacketServicePropData: () => {
            this.setState({ teacketServicePropData: null });
          },

          setTeacketServicePropShowModal: (propName, record, mode) => {
            if (!propName) {
              this.setState({ teacketServicePropShowModal: null });
              return;
            }
            this.setState({ teacketServicePropShowModal: { propName, record, mode } });
          },
          //#endregion

          //#region "ADMIN MESSAGES"
          setAdminMessagesList: (adminMessagesList) => this.setState({ adminMessagesList }),
          setAdminMessagesQuery: (adminMessagesQuery) => this.setState({ adminMessagesQuery }),
          setAdminMessagesSelectedId: (adminMessagesSelectedId) => this.setState({ adminMessagesSelectedId }),
          setAdminMessagesSelected: (adminMessagesSelected) => this.setState({ adminMessagesSelected }),
          setAdminMessagesTabsDisabled: (adminMessagesTabsDisabled) => this.setState({ adminMessagesTabsDisabled }),
          setAdminMessagesNewCreated: (adminMessagesNewCreated) => this.setState({ adminMessagesNewCreated }),
          //#endregion

          //#region Breach Notifications
          setBreachNotificationsList: (breachNotificationsList) => this.setState({ breachNotificationsList }),
          setBreachNotificationsQuery: (breachNotificationsQuery) => this.setState({ breachNotificationsQuery }),
          setBreachNotificationsSelectedId: (breachNotificationsSelectedId) => this.setState({ breachNotificationsSelectedId }),
          setBreachNotificationsSelected: (breachNotificationsSelected) => this.setState({ breachNotificationsSelected }),
          setBreachNotificationsTabsDisabled: (breachNotificationsTabsDisabled) => this.setState({ breachNotificationsTabsDisabled }),
          setBreachNotificationsNewCreated: (breachNotificationsNewCreated) => this.setState({ breachNotificationsNewCreated }),
          //#endregion

          //#region "GREEN TEACKET SERVICE ROUTING"
          setGreenTeacketServiceRoutingQuery: (greenTeacketServiceRoutingQuery) => this.setState({ greenTeacketServiceRoutingQuery }),
          //#endregion

          //#region "INVITATION LINKS"
          setTenantRolesCount: (tenantRolesCount) => this.setState({ tenantRolesCount }),
          //#endregion
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </MasterAdminContext.Provider>
    );
  }
}

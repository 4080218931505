import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Button, Space, Switch, Popover, Spin } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import { RiMailSettingsFill } from 'react-icons/ri';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from './Misc/misc';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import UserTicketListWidget from './Components/UserTicketListWidget/UserTicketListWidget';
import { MdAdd, MdManageAccounts } from 'react-icons/md';
import GraphMyTicketsActive from './Components/GraphMyTicketsActive';
import GraphMyOverdue from './Components/GraphMyOverdue';
import GraphMyTicketsByPriority from './Components/GraphMyTicketsByPriority';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import LoaderServicesForTenant from './Loaders/LoaderServicesForTenant';
import Space2 from 'src/components/layout/Space2';
import useTheme from 'src/hooks/useTheme';
import StyledButton from 'src/components/layout/StyledButton';
import useUserInfo from 'src/hooks/useUserInfo';
import UserTicketListWidgetExtras from './Components/UserTicketListWidget/UserTicketListWidgetExtras';
import { hasUserPermission } from 'src/misc/Session';
import { getUsersMeProps, putUsersMeProps } from 'src/api/users';

//import PropTypes from 'prop-types';

const Home = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { permissions: userPermissions } = useUserInfo();

  const [loading, setLoading] = useState(true);
  const [mailSettings, setMailSettings] = useState({
    conversationUpdates: false,
    memberAdditions: false,
    statusChanges: false,
    dailySummaries: false,
  });
  const [switchLoading, setSwitchLoading] = useState({
    conversationUpdates: false,
    memberAdditions: false,
    statusChanges: false,
    dailySummaries: false,
  });

  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const fetchUserMailSettings = async () => {
    setLoading(true);
    try {
      const resp = await getUsersMeProps('greenTeacketMailSettings');

      if (!resp?.value) {
        setMailSettings({
          conversationUpdates: true,
          memberAdditions: true,
          statusChanges: true,
          dailySummaries: true,
        });
      } else {
        setMailSettings(resp?.value);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchChange = (key) => async (checked) => {
    setSwitchLoading((prev) => ({ ...prev, [key]: true }));
    const newSettings = {
      ...mailSettings,
      [key]: checked,
    };
    setMailSettings(newSettings);
    await putUsersMeProps('greenTeacketMailSettings', newSettings);
    setSwitchLoading((prev) => ({ ...prev, [key]: false }));
  };

  const mailPreferencesContent = (
    <div style={{ padding: '10px', width: '300px' }}>
      {loading ? (
        <div className='text-center'>
          <Spin />
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <div>
              <span>Conversation Updates</span>
              <div className='text-xs font-light opacity-60'>Setting related to an email notification for every conversation update (email with Missed Conversation updates)</div>
            </div>
            <Switch checked={mailSettings.conversationUpdates} onChange={handleSwitchChange('conversationUpdates')} loading={switchLoading.conversationUpdates} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <div>
              <span>Member Additions</span>
              <div className='text-xs font-light opacity-60'>Related to email notifications when being added as a member</div>
            </div>
            <Switch checked={mailSettings.memberAdditions} onChange={handleSwitchChange('memberAdditions')} loading={switchLoading.memberAdditions} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <div>
              <span>Status Changes</span>
              <div className='text-xs font-light opacity-60'>Subscribe/unsubscribe to status changes</div>
            </div>
            <Switch checked={mailSettings.statusChanges} onChange={handleSwitchChange('statusChanges')} loading={switchLoading.statusChanges} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <div>
              <span>Daily Summaries</span>
              <div className='text-xs font-light opacity-60'>Subscribe/unsubscribe to daily summaries</div>
            </div>
            <Switch checked={mailSettings.dailySummaries} onChange={handleSwitchChange('dailySummaries')} loading={switchLoading.dailySummaries} />
          </div>
        </>
      )}
    </div>
  );

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.view']}>
      <LoaderServicesForTenant>
        <TitleBar
          title={
            <Space className='btn'>
              <BsCupHotFill />
              <div>
                <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
              </div>
            </Space>
          }
          afterTitleExtras={
            <Space2>
              <PageSwitch pageList={pageList} />
              <StyledButton color={theme.textBase} size='small' onClick={() => navigate('/teacket/create-new')}>
                <Space2>
                  <MdAdd />
                  <WhenDesktop>Create new</WhenDesktop>
                </Space2>
              </StyledButton>
            </Space2>
          }>
          <Popover content={mailPreferencesContent} title={<div className='text-center'>Mail Preferences</div>} trigger='click' onOpenChange={(visible) => visible && fetchUserMailSettings()}>
            <Button type='text'>
              <RiMailSettingsFill />
            </Button>
          </Popover>

          <PermissionsProvider requiredPermissions={['master.admin']} isControl>
            <Button
              type='text'
              onClick={() => {
                navigate('/master-admin/teacket-services');
              }}>
              <MdManageAccounts />
            </Button>
          </PermissionsProvider>
        </TitleBar>

        <div className='grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-3 gap-4 p-4 pb-0'>
          <DraggableLayoutItem title={<Space className='btn'>My Active Tickets by Status</Space>} hideDraggable={true} height={'300px'} centered>
            <GraphMyTicketsActive hideLegend={true} />
          </DraggableLayoutItem>

          {true === hasUserPermission('apps.teacket.ticket.edit', userPermissions) ? (
            <DraggableLayoutItem title={<Space className='btn'>My Overdue Tickets</Space>} hideDraggable={true} height={'300px'} centered>
              <GraphMyOverdue />
            </DraggableLayoutItem>
          ) : (
            <DraggableLayoutItem title={''} hideDraggable={true} height={'300px'} centered>
              <div className='flex items-center justify-center opacity-5 h-[240px]'>
                <BsCupHotFill style={{ transform: 'scale(5)' }} />
              </div>
            </DraggableLayoutItem>
          )}
          <DraggableLayoutItem title={<Space className='btn'>My Active Tickets by Priority</Space>} hideDraggable={true} height={'300px'} centered>
            <GraphMyTicketsByPriority />
          </DraggableLayoutItem>
        </div>

        <div className='grid-auto p-4'>
          <DraggableLayoutItem
            titleExtras={<UserTicketListWidgetExtras />}
            title={
              <Space className='btn'>
                {/* <MdOutlineSecurity /> */}
                My Open Tickets
              </Space>
            }
            hideDraggable={true}
            centered>
            <UserTicketListWidget />
          </DraggableLayoutItem>
        </div>

        {/* <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-2 gap-4 p-4 pb-0'>
          <DraggableLayoutItem title={<Space className='btn'>My Oldest Active Ticket</Space>} hideDraggable={true} centered height={'300px'}>
            <GraphMyTicketsActiveOldest />
          </DraggableLayoutItem>
        </div> */}

        {/* <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={2} mainColumnIndex={0} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutTeacketHome} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutTeacketHomeHidden} enabled={null === currentTicket} /> */}
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// Home.propTypes = {};

export default Home;

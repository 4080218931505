import dayjs from 'dayjs';

export const sleepMs = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export const textSorter = (a, b, fieldName) => {
  const v1 = (a[fieldName] ?? '').toLowerCase();
  const v2 = (b[fieldName] ?? '').toLowerCase();
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const textSorterNested = (a, b, fieldName1, fieldName2) => {
  const v1 = (a[fieldName1][fieldName2] ?? '').toLowerCase();
  const v2 = (b[fieldName1][fieldName2] ?? '').toLowerCase();
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const textSorterStrArray = (v1, v2) => {
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const dateTimeSorter = (a, b, fieldName) => {
  return dayjs(a[fieldName]).diff(b[fieldName]);
};

export const arrayLengthSorter = (a, b, fieldName) => {
  const v1 = a[fieldName]?.length ?? 0;
  const v2 = b[fieldName]?.length ?? 0;
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const boolSorter = (a, b) => {
  if (a && !b) return -1;
  else if (!a && b) return 1;
  else return 0;
};

export const numberSorter = (a, b, fieldName) => {
  const v1 = isNaN(a[fieldName]) ? 0 : a[fieldName];
  const v2 = isNaN(b[fieldName]) ? 0 : b[fieldName];
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const sorterMockup = () => 0;

export const numberSorterNested = (a, b, fieldName1, fieldName2) => {
  const v1 = isNaN(a[fieldName1]) || isNaN(a[fieldName1][fieldName2]) ? 0 : a[fieldName1][fieldName2];
  const v2 = isNaN(b[fieldName1]) || isNaN(b[fieldName1][fieldName2]) ? 0 : b[fieldName1][fieldName2];
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const notArrayOrEmpty = (arr) => !Array.isArray(arr) || arr.length < 1;

export const notEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;

export const notEmptyString = (str) => {
  if (!str) return false;
  if (typeof str !== 'string') return false;
  if (str.length < 1) return false;
  return true;
};

export const intToBitRepresentationArray = (integer) => {
  console.log('intToBitRepresentationArray()', integer);
  let binaryNum = new Array(32).fill(0);
  let i = 0;
  while (integer > 0) {
    binaryNum[i] = integer % 2;
    integer = Math.floor(integer / 2);
    i++;
  }
  const result = [];
  i = 1;
  for (const bit of binaryNum) {
    if (bit === 1) result.push(i);
    i *= 2;
  }
  return result;
};

export const getDistinct = (array, fieldName) => {
  let result = [];
  for (const item of array) {
    if (!item[fieldName] || result.includes(item[fieldName])) continue;
    result.push(item[fieldName]);
  }
  return result;
};

export const getDistinct1 = (array) => {
  let result = [];
  for (const item of array) {
    if (!item || result.includes(item)) continue;
    result.push(item);
  }
  return result;
};

export const getDistinct3 = (array, fieldName) => {
  let result = [];
  for (const item of array) {
    if (!item[fieldName] || true === result.some((x) => item[fieldName] === x[fieldName])) continue;
    result.push(item);
  }
  return result;
};

export const getDistinct4 = (arr) => {
  const isEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) return false;
    }
    return true;
  };

  return arr.filter((item, index, self) => index === self.findIndex((t) => isEqual(t, item)));
};

export const areStringArraysSimilar = (arr1, arr2) => {
  if (!arr1 && !arr2) return true;
  if (arr1 && !arr2) return false;
  if (!arr1 && arr2) return false;
  if (arr1.length !== arr2.length) return false;
  for (const item of arr1) if (!arr2.includes(item)) return false;
  for (const item of arr2) if (!arr1.includes(item)) return false;
  return true;
};

export const groupBy = (array, field, extras = []) => {
  let result = [];
  for (const item of array) {
    let resultItem = result.find((x) => x[field] === item[field]);
    if (!resultItem) {
      resultItem = { items: [] };
      resultItem[field] = item[field];
      if (notEmptyArray(extras)) {
        for (const extraField of extras) {
          resultItem[extraField] = item[extraField];
        }
      }
      result.push(resultItem);
    }
    resultItem.items.push(item);
  }
  return result;
};

export const objToQueryStringParams = (obj) => {
  const result = [];
  for (const prop in obj) {
    if (!obj[prop]) continue;
    const paramName = prop.replace(/[^a-zA-Z0-9_]/g, '');
    const paramValue = encodeURIComponent(obj[prop]);
    result.push(`${paramName}=${paramValue}`);
  }
  if (notArrayOrEmpty(result)) return '';
  return `?${result.join('&')}`;
};

export const splitBufferIntoBatches = (buffer, size) => {
  let result = [];
  for (let i = 0; i < buffer.byteLength; i += size) {
    const batch = buffer.slice(i, i + size);
    result.push({ batch, from: i, to: i + batch.byteLength - 1 });
  }
  return result;
};

export const splitArrayIntoBatches = (arr, size) => {
  if (!Array.isArray(arr) || !size || size < 1) return [];

  let result = [];
  for (let i = 0; i < arr.length; i += size) {
    const batch = arr.slice(i, Math.min(i + size, arr.length));
    result.push({
      batch,
      from: i,
      to: i + batch.length - 1,
    });
  }
  return result;
};

export const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const scrubHtml = (html) => html.replace(/(<([^>]+)>)/gi, ' ');

export const autoOrderComponentsGallery = (componentsGallery, columns) => {
  let currentCol = 0;
  let result = [];
  for (const c of componentsGallery) {
    if (!c.col) c.col = currentCol;
    result.push(c);
    currentCol++;

    if (currentCol >= columns) currentCol = 0;
    // console.log('autoOrderComponentsGallery()', currentCol);
  }
  return result;
};

export const getDictionaryKey = (dictionary, key) => {
  if (true === notArrayOrEmpty(dictionary) || !key) return null;
  const result = dictionary.find((x) => x.key === key);
  return result;
};

export const invertColor = (hex, bw) => {
  // Function to convert hex to RGB
  function hexToRgb(hex) {
    let bigint = parseInt(hex.slice(1), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return [r, g, b];
  }

  // Function to convert RGB to HSL
  function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }
      h /= 6;
    }

    return [h, s, l];
  }

  // Function to convert HSL to RGB
  function hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  // Function to convert RGB to hex
  function rgbToHex(r, g, b) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
  }

  // Convert hex to RGB
  let [r, g, b] = hexToRgb(hex);

  // Convert RGB to HSL
  let [h, s, l] = rgbToHsl(r, g, b);

  // Invert the lightness
  l = 1 - l;

  // Convert HSL back to RGB
  let [rNew, gNew, bNew] = hslToRgb(h, s, l);

  // Convert RGB back to hex
  return rgbToHex(rNew, gNew, bNew);
};
// </ https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color>

export const isRequestCancelled = (error) => {
  const errorString = error.toString();
  if (errorString.includes('cancelled')) return true; // Chrome
  if (errorString.includes('AbortError')) return true; // Firefox
  if (errorString.includes('Fetch is aborted')) return true; // Safari
  return false;
};

export const getMonths = (from, to) => {
  console.log('getMonths()', from, to);
  if (!from || !to) return [];
  let result = [];
  let current = from.clone();
  while (current.isBefore(to)) {
    result.push({ from: dayjs(current.format('YYYY-MM-DD')).utc(true).startOf('month').startOf('day'), to: dayjs(current.format('YYYY-MM-DD')).utc(true).endOf('month').endOf('day') });
    current = current.add(1, 'month');
  }
  return result;
};

export const getWeeks = (from, to) => {
  console.log('getWeeks()', from, to);
  if (!from || !to) return [];
  let result = [];
  let current = from.clone();
  while (current.isBefore(to)) {
    result.push({ from: dayjs(current.format('YYYY-MM-DD')).utc(true).startOf('isoWeek').startOf('day'), to: dayjs(current.format('YYYY-MM-DD')).utc(true).endOf('isoWeek').endOf('day') });
    current = current.add(1, 'week');
  }
  return result;
};

export const localDownload = (data, filename, type) => {
  var file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    var a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const truncateText = (text, maxLength) => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + '...';
};

export const selectFilterValueAndLabel = (i, o) => o.label.toLowerCase().includes(i.toLowerCase()) || o.value.toLowerCase().includes(i.toLowerCase());
export const selectFilterLabel = (i, o) => o.label.toLowerCase().includes(i.toLowerCase());

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getDistinct4, notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getServicesPropQuery } from 'src/api/teacket';
import PropertySection from 'src/components/layout/PropertySection';
import { FaPlusCircle } from 'react-icons/fa';
import useTheme from 'src/hooks/useTheme';
import { Popover } from 'antd';
import DashboardFiltersUsersSelector from './DashboardFiltersUsersSelector';
import DashboardFiltersUsersTag from './DashboardFiltersUsersTag';

const DashboardFiltersUsers = ({ serviceIdList, defaultValue, onChange }) => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();

  const [roleList, setRoleList] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(null);

  useEffect(() => {
    if (true === notArrayOrEmpty(defaultValue)) return;
    setSelectedMembers(defaultValue);
  }, [roleList]);

  useEffect(() => {
    console.log('serviceIdList:', serviceIdList);
    setSelectedMembers(null);
    if (true === notArrayOrEmpty(serviceIdList)) {
      setRoleList(null);
      return;
    }
    loadRoleList();
  }, [serviceIdList]);

  useEffect(() => {
    if (!onChange) return;
    onChange(selectedMembers);
  }, [selectedMembers]);

  const loadRoleList = () => {
    console.log('loadRoleList()');
    executeAsyncProcess(async () => {
      try {
        const result = await getServicesPropQuery(serviceIdList, ['roleList'], ['role']);

        setRoleList(result?.map((x) => x.role));
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnApply = (e) => {
    console.log('handleOnApply()', e);

    if (!e) return;
    if (!e.selectedRole) return;
    if (true === notArrayOrEmpty(e.selectedMembers)) return;

    if (true === notEmptyArray(selectedMembers)) {
      for (const sm of e.selectedMembers) {
        if (selectedMembers.find((x) => x.user_id === sm.user_id && x.role === e.role)) return;
      }
    }

    let new1 = e.selectedMembers.map((x) => {
      return { ...x, role: e.selectedRole };
    });
    let m = getDistinct4([...(true === notEmptyArray(selectedMembers) ? selectedMembers : []), ...new1]);

    setSelectedMembers(m);
    setShowAddUserModal(false);
  };

  const handleOnCancel = () => {
    console.log('handleOnCancel()');
    setShowAddUserModal(false);
  };

  const handleOnDelete = (user_id, role) => {
    console.log('handleOnDelete()', user_id, role);
    if (!user_id) return;
    let m = selectedMembers.filter((x) => !(x.user_id === user_id && x.role === role));
    setSelectedMembers(m);
  };

  return (
    <>
      <PropertySection roundedSize='md' bgColor={true === notArrayOrEmpty(serviceIdList) ? theme.backgroundDisabled : null} px={true === notEmptyArray(selectedMembers) ? 1 : 3}>
        <div className='flex flex-row items-center gap-2 flex-wrap'>
          {/* {roleList?.join(', ')} */}
          {true === notArrayOrEmpty(serviceIdList) && <div className='text-sm opacity-30'>Select a service first</div>}
          {true === notEmptyArray(serviceIdList) && (
            <>
              {true === notArrayOrEmpty(selectedMembers) && <div className='text-sm opacity-30'>[All members]</div>}
              {true === notEmptyArray(selectedMembers) &&
                selectedMembers.map((x, i) => (
                  <React.Fragment key={i}>
                    <DashboardFiltersUsersTag user_id={x.user_id} user_name={x.user_name} role={x.role} onDelete={handleOnDelete} />
                  </React.Fragment>
                ))}
              <Popover placement='bottom' content={<DashboardFiltersUsersSelector serviceIdList={serviceIdList} roleList={roleList} onCancel={handleOnCancel} onApply={handleOnApply} />} trigger={['click']} open={showAddUserModal} onOpenChange={(visible) => setShowAddUserModal(visible)}>
                <FaPlusCircle style={{ color: theme.primary }} className='cursor-pointer hover:scale-110' />
              </Popover>
            </>
          )}
        </div>
      </PropertySection>
    </>
  );
};

DashboardFiltersUsers.propTypes = {
  serviceIdList: PropTypes.array,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
};

export default DashboardFiltersUsers;

import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';
import { FaHome } from 'react-icons/fa';
import { TbLockAccess } from 'react-icons/tb';
import { PiUsersFourBold } from 'react-icons/pi';

export const pageList = [
  {
    path: '/dashboards',
    label: 'Home',
    icon: <FaHome />,
    permissions: ['apps.dashboards.view'],
  },
  {
    path: '/dashboards/features-access',
    label: 'Features Access',
    icon: <TbLockAccess />,
    permissions: [MASTER_ADMIN_PERMISSION],
  },
  {
    path: '/dashboards/who-is-signed-in-now',
    label: 'Who is signed in now?',
    icon: <PiUsersFourBold />,
    permissions: [MASTER_ADMIN_PERMISSION],
  },
];

import React, { useEffect, useContext, useRef } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { notArrayOrEmpty, getDistinct3 } from 'src/misc/Misc';
import PropertySection from 'src/components/layout/PropertySection';
import useTheme from 'src/hooks/useTheme';
import { FaAt } from 'react-icons/fa';

const ConversationPickMember = ({ onPick, onCancel }) => {
  const { currentTicketMembers } = useContext(TeacketContext);
  const ref = useRef(null);
  const { theme } = useTheme();

  useEffect(() => {
    ref.current && ref.current.focus();
  }, []);

  const handleOnKeyDown = (e) => {
    if (e.key === 'Escape' && onCancel) onCancel();
  };

  const getOptionList = () => {
    if (true === notArrayOrEmpty(currentTicketMembers)) return [];
    return getDistinct3(
      currentTicketMembers.map((member) => {
        return { label: member.user_name, value: member.user_name };
      }),
      'label'
    );
  };

  const handleOnSelect = (value) => {
    if (onPick) onPick(value);
  };

  return (
    <PropertySection bgColor={theme.backgroundBase} px={4} py={2}>
      <div className='text-xs flex flex-row items-center gap-1 flex-wrap'>
        <FaAt /> <span className='font-bold'>Mention a member</span> <span className='font-light'>to send her/him an email notification</span>
      </div>
      <Select className='w-full' size='small' allowClear showSearch ref={ref} placeholder='Type to search....' onKeyDown={handleOnKeyDown} options={getOptionList()} onSelect={handleOnSelect} />{' '}
    </PropertySection>
  );
};

ConversationPickMember.propTypes = {
  onPick: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ConversationPickMember;

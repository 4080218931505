import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GeoJSON, useMap, Polygon } from 'react-leaflet';
import L from 'leaflet';
import countriesData from '../../../assets/geojson/countries.geojson';
import { gcRegionList } from '../../../misc/Config';

const GeoJSONLayer = ({ region, style }) => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const map = useMap();

  // Debug the actual content of countriesData
  useEffect(() => {
    console.log('Countries data type:', typeof countriesData);
    console.log('Countries data structure:', countriesData);

    // Check if features exist
    if (countriesData && countriesData.features) {
      console.log('Number of features:', countriesData.features.length);
      if (countriesData.features.length > 0) {
        console.log('First feature sample:', countriesData.features[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (!region) {
      setLoading(false);
      return;
    }

    try {
      // First check if the countries data is loaded
      if (!countriesData || !countriesData.features || countriesData.features.length === 0) {
        console.error('No country data available');
        setError(new Error('GeoJSON data not loaded correctly'));
        setLoading(false);
        return;
      }

      // Get region config from gcRegionList
      const regionConfig = gcRegionList.find((item) => item.region === region);

      if (!regionConfig) {
        console.error(`Region not found in config: ${region}`);
        setError(new Error(`Region configuration not found: ${region}`));
        setLoading(false);
        return;
      }

      // Get the country code from the config
      const countryIdentifier = regionConfig.geoJSONKey;

      if (!countryIdentifier) {
        console.error(`No geoJSONKey found for region: ${region}`);
        setError(new Error(`No geoJSONKey in config for: ${region}`));
        setLoading(false);
        return;
      }

      console.log(`Looking for country with identifier: ${countryIdentifier}`);

      // Filter the countries.geojson to find the matching country
      const countryFeature = countriesData.features.find((feature) => {
        return feature.properties && (feature.properties.ISO_A3 === countryIdentifier || feature.properties.ISO_A2 === countryIdentifier || feature.properties.ADMIN === countryIdentifier);
      });

      if (!countryFeature) {
        console.error(`Could not find country data for: ${countryIdentifier} (region: ${region})`);
        if (countriesData.features && countriesData.features.length > 0) {
          console.log(
            'Available country codes sample:',
            countriesData.features
              .slice(0, 10)
              .filter((f) => f?.properties?.ISO_A3)
              .map((f) => f.properties.ISO_A3)
              .join(', ')
          );
        }
        setError(new Error(`Country data not found for: ${countryIdentifier}`));
        setLoading(false);
        return;
      }

      console.log('Found country feature for', region, ':', countryFeature.properties?.ADMIN || countryFeature.properties?.ISO_A3);

      // Create a new GeoJSON object with just this feature
      const filteredGeoJson = {
        type: 'FeatureCollection',
        features: [countryFeature],
      };

      setGeoJsonData(filteredGeoJson);

      // Fit bounds to this country
      if (map) {
        const geoJsonLayer = new L.GeoJSON(filteredGeoJson);
        map.fitBounds(geoJsonLayer.getBounds(), { padding: [20, 20] });
      }

      setLoading(false);
    } catch (err) {
      console.error(`Error processing GeoJSON for ${region}:`, err);
      setError(err);
      setLoading(false);
    }
  }, [region, map]);

  // Define default styling if none provided - with red color as requested
  const defaultStyle = {
    color: 'red',
    weight: 2,
    opacity: 1,
    fillColor: 'red',
    fillOpacity: 0.3,
  };

  // Use provided style or default to red
  const effectiveStyle = style || defaultStyle;

  // If loading or error, use a fallback to the polygon if possible
  if (loading || error || !geoJsonData) {
    // Find region in config to get boundaries
    const regionConfig = gcRegionList.find((item) => item.region === region);
    if (regionConfig?.boundaries) {
      console.log('Falling back to polygon boundaries for', region);
      // Return polygon as fallback
      return <Polygon positions={regionConfig.boundaries} pathOptions={effectiveStyle} />;
    }
    return null;
  }

  return <GeoJSON data={geoJsonData} style={() => effectiveStyle} />;
};

GeoJSONLayer.propTypes = {
  region: PropTypes.string,
  style: PropTypes.object,
};

export default GeoJSONLayer;

//#region Imports

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { Button, message, Select, Spin, Space, DatePicker, Popconfirm, Tooltip, Input, Modal, Radio, Switch } from 'antd';
import { SaveOutlined, SendOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { deleteUserFilter, getUserFilters, postUserFilter, patchUserFilter } from 'src/api/users';
import { getDashboardsTickets, getDistinctRequestStatuses, getDistinctRequestCategories, getDistinctRequestSubCategoriesForCategories, getServiceUsers, getDistinctDepartments, getDistinctWrapUpCodes } from 'src/api/teacket';
import { getDistinctRegions, getDistinctQuarters, getDistinctPlatforms, getDistinctForecasts, getDistinctStages, getDistinctTypes, getDistinctMarketSegments, getDistinctAccountGroupings, getDistinctOpportunities, getDistinctDirectIndirects, getDistinctCustomerTypes, getAccountsByName } from 'src/api/snowflake';
import { isRequestCancelled, notEmptyArray } from 'src/misc/Misc';
import UserImage from 'src/components/layout/UserImage';
import { MdOutlineEmail, MdDelete } from 'react-icons/md';
import { LuBuilding } from 'react-icons/lu';
import { FiHash } from 'react-icons/fi';
import { TbWorldPin } from 'react-icons/tb';
import { PiIdentificationBadgeFill } from 'react-icons/pi';
import TicketTypePicker from '../../../../MasterAdmin/TeacketServices/TeacketServiceEditor/TeacketServiceEditorRouting/TicketTypePicker';
import Space2 from 'src/components/layout/Space2';
import { SearchOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import useTheme from 'src/hooks/useTheme';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import DashboardFiltersUsers from './DashboardFiltersUsers';
import { FaCalendarCheck } from 'react-icons/fa';
import RangePicker2 from 'src/components/controls/RangePicker/RangePicker';
import FieldLabel from 'src/components/layout/FieldLabel';
import PropertySection from 'src/components/layout/PropertySection';

//#endregion

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * Dashboard Filters component for filtering Teacket data
 *
 * @param {string} id - Unique identifier for the filters, required for saving filter configurations
 * @param {Function} onSubmit - Function called when filters are applied. Receives all selected filter values as parameters
 * @param {Function} onFilterChange - Function called whenever any filter value changes, receives object with current filter state
 * @param {boolean} [includeDateRange=true] - Whether to include date range picker
 * @param {boolean} [includeServices=true] - Whether to include services selection
 * @param {boolean} [includeRegions=false] - Whether to include regions filter
 * @param {boolean} [includeSubRegions=false] - Whether to include subregions filter
 * @param {boolean} [includeQuarters=false] - Whether to include quarters filter
 * @param {boolean} [includeACVs=false] - Whether to include ACV filters ($100k+, $500k+)
 * @param {boolean} [includePlatforms=false] - Whether to include platforms filter
 * @param {boolean} [includeForecasts=false] - Whether to include forecasts filter
 * @param {boolean} [includeStages=false] - Whether to include stages filter
 * @param {boolean} [includeTypes=false] - Whether to include types filter
 * @param {boolean} [includeTicketTypes=false] - Whether to include ticket types filter
 * @param {boolean} [includeRequestStatuses=false] - Whether to include request statuses filter
 * @param {boolean} [includeRequestCategories=false] - Whether to include request categories filter
 * @param {boolean} [includeRequestSubCategories=false] - Whether to include request subcategories filter
 * @param {boolean} [includeMarketSegments=false] - Whether to include market segments filter
 * @param {boolean} [includeAccountGroupings=false] - Whether to include account groupings filter
 * @param {boolean} [includeAccounts=false] - Whether to include accounts selection
 * @param {boolean} [includeOpportunities=false] - Whether to include opportunities filter
 * @param {boolean} [includePeriod=false] - Whether to include period selector (week/month view)
 * @param {boolean} [includeRequesters=false] - Whether to include requesters filter
 * @param {boolean} [includeProjectManagers=false] - Whether to include project managers filter
 * @param {boolean} [includePrimaryTeamMembers=false] - Whether to include primary team members filter
 * @param {boolean} [includeSubjectMatterExperts=false] - Whether to include subject matter experts filter
 * @param {boolean} [includeTeamMembers=false] - Whether to include team members filter
 * @param {boolean} [includeDepartments=false] - Whether to include departments filter
 * @param {boolean} [includeWrapUpCodes=false] - Whether to include wrap up codes filter
 * @param {boolean} [includeDirectIndirects=false] - Whether to include direct/indirect filter
 * @param {boolean} [includeCustomerTypes=false] - Whether to include customer types filter
 * @param {boolean} [includeRegionalCareOffers=false] - Whether to include regional care offers filter
 * @param {boolean} [includeOnlyActive=false] - Whether to include only active switch
 * @param {boolean} [includeMembers=false] - Whether to include members filter
 * @param {boolean} [includeOpportunityFlag=false] - Whether to include opportunity flag filter
 * @param {boolean} [allowSave=false] - Whether to allow saving filter configurations
 * @param {boolean} [allowSearch=false] - Whether to show search input
 * @param {boolean} [allowGrouping=false] - Whether to allow grouping options
 * @param {boolean} [defaultExpanded=true] - Whether the filter section is expanded by default
 * @param {string} colorBackground - Background color for the filter container
 * @param {string} colorForeground - Text color for the filter container
 * @param {string} colorLoaderBackground - Background color for loaders
 * @param {string} colorLoaderForeground - Foreground color for loaders
 * @param {boolean} [collapsible=true] - Whether the filter section can be collapsed
 * @param {Object} additionalDescriptions - Additional descriptions for specific filters
 * @param {boolean} [overwriteOnSubmit=false] - Whether to override the standard submit behavior
 * @param {string} [servicesMode='multiple'] - Selection mode for services filter ('multiple' or 'single')
 */
const DashboardFilters = ({
  id,
  onSubmit,
  onFilterChange,
  includeDateRange = true,
  includeServices = true,
  includeRegions = false,
  includeSubRegions = false,
  includeQuarters = false,
  includeACVs = false,
  includePlatforms = false,
  includeForecasts = false,
  includeStages = false,
  includeTypes = false,
  includeTicketTypes = false,
  includeRequestStatuses = false,
  includeRequestCategories = false,
  includeRequestSubCategories = false,
  includeMarketSegments = false,
  includeAccountGroupings = false,
  includeAccounts = false,
  includeOpportunities = false,
  includePeriod = false,
  includeRequesters = false,
  includeProjectManagers = false,
  includePrimaryTeamMembers = false,
  includeSubjectMatterExperts = false,
  includeTeamMembers = false,
  includeDepartments = false,
  includeWrapUpCodes = false,
  includeDirectIndirects = false,
  includeCustomerTypes = false,
  includeRegionalCareOffers = false,
  includeOnlyActive = false,
  includeMembers = false,
  includeOpportunityFlag = false,
  allowSave = false,
  allowSearch = false,
  allowGrouping = false,
  defaultExpanded = true,
  colorBackground,
  colorForeground,
  colorLoaderBackground,
  colorLoaderForeground,
  collapsible = true,
  additionalDescriptions,
  overwriteOnSubmit = false,
  servicesMode = 'multiple',
}) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const {
    supervisorFiltersServicesForTenant,
    dashboardListServices,
    dashboardRegions,
    setDashboardRegions,
    setDashboardSubRegions,
    setDashboardQuarters,
    setDashboardACVs,
    setDashboardPlatforms,
    setDashboardForecasts,
    setDashboardStages,
    setDashboardAccountGroupings,
    setDashboardAccounts,
    setDashboardOpportunities,
    setDashboardMarketSegments,
    setDashboardTypes,
    setDashboardRequestTypes,
    setDashboardRequestStatuses,
    setDashboardRequestCategories,
    setDashboardRequestSubCategories,
    setDashboardMembers,
    setFilterRequesters,
    filterRequesters,
    setFilterProjectManagers,
    filterProjectManagers,
    setFilterPrimaryTeamMembers,
    filterPrimaryTeamMembers,
    setFilterSubjectMatterExperts,
    filterSubjectMatterExperts,
    setFilterTeamMembers,
    filterTeamMembers,
    filterRegions,
    filterPredefinedInterval,
    setFilterPredefinedInterval,
    setFilterRegions,
    setDashboardDepartments,
    setDashboardWrapUpCodes,
    setDashboardDirectIndirects,
    setDashboardCustomerTypes,
    setDashboardRegionalCareOffers,
    setDashboardListServices,
    setDashboardOnlyActive,
    dashboardOnlyActive,
    dashboardStartDate,
    setDashboardStartDate,
    dashboardEndDate,
    setDashboardEndDate,
    setDashboardDataTickets,
    ticketListQuery,
    setTicketListQuery,
    ticketListGroupBy,
    setTicketListGroupBy,
    filterIncludeOpportunityFlag,
    setFilterIncludeOpportunityFlag,
    commandPressed,
  } = useContext(TeacketContext);

  const { theme } = useTheme();
  const { isBusy } = useAsyncProcesses();
  if (!colorBackground) colorBackground = theme.backgroundMedium;
  if (!colorForeground) colorForeground = theme.textBase;
  if (!colorLoaderBackground) colorLoaderBackground = theme.light;
  if (!colorLoaderForeground) colorLoaderForeground = theme.primary;

  //#region Use States

  const [expanded, setExpanded] = useState(collapsible ? defaultExpanded : true);
  const [initiated, setInitiated] = useState(false);

  const [browserTimeZone, setBrowserTimeZone] = useState('UTC');

  const [regions, setRegions] = useState({});
  const [fetchingRegions, setFetchingRegions] = useState(false);

  const [selectedSubRegions, setSelectedSubRegions] = useState([]);

  const [periodType, setPeriodType] = useState('week');
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [quarters, setQuarters] = useState([]);
  const [fetchingQuarters, setFetchingQuarters] = useState(false);
  const [selectedQuarters, setSelectedQuarters] = useState([]);

  const [selectedACVs, setSelectedACVs] = useState([]);

  const [platforms, setPlatforms] = useState([]);
  const [fetchingPlatforms, setFetchingPlatforms] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const [forecasts, setForecasts] = useState([]);
  const [fetchingForecasts, setFetchingForecasts] = useState(false);
  const [selectedForecasts, setSelectedForecasts] = useState([]);

  const [stages, setStages] = useState([]);
  const [fetchingStages, setFetchingStages] = useState(false);
  const [selectedStages, setSelectedStages] = useState([]);

  const [accountGroupings, setAccountGroupings] = useState([]);
  const [fetchingAccountGroupings, setFetchingAccountGroupings] = useState(false);
  const [selectedAccountGroupings, setSelectedAccountGroupings] = useState([]);

  const [accountList, setAccountList] = useState([]);
  const [fetchingAccounts, setFetchingAccounts] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const [opportunities, setOpportunities] = useState([]);
  const [fetchingOpportunities, setFetchingOpportunities] = useState(false);
  const [selectedOpportunities, setSelectedOpportunities] = useState([]);
  const [opportunitiesPlaceholder, setOpportunitiesPlaceholder] = useState();

  const [marketSegments, setMarketSegments] = useState([]);
  const [fetchingMarketSegments, setFetchingMarketSegments] = useState(false);
  const [selectedMarketSegments, setSelectedMarketSegments] = useState([]);

  const [types, setTypes] = useState([]);
  const [fetchingTypes, setFetchingTypes] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const [selectedTicketTypes, setSelectedTicketTypes] = useState([]);

  const [requestStatuses, setRequestStatuses] = useState([]);
  const [fetchingRequestStatuses, setFetchingRequestStatuses] = useState(false);
  const [selectedRequestStatuses, setSelectedRequestStatuses] = useState([]);

  const [requestCategories, setRequestCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [fetchingRequestCategories, setFetchingRequestCategories] = useState(false);
  const [selectedRequestCategories, setSelectedRequestCategories] = useState([]);

  const [requestSubCategories, setRequestSubCategories] = useState([]);
  const [fetchingRequestSubCategories, setFetchingRequestSubCategories] = useState(false);
  const [selectedRequestSubCategories, setSelectedRequestSubCategories] = useState([]);

  const [requesters, setRequesters] = useState([]);
  const [fetchingRequesters, setFetchingRequesters] = useState(false);
  const [selectedRequesters, setSelectedRequesters] = useState([]);

  const [projectManagers, setProjectManagers] = useState([]);
  const [fetchingProjectManagers, setFetchingProjectManagers] = useState(false);
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);

  const [primaryTeamMembers, setPrimaryTeamMembers] = useState([]);
  const [fetchingPrimaryTeamMembers, setFetchingPrimaryTeamMembers] = useState(false);
  const [selectedPrimaryTeamMembers, setSelectedPrimaryTeamMembers] = useState([]);

  const [subjectMatterExperts, setSubjectMatterExperts] = useState([]);
  const [fetchingSubjectMatterExperts, setFetchingSubjectMatterExperts] = useState(false);
  const [selectedSubjectMatterExperts, setSelectedSubjectMatterExperts] = useState([]);

  const [teamMembers, setTeamMembers] = useState([]);
  const [fetchingTeamMembers, setFetchingTeamMembers] = useState(false);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [fetchingDepartments, setFetchingDepartments] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [wrapUpCodes, setWrapUpCodes] = useState([]);
  const [fetchingWrapUpCodes, setFetchingWrapUpCodes] = useState(false);
  const [selectedWrapUpCodes, setSelectedWrapUpCodes] = useState([]);

  const [directIndirects, setDirectIndirects] = useState([]);
  const [fetchingDirectIndirects, setFetchingDirectIndirects] = useState(false);
  const [selectedDirectIndirects, setSelectedDirectIndirects] = useState([]);

  const [customerTypes, setCustomerTypes] = useState([]);
  const [fetchingCustomerTypes, setFetchingCustomerTypes] = useState(false);
  const [selectedCustomerTypes, setSelectedCustomerTypes] = useState([]);

  const [selectedRegionalCareOffers, setSelectedRegionalCareOffers] = useState([]);

  const [savingFilter, setSavingFilter] = useState(false);
  const [newFilterName, setNewFilterName] = useState(null);
  const [showNewFilterModal, setShowNewFilterModal] = useState(false);
  const [userFilters, setUserFilters] = useState([]);
  const [fetchingUserFilters, setFetchingUserFilters] = useState(false);
  const [selectedUserFilter, setSelectedUserFilter] = useState(null);

  const [membersFilter, setMembersFilter] = useState(null);
  const [membersLoadedFilter, setMembersLoadedFilter] = useState(null);

  //#endregion

  const loadPreliminaryData = async () => {
    await Promise.all([
      includeRegions && loadRegions(),
      includeQuarters && loadQuarters(),
      includePlatforms && loadPlatforms(),
      includeForecasts && loadForecasts(),
      includeStages && loadStages(),
      includeTypes && loadTypes(),
      includeRequestStatuses && loadRequestStatuses(),
      includeRequestCategories && loadRequestCategories(),
      includeMarketSegments && loadMarketSegments(),
      includeAccountGroupings && loadAccountGroupings(),
      includeDirectIndirects && loadDirectIndirects(),
      includeCustomerTypes && loadCustomerTypes(),
      allowSave && loadUserFilters(),
    ]);
  };

  //#region Use Effects

  useEffect(() => {
    setDefaultRange();
    loadPreliminaryData();

    !includeOnlyActive && setDashboardOnlyActive(false);
  }, []);

  useEffect(() => {
    if (dashboardListServices && dashboardListServices.length === 0) {
      setSelectedRequestStatuses([]);
      setSelectedRequesters([]);
      setSelectedProjectManagers([]);
      setSelectedPrimaryTeamMembers([]);
      setSelectedSubjectMatterExperts([]);
      setSelectedTeamMembers([]);
      setSelectedDepartments([]);
      setSelectedWrapUpCodes([]);
      setRequestCategories([]);
      setSelectedRequestCategories([]);
      setDashboardOnlyActive(false);
    }

    console.log('dashboardListServices:', dashboardListServices);

    // Something wrong here - it's about set default service if only 1 is available

    // if (dashboardListServices && dashboardListServices.length === 0 && supervisorFiltersServicesForTenant && supervisorFiltersServicesForTenant.length > 0) {
    //   const defaultService = supervisorFiltersServicesForTenant.map((x) => ({
    //     value: x.id,
    //     label: x.name,
    //   }))[0].value;

    //   setDashboardListServices(defaultService);
    // }

    if (dashboardListServices && allCategories && requestCategories) {
      const selectedServiceNames = supervisorFiltersServicesForTenant.filter((x) => dashboardListServices.includes(x.id)).map((x) => x.name);
      const filteredCategories = allCategories.filter((x) => selectedServiceNames.includes(x.title));
      setRequestCategories(filteredCategories);
    }
  }, [dashboardListServices]);

  useEffect(() => {
    if (selectedRequestCategories.length === 0) {
      setRequestSubCategories([]);
      setDashboardRequestSubCategories([]);
      return;
    }

    if (includeRequestSubCategories) {
      loadRequestSubCategories();
    }
  }, [selectedRequestCategories]);

  useEffect(() => {
    if (initiated) return;
    if (!dashboardStartDate) return;
    if (!dashboardEndDate) return;
    setInitiated(true);
  }, [dashboardStartDate, dashboardEndDate]);

  useEffect(() => {
    if (selectedAccounts.length === 0) {
      setOpportunities([]);
      setSelectedOpportunities([]);
      setDashboardOpportunities([]);
      return;
    }

    if (includeOpportunities) {
      loadOpportunities(selectedAccounts);
    }
  }, [selectedAccounts]);

  useEffect(() => {
    if (dashboardListServices?.length > 0) {
      loadDepartments();
      loadWrapUpCodes();
    } else {
      setDepartments([]);
      setWrapUpCodes([]);
    }
  }, [dashboardListServices]);

  useEffect(() => setDashboardMembers(membersFilter), [membersFilter]);

  useEffect(() => {
    if (!includePeriod) return;
    switch (periodType) {
      case 'week':
        setSelectedPeriod([dayjs().utc().subtract(3, 'week').startOf('isoWeek').startOf('day'), dayjs().utc().endOf('isoWeek').endOf('day')]);
        break;
      case 'month':
        setSelectedPeriod([dayjs().utc().subtract(2, 'month').startOf('month').startOf('day'), dayjs().utc().endOf('month').endOf('day')]);
        break;
      default:
        setSelectedPeriod(null);
        break;
    }
  }, [periodType]);

  useEffect(() => {
    if (!selectedPeriod) return;

    console.log('selectedPeriod:', selectedPeriod);

    setDashboardStartDate(selectedPeriod[0]);
    setDashboardEndDate(selectedPeriod[1]);
  }, [selectedPeriod]);

  //#region Filter Changes

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange({
        from: dashboardStartDate ? dayjs(dashboardStartDate).format() : null,
        to: dashboardEndDate ? dayjs(dashboardEndDate).format() : null,
        predefinedInterval: filterPredefinedInterval,
        services: dashboardListServices,
        regions: dashboardRegions,
        subRegions: selectedSubRegions,
        quarters: selectedQuarters,
        acvs: selectedACVs,
        platforms: selectedPlatforms,
        forecasts: selectedForecasts,
        stages: selectedStages,
        types: selectedTypes,
        ticketTypes: selectedTicketTypes,
        requestStatuses: selectedRequestStatuses,
        requestCategories: selectedRequestCategories.map((rc) => rc.label),
        requestSubCategories: selectedRequestSubCategories,
        marketSegments: selectedMarketSegments,
        accountGroupings: selectedAccountGroupings,
        accounts: selectedAccounts,
        opportunities: selectedOpportunities,
        requesters: selectedRequesters,
        projectManagers: selectedProjectManagers,
        primaryTeamMembers: selectedPrimaryTeamMembers,
        subjectMatterExperts: selectedSubjectMatterExperts,
        teamMembers: selectedTeamMembers,
        departments: selectedDepartments,
        wrapUpCodes: selectedWrapUpCodes,
        directIndirects: selectedDirectIndirects,
        customerTypes: selectedCustomerTypes,
        regionalCareOffers: selectedRegionalCareOffers,
        onlyActive: dashboardOnlyActive,
        members: membersFilter,
        opportunityFlag: filterIncludeOpportunityFlag,
      });
    }
  }, [
    dashboardStartDate,
    dashboardEndDate,
    dashboardListServices,
    dashboardRegions,
    selectedSubRegions,
    selectedQuarters,
    selectedACVs,
    selectedPlatforms,
    selectedForecasts,
    selectedStages,
    selectedTypes,
    selectedTicketTypes,
    selectedRequestStatuses,
    selectedRequestCategories,
    selectedRequestSubCategories,
    selectedMarketSegments,
    selectedAccountGroupings,
    selectedAccounts,
    selectedOpportunities,
    selectedRequesters,
    selectedProjectManagers,
    selectedPrimaryTeamMembers,
    selectedSubjectMatterExperts,
    selectedTeamMembers,
    selectedDepartments,
    selectedWrapUpCodes,
    selectedDirectIndirects,
    selectedCustomerTypes,
    selectedRegionalCareOffers,
    membersFilter,
    dashboardOnlyActive,
    filterIncludeOpportunityFlag,
    filterPredefinedInterval,
  ]);

  //#endregion

  //#endregion

  //#region Load Filter Values

  const getSubRegionsOptions = () => {
    // When no regions selected, hide all subregions
    if (!filterRegions || filterRegions.length === 0) {
      return [];
    }

    // When specific regions are selected, show only their subregions
    return filterRegions.map((region) => ({
      label: <span>{region}</span>,
      title: region,
      options: (regions[region] || []).map((subRegion) => ({
        value: subRegion,
        label: subRegion,
      })),
    }));
  };

  const acvs = [
    {
      label: '$100,000+',
      value: 100000,
    },
    {
      label: '$500,000+',
      value: 500000,
    },
  ];

  const regionalCareOffers = ['Australia Business Hours Only', 'EEA Business Hours Only', 'Standard Global', 'USA Business Hours Only'];

  const loadRegions = async () => {
    try {
      setFetchingRegions(true);
      const result = await getDistinctRegions();
      setRegions(result);
    } catch (error) {
      console.error('Error while loading regions:', error);
      message.error('Error while loading regions');
    } finally {
      setFetchingRegions(false);
    }
  };

  const loadQuarters = async () => {
    try {
      setFetchingQuarters(true);
      const result = await getDistinctQuarters();
      setQuarters(result);
    } catch (error) {
      console.error('Error while loading quarters:', error);
      message.error('Error while loading quarters');
    } finally {
      setFetchingQuarters(false);
    }
  };

  const loadPlatforms = async () => {
    try {
      setFetchingPlatforms(true);
      const result = await getDistinctPlatforms();
      setPlatforms(result);
    } catch (error) {
      console.error('Error while loading platforms:', error);
      message.error('Error while loading platforms');
    } finally {
      setFetchingPlatforms(false);
    }
  };

  const loadForecasts = async () => {
    try {
      setFetchingForecasts(true);
      const result = await getDistinctForecasts();
      setForecasts(result);
    } catch (error) {
      console.error('Error while loading forecasts:', error);
      message.error('Error while loading forecasts');
    } finally {
      setFetchingForecasts(false);
    }
  };

  const loadStages = async () => {
    try {
      setFetchingStages(true);
      const result = await getDistinctStages();
      setStages(result);
    } catch (error) {
      console.error('Error while loading stages:', error);
      message.error('Error while loading stages');
    } finally {
      setFetchingStages(false);
    }
  };

  const loadAccountGroupings = async () => {
    try {
      setFetchingAccountGroupings(true);
      const result = await getDistinctAccountGroupings();
      setAccountGroupings(result);
    } catch (error) {
      console.error('Error while loading account groupings:', error);
      message.error('Error while loading account groupings');
    } finally {
      setFetchingAccountGroupings(false);
    }
  };

  const loadOpportunities = async () => {
    try {
      setFetchingOpportunities(true);
      const result = await getDistinctOpportunities(selectedAccounts);
      setOpportunities(result);
      setOpportunitiesPlaceholder(selectedAccounts?.length ? '[All Opportunities]' : 'Select an account first');
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading opportunities:', error);
        message.error('Error while loading opportunities');
      } else {
        console.log('No opportunities found for selected accounts');
        setOpportunities([]);
        setOpportunitiesPlaceholder('No opportunities found');
      }
    } finally {
      setFetchingOpportunities(false);
    }
  };

  const loadMarketSegments = async () => {
    try {
      setFetchingMarketSegments(true);
      const result = await getDistinctMarketSegments();
      setMarketSegments(result);
    } catch (error) {
      console.error('Error while loading market segments:', error);
      message.error('Error while loading market segments');
    } finally {
      setFetchingMarketSegments(false);
    }
  };

  const loadTypes = async () => {
    try {
      setFetchingTypes(true);
      const result = await getDistinctTypes();
      setTypes(result);
    } catch (error) {
      console.error('Error while loading types:', error);
      message.error('Error while loading types');
    } finally {
      setFetchingTypes(false);
    }
  };

  const loadRequestStatuses = async () => {
    try {
      setFetchingRequestStatuses(true);
      const result = await getDistinctRequestStatuses();
      setRequestStatuses(result);
    } catch (error) {
      console.error('Error while loading request statuses:', error);
      message.error('Error while loading request statuses');
    } finally {
      setFetchingRequestStatuses(false);
    }
  };

  const loadRequestCategories = async () => {
    try {
      setFetchingRequestCategories(true);
      const result = await getDistinctRequestCategories();

      const groupedCategories = result.reduce((acc, item) => {
        const { service_name, category } = item;
        if (!acc[service_name]) {
          acc[service_name] = [];
        }
        acc[service_name].push({ label: category, value: `${service_name}-${category}` });
        return acc;
      }, {});

      const options = Object.keys(groupedCategories)?.map((service_name) => ({
        label: service_name,
        title: service_name,
        options: groupedCategories[service_name],
      }));

      setAllCategories(options);
      setRequestCategories(options);
    } catch (error) {
      console.error('Error while loading request categories:', error);
      message.error('Error while loading request categories');
    } finally {
      setFetchingRequestCategories(false);
    }
  };

  const loadRequestSubCategories = async () => {
    try {
      setFetchingRequestSubCategories(true);
      const result = await getDistinctRequestSubCategoriesForCategories(selectedRequestCategories?.map((x) => x.label));
      setRequestSubCategories(result.sort((a, b) => a.localeCompare(b)));
    } catch (error) {
      console.error('Error while loading request subcategories:', error);
      message.error('Error while loading request subcategories');
    } finally {
      setFetchingRequestSubCategories(false);
    }
  };

  const loadDepartments = async () => {
    try {
      setFetchingDepartments(true);
      const result = await getDistinctDepartments(dashboardListServices);
      setDepartments(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading departments:', error);
        message.error('Error while loading departments');
      } else {
        console.log('No departments found');
        setDepartments([]);
      }
    } finally {
      setFetchingDepartments(false);
    }
  };

  const loadWrapUpCodes = async () => {
    try {
      setFetchingWrapUpCodes(true);
      const result = await getDistinctWrapUpCodes(dashboardListServices);
      setWrapUpCodes(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading wrap up codes:', error);
        message.error('Error while loading wrap up codes');
      } else {
        console.log('No wrap up codes found');
        setWrapUpCodes([]);
      }
    } finally {
      setFetchingWrapUpCodes(false);
    }
  };

  const loadDirectIndirects = async () => {
    try {
      setFetchingDirectIndirects(true);
      const result = await getDistinctDirectIndirects();
      setDirectIndirects(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading direct/indirect:', error);
        message.error('Error while loading direct/indirect');
      } else {
        console.log('No direct/indirect found');
        setDirectIndirects([]);
      }
    } finally {
      setFetchingDirectIndirects(false);
    }
  };

  const loadCustomerTypes = async () => {
    try {
      setFetchingCustomerTypes(true);
      const result = await getDistinctCustomerTypes();
      setCustomerTypes(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading customer types:', error);
        message.error('Error while loading customer types');
      } else {
        console.log('No customer types found');
        setCustomerTypes([]);
      }
    } finally {
      setFetchingCustomerTypes(false);
    }
  };

  const loadUserFilters = async () => {
    try {
      setFetchingUserFilters(true);
      const result = await getUserFilters(id);
      // console.log('User Filters:', result);
      setUserFilters(result);
    } catch (error) {
      if (error.status !== 404) {
        console.error('Error while loading user filters:', error);
        message.error('Error while loading user filters');
      } else {
        console.log('No user filters found');
        setUserFilters([]);
      }
    } finally {
      setFetchingUserFilters(false);
    }
  };

  //#region Search

  const searchAccounts = async (query) => {
    try {
      setAccountList([]);
      if (query.length < 3) return;
      setFetchingAccounts(true);
      const result = await getAccountsByName(query);
      console.log('searchAccounts:', result);
      setAccountList(result);
      setFetchingAccounts(false);
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading accounts:', error);
      message.error('Error while loading accounts');
      setFetchingAccounts(false);
    }
  };

  const searchRequesters = async (query) => {
    try {
      setFetchingRequesters(true);
      setRequesters([]);

      if (query.length < 3) return;

      if (dashboardListServices?.length > 0) {
        const allUsers = [];

        if (servicesMode === 'single') {
          const result = await getServiceUsers(dashboardListServices, query);
          allUsers.push(...result);
        } else {
          for (let index = 0; index < dashboardListServices.length; index++) {
            const service = dashboardListServices[index];
            const result = await getServiceUsers(service, query);
            allUsers.push(...result);
          }
        }

        setRequesters(allUsers);
        setFetchingRequesters(false);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading requesters:', error);
      message.error('Error while loading requesters');
      setFetchingRequesters(false);
    }
  };

  const searchProjectManagers = async (query) => {
    try {
      setProjectManagers([]);

      if (query.length < 3) return;

      setFetchingProjectManagers(true);
      if (dashboardListServices?.length > 0) {
        const allUsers = [];

        if (servicesMode === 'single') {
          const result = await getServiceUsers(dashboardListServices, query);
          allUsers.push(...result);
        } else {
          for (let index = 0; index < dashboardListServices.length; index++) {
            const service = dashboardListServices[index];
            const result = await getServiceUsers(service, query);
            allUsers.push(...result);
          }
        }
        setProjectManagers(allUsers);
        setFetchingProjectManagers(false);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading project managers:', error);
      message.error('Error while loading project managers');
      setFetchingProjectManagers(false);
    }
  };

  const searchPrimaryTeamMembers = async (query) => {
    try {
      setPrimaryTeamMembers([]);

      if (query.length < 3) return;

      setFetchingPrimaryTeamMembers(true);

      if (dashboardListServices?.length > 0) {
        const allUsers = [];

        if (servicesMode === 'single') {
          const result = await getServiceUsers(dashboardListServices, query);
          allUsers.push(...result);
        } else {
          for (let index = 0; index < dashboardListServices.length; index++) {
            const service = dashboardListServices[index];
            const result = await getServiceUsers(service, query);
            allUsers.push(...result);
          }
        }

        setPrimaryTeamMembers(allUsers);
        setFetchingPrimaryTeamMembers(false);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading primary team members:', error);
      message.error('Error while loading primary team members');
      setFetchingPrimaryTeamMembers(false);
    }
  };

  const searchSubjectMatterExperts = async (query) => {
    try {
      setSubjectMatterExperts([]);

      if (query.length < 3) return;

      setFetchingSubjectMatterExperts(true);

      if (dashboardListServices?.length > 0) {
        const allUsers = [];

        if (servicesMode === 'single') {
          const result = await getServiceUsers(dashboardListServices, query);
          allUsers.push(...result);
        } else {
          for (let index = 0; index < dashboardListServices.length; index++) {
            const service = dashboardListServices[index];
            const result = await getServiceUsers(service, query);
            allUsers.push(...result);
          }
        }

        setSubjectMatterExperts(allUsers);
        setFetchingSubjectMatterExperts(false);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading subject matter experts:', error);
      message.error('Error while loading subject matter experts');
      setFetchingSubjectMatterExperts(false);
    }
  };

  const searchTeamMembers = async (query) => {
    try {
      setTeamMembers([]);

      if (query.length < 3) return;

      setFetchingTeamMembers(true);

      if (dashboardListServices?.length > 0) {
        const allUsers = [];

        if (servicesMode === 'single') {
          const result = await getServiceUsers(dashboardListServices, query);
          allUsers.push(...result);
        } else {
          for (let index = 0; index < dashboardListServices.length; index++) {
            const service = dashboardListServices[index];
            const result = await getServiceUsers(service, query);
            allUsers.push(...result);
          }
        }

        setTeamMembers(allUsers);
        setFetchingTeamMembers(false);
      }
    } catch (error) {
      if (isRequestCancelled(error)) return;
      console.error('Error while loading team members:', error);
      message.error('Error while loading team members');
      setFetchingTeamMembers(false);
    }
  };

  //#endregion

  const setDefaultRange = () => {
    const timezone = getBrowserTimeZone();
    setBrowserTimeZone(timezone);
    const start = dayjs().tz(timezone).subtract(6, 'days').startOf('day');
    const end = dayjs().tz(timezone).endOf('day');
    setDashboardStartDate(start);
    setDashboardEndDate(end);
  };

  //#endregion

  //#region Handlers

  const handleOnSubmit = async () => {
    console.log('handleOnSubmit()');

    // console.log('typeof onSubmit:', typeof onSubmit);
    if (onSubmit) {
      onSubmit(
        dayjs(dashboardStartDate).format(),
        dayjs(dashboardEndDate).format(),
        servicesMode === 'multiple' ? (notEmptyArray(dashboardListServices) ? dashboardListServices : null) : dashboardListServices,
        notEmptyArray(dashboardRegions) ? dashboardRegions : null,
        selectedSubRegions || null,
        selectedQuarters || null,
        selectedACVs || null,
        selectedPlatforms || null,
        selectedForecasts || null,
        selectedStages || null,
        selectedTypes || null,
        selectedTicketTypes || null,
        selectedRequestStatuses || null,
        selectedRequestCategories.map((rc) => rc.label) || null, // Using label as value is merged with service name to avoid conflicts in the select control
        selectedRequestSubCategories || null,
        selectedMarketSegments || null,
        selectedAccountGroupings || null,
        selectedAccounts || null,
        selectedOpportunities || null,
        selectedRequesters || null,
        selectedProjectManagers || null,
        selectedPrimaryTeamMembers || null,
        selectedSubjectMatterExperts || null,
        selectedTeamMembers || null,
        selectedDepartments || null,
        selectedWrapUpCodes || null,
        selectedDirectIndirects || null,
        selectedCustomerTypes || null,
        selectedRegionalCareOffers || null,
        filterIncludeOpportunityFlag || null,
        selectedPeriod || null,
        periodType || null
      );
      return;
    }

    if (!overwriteOnSubmit) {
      await executeAsyncProcess(async () => {
        try {
          const result = await getDashboardsTickets(
            dashboardStartDate ? dayjs(dashboardStartDate).format() : null,
            dashboardEndDate ? dayjs(dashboardEndDate).format() : null,
            notEmptyArray(dashboardListServices) ? dashboardListServices : null,
            notEmptyArray(dashboardRegions) ? dashboardRegions : null,
            selectedSubRegions || null,
            selectedQuarters || null,
            selectedACVs || null,
            selectedPlatforms || null,
            selectedForecasts || null,
            selectedStages || null,
            selectedTypes || null,
            selectedTicketTypes || null,
            selectedRequestStatuses || null,
            selectedRequestCategories?.map((x) => x.label) || null,
            selectedRequestSubCategories || null,
            selectedMarketSegments || null,
            selectedAccountGroupings || null,
            selectedAccounts || null,
            selectedOpportunities || null,
            selectedRequesters || null,
            selectedProjectManagers || null,
            selectedPrimaryTeamMembers || null,
            selectedSubjectMatterExperts || null,
            selectedTeamMembers || null,
            selectedDepartments || null,
            selectedWrapUpCodes || null,
            selectedDirectIndirects || null,
            selectedCustomerTypes || null,
            selectedRegionalCareOffers || null,
            dashboardOnlyActive || null
          );
          setDashboardDataTickets(result);
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  const getDatesForPredefinedInterval = (predefinedInterval, timezone) => {
    let interval = null;
    const currentDate = dayjs().tz(timezone);

    // Genesys quarters start in February, May, August, November
    const getAdjustedQuarterStart = (date) => date.subtract(1, 'months').startOf('quarter').add(1, 'months');
    const getAdjustedQuarterEnd = (date) => date.subtract(1, 'months').endOf('quarter').add(1, 'months');

    switch (predefinedInterval.toLowerCase()) {
      case 'today':
        interval = [currentDate.startOf('day').format(), currentDate.endOf('day').format()];
        break;
      case 'yesterday':
        interval = [currentDate.subtract(1, 'days').startOf('day').format(), currentDate.subtract(1, 'days').endOf('day').format()];
        break;
      case 'past-7-days':
        interval = [currentDate.subtract(7, 'days').startOf('day').format(), currentDate.subtract(1, 'days').endOf('day').format()];
        break;
      case 'week-to-date':
        interval = [currentDate.startOf('isoWeek').format(), currentDate.endOf('day').format()];
        break;
      case 'last-week':
        interval = [currentDate.subtract(1, 'weeks').startOf('isoWeek').format(), currentDate.subtract(1, 'weeks').endOf('isoWeek').format()];
        break;
      case 'last-week-before-1':
        interval = [currentDate.subtract(2, 'weeks').startOf('isoWeek').format(), currentDate.subtract(2, 'weeks').endOf('isoWeek').format()];
        break;
      case 'last-week-before-2':
        interval = [currentDate.subtract(3, 'weeks').startOf('isoWeek').format(), currentDate.subtract(3, 'weeks').endOf('isoWeek').format()];
        break;
      case 'last-week-before-3':
        interval = [currentDate.subtract(4, 'weeks').startOf('isoWeek').format(), currentDate.subtract(4, 'weeks').endOf('isoWeek').format()];
        break;
      case 'past-30-days':
        interval = [currentDate.subtract(30, 'days').startOf('day').format(), currentDate.endOf('day').format()];
        break;
      case 'month-to-date':
        interval = [currentDate.startOf('month').format(), currentDate.endOf('day').format()];
        break;
      case 'last-month':
        interval = [currentDate.subtract(1, 'months').startOf('month').format(), currentDate.subtract(1, 'months').endOf('month').format()];
        break;
      case 'quarter-to-date':
        if (currentDate.isBefore(getAdjustedQuarterEnd(currentDate))) {
          interval = [getAdjustedQuarterStart(currentDate).add(1, 'days').format(), currentDate.endOf('day').format()];
        } else {
          interval = [getAdjustedQuarterStart(currentDate.subtract(1, 'quarters')).format(), getAdjustedQuarterEnd(currentDate.subtract(1, 'quarters')).format()];
        }
        break;
      case 'last-quarter':
        interval = [getAdjustedQuarterStart(currentDate.subtract(1, 'quarters')).format(), getAdjustedQuarterEnd(currentDate.subtract(1, 'quarters')).format()];
        break;
      default:
        throw new Error(`Unknown interval: ${predefinedInterval}`);
    }
    return interval;
  };

  const handleOnFilterSelect = (filterId) => {
    console.log('handleOnFilterSelect:', filterId);

    if (!filterId) {
      handleOnFilterClear();
      return;
    }

    const currentFilter = userFilters.find((x) => x.id === filterId);
    if (!currentFilter) {
      setSelectedUserFilter(null);
      return;
    }

    console.log('currentFilter:', currentFilter.filter);

    setSelectedUserFilter(currentFilter.id);

    // Do calculations for Time Range if relative
    setFilterPredefinedInterval(currentFilter.filter?.filterPredefinedInterval);

    if (currentFilter.filter?.filterPredefinedInterval) {
      const interval = getDatesForPredefinedInterval(currentFilter.filter?.filterPredefinedInterval, browserTimeZone);
      setDashboardStartDate(interval[0]);
      setDashboardEndDate(interval[1]);
    } else {
      setDashboardStartDate(currentFilter.filter?.dashboardStartDate);
      setDashboardEndDate(currentFilter.filter?.dashboardEndDate);
    }

    setPeriodType(currentFilter.filter?.periodType);
    if (currentFilter.filter?.selectedPeriod) {
      setSelectedPeriod(currentFilter.filter?.selectedPeriod.map((x) => dayjs(x)) || []); // Convert to dayjs
    }

    //#region AdminDashboard Support
    setDashboardQuarters(currentFilter.filter?.selectedQuarters);
    setDashboardRegions(currentFilter.filter?.filterRegions);
    setDashboardSubRegions(currentFilter.filter?.selectedSubRegions);
    setDashboardACVs(currentFilter.filter?.selectedACVs);
    setDashboardPlatforms(currentFilter.filter?.selectedPlatforms);
    setDashboardForecasts(currentFilter.filter?.selectedForecasts);
    setDashboardStages(currentFilter.filter?.selectedStages);
    setDashboardTypes(currentFilter.filter?.selectedTypes);
    //#endregion

    setDashboardListServices(currentFilter.filter?.dashboardListServices);
    setFilterRegions(currentFilter.filter?.filterRegions);
    setSelectedSubRegions(currentFilter.filter?.selectedSubRegions);
    setSelectedPlatforms(currentFilter.filter?.selectedPlatforms);
    setSelectedDirectIndirects(currentFilter.filter?.selectedDirectIndirects);
    setSelectedCustomerTypes(currentFilter.filter?.selectedCustomerTypes);
    setSelectedQuarters(currentFilter.filter?.selectedQuarters);
    setSelectedACVs(currentFilter.filter?.selectedACVs);
    setSelectedForecasts(currentFilter.filter?.selectedForecasts);
    setSelectedStages(currentFilter.filter?.selectedStages);
    setSelectedMarketSegments(currentFilter.filter?.selectedMarketSegments);
    setSelectedAccountGroupings(currentFilter.filter?.selectedAccountGroupings);
    setSelectedAccounts(currentFilter.filter?.selectedAccounts);
    setSelectedOpportunities(currentFilter.filter?.selectedOpportunities);
    setSelectedTypes(currentFilter.filter?.selectedTypes);
    setSelectedTicketTypes(currentFilter.filter?.selectedTypes);
    setSelectedRequestStatuses(currentFilter.filter?.selectedRequestStatuses);
    setSelectedRequestCategories(currentFilter.filter?.selectedRequestCategories);
    setSelectedRequestSubCategories(currentFilter.filter?.selectedRequestSubCategories);
    setSelectedDepartments(currentFilter.filter?.selectedDepartments);
    setSelectedWrapUpCodes(currentFilter.filter?.selectedWrapUpCodes);
    setSelectedRegionalCareOffers(currentFilter.filter?.selectedRegionalCareOffers);
    setDashboardOnlyActive(currentFilter.filter?.dashboardOnlyActive);
    setMembersLoadedFilter(currentFilter.filter?.membersFilter);

    // Requesters
    setFilterRequesters(currentFilter.filter?.selectedRequesters);
    setRequesters(currentFilter.filter?.selectedRequesters);
    setSelectedRequesters(currentFilter.filter?.selectedRequesters?.map((x) => x.user_id));

    setFilterProjectManagers(currentFilter.filter?.selectedProjectManagers);
    setProjectManagers(currentFilter.filter?.selectedProjectManagers);
    setSelectedProjectManagers(currentFilter.filter?.selectedProjectManagers?.map((x) => x.user_id));

    // PTM
    setFilterPrimaryTeamMembers(currentFilter.filter?.selectedPrimaryTeamMembers);
    setPrimaryTeamMembers(currentFilter.filter?.selectedPrimaryTeamMembers);
    setSelectedPrimaryTeamMembers(currentFilter.filter?.selectedPrimaryTeamMembers?.map((x) => x.user_id));

    // SME
    setFilterSubjectMatterExperts(currentFilter.filter?.selectedSubjectMatterExperts);
    setSubjectMatterExperts(currentFilter.filter?.selectedSubjectMatterExperts);
    setSelectedSubjectMatterExperts(currentFilter.filter?.selectedSubjectMatterExperts?.map((x) => x.user_id));

    // TM
    setFilterTeamMembers(currentFilter.filter?.selectedTeamMembers);
    setTeamMembers(currentFilter.filter?.selectedTeamMembers);
    setSelectedTeamMembers(currentFilter.filter?.selectedTeamMembers?.map((x) => x.user_id));

    setFilterIncludeOpportunityFlag(currentFilter.filter?.opportunityFlag);

    // wait a bit before handleOnSubmit to be sure that below filters are loaded and states are set
    // setTimeout(() => {
    //handleOnSubmit();
    // }, 1000);
  };

  const handleOnFilterClear = () => {
    setPeriodType('week');
    setSelectedPeriod([]);
    setSelectedUserFilter(null);
    setDefaultRange();
    setDashboardListServices(null);
    setFilterRegions([]);
    setSelectedSubRegions([]);
    setSelectedPlatforms([]);
    setSelectedDirectIndirects([]);
    setSelectedCustomerTypes([]);
    setSelectedQuarters([]);
    setSelectedACVs([]);
    setSelectedForecasts([]);
    setSelectedStages([]);
    setSelectedMarketSegments([]);
    setSelectedAccountGroupings(null);
    setSelectedAccounts([]);
    setSelectedOpportunities([]);
    setSelectedTypes([]);

    setDashboardRequestTypes(null);
    setSelectedTicketTypes([]);
    setSelectedRequestStatuses([]);
    setSelectedRequestCategories([]);
    setSelectedRequestSubCategories([]);
    setSelectedDepartments([]);
    setSelectedWrapUpCodes([]);
    setSelectedRegionalCareOffers([]);
    setDashboardOnlyActive(null);
    setMembersLoadedFilter(null);

    // Requesters
    setFilterRequesters(null);
    setRequesters(null);
    setSelectedRequesters(null);

    setFilterProjectManagers(null);
    setProjectManagers(null);
    setSelectedProjectManagers(null);

    // PTM
    setFilterPrimaryTeamMembers(null);
    setPrimaryTeamMembers(null);
    setSelectedPrimaryTeamMembers(null);

    // SME
    setFilterSubjectMatterExperts(null);
    setSubjectMatterExperts(null);
    setSelectedSubjectMatterExperts(null);

    // TM
    setFilterTeamMembers(null);
    setTeamMembers(null);
    setSelectedTeamMembers(null);

    setFilterIncludeOpportunityFlag(null);
  };

  const handleOnFilterSave = async () => {
    console.log('handleOnFilterSave()', selectedPeriod);
    try {
      setSavingFilter(true);

      const newFilter = {
        name: newFilterName || 'New Filter',
        dashboardStartDate,
        dashboardEndDate,
        filterPredefinedInterval,
        periodType: includePeriod ? periodType : null,
        selectedPeriod: includePeriod ? selectedPeriod.map((item) => item.toDate()) : null, // Need to convert to date format
        dashboardListServices: includeServices ? dashboardListServices : [],
        filterRegions: includeRegions ? filterRegions : [],
        selectedSubRegions: includeSubRegions ? selectedSubRegions : [],
        selectedPlatforms: includePlatforms ? selectedPlatforms : [],
        selectedDirectIndirects: includeDirectIndirects ? selectedDirectIndirects : [],
        selectedCustomerTypes: includeCustomerTypes ? selectedCustomerTypes : [],
        selectedQuarters: includeQuarters ? selectedQuarters : [],
        selectedACVs: includeACVs ? selectedACVs : [],
        selectedForecasts: includeForecasts ? selectedForecasts : [],
        selectedStages: includeStages ? selectedStages : [],
        selectedMarketSegments: includeMarketSegments ? selectedMarketSegments : [],
        selectedAccountGroupings: includeAccountGroupings ? selectedAccountGroupings : [],
        selectedAccounts: includeAccounts ? selectedAccounts : [],
        selectedOpportunities: includeOpportunities ? selectedOpportunities : [],
        selectedTypes: includeTypes ? selectedTypes : [],
        selectedTicketTypes: includeTicketTypes ? selectedTicketTypes : [],
        selectedRequestCategories: includeRequestCategories ? selectedRequestCategories : [],
        selectedRequestSubCategories: includeRequestSubCategories ? selectedRequestSubCategories : [],
        selectedRequestStatuses: includeRequestStatuses ? selectedRequestStatuses : [],
        selectedDepartments: includeDepartments ? selectedDepartments : [],
        selectedWrapUpCodes: includeWrapUpCodes ? selectedWrapUpCodes : [],
        selectedRegionalCareOffers: includeRegionalCareOffers ? selectedRegionalCareOffers : [],
        dashboardOnlyActive: includeOnlyActive ? dashboardOnlyActive : false,
        membersFilter: includeMembers ? membersFilter : null,
        opportunityFlag: includeOpportunityFlag ? filterIncludeOpportunityFlag : null,

        // Users
        selectedRequesters: includeRequesters ? filterRequesters : [],
        selectedProjectManagers: includeProjectManagers ? filterProjectManagers : [],
        selectedPrimaryTeamMembers: includePrimaryTeamMembers ? filterPrimaryTeamMembers : [],
        selectedSubjectMatterExperts: includeSubjectMatterExperts ? filterSubjectMatterExperts : [],
        selectedTeamMembers: includeTeamMembers ? filterTeamMembers : [],
      };

      console.log('=== newFilter:', newFilter);

      if (selectedUserFilter) {
        const newCurrentFilter = userFilters.find((x) => x.id === selectedUserFilter)?.filter || null;

        if (!newCurrentFilter) {
          console.log('Curretnly selected Filter cannot be found in userFilters');
          setSavingFilter(false);
          message.error('Filter cannot be saved');
          return;
        }
        console.log('Updating existing filter', newCurrentFilter.name);
        newFilter.name = newCurrentFilter.name;

        const newSavedFilter = await patchUserFilter(id, selectedUserFilter, newFilter);
        // Update userFilters
        const tmpUserFilters = [...userFilters];
        tmpUserFilters[userFilters.findIndex((x) => x.id === newSavedFilter.id)] = newSavedFilter;
        setUserFilters(tmpUserFilters);

        message.success('Filter updated');
      } else {
        // Save new filter
        console.log('Saving new filter');
        const newSaveFitler = await postUserFilter(id, newFilter);
        setUserFilters([...userFilters, newSaveFitler]);
        setSelectedUserFilter(newSaveFitler.id);

        message.success('Filter saved');
      }
      setShowNewFilterModal(false);
    } catch (error) {
    } finally {
      setSavingFilter(false);
    }
  };

  const handleDeleteFilter = async () => {
    try {
      const updatedUserFilters = [...userFilters];

      // Delete from DB
      const currentFilter = updatedUserFilters.find((x) => x.id === selectedUserFilter);
      await deleteUserFilter(id, currentFilter.id);

      // Delete from filters dropdown
      const currentFilterIndex = updatedUserFilters.findIndex((x) => x.id === selectedUserFilter);
      updatedUserFilters.splice(currentFilterIndex, 1);
      setUserFilters(updatedUserFilters);

      // Clear selected filter
      handleOnFilterClear();

      message.success('Filter deleted');
    } catch (error) {
      console.error(error);
      message.error('Error while deleting filter');
    }
  };

  const handleFilterUserChanged = (setSelected, setFilter, items, filterItems) => (v) => {
    setSelected(v);
    if (!filterItems) {
      setFilter(items.filter((x) => v.includes(x.user_id)));
    } else if (filterItems.length === 0) {
      setFilter(items.filter((x) => v.includes(x.user_id)));
    } else {
      let newFilteredItems = [...filterItems];
      v.forEach((user_id) => {
        if (!newFilteredItems.some((x) => x.user_id === user_id)) {
          newFilteredItems.push(items.find((x) => x.user_id === user_id));
        }
      });

      newFilteredItems = newFilteredItems.filter((x) => v.includes(x.user_id));
      setFilter(newFilteredItems);
    }
  };

  const handleWeekOnChange = (dates) => {
    console.log('handleWeekOnChange:', dates);
    if (!dates) return;
    const [from, to] = dates;
    setSelectedPeriod([dayjs(from.format('YYYY-MM-DD')).utc(true).startOf('isoWeek').startOf('day'), dayjs(to.format('YYYY-MM-DD')).utc(true).endOf('isoWeek').endOf('day')]);
  };

  const handleMonthOnChange = (dates) => {
    if (!dates) return;
    const [from, to] = dates;
    setSelectedPeriod([dayjs(from.format('YYYY-MM-DD')).utc(true).startOf('month').startOf('day'), dayjs(to.format('YYYY-MM-DD')).utc(true).endOf('month').endOf('day')]);
  };

  //#endregion

  const groupableColumns = [
    { key: 'account', title: 'Account', dataIndex: 'acc_id' },
    { key: 'category', title: 'Category', dataIndex: 'category' },
    { key: 'priority', title: 'Priority', dataIndex: 'priority' },
    { key: 'status', title: 'Status', dataIndex: 'status' },
  ];

  const formatWeek = (value) => `week of ${value.startOf('week').startOf('day').format('MMM DD')}`;
  const formatMonth = (value) => value.format('MMM YYYY');

  return (
    <>
      {collapsible && !isBusy && !expanded && (
        <div className='absolute cursor-pointer rounded-full animate-appear3 drop-shadow-md z-10 flex flex-col justify-center items-center hover:scale-110 transition-all' style={{ marginLeft: 'calc(50% - 10px)', marginTop: '-10px', backgroundColor: colorBackground, border: `1px solid ${colorLoaderForeground}`, width: '20px', height: '20px' }} onClick={() => setExpanded(!expanded)}>
          <Tooltip title='Click to expand'>
            <FaAngleDown />
          </Tooltip>
        </div>
      )}
      <div className={`${expanded ? 'h-auto' : 'h-0 overflow-hidden'}`}>
        <div className={collapsible ? 'p-4 top-0 shadow-md' : null} style={collapsible ? { backgroundColor: theme.backgroundBase, borderLeft: `1px solid ${theme.borderLight}`, borderRight: `1px solid ${theme.borderLight}`, borderBottom: `1px solid ${theme.borderLight}`, borderRadius: '0 0 0.5rem 0.5rem' } : null}>
          <div className='grid grid-cols-1 md:grid-cols-4 2xl:grid-cols-6 gap-4'>
            <Modal title={'Save New Filter'} open={showNewFilterModal} onCancel={() => setShowNewFilterModal(false)} onOk={() => handleOnFilterSave()} okButtonProps={{ disabled: !newFilterName || (newFilterName && newFilterName.trim().length === 0), loading: savingFilter }}>
              <Input value={newFilterName} onChange={(e) => setNewFilterName(e.target.value)} maxLength={25} />
              <Space className='flex justify-between items-center mt-2'>
                <div className='text-xs opacity-60'>Provide a Filter Name</div>
                <div className='text-xs text-right text-gray-500'>{newFilterName?.length ?? 0}/25</div>
              </Space>
            </Modal>
            {includePeriod && (
              <div className='md:col-span-2 2xl:col-span-2'>
                <FieldLabel label='Periods'>
                  <PropertySection roundedSize='md' bgColor={'#88888822'} color='transparent'>
                    <div className='flex flex-row items-center '>
                      <div className='flex-initial'>
                        <Radio.Group
                          className='w-32'
                          value={periodType}
                          buttonStyle='solid'
                          size='small'
                          onChange={(e) => {
                            setPeriodType(e.target.value);
                          }}>
                          <Radio.Button value='week'>Weeks</Radio.Button>
                          <Radio.Button value='month'>Months</Radio.Button>
                        </Radio.Group>
                      </div>
                      <div className='flex-auto overflow-hidden'>
                        {periodType && selectedPeriod && <RangePicker picker={periodType} value={selectedPeriod} onChange={periodType === 'week' ? handleWeekOnChange : handleMonthOnChange} format={periodType === 'week' ? formatWeek : formatMonth} size='small' allowClear={false} />}
                        {commandPressed && (
                          <Tooltip
                            title={
                              true === notEmptyArray(selectedPeriod) ? (
                                <div className='text-xs opacity-60 font-thin'>
                                  <div className='opacity-100 font-normal'>Resultant date range:</div>
                                  <div>
                                    from: <span className='opacity-100 font-bold'>{selectedPeriod[0].utc().format()}</span>
                                  </div>
                                  <div>
                                    to: <span className='opacity-100 font-bold'>{selectedPeriod[1].utc().format()}</span>
                                  </div>
                                </div>
                              ) : (
                                'a range is not selected'
                              )
                            }>
                            <FaCalendarCheck className='text-genesys-orange-base opacity-60 hover:opacity-100' />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </PropertySection>
                </FieldLabel>
              </div>
            )}
            {includeDateRange && (
              <div className='md:col-span-2 2xl:col-span-2'>
                <FieldLabel label='Date Range'>
                  <RangePicker2
                    startDate={dashboardStartDate}
                    endDate={dashboardEndDate}
                    onChange={(v) => {
                      setFilterPredefinedInterval(v.predefinedInterval || null);
                      setDashboardStartDate(v.startDate);
                      setDashboardEndDate(v.endDate);
                    }}
                    width={'100%'}
                    disabled={dashboardOnlyActive}
                  />
                  {filterPredefinedInterval && <div className='text-xs text-right mr-1 mt-1 opacity-60'>{filterPredefinedInterval.charAt(0).toUpperCase() + filterPredefinedInterval.slice(1)}</div>}
                </FieldLabel>
              </div>
            )}
            {includeQuarters && (
              <div>
                <FieldLabel label='Quarters'>
                  <Select
                    mode='multiple'
                    value={selectedQuarters}
                    loading={fetchingQuarters}
                    onChange={(v) => {
                      setSelectedQuarters(v);
                      setDashboardQuarters(v);
                    }}
                    className='w-full'
                    options={quarters.map((x) => ({
                      value: x,
                      label: x,
                    }))}
                    allowClear
                    notFoundContent={fetchingQuarters ? <Spin size='small' /> : null}
                    placeholder='[All Quarters]'
                  />
                </FieldLabel>
                <div className='text-xs text-right mr-1 mt-1 opacity-60'>{additionalDescriptions?.includeQuarters && <div className='text-xs opacity-60'>{additionalDescriptions?.includeQuarters}</div>}</div>
              </div>
            )}
            {includeServices && (
              <div className='md:col-span-2 2xl:col-span-2'>
                <FieldLabel label='Services'>
                  <Select
                    mode={servicesMode}
                    value={dashboardListServices}
                    onChange={(v) => {
                      console.log('services:', v);
                      setDashboardListServices(v);
                    }}
                    className='w-full'
                    options={supervisorFiltersServicesForTenant
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((x) => ({
                        value: x.id,
                        label: x.name,
                      }))}
                    allowClear
                    placeholder='[All Services]'
                  />
                </FieldLabel>
              </div>
            )}
            {includeRegions && (
              <FieldLabel label='Regions'>
                <Select
                  mode='multiple'
                  value={filterRegions}
                  loading={fetchingRegions}
                  onChange={(v) => {
                    setFilterRegions(v);
                    setDashboardRegions(v);

                    // If no regions are selected, clear subregions
                    if (v.length === 0) {
                      setSelectedSubRegions([]);
                      setDashboardSubRegions([]);
                    }
                  }}
                  className='w-full'
                  options={Object.keys(regions)?.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  allowClear
                  notFoundContent={fetchingRegions ? <Spin size='small' /> : null}
                  placeholder='[All Regions]'
                />
              </FieldLabel>
            )}
            {includeSubRegions && (
              <FieldLabel label='Subregions'>
                <Select
                  mode='multiple'
                  value={selectedSubRegions}
                  loading={fetchingRegions}
                  onChange={(v) => {
                    setSelectedSubRegions(v);
                    setDashboardSubRegions(v);
                  }}
                  className='w-full'
                  options={getSubRegionsOptions()}
                  allowClear
                  placeholder='[All Subregions]'
                  disabled={includeRegions && filterRegions?.length === 0}
                />
              </FieldLabel>
            )}
            {includePlatforms && (
              <FieldLabel label='Platforms'>
                <Select
                  mode='multiple'
                  value={selectedPlatforms}
                  loading={fetchingPlatforms}
                  onChange={(v) => {
                    setSelectedPlatforms(v);
                    setDashboardPlatforms(v);
                  }}
                  className='w-full'
                  options={platforms.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  allowClear
                  notFoundContent={fetchingPlatforms ? <Spin size='small' /> : null}
                  placeholder='[All Platforms]'
                />
              </FieldLabel>
            )}
            {includeDirectIndirects && (
              <FieldLabel label='Direct/Indirect'>
                <Select
                  mode='multiple'
                  value={selectedDirectIndirects}
                  loading={fetchingDirectIndirects}
                  onChange={(v) => {
                    setSelectedDirectIndirects(v);
                    setDashboardDirectIndirects(v);
                  }}
                  className='w-full'
                  options={directIndirects.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  allowClear
                  notFoundContent={fetchingDirectIndirects ? <Spin size='small' /> : null}
                  placeholder='[Direct/Indirect]'
                />
              </FieldLabel>
            )}
            {includeCustomerTypes && (
              <FieldLabel label='Customer Types'>
                <Select
                  mode='multiple'
                  value={selectedCustomerTypes}
                  loading={fetchingCustomerTypes}
                  onChange={(v) => {
                    setSelectedCustomerTypes(v);
                    setDashboardCustomerTypes(v);
                  }}
                  className='w-full'
                  options={customerTypes.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  allowClear
                  notFoundContent={fetchingCustomerTypes ? <Spin size='small' /> : null}
                  placeholder='[All Customer Types]'
                />
              </FieldLabel>
            )}
            {includeACVs && (
              <FieldLabel label='ACVs'>
                <Select
                  mode='multiple'
                  value={selectedACVs}
                  onChange={(v) => {
                    setSelectedACVs(v);
                    setDashboardACVs(v);
                  }}
                  className='w-full'
                  options={acvs.map((x) => ({
                    value: x.value,
                    label: x.label,
                  }))}
                  allowClear
                  placeholder='[All ACVs]'
                />
              </FieldLabel>
            )}
            {includeForecasts && (
              <FieldLabel label='Forecasts'>
                <Select
                  mode='multiple'
                  value={selectedForecasts}
                  loading={fetchingForecasts}
                  onChange={(v) => {
                    setSelectedForecasts(v);
                    setDashboardForecasts(v);
                  }}
                  className='w-full'
                  options={forecasts.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  allowClear
                  notFoundContent={fetchingForecasts ? <Spin size='small' /> : null}
                  placeholder='[All Forecasts]'
                />
              </FieldLabel>
            )}
            {includeStages && (
              <FieldLabel label='Stages'>
                <Select
                  mode='multiple'
                  value={selectedStages}
                  loading={fetchingStages}
                  onChange={(v) => {
                    setSelectedStages(v);
                    setDashboardStages(v);
                  }}
                  className='w-full'
                  options={stages.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  allowClear
                  notFoundContent={fetchingStages ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                  placeholder='[All Stages]'
                />
              </FieldLabel>
            )}
            {includeMarketSegments && (
              <FieldLabel label='Market Segments'>
                <Select
                  mode='multiple'
                  value={selectedMarketSegments}
                  loading={fetchingMarketSegments}
                  onChange={(v) => {
                    setSelectedMarketSegments(v);
                    setDashboardMarketSegments(v);
                  }}
                  className='w-full'
                  options={marketSegments.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  allowClear
                  notFoundContent={fetchingMarketSegments ? <Spin size='small' /> : null}
                  placeholder='[All Market Segments]'
                />
              </FieldLabel>
            )}
            {includeAccountGroupings && (
              <div className='md:col-span-2 2xl:col-span-2'>
                <FieldLabel label='Account Groupings'>
                  <Select
                    mode='multiple'
                    value={selectedAccountGroupings}
                    loading={fetchingAccountGroupings}
                    onChange={(v) => {
                      setSelectedAccountGroupings(v);
                      setDashboardAccountGroupings(v);
                    }}
                    className='w-full'
                    options={accountGroupings.map((x) => ({
                      value: x,
                      label: x,
                    }))}
                    allowClear
                    notFoundContent={fetchingAccountGroupings ? <Spin size='small' /> : null}
                    placeholder='[All Account Groupings]'
                  />
                </FieldLabel>
              </div>
            )}
            {includeAccounts && (
              <div className='md:col-span-2 2xl:col-span-2'>
                <FieldLabel label='Accounts'>
                  <Select
                    mode='multiple'
                    value={selectedAccounts}
                    loading={fetchingAccounts}
                    onChange={(v) => {
                      setSelectedAccounts(v);
                      setDashboardAccounts(v);
                    }}
                    className='w-full'
                    style={{
                      width: '100%',
                    }}
                    allowClear
                    notFoundContent={fetchingAccounts ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                    placeholder={'[All Accounts]'}
                    showSearch
                    onSearch={searchAccounts}
                    optionRender={(option) => {
                      const item = accountList.find((x) => x.ACCOUNT_ID === option.key);
                      if (!item) return null;

                      return (
                        <div className='flex flex-row gap-2 my-1'>
                          <div className='flex flex-col'>
                            <div className='overflow-hidden'>{item.ACCOUNT_NAME}</div>
                            <div className='text-xs font-light opacity-60 overflow-hidden'>
                              <Space className='btn'>
                                <FiHash />
                                {item.ACCOUNT_ID}
                              </Space>
                            </div>
                            <div className='text-xs font-light opacity-60 overflow-hidden'>
                              <Space className='btn'>
                                <PiIdentificationBadgeFill />
                                {item.OWNER_NAME || 'N/A'}
                              </Space>
                            </div>
                            <div className='text-xs font-light opacity-60 overflow-hidden'>
                              <Space className='btn'>
                                <TbWorldPin />
                                {item.ACCOUNT_REGION || 'N/A'}
                              </Space>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    filterOption={false}>
                    {accountList.map((account) => (
                      <Option key={account.ACCOUNT_ID} autoComplete='off'>
                        {account.ACCOUNT_NAME} ({account.ACCOUNT_ID})
                      </Option>
                    ))}
                  </Select>
                </FieldLabel>
              </div>
            )}
            {includeOpportunities && (
              <div className='md:col-span-2 2xl:col-span-2'>
                <FieldLabel label='Opportunitites'>
                  <Select
                    mode='multiple'
                    value={selectedOpportunities}
                    loading={fetchingOpportunities}
                    disabled={selectedAccounts.length === 0 || opportunities.length === 0}
                    onChange={(v) => {
                      setSelectedOpportunities(v);
                      setDashboardOpportunities(v);
                    }}
                    className='w-full'
                    options={opportunities.map((x) => ({
                      value: x.id,
                      label: `${x.name} (${x.id})`,
                    }))}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    allowClear
                    notFoundContent={fetchingOpportunities ? <Spin size='small' /> : null}
                    placeholder={opportunitiesPlaceholder || selectedAccounts?.length ? '[All Opportunities]' : 'Select an account first'}
                  />
                </FieldLabel>
              </div>
            )}
            {includeTypes && (
              <FieldLabel label='Types'>
                <Select
                  mode='multiple'
                  value={selectedTypes}
                  loading={fetchingTypes}
                  onChange={(v) => {
                    setSelectedTypes(v);
                    setDashboardTypes(v);
                  }}
                  className='w-full'
                  options={types.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  allowClear
                  notFoundContent={fetchingTypes ? <Spin size='small' /> : null}
                  placeholder='[All Types]'
                />
              </FieldLabel>
            )}
            {includeTicketTypes && (
              <FieldLabel label='Ticket Types'>
                <TicketTypePicker
                  disabled={!dashboardListServices?.length}
                  mode='multiple'
                  placeholder={dashboardListServices?.length ? '[All Ticket Types]' : 'Select a service first'}
                  serviceIds={dashboardListServices}
                  onChange={(v) => {
                    setSelectedTicketTypes(v);
                    setDashboardRequestTypes(v);
                  }}
                />
              </FieldLabel>
            )}
            {includeRequestCategories && (
              <>
                <FieldLabel label='Categories'>
                  <Select
                    mode='multiple'
                    value={selectedRequestCategories}
                    loading={fetchingRequestCategories}
                    onChange={(v, selectedOptions) => {
                      setSelectedRequestCategories(selectedOptions);
                      setDashboardRequestCategories(selectedOptions);

                      if (selectedOptions.length === 0) {
                        setSelectedRequestSubCategories([]);
                        setDashboardRequestSubCategories([]);
                      }
                    }}
                    className='w-full'
                    options={requestCategories}
                    allowClear
                    notFoundContent={fetchingRequestCategories ? <Spin size='small' /> : null}
                    placeholder={dashboardListServices?.length ? '[All Ticket Categories]' : 'Select a service first'}
                    disabled={!dashboardListServices?.length}
                  />
                </FieldLabel>
                <FieldLabel label='Subcategories'>
                  <Select
                    mode='multiple'
                    disabled={selectedRequestCategories.length === 0}
                    value={selectedRequestSubCategories}
                    loading={fetchingRequestSubCategories}
                    onChange={(v) => {
                      setSelectedRequestSubCategories(v);
                      setDashboardRequestSubCategories(v);
                    }}
                    className='w-full'
                    options={requestSubCategories?.map((x) => ({
                      value: x,
                      label: x,
                    }))}
                    allowClear
                    notFoundContent={fetchingRequestSubCategories ? <Spin size='small' /> : null}
                    placeholder='[All Ticket Subcategories]'
                  />
                  <div className='text-xs text-right mr-1 mt-1 opacity-60'></div>
                </FieldLabel>
              </>
            )}
            {includeRequestStatuses && (
              <FieldLabel label='Statuses'>
                <Select
                  mode='multiple'
                  value={selectedRequestStatuses}
                  loading={fetchingRequestStatuses}
                  onChange={(v) => {
                    setSelectedRequestStatuses(v);
                    setDashboardRequestStatuses(v);
                  }}
                  className='w-full'
                  options={requestStatuses?.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  allowClear
                  notFoundContent={fetchingRequestStatuses ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                  placeholder={dashboardListServices?.length ? '[All Statuses]' : 'Select a service first'}
                  disabled={!dashboardListServices?.length || dashboardOnlyActive}
                />
              </FieldLabel>
            )}
            {includeRequesters && (
              <FieldLabel label='Requesters'>
                <Select
                  mode='multiple'
                  value={selectedRequesters}
                  loading={fetchingRequesters}
                  onChange={handleFilterUserChanged(setSelectedRequesters, setFilterRequesters, requesters, filterRequesters)}
                  className='w-full'
                  allowClear
                  notFoundContent={fetchingRequesters ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                  placeholder={dashboardListServices?.length ? '[All Requesters]' : 'Select a service first'}
                  disabled={!dashboardListServices?.length}
                  showSearch
                  onSearch={searchRequesters}
                  optionRender={(option) => {
                    const user = requesters?.find((x) => x.user_id === option.key);
                    // if (!user && value) return value;
                    if (!user) return null;
                    return (
                      <div className='flex flex-row gap-2 my-1'>
                        <div>
                          <UserImage image={user.user_pic} size={32} />
                        </div>
                        <div className='flex flex-col'>
                          <div className='overflow-hidden'>{user.user_name}</div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <MdOutlineEmail />
                              {user.user_email}
                            </Space>
                          </div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <LuBuilding />
                              {user.tenant_name}
                            </Space>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  filterOption={false}>
                  {requesters?.map((user) => (
                    <Option key={user.user_id} autoComplete='off'>
                      {user.user_name}
                    </Option>
                  ))}
                </Select>
              </FieldLabel>
            )}
            {includeProjectManagers && (
              <FieldLabel label='PMs'>
                <Select
                  mode='multiple'
                  value={selectedProjectManagers}
                  loading={fetchingProjectManagers}
                  onChange={handleFilterUserChanged(setSelectedProjectManagers, setFilterProjectManagers, projectManagers, filterProjectManagers)}
                  className='w-full'
                  allowClear
                  notFoundContent={fetchingProjectManagers ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                  placeholder={dashboardListServices?.length ? '[All Project Managers]' : 'Select a service first'}
                  disabled={!dashboardListServices?.length}
                  showSearch
                  onSearch={searchProjectManagers}
                  optionRender={(option) => {
                    const user = projectManagers?.find((x) => x.user_id === option.key);
                    // if (!user && value) return value;
                    if (!user) return null;
                    return (
                      <div className='flex flex-row gap-2 my-1'>
                        <div>
                          <UserImage image={user.user_pic} size={32} />
                        </div>
                        <div className='flex flex-col'>
                          <div className='overflow-hidden'>{user.user_name}</div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <MdOutlineEmail />
                              {user.user_email}
                            </Space>
                          </div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <LuBuilding />
                              {user.tenant_name}
                            </Space>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  filterOption={false}>
                  {projectManagers?.map((user) => (
                    <Option key={user.user_id} autoComplete='off'>
                      {user.user_name}
                    </Option>
                  ))}
                </Select>
              </FieldLabel>
            )}
            {includePrimaryTeamMembers && (
              <FieldLabel label='Primary Team Members'>
                <Select
                  mode='multiple'
                  value={selectedPrimaryTeamMembers}
                  loading={fetchingPrimaryTeamMembers}
                  onChange={handleFilterUserChanged(setSelectedPrimaryTeamMembers, setFilterPrimaryTeamMembers, primaryTeamMembers, filterPrimaryTeamMembers)}
                  className='w-full'
                  allowClear
                  notFoundContent={fetchingPrimaryTeamMembers ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                  placeholder={dashboardListServices?.length ? '[All Primary Team Members]' : 'Select a service first'}
                  disabled={!dashboardListServices?.length}
                  showSearch
                  onSearch={searchPrimaryTeamMembers}
                  optionRender={(option) => {
                    const user = primaryTeamMembers?.find((x) => x.user_id === option.key);
                    // if (!user && value) return value;
                    if (!user) return null;
                    return (
                      <div className='flex flex-row gap-2 my-1'>
                        <div>
                          <UserImage image={user.user_pic} size={32} />
                        </div>
                        <div className='flex flex-col'>
                          <div className='overflow-hidden'>{user.user_name}</div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <MdOutlineEmail />
                              {user.user_email}
                            </Space>
                          </div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <LuBuilding />
                              {user.tenant_name}
                            </Space>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  filterOption={false}>
                  {primaryTeamMembers?.map((user) => (
                    <Option key={user.user_id} autoComplete='off'>
                      {user.user_name}
                    </Option>
                  ))}
                </Select>
              </FieldLabel>
            )}
            {includeSubjectMatterExperts && (
              <FieldLabel label='Subject Matter Experts'>
                <Select
                  mode='multiple'
                  value={selectedSubjectMatterExperts}
                  loading={fetchingSubjectMatterExperts}
                  onChange={handleFilterUserChanged(setSelectedSubjectMatterExperts, setFilterSubjectMatterExperts, subjectMatterExperts, filterSubjectMatterExperts)}
                  className='w-full'
                  allowClear
                  notFoundContent={fetchingSubjectMatterExperts ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                  placeholder={dashboardListServices?.length ? '[All Subject Matter Experts]' : 'Select a service first'}
                  disabled={!dashboardListServices?.length}
                  showSearch
                  onSearch={searchSubjectMatterExperts}
                  optionRender={(option) => {
                    const user = subjectMatterExperts?.find((x) => x.user_id === option.key);
                    // if (!user && value) return value;
                    if (!user) return null;
                    return (
                      <div className='flex flex-row gap-2 my-1'>
                        <div>
                          <UserImage image={user.user_pic} size={32} />
                        </div>
                        <div className='flex flex-col'>
                          <div className='overflow-hidden'>{user.user_name}</div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <MdOutlineEmail />
                              {user.user_email}
                            </Space>
                          </div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <LuBuilding />
                              {user.tenant_name}
                            </Space>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  filterOption={false}>
                  {subjectMatterExperts?.map((user) => (
                    <Option key={user.user_id} autoComplete='off'>
                      {user.user_name}
                    </Option>
                  ))}
                </Select>
              </FieldLabel>
            )}
            {includeTeamMembers && (
              <FieldLabel label='Team Members'>
                <Select
                  mode='multiple'
                  value={selectedTeamMembers}
                  loading={fetchingTeamMembers}
                  onChange={handleFilterUserChanged(setSelectedTeamMembers, setFilterTeamMembers, teamMembers, filterTeamMembers)}
                  className='w-full'
                  allowClear
                  notFoundContent={fetchingTeamMembers ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                  placeholder={dashboardListServices?.length ? '[All Team Members]' : 'Select a service first'}
                  disabled={!dashboardListServices?.length}
                  showSearch
                  onSearch={searchTeamMembers}
                  optionRender={(option) => {
                    const user = teamMembers?.find((x) => x.user_id === option.key);
                    // if (!user && value) return value;
                    if (!user) return null;
                    return (
                      <div className='flex flex-row gap-2 my-1'>
                        <div>
                          <UserImage image={user.user_pic} size={32} />
                        </div>
                        <div className='flex flex-col'>
                          <div className='overflow-hidden'>{user.user_name}</div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <MdOutlineEmail />
                              {user.user_email}
                            </Space>
                          </div>
                          <div className='text-xs font-light opacity-60 overflow-hidden'>
                            <Space className='btn'>
                              <LuBuilding />
                              {user.tenant_name}
                            </Space>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  filterOption={false}>
                  {teamMembers?.map((user) => (
                    <Option key={user.user_id} autoComplete='off'>
                      {user.user_name}
                    </Option>
                  ))}
                </Select>
              </FieldLabel>
            )}
            {includeMembers && (
              <div className='md:col-span-2 2xl:col-span-2'>
                <FieldLabel label='Members'>
                  <DashboardFiltersUsers serviceIdList={dashboardListServices} onChange={(v) => setMembersFilter(v)} defaultValue={membersLoadedFilter} />
                </FieldLabel>
              </div>
            )}
            {includeDepartments && (
              <FieldLabel label='Departments'>
                <Select
                  mode='multiple'
                  value={selectedDepartments}
                  loading={fetchingDepartments}
                  onChange={(v) => {
                    setSelectedDepartments(v);
                    setDashboardDepartments(v);
                  }}
                  className='w-full'
                  allowClear
                  options={departments?.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  notFoundContent={fetchingDepartments ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                  placeholder={dashboardListServices?.length ? '[All Departments]' : 'Select a service first'}
                  disabled={!dashboardListServices?.length}
                  showSearch
                />
              </FieldLabel>
            )}
            {includeWrapUpCodes && (
              <FieldLabel label='Wrapup Codes'>
                <Select
                  mode='multiple'
                  value={selectedWrapUpCodes}
                  loading={fetchingWrapUpCodes}
                  onChange={(v) => {
                    setSelectedWrapUpCodes(v);
                    setDashboardWrapUpCodes(v);
                  }}
                  className='w-full'
                  allowClear
                  options={wrapUpCodes?.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  notFoundContent={fetchingWrapUpCodes ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
                  placeholder={dashboardListServices?.length ? '[All Wrap Up Codes]' : 'Select a service first'}
                  disabled={!dashboardListServices?.length}
                  showSearch
                />
              </FieldLabel>
            )}
            {includeOpportunityFlag && (
              <FieldLabel label='Opportunity Flag'>
                <Select
                  value={filterIncludeOpportunityFlag}
                  onChange={(v) => {
                    setFilterIncludeOpportunityFlag(v);
                  }}
                  className='w-full'
                  allowClear
                  options={[
                    {
                      value: '1',
                      label: 'With Opportunity',
                    },
                    {
                      value: '0',
                      label: 'No Opportunity',
                    },
                  ]}
                  placeholder='[Opportunity filter not set]'
                />
              </FieldLabel>
            )}
            {includeRegionalCareOffers && (
              <FieldLabel label='Regional Care Offer'>
                <Select
                  mode='multiple'
                  value={selectedRegionalCareOffers}
                  onChange={(v) => {
                    setSelectedRegionalCareOffers(v);
                    setDashboardRegionalCareOffers(v);
                  }}
                  className='w-full'
                  allowClear
                  options={regionalCareOffers?.map((x) => ({
                    value: x,
                    label: x,
                  }))}
                  placeholder={'[All Regional Care Offers]'}
                  showSearch
                />
                <div className='text-xs text-right mr-1 mt-1 opacity-60'>s</div>
              </FieldLabel>
            )}
            {includeOnlyActive && (
              <FieldLabel label='Include only Active'>
                <div className='flex flex-row items-center gap-2'>
                  <Switch
                    checked={dashboardOnlyActive}
                    onChange={(v) => {
                      setDashboardOnlyActive(v);
                      if (v) {
                        setSelectedRequestStatuses([]);
                        setDashboardRequestStatuses([]);
                      }
                    }}
                  />

                  <Tooltip
                    title={
                      <div className='text-xs' style={{ lineHeight: '1rem' }}>
                        When enabled, only <b>active (not closed)</b> tickets will be included. This will remove and disable the selection of statuses and date range.
                      </div>
                    }>
                    <InfoCircleOutlined className='opacity-60' />
                  </Tooltip>
                </div>
              </FieldLabel>
            )}
            {allowSearch && (
              <FieldLabel label='Search'>
                <Input
                  className='w-full'
                  placeholder='Search...'
                  prefix={<SearchOutlined className='mr-2' />}
                  addonAfter={
                    <Tooltip
                      title={
                        <div className='text-xs' style={{ lineHeight: '1rem' }}>
                          <div className='font-bold'>Search by:</div>
                          <div>
                            <ul className='m-0'>
                              <li>Ticket id</li>
                              <li>Title</li>
                              <li>Category or subcategory</li>
                              <li>Account name</li>
                              <li>Opportunity name</li>
                            </ul>
                          </div>
                        </div>
                      }>
                      <InfoCircleOutlined className='opacity-60' />
                    </Tooltip>
                  }
                  allowClear
                  value={ticketListQuery}
                  onChange={(e) => {
                    setTicketListQuery(e.target.value);
                  }}
                />
              </FieldLabel>
            )}
            {allowGrouping && (
              <FieldLabel label='Group By'>
                <Select placeholder='Group By' style={{ width: 120 }} onChange={(value) => setTicketListGroupBy(value)} value={ticketListGroupBy} allowClear>
                  {groupableColumns.map((column) => (
                    <Option key={column.key} value={column.dataIndex}>
                      {column.title}
                    </Option>
                  ))}
                </Select>
              </FieldLabel>
            )}
            {allowSave && (
              <div className='md:col-span-2 2xl:col-span-2'>
                <FieldLabel label='Filter configuration'>
                  <div className='flex flex-row gap-2'>
                    <div className='flex-auto'>
                      <Select
                        value={selectedUserFilter}
                        onChange={handleOnFilterSelect}
                        disabled={fetchingUserFilters || savingFilter}
                        className='w-full'
                        allowClear
                        options={userFilters?.map((x) => ({
                          value: x.id,
                          label: x.filter?.name,
                        }))}
                        placeholder={'[All My Filters]'}
                      />
                    </div>
                    <div className='flex flex-row text-right justify-end gap-2'>
                      <Tooltip title='Delete filter'>
                        <Popconfirm title='Delete this filter?' onConfirm={handleDeleteFilter} okText='Yes' cancelText='No' description='This cannot be undone.'>
                          <Button disabled={!selectedUserFilter}>
                            <MdDelete className='text-red-600' />
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                      <Tooltip title={`${selectedUserFilter ? 'Update current Filter' : 'Save selected items as a New Filter'}`}>
                        <Button
                          onClick={() => {
                            if (!selectedUserFilter) {
                              setNewFilterName(null);
                              setShowNewFilterModal(true);
                            } else handleOnFilterSave();
                          }}>
                          <SaveOutlined />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </FieldLabel>
              </div>
            )}
          </div>
          {/* SUBMIT */}
          <div className='mt-6 mb-2 text-center'>
            <Button onClick={handleOnSubmit} type='primary' loading={isBusy} disabled={servicesMode === 'single' && !dashboardListServices?.length}>
              <Space2>
                <WhenDesktop>Apply filters</WhenDesktop>
                <SendOutlined />
              </Space2>
            </Button>
          </div>
          {collapsible && expanded && (
            <div className='absolute cursor-pointer rounded-full animate-appear3 drop-shadow-md z-10 flex flex-col justify-center items-center hover:scale-110 transition-all' style={{ marginLeft: 'calc(50% - 30px)', marginTop: '10px', backgroundColor: colorBackground, border: `1px solid ${colorLoaderForeground}`, width: '20px', height: '20px' }} onClick={() => setExpanded(!expanded)}>
              <Tooltip title='Click to collapse'>
                <FaAngleUp />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

DashboardFilters.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onFilterChange: PropTypes.func,
  includeDateRange: PropTypes.bool,
  includeServices: PropTypes.bool,
  includeRegions: PropTypes.bool,
  includeSubRegions: PropTypes.bool,
  includeQuarters: PropTypes.bool,
  includePeriod: PropTypes.bool,
  includeACVs: PropTypes.bool,
  includePlatforms: PropTypes.bool,
  includeForecasts: PropTypes.bool,
  includeStages: PropTypes.bool,
  includeTypes: PropTypes.bool,
  includeTicketTypes: PropTypes.bool,
  includeRequestStatuses: PropTypes.bool,
  includeRequestCategories: PropTypes.bool,
  includeRequestSubCategories: PropTypes.bool,
  includeMarketSegments: PropTypes.bool,
  includeAccountGroupings: PropTypes.bool,
  includeAccounts: PropTypes.bool,
  includeOpportunities: PropTypes.bool,
  includeRequesters: PropTypes.bool,
  includeProjectManagers: PropTypes.bool,
  includePrimaryTeamMembers: PropTypes.bool,
  includeSubjectMatterExperts: PropTypes.bool,
  includeTeamMembers: PropTypes.bool,
  includeDepartments: PropTypes.bool,
  includeWrapUpCodes: PropTypes.bool,
  includeDirectIndirects: PropTypes.bool,
  includeCustomerTypes: PropTypes.bool,
  includeRegionalCareOffers: PropTypes.bool,
  includeOnlyActive: PropTypes.bool,
  includeMembers: PropTypes.bool,
  includeOpportunityFlag: PropTypes.bool,
  allowSave: PropTypes.bool,
  allowSearch: PropTypes.bool,
  allowGrouping: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  colorBackground: PropTypes.string,
  colorForeground: PropTypes.string,
  colorLoaderBackground: PropTypes.string,
  colorLoaderForeground: PropTypes.string,
  collapsible: PropTypes.bool,
  additionalDescriptions: PropTypes.object,
  overwriteOnSubmit: PropTypes.bool,
  servicesMode: PropTypes.oneOf(['single', 'multiple']),
};
export default DashboardFilters;

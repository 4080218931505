import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { getTenantRoles } from 'src/api/accesscontrol';
import MasterAdminContext from 'src/context/MasterAdminContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';

const LoaderTenantRoles = ({ children }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { tenantRolesCount, setTenantRolesCount } = useContext(MasterAdminContext);

  useEffect(() => {
    if (null !== tenantRolesCount) return;
    executeAsyncProcess(async () => {
      try {
        setTenantRolesCount((await getTenantRoles())?.entities?.length ?? 0);
      } catch (error) {
        console.log(error);
      }
    });
  }, []);

  if (null === tenantRolesCount) return <FullScreenSpin title='Please wait' subtitle='Loading tenant roles' />;

  return children;
};

LoaderTenantRoles.propTypes = {
  children: PropTypes.node,
};

export default LoaderTenantRoles;

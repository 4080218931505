import React from 'react';
import useTheme from 'src/hooks/useTheme';
import PropTypes from 'prop-types';
import { Button, Space, Steps, Tooltip } from 'antd';
import { FaChevronRight, FaChevronLeft, FaCheck } from 'react-icons/fa6';
import BarLoader from 'react-bar-loader';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { IoClose } from 'react-icons/io5';

const Wizard = ({ steps, currentStep, onPrev, onNext, onFinish, onAbort, finishLabel = 'Finish' }) => {
  const { theme } = useTheme();
  const { isBusy } = useAsyncProcesses();

  const handleBtnBack = () => {
    if (currentStep === 0) return;
    if (onPrev) onPrev();
  };

  const handleBtnNext = () => {
    if (currentStep === steps.length - 1) return;
    if (onNext) onNext();
  };

  const handleBtnFinish = () => {
    if (onFinish) onFinish();
  };

  return (
    <div className='px-4 py-24 flex flex-col items-center'>
      <div className='relative w-full md:w-10/12 xl:w-[1024px] rounded-xl shadow-lg shadow-gray-500 dark:shadow-gray-800 border border-solid border-gray-200 dark:border-gray-800' style={{ backgroundColor: theme.backgroundBase }}>
        {onAbort && (
          <div className='absolute right-4 top-4'>
            <Tooltip title='Abort'>
              <IoClose
                className='cursor-pointer text-2xl hover:scale-105'
                onClick={() => {
                  if (onAbort) onAbort();
                }}
              />
            </Tooltip>
          </div>
        )}
        <div className='p-4 text-center text-xl font-bold'>{steps[currentStep].header}</div>
        {isBusy ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px', backgroundColor: theme.primary }}></div>}
        <div className='p-4 flex flex-row gap-2'>
          <div className='flex-initial border-b-0 border-l-0 border-t-0 border-r border-solid border-gray-200 dark:border-gray-800 pr-4 mr-4'>
            <Steps size='small' direction='vertical' progressDot current={currentStep} items={steps} />
          </div>
          <div className='flex-auto'>{steps[currentStep].content}</div>
        </div>
        {/* BUTTONS */}
        <div className='p-4 flex flex-row gap-1 justify-end'>
          {currentStep !== 0 && (
            <Button onClick={handleBtnBack} disabled={isBusy || steps[currentStep].allowBack === false}>
              <Space className='btn'>
                <FaChevronLeft />
                Back
              </Space>
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button onClick={handleBtnNext} disabled={isBusy || steps[currentStep].allowNext === false} type='primary'>
              <Space className='btn'>
                Next
                <FaChevronRight />
              </Space>
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button onClick={handleBtnFinish} type='primary' disabled={isBusy}>
              <Space className='btn'>
                {finishLabel}
                <FaCheck />
              </Space>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Wizard.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onFinish: PropTypes.func,
  onAbort: PropTypes.func,
  finishLabel: PropTypes.string,
};

export default Wizard;

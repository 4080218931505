import React, { useContext } from 'react';
import DataNexusContext from 'src/context/DataNexusContext';
import FieldLabel from 'src/components/layout/FieldLabel';
import { Input } from 'antd';
import { notEmptyString } from 'src/misc/Misc';
import { SearchOutlined } from '@ant-design/icons';
//import PropTypes from 'prop-types';

const SubcontractorsFilters = () => {
  const { subcontractorsFilters, setSubcontractorsFilters } = useContext(DataNexusContext);

  const handleQueryOnChange = (e) => {
    console.log('handleQueryOnChange', e.target.value);
    setSubcontractorsFilters('query', true === notEmptyString(e.target.value) ? e.target.value : null);
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-4 2xl:grid-cols-6 gap-4'>
      <FieldLabel
        label='Search'
        help={
          <ul>
            <li>name</li>
            <li>address</li>
            <li>country_of_registration</li>
            <li>type</li>
            <li>lei</li>
          </ul>
        }
      >
        <Input placeholder='type to search...' value={subcontractorsFilters?.query ?? ''} onChange={handleQueryOnChange} allowClear prefix={<SearchOutlined className='mr-2' />} />
      </FieldLabel>
    </div>
  );
};

SubcontractorsFilters.propTypes = {};

export default SubcontractorsFilters;

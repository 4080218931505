import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getServiceHosting, patchServiceHosting, postServiceHosting } from 'src/api/datanexus';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import { Button, Drawer, Form, Input } from 'antd';
import { sleepMs } from 'src/misc/Misc';
import Space2 from 'src/components/layout/Space2';
import PickerRegions from '../../Controls/PickerRegions';
import PickerFeatures from '../../Controls/PickerFeatures';
import PickerTypes from '../../Controls/PickerTypes';
import PickerNames from '../../Controls/PickerNames';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { CREATE_NEW_ID } from '../../Misc/misc';

const ServiceHostingEditor = ({ id, onClose }) => {
  const [item, setItem] = useState(null);
  const [editItemForm] = Form.useForm();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const creatingMode = CREATE_NEW_ID === id;

  useEffect(() => {
    if (!id) return;
    if (creatingMode) setData({});
    else loadData();
  }, [id]);

  const loadData = async () => {
    try {
      const resp = await getServiceHosting(id);
      //console.log(resp);
      setItem(resp);
      editItemForm.setFieldsValue(resp);
    } catch (error) {
      console.error(error);
    }
  };

  const setData = () => {
    const newItem = { id, region: null, use_other_region: null, feature: null, type: null, name: null, third_party_region: null, comments: null };
    setItem(newItem);
    editItemForm.setFieldsValue(newItem);
  };

  const close = async (values) => {
    onClose(values);
    await sleepMs(500); //allow to complete collapsing the drawer
    setItem(null);
  };

  const handleFormOnFinish = async (values) =>
    executeAsyncProcess(async () => {
      console.log('handleFormOnFinish', values);
      try {
        const resp = creatingMode ? await postServiceHosting(values) : await patchServiceHosting(id, { ...values, id: undefined });
        console.log(resp);
        close(resp);
      } catch (error) {
        console.error(error);
      }
    });

  const handleFormOnFailure = async (errorInfo) => {
    console.log('handleFormOnFailure', errorInfo);
  };

  return (
    <Drawer
      open={null !== id}
      onClose={close}
      closable={false}
      maskClosable={false}
      title={creatingMode ? 'Create new' : 'Edit'}
      extra={
        <Space2>
          <Button type='primary' size='small' onClick={() => editItemForm.submit()} disabled={!item || isBusy}>
            Save
          </Button>
          <Button size='small' onClick={() => close()} disabled={!item || isBusy}>
            Cancel
          </Button>
        </Space2>
      }
      styles={{ header: { padding: '12px' }, body: { padding: '12px' } }}>
      {!item ? (
        <FullScreenSpin title='Please wait' subtitle='Loading' />
      ) : (
        <>
          <Form form={editItemForm} name='ticket-create-form' onFinish={handleFormOnFinish} onFinishFailed={handleFormOnFailure} autoComplete='off' layout='vertical'>
            {!creatingMode && (
              <Form.Item label='Id' name='id'>
                <Input disabled />
              </Form.Item>
            )}

            <Form.Item label='Hosting Region' name='region' rules={[{ required: true, message: "Value can't be empty" }]}>
              <PickerRegions allowSeacrh initialValue={item.region} onPick={(v) => editItemForm.setFieldValue('region', v)} />
            </Form.Item>

            <Form.Item label='AWS Region' name='use_other_region'>
              <PickerRegions allowSeacrh initialValue={item.use_other_region} onPick={(v) => editItemForm.setFieldValue('use_other_region', v)} />
            </Form.Item>

            <Form.Item label='Feature' name='feature'>
              <PickerFeatures allowSeacrh initialValue={item.feature} onPick={(v) => editItemForm.setFieldValue('feature', v)} />
            </Form.Item>

            <Form.Item label='Type' name='type'>
              <PickerTypes allowSeacrh initialValue={item.type} onPick={(v) => editItemForm.setFieldValue('type', v)} />
            </Form.Item>

            <Form.Item label='Name' name='name'>
              <PickerNames allowSeacrh initialValue={item.name} onPick={(v) => editItemForm.setFieldValue('name', v)} />
            </Form.Item>

            <Form.Item label='3rd Party Region' name='third_party_region'>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 8 }} maxLength={1000} showCount />
            </Form.Item>

            <Form.Item label='Comments' name='comments'>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 8 }} maxLength={1000} showCount />
            </Form.Item>
          </Form>
        </>
      )}
    </Drawer>
  );
};

ServiceHostingEditor.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
};

export default ServiceHostingEditor;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoxType2 from './BoxType2';
import BoxType3 from './BoxType3';
import { Space } from 'antd';
import { FaTools, FaCheckCircle } from 'react-icons/fa';
import { AiOutlineAudit } from 'react-icons/ai';
import { MdSecurity } from 'react-icons/md';
import { BsPersonRaisedHand } from 'react-icons/bs';
import { hasUserPermission } from 'src/misc/Session';
import useUserInfo from 'src/hooks/useUserInfo';
import Space2 from 'src/components/layout/Space2';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import StyledButton from 'src/components/layout/StyledButton';
import useTheme from 'src/hooks/useTheme';

const SectionSpcTools = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { permissions: userPermissions } = useUserInfo();

  const title = (
    <Space className='btn'>
      <FaTools />
      Security Tools
    </Space>
  );

  // the btn available only for tier 3a (permission apps.teacket.spcportal.categories.requestfullaccess)
  const GetAccessBtn = (
    <PermissionsProvider permissions={userPermissions} requiredPermissions={['apps.teacket.spcportal.categories.requestfullaccess']} isControl>
      <StyledButton color={theme.textLight} onClick={() => navigate('/teacket/full-access-request-form')}>
        Get Access
      </StyledButton>
    </PermissionsProvider>
  );

  return (
    <BoxType2 title={title}>
      <div className='flex flex-row flex-wrap gap-6 justify-center'>
        {/* SECURITY ADVISOR */}
        <BoxType3
          title={
            <Space className='btn'>
              <MdSecurity />
              Security Advisor
            </Space>
          }
          footer={
            <Space2 className='w-full justify-center'>
              <StyledButton color={theme.textBase} onClick={() => (hasUserPermission('apps.securityadvisor.view', userPermissions) ? navigate('/security-advisor') : window.open('/products/security-advisor', 'blank'))}>
                {hasUserPermission('apps.securityadvisor.view', userPermissions) ? 'Try now' : 'Learn More'}
              </StyledButton>
              {true === hasUserPermission('apps.securityadvisor.view', userPermissions) || GetAccessBtn}
            </Space2>
          }>
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Genesys Cloud security optimization
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Security risk scheduled reports
              </Space>
            </div>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Real-time security alerts
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* DATA SUBJECT REQUESTS */}
        <BoxType3
          title={
            <Space className='btn'>
              <BsPersonRaisedHand />
              Data Subject Requests
            </Space>
          }
          footer={
            <Space2 className='w-full justify-center'>
              <StyledButton color={theme.textBase} onClick={() => (hasUserPermission('apps.datasubjectrequests.view', userPermissions) ? navigate('/data-subject-requests') : window.open('/products/data-subject-requests', 'blank'))}>
                {hasUserPermission('apps.datasubjectrequests.view', userPermissions) ? 'Try now' : 'Learn More'}
              </StyledButton>
              {true === hasUserPermission('apps.datasubjectrequests.view', userPermissions) || GetAccessBtn}
            </Space2>
          }>
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Undestand GDPR compliance with Genesys Cloud
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Manage GDPR requests
              </Space>
            </div>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                User-friendly interface
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* AUDIT VIEWER */}
        <BoxType3
          title={
            <Space className='btn'>
              <AiOutlineAudit />
              Audit viewer
            </Space>
          }
          footer={
            <Space2 className='w-full justify-center'>
              <StyledButton color={theme.textBase} onClick={() => (hasUserPermission('apps.auditviewer.view', userPermissions) ? navigate('/audit-viewer') : window.open('/products/audit-viewer', 'blank'))}>
                {hasUserPermission('apps.auditviewer.view', userPermissions) ? 'Try now' : 'Learn More'}
              </StyledButton>
              {true === hasUserPermission('apps.auditviewer.view', userPermissions) || GetAccessBtn}
            </Space2>
          }>
          <div className=' overflow-hidden'>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Insights into your Genesys Cloud organization activities
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* RECORDING CONSENT */}
        {/* <BoxType3
          title={
            <Space className='btn'>
              <IoRecording />
              Recording Consent (available soon)
            </Space>
          }
          footer={
            <div className='text-center'>
              <Button disabled type='primary' onClick={() => navigate(hasUserPermission('apps.recordingconsent.view', userPermissions) ? '/recording-consent' : '/products/recording-consent')}>
                {hasUserPermission('apps.recordingconsent.view', userPermissions) ? 'Try now' : 'Learn More'}
              </Button>
            </div>
          }>
          <div className=' overflow-hidden'>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Unsderstand how to implement recording consents in Genesys Cloud
              </Space>
            </div>
          </div>
        </BoxType3> */}
      </div>
    </BoxType2>
  );
};

SectionSpcTools.propTypes = {};

export default SectionSpcTools;

import React, { useRef } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import PropTypes from 'prop-types';

import { Spin } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const GraphPieChartUni = ({ data, title, hiddenLegend }) => {
  const { isDarkMode } = useTheme();

  const refContainer = useRef();

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={themeMode}>
      <div ref={refContainer}>
        {!data ? (
          <div className='text-center mt-10'>
            <Spin />
          </div>
        ) : (
          <>
            {title && <h3 className='text-center font-medium mb-2'>{title}</h3>}

            <div className='flex flex-row flex-wrap justify-center'>
              <PieChart
                colors={['#76b7b2', 'rgb(251 113 133)', '#f28e2c', '#59a14f', '#edc949', '#af7aa1', '#ff9da7', '#9c755f', '#bab0ab', '#adadad']}
                series={[
                  {
                    arcLabel: (item) => `${item.value}`,
                    data,
                    arcLabelMinAngle: 25,
                    innerRadius: 20,
                    outerRadius: 70,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    cy: 100,
                    cx: hiddenLegend ? 200 : 100, // Adjust center point based on legend visibility
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                  },
                }}
                width={400}
                height={200}
                slotProps={{
                  legend: {
                    hidden: hiddenLegend,
                    position: { vertical: 'middle', horizontal: 'right' },
                  },
                }}
              />
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

GraphPieChartUni.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  hiddenLegend: PropTypes.bool,
};

GraphPieChartUni.defaultProps = {
  data: [],
  hiddenLegend: false,
};

export default GraphPieChartUni;

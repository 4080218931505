import React, { useState, useEffect } from 'react';
import CondensedTable from 'src/components/layout/CondensedTable';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getHistory } from 'src/api/download';
import GraphPieChartUni from './GraphPieChartUni';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import { Space } from 'antd';

const DownloadStats = () => {
  const { executeAsyncProcess } = useAsyncProcesses();

  const pageSizeOptions = [20, 50, 100];

  const [fileStats, setFileStats] = useState([]);
  const [sectionStats, setSectionStats] = useState([]);
  const [fileStatsTopN, setFileStatsTopN] = useState([]);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);

  const columns = [
    {
      title: 'Document',
      key: 'filename',
      render: (record) => <>{record.filename.length > 20 ? `${record.filename.substring(0, 75)}...` : record.filename}</>,
    },
    {
      title: '# Of downloads',
      key: 'count',
      width: 150,
      render: (record) => <>{record.count}</>,
      sorter: (a, b) => a.count - b.count,
      defaultSortOrder: 'descend',
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getHistory();
        console.log(resp);
        setFileStats(resp?.dataByFiles);
        setSectionStats(resp?.dataBySection);
        setFileStatsTopN(resp?.dataByFilesTopN);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-2 gap-4 p-4 pb-0 m-4'>
      <div className='flex-auto lg:flex-row gap-4'>
        <DraggableLayoutItem title={<Space className='btn'>Top 3 retrieved documents</Space>} hideDraggable={true} height={'300px'} centered>
          <GraphPieChartUni data={fileStatsTopN} hiddenLegend={true} />
        </DraggableLayoutItem>
      </div>

      <div className='flex-auto lg:flex-row gap-4'>
        <DraggableLayoutItem title={<Space className='btn'>Downloads by Section</Space>} hideDraggable={true} height={'300px'} centered>
          <GraphPieChartUni data={sectionStats} />
        </DraggableLayoutItem>
      </div>

      <DraggableLayoutItem title={<Space className='btn'>Downloaded documents</Space>} hideDraggable={true} centered>
        <CondensedTable
          pagination={{
            showSizeChanger: true,
            pageSizeOptions,
            size: 'small',
            onShowSizeChange: (current, size) => {
              setPageSize(size);
            },
            current: page,
            pageSize,
            onChange: (page) => setPage(page),
          }}
          columns={columns}
          dataSource={fileStats}
        />
      </DraggableLayoutItem>
    </div>
  );
};

export default DownloadStats;

//#region Imports

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import MuiTheme from 'src/misc/MuiTheme';
import { ResponsiveChartContainer, LinePlot, ChartsXAxis, ChartsYAxis, ChartsLegend, ChartsTooltip, ChartsReferenceLine, ChartsAxisHighlight, MarkPlot, LineHighlightPlot, BarPlot } from '@mui/x-charts';
import NoData from 'src/components/layout/NoData';
import useTheme from 'src/hooks/useTheme';
import { colors } from '../../Misc/misc';
import { IconButton, Dialog, DialogContent, Checkbox, FormControlLabel } from '@mui/material';
import { AiOutlineFullscreen, AiOutlineClose } from 'react-icons/ai';

//#endregion

const Overdue = () => {
  const { isDarkMode } = useTheme();
  const { dashboardDataTickets } = useContext(TeacketContext);

  //#region Use States

  const [data, setData] = useState(null);
  const [period, setPeriod] = useState(null);
  const [hiddenSeries, setHiddenSeries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAverages, setShowAverages] = useState(false);
  const [showTrends, setShowTrends] = useState(false);

  //#endregion

  //#region Chart Data & Options

  const seriesDefinitions = [
    {
      label: 'Overdue By Closed Date',
      getData: () => data?.overdueByClosedDate?.map((x) => x.value),
      showAverage: showAverages,
      showTrend: showTrends,
      type: 'bar', // 'line' by default. Make sure you add a <BarPlot /> component to the chart content if you set it to 'bar'
    },
    {
      label: 'Overdue By Work Completed',
      getData: () => data?.overdueByWorkCompleted?.map((x) => x.value),
      showAverage: showAverages,
      showTrend: showTrends,
      type: 'bar', // 'line' by default. Make sure you add a <BarPlot /> component to the chart content if you set it to 'bar'
    },
  ];

  const chartColors = [isDarkMode ? colors.open.light : colors.open.dark, isDarkMode ? colors.closed.light : colors.closed.dark, isDarkMode ? colors.remaining.light : colors.remaining.dark];

  const series = seriesDefinitions.map((def) => ({
    type: def.type || 'line',
    data: hiddenSeries.includes(def.label) ? [] : def.getData(),
    label: def.label,
    xAxisKey: 'x-axis',
    yAxisKey: 'y-axis',
    valueFormatter: (value) => value || 'N/A',
    showMark: true,
    yAxisId: def.yAxisId || 'y-axis',
    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
    highlightScope: { fade: 'global', highlight: 'item' },
    ...(def.type === 'bar' && {
      color: `${chartColors[seriesDefinitions.findIndex((d) => d.label === def.label)]}${def.opacity ? Math.max(def.opacity * 10, 1) + 'A' : ''}`,
    }),
  }));

  //#endregion

  //#region Use Effects

  useEffect(() => {
    const { overdueByClosedDateByPeriods, overdueByWorkCompletedByPeriods } = dashboardDataTickets ?? {};
    console.log('overdueByClosedDateByPeriods', overdueByClosedDateByPeriods);
    console.log('overdueByWorkCompletedByPeriods', overdueByWorkCompletedByPeriods);

    if (!overdueByClosedDateByPeriods) return;
    if (!overdueByWorkCompletedByPeriods) return;

    const overdueByClosedDate = overdueByClosedDateByPeriods.map((item) => ({
      id: item.label,
      label: item.label,
      value: item.value,
    }));

    const overdueByWorkCompleted = overdueByWorkCompletedByPeriods.map((item) => ({
      id: item.label,
      label: item.label,
      value: item.value,
    }));

    setData({
      overdueByClosedDate,
      overdueByWorkCompleted,
    });

    const p = overdueByClosedDateByPeriods[0]?.period ?? null;
    setPeriod(p);
  }, [dashboardDataTickets]);

  //#endregion

  //#region No Data Rendering

  if (!data) {
    return (
      <div className='py-12'>
        <NoData />
      </div>
    );
  }

  //#endregion

  //#region Chart Content

  const handleAverageToggle = (event) => {
    setShowAverages(event.target.checked);
  };

  const handleTrendToggle = (event) => {
    setShowTrends(event.target.checked);
  };

  const ChartContent = ({ height = 400 }) => (
    <>
      <FormControlLabel control={<Checkbox checked={showAverages} onChange={handleAverageToggle} color='primary' />} label='Show Average' sx={{ mb: 1 }} />
      <FormControlLabel control={<Checkbox checked={showTrends} onChange={handleTrendToggle} color='primary' />} label='Show Trend' sx={{ mb: 1 }} />
      <ResponsiveChartContainer
        skipAnimation={true}
        colors={chartColors}
        margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
        xAxis={[
          {
            id: 'x-axis',
            label: period,
            scaleType: 'band',
            data: data?.overdueByClosedDate?.map((x) => x.label),
            tickLabelStyle: {
              angle: -45,
              textAnchor: 'end',
            },
          },
        ]}
        yAxis={[
          {
            id: 'y-axis',
            tickMinStep: 1,
            scaleType: 'linear',
          },
        ]}
        series={[...series, ...(showTrends ? trendLines : [])]}
        height={height}>
        <ChartsLegend position={{ vertical: 'top', horizontal: 'middle' }} onItemClick={handleLegendClick} />
        <LinePlot showMark={true} />
        <BarPlot borderRadius={10} />
        <ChartsXAxis />
        <ChartsYAxis />
        <MarkPlot />
        <LineHighlightPlot />
        <ChartsAxisHighlight x='line' />
        <ChartsTooltip trigger='axis' />
        {averageLines.map(({ value, label, color, serieLabel, yAxisId }) => !hiddenSeries.includes(serieLabel) && showAverages && <ChartsReferenceLine key={label} y={value} label={label} labelAlign='end' lineStyle={{ stroke: color, strokeDasharray: '3 3' }} axisId={yAxisId} />)}
        {({ value, label, color, serieLabel, yAxisId, isStart }) =>
          !hiddenSeries.includes(serieLabel) &&
          showTrends && (
            <ChartsReferenceLine
              key={label}
              y={value}
              label={isStart ? '' : label}
              labelAlign='start'
              lineStyle={{
                stroke: color,
                strokeWidth: 2,
              }}
              axisId={yAxisId}
            />
          )
        }
      </ResponsiveChartContainer>
    </>
  );

  ChartContent.propTypes = {
    height: PropTypes.number,
  };

  //#endregion

  //#region Averages

  const calculateAverage = (data) => {
    if (!data?.length) return 0;
    return data.reduce((sum, item) => sum + item.value, 0) / data.length;
  };

  const averageLines = series
    .filter((serie) => {
      const definition = seriesDefinitions.find((def) => def.label === serie.label);
      return definition.showAverage && !hiddenSeries.includes(serie.label);
    })
    .map((serie) => {
      const value = calculateAverage(data?.[serie.label.toLowerCase()]);
      return {
        value,
        serieLabel: serie.label,
        label: `${serie.label} (${value.toFixed(1)})`,
        color: chartColors[seriesDefinitions.findIndex((def) => def.label === serie.label)],
        yAxisId: serie.yAxisId || 'y-axis',
      };
    });

  //#endregion

  //#region Trends

  const calculateTrendLine = (data) => {
    if (!data || data.length < 2) return null;

    const values = data.map((d) => d.value);
    const x = Array.from({ length: values.length }, (_, i) => i);
    const y = values;

    // Calculate means
    const meanX = x.reduce((a, b) => a + b, 0) / x.length;
    const meanY = y.reduce((a, b) => a + b, 0) / y.length;

    // Calculate slope and intercept using least squares method
    const numerator = x.reduce((acc, curr, i) => acc + (curr - meanX) * (y[i] - meanY), 0);
    const denominator = x.reduce((acc, curr) => acc + Math.pow(curr - meanX, 2), 0);

    const slope = numerator / denominator;
    const intercept = meanY - slope * meanX;

    // Calculate points for the trend line
    const points = x.map((x) => slope * x + intercept);

    // Calculate monthly rate of change
    // Multiply by data points per month to get monthly rate
    const monthlyChange = slope * (values.length / 12);

    return {
      points,
      slope: monthlyChange,
      label: `Trend: ${monthlyChange === 0 ? 'stable' : `${monthlyChange > 0 ? '↑' : '↓'} ${Math.abs(monthlyChange).toFixed(1)}/month`}`,
    };
  };

  const trendLines = series
    .filter((serie) => {
      const definition = seriesDefinitions.find((def) => def.label === serie.label);
      return definition.showTrend && !hiddenSeries.includes(serie.label);
    })
    .map((serie) => {
      const trendLine = calculateTrendLine(data?.[serie.label.toLowerCase()]);
      if (!trendLine) return null;

      // Create a series for the trend line
      return {
        type: 'line',
        data: trendLine.points,
        label: trendLine.label,
        color: chartColors[seriesDefinitions.findIndex((def) => def.label === serie.label)],
        serieLabel: serie.label,
        yAxisId: serie.yAxisId || 'y-axis',
        showMark: false,
        lineStyle: {
          strokeDasharray: '5 5',
          strokeWidth: 2,
        },
      };
    })
    .filter(Boolean);

  //#endregion

  //#region Handle Clicks

  const handleLegendClick = (event, itemId) => {
    setHiddenSeries((prev) => (prev.includes(itemId.label) ? prev.filter((item) => item !== itemId.label) : [...prev, itemId.label]));
  };

  const handleFullscreenClick = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  //#endregion

  return (
    <MuiTheme>
      {/* Fullscreen Button */}
      <div style={{ position: 'relative' }}>
        <IconButton
          onClick={handleFullscreenClick}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1,
          }}>
          <AiOutlineFullscreen className='text-genesys-gray-500' />
        </IconButton>
        <ChartContent />
      </div>

      {/* Fullscreen Modal */}
      <Dialog fullWidth maxWidth='xl' open={isModalOpen} onClose={handleCloseModal}>
        <DialogContent>
          <IconButton
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 1,
            }}>
            <AiOutlineClose />
          </IconButton>
          <ChartContent height={700} />
        </DialogContent>
      </Dialog>
    </MuiTheme>
  );
};

export default Overdue;

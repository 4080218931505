//import { sleepMs } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders, handleResponseLegacy } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';
import dayjs from 'dayjs';

//#region "USERS"
var controllerGetUsers;
export const getUsers = async (query, services, page = 1, pageSize = 8) => {
  controllerGetUsers?.abort('cancelled');
  controllerGetUsers = new AbortController();
  const signal = controllerGetUsers.signal;
  const queryStringParams = objToQueryStringParams({ query, services, page, pageSize });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/users${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

//#endregion

//#region "SERVICES"

export const getServicesPropQuery = async (services, props, fields) =>
  handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/teacket/config/service-props/query${objToQueryStringParams({ services, props, fields })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getServicesForTenant = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/services/tenant`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

var controllerGetServiceUsers;
export const getServiceUsers = async (serviceId, query, page = 1, pageSize = 8, countries) => {
  controllerGetServiceUsers?.abort('cancelled');
  controllerGetServiceUsers = new AbortController();
  const signal = controllerGetServiceUsers.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize, countries });

  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/services/${serviceId}/users${queryStringParams}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getServicesIsMaster = async (serviceId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/services/${serviceId}/is-master`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getServiceProps = async (id, propName, include_schema = undefined) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/services/${id}/props/${propName}${objToQueryStringParams({ include_schema })}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "TICKETS"

export const postTicket = async (body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchTicket = async (id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchTicketProperties = async (id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/props`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

var controllerGetTickets;
export const getTickets = async (page, pageSize, orderBy, orderDirection, query, services, priorities, statuses, users, from, to, ticketTypes, groupName, groupValue, accountId, opportunityId, onlyActive) => {
  if (orderDirection?.startsWith('asc')) orderDirection = 'asc';
  if (orderDirection?.startsWith('desc')) orderDirection = 'desc';
  console.log('getTickets', page, pageSize, orderBy, orderDirection, query, services, priorities, statuses, users, from, to, ticketTypes, groupName, groupValue, accountId, opportunityId, onlyActive);
  controllerGetTickets?.abort('cancelled');
  controllerGetTickets = new AbortController();
  const signal = controllerGetTickets.signal;
  const queryStringParams = objToQueryStringParams({ page, pageSize, orderBy, orderDirection, query, services, priorities, statuses, users, from, to, ticketTypes, groupName, groupValue, accountId, opportunityId, onlyActive });

  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

//export const getTickets2 = async (page, pageSize, orderBy, orderDirection, from, to, services, regions, subRegions, quarters, acvs, platforms, forecasts, stages, types, ticketTypes, requestStatuses, requestCategories, requestSubCategories, marketSegments, accountGroupings, accounts, opportunities, requesters, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers, departments, wrapUpCodes, directIndirects, customerTypes, regionalCareOffers) => {
export const getTickets2 = async (page, pageSize, orderBy, orderDirection, query, filters, groupBy, includeTimeTracking = null) => {
  if (orderDirection?.startsWith('asc')) orderDirection = 'asc';
  if (orderDirection?.startsWith('desc')) orderDirection = 'desc';
  //console.log('getTickets', { page, pageSize, orderBy, orderDirection, from, to, services, regions, subRegions, quarters, acvs, platforms, forecasts, stages, types, ticketTypes, requestStatuses, requestCategories, requestSubCategories, marketSegments, accountGroupings, accounts, opportunities, requesters, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers, departments, wrapUpCodes, directIndirects, customerTypes, regionalCareOffers });
  console.log('getTickets2', { page, pageSize, orderBy, orderDirection, groupBy, query, filters });
  controllerGetTickets?.abort('cancelled');
  controllerGetTickets = new AbortController();
  const signal = controllerGetTickets.signal;

  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets2${objToQueryStringParams({ includeTimeTracking })}`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ page, pageSize, orderBy, orderDirection, groupBy, query, filters }),
      signal,
    })
  );
};

export const getMyTickets = async (page, pageSize, query, services, priorities, statuses, roles) => {
  controllerGetTickets?.abort('cancelled');
  controllerGetTickets = new AbortController();
  const signal = controllerGetTickets.signal;
  const queryStringParams = objToQueryStringParams({ page, pageSize, query, services, priorities, statuses, roles });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/my${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getTicketsMyOpen = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/my/open`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getTicket = async (id) =>
  handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    }),
    true,
    false
  );

export const getTicketsSameOpp = async (id) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/others-same-opp`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getTicketAISummary = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/summary`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getTicketProperties = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/opp-details-reload`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

var controllerHistory = null;
export const getTicketHistory = async (ticketId) => {
  controllerHistory?.abort('cancelled');
  controllerHistory = new AbortController();
  const signal = controllerHistory.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/history`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const postTicketHistory = async (ticketId, type, action, props) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/history`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...props, type, action }),
    })
  );
};

export const getTicketHistoryDetails = async (ticketId, itemId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/history/${itemId}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const postEscalateTicket = async (ticketId, status, justification) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/escalate`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ status, justification }),
    })
  );
};

export const getTicketGroupCounts = async (page, pageSize, query, priorities, statuses, users, from, to, groupBy) => {
  const queryStringParams = objToQueryStringParams({
    query,
    priorities,
    statuses,
    users,
    from,
    to,
    groupBy,
  });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/group-counts${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postTicketShare = async (ticketId, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/share`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

//#endregion

//#region "CONVERSATION"

export const postTicketConversation = async (ticketId, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/conversations`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const getTicketConversation = async (ticketId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/conversations`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchTicketConversation = async (ticketId, id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/conversations/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

//#endregion

//#region "MEMBERS"

export const getTicketMemeberAllowedRoles = async (ticketId, userId) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/members/${userId}/allowed-roles`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const postTicketMember = async (ticketId, userId, role) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/members/${userId}`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ role }),
    })
  );
};

export const getTicketMembers = async (ticketId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/members`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchTicketMembers = async (ticketId, userId, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/members/${userId}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const deleteTicketMember = async (ticketId, userId, role) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/members/${userId}/roles/${role}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "VALUES"

export const getTicketWidgetsValues = async (ticketId, widgetId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/widgets/${encodeURIComponent(widgetId)}/values`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const putTicketWidgetsValues = async (ticketId, widgetId, field, value) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/widgets/${encodeURIComponent(widgetId)}/values/${encodeURIComponent(field)}`, {
      method: 'PUT',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ value }),
    })
  );
};

//#endregion

//#region "TIME TRACKING"

export const postTicketTimeTracking = async (ticketId, userId, hours, date) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/time-tracking/${userId}`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ hours, date }),
    })
  );
};

export const getTicketTimeTracking = async (ticketId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/time-tracking`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getTicketTimeTrackingTotal = async (ticketId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/time-tracking/total`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

var controllerTimeTracking;
export const getTicketTimeTrackingDate = async (ticketId, userId, date) => {
  controllerTimeTracking?.abort('cancelled');
  controllerTimeTracking = new AbortController();
  const signal = controllerTimeTracking.signal;
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/time-tracking/${userId}/dates/${decodeURIComponent(dayjs(date).utc(true).format('YYYY-MM-DD'))}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    }),
    true,
    false
  );
};

export const deleteTicketTimeTracking = async (ticketId, userId, tracking_id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/time-tracking/${userId}/${tracking_id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "TIME TRACKING DASHBOARD"

export const getTimeTrackingDashboardPeriods = async (serviceId, groupBy, from, to, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/dashboards/time-tracking/${serviceId}/periods${objToQueryStringParams({ from, to, groupBy, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTimeTrackingDashboardAccounts = async (serviceId, from, to, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/dashboards/time-tracking/${serviceId}/accounts${objToQueryStringParams({ from, to, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTimeTrackingDashboardAccountsUsers = async (serviceId, accountId, from, to, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/dashboards/time-tracking/${serviceId}/accounts/${accountId}/users${objToQueryStringParams({ from, to, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTimeTrackingDashboardAccountsUsersByDate = async (serviceId, accountId, userId, from, to) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/dashboards/time-tracking/${serviceId}/accounts/${accountId}/users/${userId}${objToQueryStringParams({ from, to })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTimeTrackingDashboardUsers = async (serviceId, from, to, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/dashboards/time-tracking/${serviceId}/users${objToQueryStringParams({ from, to, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTimeTrackingDashboardUsersAccounts = async (serviceId, userId, from, to) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/dashboards/time-tracking/${serviceId}/users/${userId}/accounts${objToQueryStringParams({ from, to })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTimeTrackingDashboardUsersAccountsByDate = async (serviceId, userId, accountId, from, to) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/dashboards/time-tracking/${serviceId}/users/${userId}/accounts/${accountId}${objToQueryStringParams({ from, to })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "STATS"

export const getMyTicketsGraph = async (type) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/my-graph?type=${type}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "S3Uploader"

export const postUploadSession = async (id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/files`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const getFiles = async (id, fileName = null) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/files${fileName ? `?fileName=${fileName}` : ''}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const deleteFile = async (id, fileName) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/files?fileName=${encodeURIComponent(fileName)}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "ADMIN"

export const postAdminServices = async (name, ticket_no_prefix) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, ticket_no_prefix }),
    })
  );
};

export const patchAdminServices = async (id, changes) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const getAdminServices = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAdminService = async (id, fields) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${id}${objToQueryStringParams({ fields })}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAdminServiceTenants = async (serviceId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const putAdminServiceTenant = async (serviceId, tenantId, isMaster) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants/${tenantId}`, {
      method: 'PUT',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ isMaster }),
    })
  );
};

export const deleteAdminServiceTenant = async (serviceId, tenantId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants/${tenantId}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAdminServiceTenantProp = async (serviceId, tenantId, propName) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants/${tenantId}/props/${propName}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const putAdminServiceTenantProp = async (serviceId, tenantId, propName, propValue) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants/${tenantId}/props`, {
      method: 'PUT',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ [propName]: propValue }),
    })
  );

export const getAdminServicePropList = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/config/service-props`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const putAdminServiceProps = async (id, propName, data) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${id}/props/${propName}`, {
      method: 'PUT',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(
        data?.map((x) => {
          return { ...x, key: undefined };
        })
      ),
    })
  );
};

//#endregion

//#region "DASHBOARD"

export const getDashboardsTickets = async (from, to, services, regions, subRegions, quarters, acvs, platforms, forecasts, stages, types, requestTypes, requestStatuses, requestCategories, requestSubCategories, marketSegments, accountGroupings, accounts, opportunities, requesters, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers, departments, wrapUpCodes, directIndirects, customerTypes, onlyActive) => {
  console.log('Query String values:', { from, to, services, period: 'week', regions, subRegions, quarters, acvs, platforms, forecasts, stages, types, requestTypes, requestStatuses, requestCategories, requestSubCategories, marketSegments, accountGroupings, accounts, opportunities, requesters, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers, departments, wrapUpCodes, directIndirects, customerTypes, onlyActive });
  const queryStringParams = objToQueryStringParams({ from, to, services, period: 'week', regions, subRegions, quarters, acvs, platforms, forecasts, stages, types, requestTypes, requestStatuses, requestCategories, requestSubCategories, marketSegments, accountGroupings, accounts, opportunities, requesters, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers, departments, wrapUpCodes, directIndirects, customerTypes, onlyActive });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/dashboards/tickets${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTeamDashboardData = async (from, to, services, regions, subRegions, quarters, acvs, platforms, forecasts, stages, types, ticketTypes, requestStatuses, requestCategories, requestSubCategories, marketSegments, accountGroupings, accounts, opportunities, requesters, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers, departments, wrapUpCodes, directIndirects, customerTypes, regionalCareOffers, onlyActive) => {
  const queryStringParams = objToQueryStringParams({ from, to, services, regions, subRegions, quarters, acvs, platforms, forecasts, stages, types, ticketTypes, requestStatuses, requestCategories, requestSubCategories, marketSegments, accountGroupings, accounts, opportunities, requesters, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers, departments, wrapUpCodes, directIndirects, customerTypes, regionalCareOffers, onlyActive });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/teamdashboard/data${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctRequestTypes = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/requestTypes`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctRequestStatuses = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/requestStatuses`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctRequestCategories = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/requestCategories`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctRequestSubCategoriesForCategories = async (categories) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/requestSubCategories?categories=${categories.join(',')}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTicketsForAccountOrOpportunity = async (accountId, opportunityId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/accoropp?accountId=${accountId}&opportunityId=${opportunityId}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctDepartments = async (serviceIds) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/departments?serviceIds=${serviceIds}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctWrapUpCodes = async (serviceIds) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/wrapUpCodes?serviceIds=${serviceIds}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctStages = async (serviceIds) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/stages?serviceIds=${serviceIds}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctStatuses = async (serviceIds) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/statuses?serviceIds=${serviceIds}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTicketCounts = async (sfData, dashboardListServices, dashboardRequestTypes, dashboardRequestStatuses, dashboardRequestCategories, dashboardRequestSubCategories) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/count`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sfData,
        dashboardListServices,
        dashboardRequestTypes,
        dashboardRequestStatuses,
        dashboardRequestCategories,
        dashboardRequestSubCategories,
      }),
    })
  );
};

//#endregion

//#region "ROUTING"

export const postAdminRoutingQuery = async (serviceId, query) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/routing/services/${serviceId}/query`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(query),
    })
  );
};

export const getAdminRoutings = async (serviceId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/routing/services/${serviceId}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postAdminRouting = async (serviceId, data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/routing/services/${serviceId}`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const patchAdminRouting = async (id, changes) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/routing/${id}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const deleteAdminRouting = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/routing/${id}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "ADMIN DASHBOARD EXTRAS"

var controllerAdminDashboardExtras = null;

export const putAdminDashboardExtras = async (body) => {
  controllerAdminDashboardExtras?.abort('cancelled');
  controllerAdminDashboardExtras = new AbortController();
  const signal = controllerAdminDashboardExtras.signal;
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/dashboard/extras`, {
      method: 'PUT',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      signal,
    })
  );
};

export const postAdminDashboardExtrasQuery = async (query) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/dashboard/extras/query`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    })
  );

//#endregion

//#region "FULL ACCESS REQUEST"

export const getFullAccessRequest = async () => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/teacket/full-access-request/tickets`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postFullAccessRequest = async () => {
  return handleResponseLegacy(
    await fetch(`${ENV.apiUrl}/teacket/full-access-request/tickets`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

// export const dashboardGetUserTickets = async (userId) => {
//   const headers = await getAccessHeaders();
//   return handleResponse(
//     await fetch(`${ENV.apiUrl}/tickets/dashboard/users/${userId}`, {
//       method: 'GET',
//       headers: { ...headers, 'Content-Type': 'application/json' },
//     })
//   );
// };

// export const getTicketHistory = async (ticketId) => {
//   const headers = await getAccessHeaders();
//   return handleResponse(
//     await fetch(`${ENV.apiUrl}/tickets/${ticketId}/history`, {
//       method: 'GET',
//       headers: { ...headers, 'Content-Type': 'application/json' },
//     })
//   );
// };

// export const getConversationHistory = async (id) => {
//   await sleepMs(3000);
//   return [
//     {
//       id: '1',
//       text: 'Good Morning',
//       createdAt: '2021-10-22',
//       userId: '1',
//       visibility: 'External', // External, Internal
//     },
//     {
//       id: '2',
//       text: 'Whats up?',
//       createdAt: '2021-11-22',
//       userId: '2',
//       visibility: 'External', // External, Internal
//     },
//     {
//       id: '3',
//       text: 'HELLO',
//       createdAt: '2021-12-22',
//       userId: '2',
//       visibility: 'External', // External Internal
//     },
//     {
//       id: '4',
//       text: 'HELLO',
//       createdAt: '2022-10-22',
//       userId: '1',
//       visibility: 'External', // External, Internal
//     },
//     {
//       id: '5',
//       text: 'HELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONG',
//       createdAt: '2023-10-22',
//       userId: '1',
//       visibility: 'External', // External, Internal
//     },
//   ];
//   // const access_token = await getAccessToken();
//   // if (!access_token) throw new Error('No access token');
//   // return handleResponse(
//   //   await fetch(`${ENV.apiUrl}/tickets/${id}`, {
//   //     method: 'GET',
//   //     headers: {
//   //       'Content-Type': 'application/json',
//   //       token: access_token,
//   //     },
//   //   })
//   // );
// };

import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Button, Space, message } from 'antd';
import { FaStar } from 'react-icons/fa6';
import CorporateColors from 'src/misc/CorporateColors';
import { MdRefresh } from 'react-icons/md';
import { FaFileSignature } from 'react-icons/fa6';
import Space2 from 'src/components/layout/Space2';
import { getFullAccessRequest, postFullAccessRequest } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { v4 as uuid } from 'uuid';
import BoxType4 from '../../Compliance/Components/BoxType4';
import StyledButton from 'src/components/layout/StyledButton';
import useTheme from 'src/hooks/useTheme';

//import PropTypes from 'prop-types';

const FullAccessRequestForm = () => {
  const { theme } = useTheme();
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const [data, setData] = useState(null);

  useEffect(() => {
    loadData();
    setBreadcrumb([
      {
        title: 'Full Access Request',
      },
    ]);
    return () => {
      dropBreadcrumb();
    };
  }, []);

  const loadData = () =>
    executeAsyncProcess(async () => {
      console.log('loadData()');
      try {
        const resp = await getFullAccessRequest();
        setData(resp?.entities ?? []);
      } catch (error) {
        console.log(error);
      }
    });

  const submitRequest = async () =>
    await executeAsyncProcess(async () => {
      console.log('submitRequest()');
      const key = uuid();
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Registering the request...',
          duration: 0,
        });
        await postFullAccessRequest();
        messageApi.open({
          key,
          type: 'loading',
          content: 'Loading...',
          duration: 0,
        });
        const resp = await getFullAccessRequest();
        setData(resp?.entities ?? []);
        messageApi.open({
          key,
          type: 'success',
          content: 'Done!',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
        messageApi.destroy(key);
      }
    });

  const handleRefresh = () => loadData();

  const handleDownload = async () => {
    console.log('handleDownload()');
    if (true === needSubmitRequest()) await submitRequest();
    const link = document.createElement('a');
    link.href = '/documents/Trial Agreement.docx';
    link.download = 'Trial Agreement.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const needSubmitRequest = () => {
    if (data === null) return true;
    if (true === notArrayOrEmpty(data)) return true;
    if (true === notEmptyArray(data) && data[0]?.closed_at) return true;
    return false;
  };

  return (
    <>
      {contextHolder}
      <TitleBar
        title={
          <Space className='btn'>
            <FaStar style={{ color: CorporateColors.orange }} />
            <div>
              <span className='font-bold'>Full Access</span> <span className='font-thin'>Request</span>
            </div>
          </Space>
        }>
        <Button type='text' onClick={handleRefresh}>
          <Space2>
            <MdRefresh />
            <WhenDesktop>Refresh</WhenDesktop>
          </Space2>
        </Button>
      </TitleBar>
      <div className='w-full flex flex-row justify-center p-6'>
        <div className='w-[1100px] flex flex-col gap-6'>
          <BoxType4>
            <div className='flex flex-col gap-6'>
              <div className='text-4xl mb-4'>
                <span className='font-thin opacity-60'>How to get full access to the</span> <span className='font-bold font-roboto'>SPC</span> <span className='opacity-60 text-2xl font-roboto'>Portal</span>
              </div>
              <div className='text-lg' style={{ lineHeight: '1.5' }}>
                Full Access allows up to 90 days free usage of additional Security, Privacy and Compliance tools. Genesys has a right to remove access, charge or extend the trial period unless agreed otherwise. To enable Full Access, complete these steps:
                <ul>
                  <li>
                    <span onClick={handleDownload} className='cursor-pointer hover:underline'>
                      <strong>Download</strong>
                    </span>{' '}
                    and review the Free Trial Agreement.
                  </li>
                  <li>Review the Agreement and contact your Genesys representative to complete the signature process.</li>
                </ul>
              </div>
              <StyledButton color={theme.textBase} size='large' className='font-bold' onClick={handleDownload}>
                <Space2>
                  <FaFileSignature />
                  Download the Free Trial agreement
                </Space2>
              </StyledButton>
            </div>
          </BoxType4>
        </div>
      </div>
    </>
  );
};

// FullAccessRequestForm.propTypes = {};

export default FullAccessRequestForm;

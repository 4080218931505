import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoxType2 from './BoxType2';
import { BsCupHotFill } from 'react-icons/bs';
import { MdHome } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa6';
import GraphMyTicketsActive from '../../Teacket/Components/GraphMyTicketsActive';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import Space2 from 'src/components/layout/Space2';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import StyledButton from 'src/components/layout/StyledButton';
import useTheme from 'src/hooks/useTheme';

const SectionSpeedTickets = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.ticket.create']} isControl>
      <BoxType2
        title={
          <Space2>
            <BsCupHotFill />
            Tickets
          </Space2>
        }
        extras2={
          <Space2>
            <StyledButton
              color={theme.textBase}
              size='small'
              type='primary'
              onClick={() => {
                navigate('/teacket');
              }}
            >
              <Space2>
                <MdHome />
                <WhenDesktop>Home</WhenDesktop>
              </Space2>
            </StyledButton>

            <StyledButton
              color={theme.textBase}
              size='small'
              type='primary'
              //className='bg-green-600'
              onClick={() => {
                navigate('/teacket/create-new');
              }}
            >
              <Space2>
                <FaPlus />
                <WhenDesktop>Create new</WhenDesktop>
              </Space2>
            </StyledButton>
          </Space2>
        }
      >
        <GraphMyTicketsActive hideLegend={true} hideFooter={false} />
      </BoxType2>
    </PermissionsProvider>
  );
};

SectionSpeedTickets.propTypes = {};

export default SectionSpeedTickets;

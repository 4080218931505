import React, { useEffect, useContext } from 'react';
import DataNexusContext from 'src/context/DataNexusContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageTitle, pageList } from '../../Misc/misc';
import ServiceHostingFilters from './ServiceHostingFilters';
import ServiceHostingData from './ServiceHostingData';
import LoaderDict from 'src/components/pages/DataNexus/Loaders/DictLoader';
import { MdAdd } from 'react-icons/md';
import Space2 from 'src/components/layout/Space2';
import StyledButton from 'src/components/layout/StyledButton';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import useTheme from 'src/hooks/useTheme';
import { FaFileCsv } from 'react-icons/fa6';
//import PropTypes from 'prop-types';

const ServiceHosting = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { theme } = useTheme();
  const { adminServiceHostingFilters, setAdminServiceHostingAddNew, setAdminServiceHostingExport } = useContext(DataNexusContext);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Data Nexus',
        path: '/datanexus',
      },
      {
        title: 'Admin',
      },
      {
        title: 'Service Hosting',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.datanexus.admin']}>
      <LoaderDict>
        <TitleBar
          title={pageTitle}
          afterTitleExtras={
            <Space2>
              <PageSwitch pageList={pageList} />
              <StyledButton color={theme.textBase} size='small' onClick={() => setAdminServiceHostingAddNew(true)}>
                <Space2>
                  <MdAdd />
                  <WhenDesktop>Create new</WhenDesktop>
                </Space2>
              </StyledButton>

              <StyledButton color={theme.textBase} onClick={() => setAdminServiceHostingExport(true)} size='small'>
                <Space2>
                  <FaFileCsv />
                  Export
                </Space2>
              </StyledButton>
            </Space2>
          }
          bottomExtras={<ServiceHostingFilters />}
          defaultExpanded
        />
        <div className='p-4'>
          <ServiceHostingData filters={adminServiceHostingFilters} editable />
        </div>
      </LoaderDict>
    </PermissionsProvider>
  );
};

// ServiceHosting.propTypes = {};

export default ServiceHosting;

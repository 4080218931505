import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import PropTypes from 'prop-types';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import { getTimeTrackingDashboardUsers } from 'src/api/teacket';
import Space2 from 'src/components/layout/Space2';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import DashboardDataUsersAccounts from './DashboardDataUsersAccounts';
import { FaRegUser } from 'react-icons/fa';

const DashboardDataUsers = (props) => {
  const { from, to, services, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers } = props;
  console.log('DashboardDataAccounts:', props);

  const { commandPressed } = useContext(GlobalContext);

  const columns = [
    {
      title: 'Account',
      key: 'account',
      render: (record) => (
        <>
          <Space2>
            <FaRegUser className='opacity-40' />
            <span className='font-bold text-sm'>{record?.name}</span>
            {commandPressed && <span className='font-light text-xs text-genesys-orange-base'>{record?.user_id}</span>}
          </Space2>
        </>
      ),
    },
    {
      title: 'Hours',
      key: 'hours',
      align: 'right',
      width: '80px',
      render: (record) => <span className='font-bold text-sm'>{record?.hours}</span>,
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log('from/to:', from, to);
    if (!from || !to) return;
    loadData();
  }, [from, to]);

  const loadData = async () => {
    console.log('loadData');
    setLoading(true);
    try {
      const resp = await getTimeTrackingDashboardUsers(services, from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'), projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers);
      setData(resp);
    } catch {
      console.error('Error loading data');
      setData(null);
    }
    setLoading(false);
  };

  if (loading) return <SkeletonTable />;

  return (
    <CondensedTableLegacy
      showHeader={false}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={'user_id'}
      expandable={{
        expandedRowRender: (record) => (
          <p style={{ margin: 0 }} className='pl-10'>
            <DashboardDataUsersAccounts from={from} to={to} serviceId={services} userId={record?.user_id} projectManagers={projectManagers} primaryTeamMembers={primaryTeamMembers} subjectMatterExperts={subjectMatterExperts} teamMembers={teamMembers} />
          </p>
        ),
        // rowExpandable: () => true,
      }}
    />
  );
};

DashboardDataUsers.propTypes = {
  selectedFilters: PropTypes.object,
  from: PropTypes.object,
  to: PropTypes.object,
  services: PropTypes.array,
  projectManagers: PropTypes.array,
  primaryTeamMembers: PropTypes.array,
  subjectMatterExperts: PropTypes.array,
  teamMembers: PropTypes.array,
};

export default DashboardDataUsers;

import React /*useContext*/ from 'react';
// import HomePageContext from 'src/context/HomePageContext';
// import { useNavigate } from 'react-router-dom';
// import BoxType1 from './BoxType1';
import BoxType2 from './BoxType2';
import BoxType3 from './BoxType3';
import { Space } from 'antd';
import { FaTools, FaCheckCircle } from 'react-icons/fa';
import { PiPottedPlantFill } from 'react-icons/pi';
import { VscVerifiedFilled, VscOpenPreview } from 'react-icons/vsc';
import { Ri24HoursFill } from 'react-icons/ri';
import { GoLaw } from 'react-icons/go';
import { GrResources } from 'react-icons/gr';
import { BsDatabaseFillLock } from 'react-icons/bs';
import { hasUserPermission } from 'src/misc/Session';
import useUserInfo from 'src/hooks/useUserInfo';
import StyledButton from 'src/components/layout/StyledButton';
import useTheme from 'src/hooks/useTheme';
import Space2 from 'src/components/layout/Space2';

//import PropTypes from 'prop-types';

const SectionSecurity = () => {
  const { theme } = useTheme();
  // const navigate = useNavigate();
  // const { openDrawer } = useContext(HomePageContext);

  const { permissions: userPermissions } = useUserInfo();

  const title = (
    <Space className='btn'>
      <FaTools />
      Security, Privacy & Compliance
    </Space>
  );

  // const handleOpenDrawerSpcCopilot = () => {
  //   openDrawer(
  //     <Space className='btn'>
  //       <BsRobot />
  //       SPC Copilot
  //     </Space>,
  //     <>
  //       <BoxType1>
  //         <p>Your virtual advisor, providing real-time assistance, insights, and expert guidance tailored specifically to the intricacies of Genesys Cloud product, security, privacy and Compliance. Whether you're a seasoned professional seeking advanced strategies or a newcomer looking to understand the fundamentals, SPC Copilot is here to assist you every step of the way with most up to date information coming from managed data sources.</p>
  //         <p>This tool will not only provide you with answers to your questions but will also help to build tables & diagram from actual texts using AWS Bedrock within Genesys COE team managed environment.</p>
  //         <div className='mt-4'>
  //           <Button
  //             size='large'
  //             type='primary'
  //             onClick={() => {
  //               navigate('/COEAIAssistant');
  //             }}>
  //             <Space className='btn'>
  //               Try
  //               <BsRobot />
  //               <span className='font-bold'>SPC Copilot</span>
  //               now
  //             </Space>
  //           </Button>
  //         </div>
  //       </BoxType1>
  //     </>
  //   );
  // };

  // const handleOpenDrawerSecurityAdvicor = () => {
  //   openDrawer(
  //     <Space className='btn'>
  //       <MdSecurity />
  //       Security Advisor
  //     </Space>,
  //     <>
  //       <BoxType1>
  //         <p>Introducing Security Advisor – your indispensable companion for Genesys Cloud security optimization. This advanced tool meticulously scrutinizes your organization's Genesys Cloud configuration, uncovering any misconfigurations that could potentially compromise security.</p>
  //         <p>Security Advisor operates with precision, meticulously analyzing every aspect of your Genesys Cloud setup to identify areas where adjustments may be needed. By providing detailed insights into misconfigured items, Security Advisor empowers your organization to take proactive steps towards enhancing security.</p>
  //         <p>Through its user-friendly report, Security Advisor presents findings in a clear and concise manner, allowing your team to efficiently address any identified issues. From user permissions to access controls and more, Security Advisor offers a comprehensive overview of your security landscape.</p>
  //         <p>By leveraging Security Advisor, your organization can improve compliance with security standards and maintain a robust security posture in the face of evolving threats. With Security Advisor as your trusted ally, you can navigate the complexities of Genesys Cloud configuration with confidence, knowing that your organization's sensitive data and operations are safeguarded against potential risks.</p>
  //         <div className='mt-4'>
  //           <Button
  //             size='large'
  //             type='primary'
  //             onClick={() => {
  //               navigate('/security-advisor');
  //             }}>
  //             <Space className='btn'>
  //               <Space className='btn'>
  //                 Try
  //                 <MdSecurity />
  //                 <span className='font-bold'>Security Advisor</span>
  //                 now
  //               </Space>
  //             </Space>
  //           </Button>
  //         </div>
  //       </BoxType1>
  //     </>
  //   );
  // };

  // const handleOpenDrawerSubjectRequest = () => {
  //   openDrawer(
  //     <Space className='btn'>
  //       <BsPersonRaisedHand />
  //       Subject request
  //     </Space>,
  //     <>
  //       <BoxType1>
  //         <p>Introducing the GDPR Demo tool – your comprehensive solution for understanding and navigating the intricacies of GDPR compliance within your Genesys Cloud organization. Designed to provide hands-on experience and real-world insights, the GDPR Demo tool empowers organizations to efficiently manage GDPR-related operations while ensuring compliance with data protection regulations.</p>
  //         <p>At its core, the GDPR Demo tool offers a dynamic platform for performing real GDPR API operations within your Genesys Cloud environment. With seamless integration and intuitive controls, users can interact with the Genesys Cloud API to execute essential GDPR tasks, such as data access requests, data erasure requests, and data portability requests.</p>
  //         <p>The GDPR Demo tool goes beyond mere functionality by providing a simulated environment for conducting fake GDPR requests. This invaluable feature allows organizations to simulate various GDPR scenarios and workflows, enabling users to gain practical experience in handling GDPR-related processes without impacting real data or operations.</p>
  //         <p>Through its user-friendly interface, the GDPR Demo tool offers a step-by-step walkthrough of GDPR operations, guiding users through each stage of the process with clarity and precision. Whether you're managing data access requests from customers or responding to data erasure requests, the GDPR Demo tool streamlines the entire process, ensuring compliance with GDPR regulations while minimizing administrative overhead.</p>
  //         <p>By leveraging the GDPR Demo tool, organizations can proactively address GDPR compliance requirements, mitigate risks, and build trust with customers by demonstrating a commitment to protecting their privacy and data rights. With its powerful features and simulated environment, the GDPR Demo tool is the ultimate solution for mastering GDPR compliance within your Genesys Cloud organization.</p>
  //       </BoxType1>
  //     </>
  //   );
  // };

  // const handleOpenDrawerAuditViewer = () => {
  //   openDrawer(
  //     <Space className='btn'>
  //       <FaEye />
  //       Audit viewer
  //     </Space>,
  //     <>
  //       <BoxType1>
  //         <p>Introducing Audit Viewer – your indispensable tool for gaining comprehensive insights into your organization's Genesys Cloud activities and ensuring transparency, accountability, and security. Designed with user-friendly functionality in mind, Audit Viewer empowers Genesys Cloud customers to effortlessly navigate and interpret audit logs through an intuitive and accessible interface.</p>
  //         <p>At its core, Audit Viewer provides a centralized platform for viewing and analyzing audit logs generated within your Genesys Cloud environment. With its streamlined interface, Audit Viewer enables users to quickly locate and review specific events, actions, or changes across a wide range of parameters, including user activity and configuration modifications.</p>
  //         <p>Audit Viewer also plays a crucial role in promoting accountability and transparency within organizations. By providing a detailed record of user actions and system activities, Audit Viewer helps organizations track changes, detect unauthorized access, and ensure compliance with internal policies and external regulations, such as GDPR, HIPAA, and PCI-DSS.</p>
  //         <p>In today's fast-paced digital landscape, maintaining visibility and control over organizational activities is more important than ever. With Audit Viewer as your trusted companion, you can gain the insights you need to protect your data, safeguard your operations, and demonstrate compliance with confidence.</p>
  //       </BoxType1>
  //     </>
  //   );
  // };

  // const handleOpenRecordingConsent = () => {
  //   openDrawer(
  //     <Space className='btn'>
  //       <IoRecording />
  //       Recording Consent
  //     </Space>,
  //     <>
  //       <BoxType1>
  //         <p>Officia proident mollit nisi sit velit dolore est eiusmod proident ea. Officia consequat et exercitation veniam velit tempor enim non. Aute sit occaecat reprehenderit est ex. Ea non anim laboris sint consequat qui sint enim dolor et laborum nulla labore voluptate.</p>
  //         <p>Id irure anim irure ex anim nisi consequat enim nisi ex est incididunt velit amet. Exercitation quis in culpa pariatur dolor qui minim nostrud eu duis velit aliqua. Anim qui enim ex cillum cupidatat labore dolore cupidatat anim reprehenderit amet. Ad eiusmod excepteur commodo id nulla ea est sint sint voluptate ad sit. Sint consequat dolore exercitation eiusmod.</p>
  //         <p>Ad sit ad est incididunt. Qui fugiat ea voluptate eiusmod enim in ea sint aliqua incididunt. Id Lorem aliqua id laboris esse velit est id culpa occaecat Lorem Lorem. Do aute elit labore eiusmod aliquip labore ad laborum nulla Lorem in ut.</p>
  //       </BoxType1>
  //     </>
  //   );
  // };

  return (
    <BoxType2 title={title}>
      <div className='flex flex-row flex-wrap gap-6 justify-center'>
        {/* ----------------------------------------------------------- */}
        <BoxType3
          title={
            <Space className='btn'>
              <Ri24HoursFill />
              Availability & SLAs
            </Space>
          }
          footer={
            <Space2 className='w-full justify-center'>
              <StyledButton color={theme.textBase} onClick={() => window.open('https://status.mypurecloud.com/', 'rel=noopener noreferrer')}>
                Open Status Page
              </StyledButton>
            </Space2>
          }
        >
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Current status of services
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                SLAs for each AWS region
              </Space>
            </div>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Operation Incidents
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* ----------------------------------------------------------- */}
        <BoxType3
          title={
            <Space className='btn text-green-800 dark:text-green-200'>
              <PiPottedPlantFill />
              Sustainability
            </Space>
          }
          footer={
            <Space2 className='w-full justify-center'>
              <StyledButton color='rgb(34 197 94)' className='bg-green-700' onClick={() => window.open('https://www.genesys.com/company/sustainability', 'rel=noopener noreferrer')}>
                Find out more
              </StyledButton>
            </Space2>
          }
        >
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-green-500' />
                Get our report
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-green-500' />
                Our approach
              </Space>
            </div>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-green-500' />
                Our recognitions and more
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* ----------------------------------------------------------- */}
        <BoxType3
          title={
            <Space className='btn'>
              <GoLaw />
              Legal, Ethics & Compliance
            </Space>
          }
          footer={
            <Space2 className='w-full justify-center'>
              <StyledButton color={theme.textBase} onClick={() => window.open('https://www.genesys.com/company/legal/ethics-and-compliance', 'rel=noopener noreferrer')}>
                Find out more
              </StyledButton>
            </Space2>
          }
        >
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Legal Terms
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Codes of Conduct
              </Space>
            </div>
            <div className='py-4 '>
              <Space className='btn'>
                <FaCheckCircle className='text-sky-500' />
                Policies
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* ----------------------------------------------------------- */}
        <BoxType3
          title={
            <Space className='btn'>
              <GrResources />
              Resources
            </Space>
          }
        >
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700' onClick={() => window.open('https://help.mypurecloud.com/articles/genesys-cloud-security-policy/', 'rel=noopener noreferrer')}>
              <Space className='btn cursor-pointer'>
                <FaCheckCircle className='text-sky-500' />
                Our Security Policy
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700' onClick={() => window.open('https://help.mypurecloud.com/articles/genesys-cloud-privacy-policy/', 'rel=noopener noreferrer')}>
              <Space className='btn cursor-pointer'>
                <FaCheckCircle className='text-sky-500' />
                Our Privacy Policy
              </Space>
            </div>
            <div className='py-4' onClick={() => window.open('https://help.mypurecloud.com/articles/about-architecture-and-technology/', 'rel=noopener noreferrer')}>
              <Space className='btn cursor-pointer'>
                <FaCheckCircle className='text-sky-500' />
                Architecture
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* ----------------------------------------------------------- */}
        <BoxType3
          title={
            <Space className='btn'>
              <VscVerifiedFilled />
              Security & Compliance
            </Space>
          }
        >
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700' onClick={() => window.open('https://help.mypurecloud.com/wp-content/uploads/2023/10/0510527c-08c7-4ddf-a750-cd3e257595fb.Update-Security-and-Compliance-Overview_EN.pdf', 'rel=noopener noreferrer')}>
              <Space className='btn cursor-pointer'>
                <FaCheckCircle className='text-sky-500' />
                Overview
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700' onClick={() => window.open('https://library.genesys.com/m/61ce63df8569f705/original/Customer-GC-environment-hardening-guide.pdf', 'rel=noopener noreferrer')}>
              <Space className='btn cursor-pointer'>
                <FaCheckCircle className='text-sky-500' />
                Hardening Guide
              </Space>
            </div>
            <div className='py-4' onClick={() => window.open('https://library.genesys.com/m/13342aeed5e457fd/original/Shared-responsibilities-matrix.pdf', 'rel=noopener noreferrer')}>
              <Space className='btn cursor-pointer'>
                <FaCheckCircle className='text-sky-500' />
                Shared Responsibility Matrix
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* ----------------------------------------------------------- */}
        <BoxType3
          title={
            <Space className='btn'>
              <BsDatabaseFillLock />
              EU Privacy
            </Space>
          }
        >
          <div className=' overflow-hidden'>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700' onClick={() => window.open('https://help.mypurecloud.com/wp-content/uploads/2023/01/DPA-Direct-Customer-C2P-EU-UK-Entity-Dec-8-2022.pdf', 'rel=noopener noreferrer')}>
              <Space className='btn cursor-pointer'>
                <FaCheckCircle className='text-sky-500' />
                Data Processing Agreement
              </Space>
            </div>
            <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700' onClick={() => window.open('https://help.mypurecloud.com/articles/genesys-subprocessors/', 'rel=noopener noreferrer')}>
              <Space className='btn cursor-pointer'>
                <FaCheckCircle className='text-sky-500' />
                Genesys Subprocessors
              </Space>
            </div>
            <div className='py-4' onClick={() => window.open('https://help.mypurecloud.com/wp-content/uploads/2020/10/Genesys-Security-Measures.pdf', 'rel=noopener noreferrer')}>
              <Space className='btn cursor-pointer'>
                <FaCheckCircle className='text-sky-500' />
                Technical & Organizational Measures
              </Space>
            </div>
          </div>
        </BoxType3>
        {/* ----------------------------------------------------------- */}
        {true === hasUserPermission('apps.home.spc.vendorduediligence', userPermissions) && (
          <BoxType3
            title={
              <Space className='btn'>
                <VscOpenPreview />
                Vendor Due Diligence
              </Space>
            }
            footer={
              <Space2 className='w-full justify-center'>
                <StyledButton color={theme.textBase} type='primary' onClick={() => window.open('https://onegenesys.lightning.force.com/visualforce/session?url=https%3A%2F%2Fonegenesys--simpplr.vf.force.com%2Fapex%2Fsimpplr__app%3Fu%3D%252Fsite%252Fa145e000000F6hfAAC%252Fpage%252Fa125e000001V62bAAC', 'rel=noopener noreferrer _blank')}>
                  Find out more
                </StyledButton>
              </Space2>
            }
          >
            <div className=' overflow-hidden'>
              <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
                <Space className='btn'>
                  <FaCheckCircle className='text-sky-500' />
                  Risk Mitigation
                </Space>
              </div>
              <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
                <Space className='btn'>
                  <FaCheckCircle className='text-sky-500' />
                  Compliance and Security
                </Space>
              </div>
              <div className='py-4 border border-solid border-l-0 border-t-0 border-r-0 border-b border-zinc-300 dark:border-zinc-700'>
                <Space className='btn'>
                  <FaCheckCircle className='text-sky-500' />
                  Operational and Financial Review
                </Space>
              </div>
            </div>
          </BoxType3>
        )}
        {/* ----------------------------------------------------------- */}
      </div>
    </BoxType2>
  );
};

SectionSecurity.propTypes = {};

export default SectionSecurity;

import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UserMockup } from 'src/components/svg/user-mockup.svg';
import GcPicture from '../controls/UserPicture/GcPicture';

const UserImage = ({ image, size = '64px', scaleOnHover = false }) => {
  if (image === 'GENESYS')
    return (
      <>
        <GcPicture color='#ffffff' bgColor='#fc492b' size={parseInt(size)} />
      </>
    );

  return <>{image ? <img src={image} alt='user' className={`rounded-full border-2 border-solid border-genesys-azure-500 ${scaleOnHover ? 'hover:scale-95' : null}`} style={{ minHeight: size, height: size, minWidth: size, width: size }} /> : <UserMockup className={`rounded-full border-2 border-solid border-sky-500 ${scaleOnHover ? 'hover:scale-95' : null}`} style={{ minHeight: size, height: size, minWidth: size, width: size }} />}</>;
};

UserImage.propTypes = {
  image: PropTypes.string,
  size: PropTypes.string,
  scaleOnHover: PropTypes.bool,
};

export default UserImage;

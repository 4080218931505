import React from 'react';
import { Alert } from 'antd';

//import PropTypes from 'prop-types';

const NoInvitationLinks = () => {
  return (
    <>
      <div className='mt-8'>
        <Alert message={<span className='font-bold text-lg'>You need to be invited by your Genesys Cloud administrator to access this portal.</span>} type='warning' showIcon />
        <div className='my-4 font-bold text-2xl'>What should you do?</div>
        <div>
          <ul className='list-decimal space-y-4'>
            <li>
              Please contact your <span className='font-bold text-genesys-arctic-500 dark:text-genesys-arctic-400'>Genesys Cloud</span> administrator to create an invitation link for you.
            </li>
            <li>
              If you are a <span className='font-bold text-genesys-arctic-500 dark:text-genesys-arctic-400'>Genesys Cloud</span> administrator, please follow <a href='/Products/getting-started'>the Getting Started process</a>.
            </li>
          </ul>
        </div>
        <div>
          If you have any questions, please contact <a href='mailto:spc.portal@genesys.com'>spc.portal@genesys.com</a>
        </div>
      </div>
    </>
  );
};

NoInvitationLinks.propTypes = {};

export default NoInvitationLinks;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useUserInfo from 'src/hooks/useUserInfo';
import { determineAdminLevel } from 'src/misc/Session';
import FullScreenSpin from '../FullScreenSpin';
import AdminDisclaimerContent from './AdminDisclaimerContent';
import { ADMIN_DISCLAIMER_ACCEPTED_PROP_NAME } from 'src/misc/Config';
import { getUsersMeProps } from 'src/api/users';

const AdminDisclaimer = ({ children }) => {
  const [showDisclamer, setShowDisclaimer] = useState(null);
  const { permissions: userPermissions } = useUserInfo();

  useEffect(() => {
    const adminLevel = determineAdminLevel(userPermissions);
    console.log('adminLevel', adminLevel, userPermissions);
    if (adminLevel.master || adminLevel.tenant) {
      loadData();
      return;
    }
    setShowDisclaimer(false);
  }, []);

  const loadData = async () => {
    try {
      const resp = await getUsersMeProps(ADMIN_DISCLAIMER_ACCEPTED_PROP_NAME);
      //console.log('resp', resp);
      setShowDisclaimer(resp?.value ? false : true);
      // setShowDisclaimer(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (null === showDisclamer) return <FullScreenSpin title='Please wait' subtitle='Loading' />;
  if (true === showDisclamer) return <AdminDisclaimerContent />;
  return children;
};

AdminDisclaimer.propTypes = {
  children: PropTypes.node,
};

export default AdminDisclaimer;

import React from 'react';
import { Alert } from 'antd';

//import PropTypes from 'prop-types';

const UnknownAccType = () => {
  return (
    <>
      <div className='mt-8'>
        <Alert message={<span className='font-bold text-lg'>Your organization is not currently registered as a customer instance. This platform is only available to customers and partners at this time. Thank you.</span>} type='warning' showIcon />
        <div className='my-4 font-bold text-2xl'>What should you do?</div>
        <div>
          <ul className='list-disc space-y-4'>
            <li>Please contact your Account Executive for more details</li>
          </ul>
        </div>
      </div>
    </>
  );
};

UnknownAccType.propTypes = {};

export default UnknownAccType;

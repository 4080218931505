import React, { useContext, useState, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { Select, Button, Space, message, Modal, Input, Checkbox, Tag, Tooltip, Typography } from 'antd';
import { patchTicket, postEscalateTicket, patchTicketProperties } from 'src/api/teacket';
import { FaWindowClose, FaCheck } from 'react-icons/fa';
import { PiSirenFill } from 'react-icons/pi';
import { MdSend, MdRestore } from 'react-icons/md';
import PropertyItem from 'src/components/layout/PropertyItem';
import useUserInfo from 'src/hooks/useUserInfo';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { hasMasterAdminRights, hasTicketAdminRights, hasSupervisorRights, hasTicketEditRights, needNumberOfQuestions } from '../Misc/misc';
import TicketStatusColor from './TicketStatusColor';
import TicketPriorityColor from './TicketPriorityColor';
import { postTicketConversation } from 'src/api/teacket';
import { uuid } from 'short-uuid';
import TicketStage from './TicketStage';
import TicketMembers from './TicketMembers';
import TicketCompletionDates from './TicketCompletionDates';
import { MdWarning, MdCheckCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Space2 from 'src/components/layout/Space2';
import PropertySection from 'src/components/layout/PropertySection';
import CustomFieldInt from './TicketCustomProps/CustomFieldInt';
import { FaExclamationTriangle, FaUserClock } from 'react-icons/fa';
import { InfoCircleOutlined } from '@ant-design/icons';
import { hasUserPermission } from 'src/misc/Session';

const TicketStatus = () => {
  const { currentTicket, setCurrentTicket, currentTicketMembers, currentTicketCompletionDates, currentTicketReadOnly, determineIsWidgetReadOnly, getCurrentTicketService, servicesForTenant, updateCurrentTicket, setTicketAfterUpdateSync, addCurrentTicketConversationMessage, currentTicketIsMasterTenant, currentTicketNumberOfQuestions, setCurrentTicketNumberOfQuestions, currentTicketTimeTrackingTotal } = useContext(TeacketContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();

  const userInfo = useUserInfo();

  // const priorityOptions = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.priorityList?.map(({ priority }) => ({ key: priority, label: priority }));

  const [priorityList, setPriorityList] = useState(null);

  const [statusListNotClosed, setStatusListNotClosed] = useState(null);
  const [statusListClosed, setStatusListClosed] = useState(null);
  const [statusListEscalated, setStatusListEscalated] = useState(null);
  const [wrapUpCodeList, setWrapUpCodeList] = useState(null);

  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [closeStatus, setCloseStatus] = useState(null);
  const [closeWrapUpCode, setCloseWrapUpCode] = useState(null);
  const [ackNoHoursWarning, setAckNoHoursWarning] = useState(false);

  const [escalateModalOpen, setEscalateModalOpen] = useState(false);
  const [escalateStatus, setEscalateStatus] = useState(null);
  const [escalateJustification, setEscalateJustification] = useState(null);
  const [reopenToStatus, setReopenToStatus] = useState(null);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [criticalOpportunity, setCriticalOpportunity] = useState(false);

  const [modalStatus, setModalStatus] = useState(null);
  const [modalRejectedReason, setModalRejectedReason] = useState(null);
  const [rejectReason, setRejectReason] = useState(null);
  const [showModalReject, setShowModalReject] = useState(false);

  const [reviewNotes, setReviewNotes] = useState(null);
  const [isPublicReviewNotes, setIsPublicReviewNotes] = useState(false);

  const [isModalConfirmationVisible, setIsModalConfirmationVisible] = useState(false);

  const JUSTIFICATION_MIN_LENGTH = 25;

  useEffect(() => {
    determinePriorityList();
    determineStatusLists();
  }, []);

  useEffect(() => {
    if (!closeModalOpen) return;
    setCloseStatus(statusListClosed?.length === 1 ? statusListClosed[0].value : null);
    setCloseWrapUpCode(null);
  }, [closeModalOpen]);

  useEffect(() => {
    if (!escalateModalOpen) return;
    setEscalateStatus(statusListEscalated?.length === 1 ? statusListEscalated[0].value : null);
    setEscalateJustification(null);
  }, [escalateModalOpen]);

  //#region "Approve Requirements"

  const isProjectManagerSet = () => {
    // return true/false if ProjectManager is set
    return currentTicketMembers?.some((member) => member.role === 'Program Manager' || member.role === 'Project Manager');
  };

  const isForecastedDateSet = () => {
    return currentTicketCompletionDates?.some((item) => item.field === 'Forecasted date' && item.value);
  };

  const handleApproveModalOnSubmit = async () => {
    console.log('handleApproveModalOnSubmit()');
    if (!currentTicket) return;
    const properties = { OPPORTUNITY_CRITICAL: criticalOpportunity };

    // Check if Final Approval is required first
    const reqSecondApproval = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.settingList?.find((x) => x.name === 'FinalApprovalSupportStatus')?.value ?? undefined;

    executeAsyncProcess(async () => {
      try {
        // Check if ReviewNotes were included during finalApproval
        if (reviewNotes) {
          const text = `Review Notes: ${reviewNotes}`.replace(/\n/g, '<br />');

          const userId = userInfo.id;
          //text = text.replace(/\n/g, '<br />');
          const visibility = isPublicReviewNotes ? 'external' : 'internal';

          let newMessage = {
            userId,
            text,
            visibility,
            is_master_tenant: true === currentTicketIsMasterTenant,
            props: {
              is_reviewMsg: true,
            },
          };

          addCurrentTicketConversationMessage({ ...newMessage, isPosting: true, user_name: userInfo.name, user_pic: userInfo.user_pic });

          delete newMessage.owner;
          let confirmedMessage = await postTicketConversation(currentTicket.id, newMessage);
          addCurrentTicketConversationMessage({ ...confirmedMessage, isNew: true, user_name: userInfo.name, user_pic: userInfo.user_pic, is_master_tenant: true === currentTicketIsMasterTenant });
        }

        await patchTicketProperties(currentTicket.id, properties);
        setCurrentTicket({ ...currentTicket, props: { ...currentTicket.props, ...properties } });

        if (isWaitingForFinalApproval()) await handleOnStatusChange('Approved');
        else await handleOnStatusChange(reqSecondApproval && criticalOpportunity ? reqSecondApproval : 'Approved');
        setApproveModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    });
  };

  //#endregion

  const determinePriorityList = () => {
    const priorities = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.priorityList?.map(({ priority }) => ({ value: priority, label: priority }));
    setPriorityList(priorities);
  };

  const determineStatusLists = () => {
    const notClosed = servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.statusList?.filter((s) => s.userSelectable && !s.isClosed)
      .sort((a, b) => a.status.localeCompare(b.status))
      .map(({ status }) => ({ value: status, label: status }));
    const closed = servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.statusList?.filter((s) => s.userSelectable && s.isClosed)
      .map(({ status }) => ({ value: status, label: status }));
    const escalated = servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.statusList?.filter((s) => s.isEscalated)
      .map(({ status }) => ({ value: status, label: status }));
    const wrapUpCodes = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.wrapUpCodes?.map(({ wrapUpCode, description }) => ({ value: wrapUpCode, label: wrapUpCode, description }));

    setStatusListNotClosed(notClosed);
    setStatusListClosed(closed);
    setStatusListEscalated(escalated);
    // console.log('=== wrap up codes:', wrapUpCodes);
    setWrapUpCodeList(wrapUpCodes.sort((a, b) => a.label.localeCompare(b.label)));

    const reopenStatus = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.settingList?.find((x) => x.name === 'ReopenToStatus')?.value;
    setReopenToStatus(reopenStatus);
  };

  const handleOnPriorityChange = (priority) => {
    console.log('handleOnPriorityChange()', priority);
    executeAsyncProcess(async () => {
      try {
        const ticket = await patchTicket(currentTicket.id, { priority });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Priority updated',
          duration: 3,
        });
        setTicketAfterUpdateSync(true);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const updateTicketStatus = async (status, rejectedReason, setWrapupCode) => {
    await executeAsyncProcess(async () => {
      try {
        const ticket = await patchTicket(currentTicket.id, { status, wrap_up_code: setWrapupCode ? closeWrapUpCode : null, closed_at: isClosedStatus(status) ? new Date() : null, justification: null, rejectedReason: rejectedReason });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Status updated',
          duration: 3,
        });
        setTicketAfterUpdateSync(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsModalConfirmationVisible(false);
      }
    });
  };

  const handleOnStatusChange = async (status, rejectedReason, setWrapupCode = false) => {
    console.log('handleOnStatusChange()', status);

    if (getStatusDetails(status)?.confirmationDialog) {
      setIsModalConfirmationVisible(true);
      setModalStatus(status);
      setModalRejectedReason(rejectedReason);
      return;
    }

    await updateTicketStatus(status, rejectedReason, setWrapupCode);
  };

  const handleOkConfirmation = async () => {
    await updateTicketStatus(modalStatus, modalRejectedReason);
  };

  const priorityLabelRender = ({ value }) => {
    return (
      <div className='flex flex-row gap-2 items-center'>
        <TicketPriorityColor serviceId={currentTicket?.service_id} priority={value} size={'12px'} />
        <span>{value}</span>
      </div>
    );
  };

  const priorityOptionRender = ({ value }) => {
    return (
      <div className='flex flex-row gap-2 items-center'>
        <TicketPriorityColor serviceId={currentTicket?.service_id} priority={value} size={'10px'} />
        <span>{value}</span>
      </div>
    );
  };

  const statusLabelRender = ({ value }) => {
    return (
      <div className='flex flex-row gap-2 items-center'>
        <TicketStatusColor serviceId={currentTicket?.service_id} status={value} size={'12px'} />
        <span>{value}</span>
      </div>
    );
  };

  const statusOptionRender = ({ value }) => {
    return (
      <div className='flex flex-row gap-2 items-center'>
        <TicketStatusColor serviceId={currentTicket?.service_id} status={value} size={'10px'} />
        <span>{value}</span>
      </div>
    );
  };

  //#region "HANDLERS - MODAL CLOSE"

  const handleOnCloseTicketClick = async () => {
    console.log('handleOnCloseTicketClick()');
    setCloseModalOpen(true);
  };

  const handleCloseModalOnCancel = () => {
    setCloseModalOpen(false);
  };

  const handleCloseModalOnCloseStatusChange = (status) => {
    console.log('handleCloseModalOnCloseStatusChange()', status);
    setCloseStatus(status);
  };

  const handleCloseModalOnWrapUpCodeChange = (wrapUpCode) => {
    console.log('handleCloseModalOnWrapUpCodeChange()', wrapUpCode);
    setCloseWrapUpCode(wrapUpCode);
  };

  const handleCloseModalOnSubmit = async () => {
    console.log('handleCloseModalOnSubmit()');
    await executeAsyncProcess(async () => {
      try {
        const ticket = await patchTicket(currentTicket.id, { status: closeStatus, wrap_up_code: closeWrapUpCode, closed_at: new Date().toISOString() });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Ticket closed',
          duration: 3,
        });
        setCloseModalOpen(false);
        setTicketAfterUpdateSync(true);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const isClosedStatus = (_status = currentTicket?.status) =>
    servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.statusList?.filter((s) => s.isClosed)
      .map(({ status }) => ({ value: status, label: status }))
      .some((s) => s.value === _status);

  const isEscalatedStatus = () => statusListEscalated?.some((s) => s.value === currentTicket?.status);
  const getStatusDetails = (status) => servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.statusList?.find((s) => s.status === status);

  const isWaitingForFinalApproval = () => {
    const service = servicesForTenant?.find((service) => service.id === currentTicket?.service_id);
    const statusList = service?.props?.statusList;
    const finalApprovalStatusName = service?.props?.settingList?.find((x) => x.name === 'FinalApprovalSupportStatus')?.value ?? undefined;
    return statusList?.find((s) => s.status === currentTicket?.status)?.status === finalApprovalStatusName;
  };

  //#endregion

  //#region "HANDLERS - MODAL ESCALATE"

  const handleOnEscalateTicketClick = async () => {
    console.log('handleOnEscalateTicketClick()');
    setEscalateModalOpen(true);
  };

  const handleEscalateModalOnCancel = () => {
    setEscalateModalOpen(false);
  };

  const handleEscalateModalOnCloseStatusChange = (status) => {
    console.log('handleEscalateModalOnCloseStatusChange()', status);
    setEscalateStatus(status);
  };

  const handleEscalateModalOnJustificationChange = (e) => {
    console.log('handleEscalateModalOnJustificationChange()', e.target.value);
    setEscalateJustification(e.target.value);
  };

  const handleEscalateModalOnSubmit = async () => {
    console.log('handleEscalateModalOnSubmit()');
    await executeAsyncProcess(async () => {
      try {
        const ticket = await postEscalateTicket(currentTicket.id, escalateStatus, escalateJustification);
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Ticket escalated',
          duration: 3,
        });
        setEscalateModalOpen(false);
        setTicketAfterUpdateSync(true);
      } catch (error) {
        console.error(error);
      }
    });
  };

  //#endregion

  const canManageStatus = () => {
    // admin can always manage status
    if (notEmptyArray(userInfo?.permissions) && true === hasMasterAdminRights(userInfo?.permissions)) return true;

    // if the ticket is read only, nobody can manage status

    if (true === determineIsWidgetReadOnly('Status / Priority')) return false;

    // superuser can always manage status
    if (notEmptyArray(userInfo?.permissions) && true === hasTicketAdminRights(userInfo?.permissions)) return true;

    if (hasSupervisorRights(userInfo?.permissions)) {
      return true;
    }

    // if the ticket has no members, nobody can manage status

    if (true === notArrayOrEmpty(currentTicketMembers)) return false;

    // if the ticket has members, only people with a role flag can manage status
    const member = currentTicketMembers.find((x) => x.user_id === userInfo.id);

    const currentTicketService = getCurrentTicketService();

    if (member && currentTicketService?.props?.roleList?.find((x) => x.role === member.role)?.canManageStatuses) return true;

    return false;
  };

  const canEscalate = () => {
    if (true === isEscalatedStatus()) return false;
    if (statusListEscalated?.length < 1) return false;
    if (true === determineIsWidgetReadOnly('Status / Priority')) return false;

    const isDefaultStatus = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.statusList?.find((s) => s.status === currentTicket?.status)?.default;

    const isDefaultPriority = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.priorityList?.find((s) => s.priority === currentTicket?.priority)?.default;

    // hide escalate button for default status and priority
    if (true === isDefaultStatus || true === isDefaultPriority) return false;

    return true;
  };

  const isNotAccepted = () => {
    const service = servicesForTenant?.find((service) => service.id === currentTicket?.service_id);
    const statusList = service?.props?.statusList;

    const isDefaultStatus = statusList?.find((s) => s.status === currentTicket?.status)?.default;
    const isApproveStatus = statusList?.some((s) => s.status === 'Approved');
    const isRejectedStatus = statusList?.some((s) => s.status === 'Rejected');

    // show accept / reject button for default status
    if (isDefaultStatus && isApproveStatus && isRejectedStatus) return true;

    return false;
  };

  const canAcceptReject = () => {
    const service = servicesForTenant?.find((service) => service.id === currentTicket?.service_id);
    const statusList = service?.props?.statusList;
    const finalApprovalStatusName = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.settingList?.find((x) => x.name === 'FinalApprovalSupportStatus')?.value ?? undefined;

    const isDefaultStatus = statusList?.find((s) => s.status === currentTicket?.status)?.default;
    const isFinalApprovalStatus = statusList?.find((s) => s.status === currentTicket?.status)?.status === finalApprovalStatusName;

    const statusRequireApproval = isDefaultStatus || isFinalApprovalStatus;

    const isApproveStatus = statusList?.some((s) => s.status === 'Approved');
    const isRejectedStatus = statusList?.some((s) => s.status === 'Rejected');

    // show accept / reject button for default status
    if (statusRequireApproval && isApproveStatus && isRejectedStatus && hasTicketEditRights(userInfo?.permissions)) return true;

    return false;
  };

  const canAcceptFinalApproval = () => {
    const service = servicesForTenant?.find((service) => service.id === currentTicket?.service_id);
    if (!service) return false;
    const finalApprovalStatusName = service?.props?.settingList?.find((x) => x.name === 'FinalApprovalSupportStatus')?.value ?? undefined;
    const approversString = service?.props?.statusList?.find((s) => s.status === finalApprovalStatusName)?.autoAssign ?? undefined;

    if (!approversString) return false;

    if (hasUserPermission('admin.master', userInfo?.permissions)) return true; // master admin can always approve

    const approvers = approversString.split(',').map((x) => x.split(':')[0]);

    // check if current user is a member
    const amIMember = currentTicketMembers?.some((member) => member.user_id === userInfo.id) || hasUserPermission('admin.master', userInfo?.permissions);

    // check if current user is in the list
    if (approvers.includes(userInfo.id) && amIMember) return true;

    return false;
  };

  const handleRejectOnSubmit = async () => {
    console.log('handleRejectOnSubmit()');

    // Add reason to the conversation
    const text = `Rejected ${rejectReason ? `: ${rejectReason}` : ' (no reason provided)'}`.replace(/\n/g, '<br />');
    executeAsyncProcess(async () => {
      try {
        const userId = userInfo.id;
        //text = text.replace(/\n/g, '<br />');
        const visibility = 'external';
        let newMessage = {
          userId,
          text,
          visibility,
          is_master_tenant: true === currentTicketIsMasterTenant,
          props: {
            is_rejectedMsg: true,
          },
        };
        addCurrentTicketConversationMessage({ ...newMessage, isPosting: true, user_name: userInfo.name, user_pic: userInfo.user_pic });

        delete newMessage.owner;
        let confirmedMessage = await postTicketConversation(currentTicket.id, newMessage);
        addCurrentTicketConversationMessage({ ...confirmedMessage, isNew: true, user_name: userInfo.name, user_pic: userInfo.user_pic, is_master_tenant: true === currentTicketIsMasterTenant });

        await handleOnStatusChange('Rejected', rejectReason, true);
      } catch (error) {
        console.log(error);
      } finally {
        setShowModalReject(false);
      }
    });
  };

  const handleReopenClick = () => {
    Modal.confirm({
      title: 'Reopen this ticket?',
      content: 'Are you sure you want to reopen ?',
      onOk: () => handleOnStatusChange(reopenToStatus),
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  const formatCurrency = (value, currency) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  };

  return (
    <>
      {contextHolder}
      {/* <MODAL - CLOSE> */}
      <Modal
        title={`Closing the ${currentTicket?.id} ticket`}
        open={closeModalOpen}
        onCancel={handleCloseModalOnCancel}
        footer={[
          <>
            {currentTicketTimeTrackingTotal === 0 && (
              <div className='flex flex-row gap-2 mt-4 mb-2'>
                <Checkbox disabled={isBusy} className='w-full' onChange={(e) => setAckNoHoursWarning(e.target.checked)} checked={ackNoHoursWarning}>
                  <FaExclamationTriangle className='text-orange-500 mr-2 mt-1' />
                  <span>I acknowledge that no hours will be submitted for this ticket</span>
                </Checkbox>
              </div>
            )}
            <Button type='primary' loading={isBusy} disabled={!closeStatus || !closeWrapUpCode || (currentTicketTimeTrackingTotal === 0 && !ackNoHoursWarning)} onClick={handleCloseModalOnSubmit}>
              <Space className='btn'>
                <MdSend />
                Submit
              </Space>
            </Button>
          </>,
        ]}
      >
        <div className='flex flex-col gap-2'>
          <div>
            Status:
            <Select disabled={isBusy} className='w-full' options={statusListClosed} value={closeStatus} onChange={handleCloseModalOnCloseStatusChange} placeholder='Not selected' labelRender={statusLabelRender} optionRender={statusOptionRender} />
          </div>
          <div>
            Wrap-up code:
            <Select disabled={isBusy} className='w-full' options={wrapUpCodeList} value={closeWrapUpCode} onChange={handleCloseModalOnWrapUpCodeChange} placeholder='Not selected' />
          </div>
          <div className='text-xs opacity-60 text-center'>{wrapUpCodeList?.find((x) => x.value === closeWrapUpCode)?.description}</div>
        </div>
      </Modal>
      {/* </MODAL - CLOSE> */}

      {/* <MODAL - ESCALATE> */}
      <Modal
        title={`Escalating the ${currentTicket?.id} ticket`}
        open={escalateModalOpen}
        onCancel={handleEscalateModalOnCancel}
        footer={[
          <Button type='primary' loading={isBusy} disabled={!escalateStatus || !escalateJustification || escalateJustification.length < JUSTIFICATION_MIN_LENGTH} onClick={handleEscalateModalOnSubmit}>
            <Space className='btn'>
              <MdSend />
              Submit
            </Space>
          </Button>,
        ]}
      >
        <div className='flex flex-col gap-2'>
          <div>
            Status:
            <Select disabled={isBusy} className='w-full' options={statusListEscalated} value={escalateStatus} onChange={handleEscalateModalOnCloseStatusChange} placeholder='Not selected' labelRender={statusLabelRender} optionRender={statusOptionRender} />
          </div>
          <div>
            Justification <span className='text-xs font-light opacity-60'>(min {JUSTIFICATION_MIN_LENGTH} characters)</span>:
            <Input.TextArea disabled={isBusy} className='w-full mb-4' value={escalateJustification} onChange={handleEscalateModalOnJustificationChange} autoSize={{ minRows: 2, maxRows: 8 }} maxLength={250} showCount />
          </div>
        </div>
      </Modal>
      {/* </MODAL - ESCALATE> */}

      {/* <MODAL - APPROVE> */}
      <Modal
        title={`Approve the ${currentTicket?.id} ticket process`}
        open={approveModalOpen}
        onCancel={() => setApproveModalOpen(false)}
        footer={[
          <div className='mt-4'>
            <Button type='primary' loading={isBusy} disabled={!isProjectManagerSet() || !isForecastedDateSet() || (true === needNumberOfQuestions(currentTicket) && currentTicketNumberOfQuestions?.value < 1)} onClick={() => handleApproveModalOnSubmit()}>
              <Space className='btn'>
                <FaCheck />
                Approve
              </Space>
            </Button>
          </div>,
        ]}
      >
        <div className='flex flex-col gap-4'>
          <span className='opacity-60'>All required fields below must be completed before proceeding. Once all criteria are satisfied, click the Approve button.</span>

          <PropertySection>
            <div className='flex flex-col items-center gap-2 my-2'>
              <Space2>
                <strong>PM must be assigned</strong>
                {isProjectManagerSet() ? <MdCheckCircle className='text-green-500' /> : <MdWarning className='text-orange-400' />}
              </Space2>
              <div className='w-[75%] items-center'>
                <TicketMembers hideBorder={true} addButtonType='primary' />
              </div>
            </div>
          </PropertySection>

          <PropertySection>
            <div className='flex flex-col items-center gap-2 my-2'>
              <Space2>
                <strong>Forecasted Date is required</strong>
                {isForecastedDateSet() ? <MdCheckCircle className='text-green-500' /> : <MdWarning className='text-orange-400' />}
              </Space2>
              <div className='w-[75%] items-center'>
                <TicketCompletionDates />
              </div>
            </div>
          </PropertySection>

          {/* CRITICAL OPPORTUNITY */}
          {currentTicket?.opp_id && (
            <PropertySection>
              <div className='flex flex-col gap-2 items-center my-2'>
                <Checkbox checked={criticalOpportunity} onChange={() => setCriticalOpportunity(!criticalOpportunity)} color='primary'>
                  <strong>Is this a Critical Opportunity?</strong>
                </Checkbox>
                <div className='flex flex-row gap-1 w-[100%]'>
                  <div className='w-[75%] flex flex-col items-center'>
                    <Tag color='magenta'>
                      ACV: {formatCurrency(currentTicket?.props?.OPPORTUNITY_GROSS_ACV_BOOKING || 0, currentTicket?.props?.OPPORTUNITY_CURRENCY_ISO_CODE || 'USD')} {currentTicket?.props?.OPPORTUNITY_CURRENCY_ISO_CODE === 'USD' ? '' : `(${formatCurrency(currentTicket?.props?.OPPORTUNITY_GROSS_ACV_BOOKING_USD?.toFixed(2) || 0, 'USD') || '$0'})`}
                    </Tag>
                    <div className='text-xs opacity-60 mt-1'>
                      <span>(Gross ACV Booking Value)</span>
                    </div>
                  </div>
                  <div className='w-[50%]'>
                    {/* READ-ONLY PROPERTIES */}
                    <div className='flex flex-row'>
                      <div className='flex-1 ml-2 min-w-0'>
                        <Tooltip title={`https://genesys.lightning.force.com/lightning/r/Opportunity/${currentTicket?.opp_id}/view`}>
                          <Link className='block truncate' to={`https://genesys.lightning.force.com/lightning/r/Opportunity/${currentTicket?.opp_id}/view`}>
                            {currentTicket?.opp_name}
                          </Link>
                        </Tooltip>
                        <div>
                          <span className='text-xs opacity-60'>{currentTicket?.opp_id}</span>
                        </div>
                      </div>
                      <div className='flex flex-col items-center gap-1 mr-2'>
                        <Typography.Text
                          className='text-xs text-left opacity-60 scale-85'
                          copyable={{
                            tooltips: [currentTicket?.opp_name, 'Copied'],
                            text: currentTicket?.opp_name,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {(() => {
                  const finalApprovalEnabled = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.settingList?.find((x) => x.name === 'FinalApprovalSupportStatus')?.value ?? undefined;
                  if (criticalOpportunity && finalApprovalEnabled) {
                    return <div className='text-yellow-500'>Additional approval process required.</div>;
                  }
                  return null;
                })()}
              </div>
            </PropertySection>
          )}

          {/* NUMBER OF QUESTIONS */}
          {true === needNumberOfQuestions(currentTicket) && (
            <CustomFieldInt
              ticketId={currentTicket?.id}
              widgetId='Properties'
              field={currentTicketNumberOfQuestions}
              onUpdate={(v) => {
                const { value } = v?.result ?? {};
                setCurrentTicketNumberOfQuestions({ ...currentTicketNumberOfQuestions, value });
              }}
            />
          )}
          {isWaitingForFinalApproval() && (
            <div className='mb-4'>
              <Input.TextArea autoSize={{ minRows: 6, maxRows: 12 }} maxLength={5000} showCount placeholder='Review notes (optional). To be included in a Ticket Conversation' onChange={(e) => setReviewNotes(e.target.value)} />
              <Checkbox checked={isPublicReviewNotes} onChange={() => setIsPublicReviewNotes(!isPublicReviewNotes)} color='primary'>
                Make review notes Public
              </Checkbox>
            </div>
          )}
        </div>
      </Modal>
      {/* </MODAL - APPROVE> */}

      <Modal disabled={isBusy} confirmLoading={isBusy} title='Confirmation' open={isModalConfirmationVisible} onOk={handleOkConfirmation} icon={<InfoCircleOutlined />} onCancel={() => setIsModalConfirmationVisible(false)}>
        <div className='flex flex-row gap-4 mt-4'>
          <div className='flex-initial'>
            <InfoCircleOutlined style={{ fontSize: '36px', color: '#faad14' }} />
          </div>
          {getStatusDetails(modalStatus)?.confirmationDialog}
        </div>
      </Modal>

      {/* Reject Dialog */}

      <Modal
        disabled={isBusy}
        okButtonProps={{
          disabled: !closeWrapUpCode,
        }}
        confirmLoading={isBusy}
        title='Reject this ticket ?'
        open={showModalReject}
        onOk={handleRejectOnSubmit}
        onCancel={() => setShowModalReject(false)}
      >
        <div className={`flex flex-col gap-2 ${isBusy ? 'disabled' : null} `}>
          <div className='opacity-60 mb-2'>Are you sure you want to reject? This action cannot be undone.</div>
          <Input.TextArea autoSize={{ minRows: 6, maxRows: 12 }} maxLength={5000} showCount placeholder='Reason for rejection (optional)' onChange={(e) => setRejectReason(e.target.value)} />

          <div>
            Wrap-up code:
            <Select disabled={isBusy} className='w-full' options={wrapUpCodeList} value={closeWrapUpCode} onChange={handleCloseModalOnWrapUpCodeChange} placeholder='Not selected' />
          </div>
        </div>
      </Modal>

      {/* /Reject Dialog */}

      <div className='flex flex-col gap-2'>
        <div className='flex flex-col gap-2'>
          {canManageStatus() && canAcceptReject() ? (
            <div className={`${isWaitingForFinalApproval() && !canAcceptFinalApproval() && 'disabled'} `}>
              {/* // Accept / Reject buttons */}
              <div className={`text-xs text-center mb-2 ${isWaitingForFinalApproval() ? '' : 'opacity-60'} `}>
                {isWaitingForFinalApproval() ? (
                  <div className='flex flex-auto flex-row gap-2 items-center justify-center '>
                    <FaUserClock color='gold' className='animate-pulse' />
                    This ticket is waiting for final approval
                  </div>
                ) : (
                  'This is a new ticket. Please review it and decide whether to accept or reject it. Status and Priority can be changed after the ticket is accepted.'
                )}
              </div>
              <div className='flex justify-center'>
                <Space>
                  <Button
                    size='small'
                    type='text'
                    className='bg-green-600 text-white'
                    onClick={() => {
                      setCriticalOpportunity(currentTicket?.props?.OPPORTUNITY_CRITICAL);
                      setApproveModalOpen(true);
                    }}
                  >
                    <Space className='btn'>
                      <FaCheck />
                      Approve
                    </Space>
                  </Button>
                  <Button size='small' type='text' className='bg-red-600 text-white' onClick={() => setShowModalReject(true)}>
                    <Space className='btn'>
                      <FaWindowClose />
                      Reject
                    </Space>
                  </Button>
                </Space>
              </div>
            </div>
          ) : (
            <>
              <TicketStage serviceId={currentTicket?.service_id} status={currentTicket?.status} scale={90} />

              <div className={`${isClosedStatus() || isNotAccepted() ? 'disabled' : null}`}>
                <div className='flex flex-col gap-1'>
                  <div>Status:</div>
                  <Select className='w-full' options={statusListNotClosed} value={currentTicket?.status} onChange={(x) => handleOnStatusChange(x)} disabled={false === canManageStatus()} labelRender={statusLabelRender} optionRender={statusOptionRender} />
                </div>
                <div className='flex flex-col gap-1'>
                  <div>Priority:</div>
                  <Select className='w-full' options={priorityList} value={currentTicket?.priority} onChange={handleOnPriorityChange} disabled={false === canManageStatus()} labelRender={priorityLabelRender} optionRender={priorityOptionRender} />
                </div>

                {currentTicket?.justification && (
                  <div className='mt-6'>
                    <PropertyItem label='Justification for escalation' value={currentTicket?.justification} />
                  </div>
                )}
                {isClosedStatus() ? (
                  <div className='mt-4'>
                    <PropertyItem label='Wrap-up code' value={currentTicket?.wrap_up_code} />
                  </div>
                ) : (
                  <div className='flex flex-col gap-2 mt-2'>
                    {true === canManageStatus() && (
                      <Button type='text' onClick={handleOnCloseTicketClick}>
                        <Space className='btn'>
                          <FaWindowClose />
                          Close this ticket
                        </Space>
                      </Button>
                    )}
                    {true === canEscalate() && (
                      <Button type='text' onClick={handleOnEscalateTicketClick}>
                        <Space className='btn'>
                          <PiSirenFill />
                          Escalate this ticket
                        </Space>
                      </Button>
                    )}
                  </div>
                )}
              </div>

              {isClosedStatus() && reopenToStatus && !currentTicketReadOnly && (
                <div className='flex flex-col gap-2'>
                  <Button type='text' onClick={handleReopenClick}>
                    <Space className='btn'>
                      <MdRestore />
                      Reopen
                    </Space>
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

TicketStatus.propTypes = {};

export default TicketStatus;

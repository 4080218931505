import React, { useContext, useState, useEffect, useRef } from 'react';
import HomePageContext from 'src/context/HomePageContext';
import BoxType1 from './BoxType1';
import BoxType2 from './BoxType2';
import { PiCertificateFill } from 'react-icons/pi';
import { FaFileDownload, FaFile, FaArrowAltCircleDown, FaEye, FaCloudDownloadAlt } from 'react-icons/fa';
import { RiCheckboxMultipleBlankFill, RiCheckboxMultipleFill } from 'react-icons/ri';
import { Button, Space, notification, Modal, Tooltip, Spin, Input, Checkbox } from 'antd';
import DownloadItem from './DownloadItem';
import NoData from 'src/components/layout/NoData';
import useUserInfo from 'src/hooks/useUserInfo';
import { getWatermarkedFile } from 'src/api/microsoft';
import { postSubscriber, deleteSubscriber } from 'src/api/download';
import { getAll } from 'src/api/download';
import PropTypes from 'prop-types';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { CloseOutlined } from '@ant-design/icons';
import DocumentsCheckboxGroup from './DocumentsCheckboxGroup';
import { SignatureOutlined, WarningOutlined } from '@ant-design/icons';
import { hasMasterAdminRights } from '../../Teacket/Misc/misc';
import { PiBellSimpleLight, PiBellSimpleRingingFill } from 'react-icons/pi';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';

const DownloadButtonComponent = ({ children, type, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button type={type} loading={isLoading} onClick={handleClick} size='large'>
      {children}
    </Button>
  );
};
DownloadButtonComponent.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

const contentStyle = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};
const content = <div style={contentStyle} />;

const SectionDownload = () => {
  const userInfo = useUserInfo();
  const [message, contextHolder] = notification.useNotification();

  const [sections, setSections] = useState([]);
  const [busy, setBusy] = useState(false);
  const { openDrawer } = useContext(HomePageContext);

  const [openModal, setOpenModal] = useState(false);
  const [selectedDocsModal, setSelectedDocsModal] = useState([]);

  const [downloadDialog_item, setDownloadDialog_item] = useState(null);
  const [downloadDialog_files, setDownloadDialog_files] = useState(null);
  const downloadDialogItemRef = useRef(null);
  const downloadDialogFilesRef = useRef(null);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [downloadCustomWatermark, setDownloadCustomWatermark] = useState(null);
  const [downloadCustomWatermarkChecked, setDownloadCustomWatermarkChecked] = useState(false);

  const [downloadDisclaimerDialogOpen, setDownloadDisclaimerDialogOpen] = useState(false);
  const [downloadDisclaimerChecked, setDownloadDisclaimerChecked] = useState(false);

  const [disclaimerOkHandler, setDisclaimerOkHandler] = useState(null);
  const { executeAsyncProcess } = useAsyncProcesses();

  useEffect(() => {
    loadSections();
  }, []);

  useEffect(() => {
    if (!openModal) setSelectedDocsModal([]);
  }, [openModal]);

  useEffect(() => {
    if (downloadDisclaimerDialogOpen) setDownloadDisclaimerChecked(false);
  }, [downloadDisclaimerDialogOpen]);

  useEffect(() => {
    if (downloadDialogOpen) {
      setDownloadCustomWatermarkChecked(false);
      setDownloadCustomWatermark(null);
    }
  }, [downloadDialogOpen]);

  useEffect(() => {
    downloadDialogFilesRef.current = downloadDialog_files;
  }, [downloadDialog_files]);

  useEffect(() => {
    downloadDialogItemRef.current = downloadDialog_item;
  }, [downloadDialog_item]);

  const loadSections = async () => {
    try {
      setBusy(true);
      const resp = (await getAll()) ?? [];
      setSections(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setBusy(false);
    }
  };

  const setPicture = (picId) => {
    const base64Image = 'data:image/png;base64,' + picId;
    return picId ? <img src={base64Image} alt='logo' className='w-16 h-16 object-contain' /> : <NoData label='' />;
  };

  const canSetCustomWatermark = () => {
    if (notEmptyArray(userInfo?.permissions) && true === hasMasterAdminRights(userInfo?.permissions)) return true;
    if (notEmptyArray(userInfo?.permissions) && userInfo.permissions.includes('features.download.watermark.edit')) return true;

    return false;
  };

  const downloadWatermarkedFiles = async (item, files) => {
    setBusy(true);
    for await (const file of files) {
      try {
        console.log('Downloading:', item, file);
        const fileUrl = await getWatermarkedFile(item.section_id, item.id, file.id, file.url, file.sp_path ?? 'SPC Portal/AI-approved Documents', downloadCustomWatermark);
        console.log('File url:', fileUrl);
        let anchor = document.createElement('a');
        anchor.id = file.id;
        anchor.href = fileUrl.url;
        anchor.download = file.name;
        anchor.target = '_self';

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } catch (error) {
        console.error('Error downloading:', error);
      }
    }
    setBusy(false);
  };

  const handleOkConfirmation = async () => {
    try {
      await downloadWatermarkedFiles(downloadDialogItemRef.current, downloadDialogFilesRef.current);
      setDownloadDialogOpen(false);
      setDownloadDisclaimerDialogOpen(false);
      setDownloadCustomWatermark(null);
    } catch (error) {
      console.error(error);
      if (error?.status === 404) message.warning({ message: 'File not found' });
      else message.error({ message: 'Error', description: 'An error occurred while downloading the file' });
    } finally {
      setBusy(false);
    }
  };

  const handleItemOnClick = async (item) => {
    openDrawer(
      <Space className='btn'>{item.title}</Space>,
      <div className='flex flex-col gap-4'>
        <BoxType1 title={setPicture(item.icon)}>
          <div className='flex flex-col gap-4'>
            <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
            <div>
              {item.url && (
                <Button type='primary' href={item.url} target='_blank' size='large'>
                  <Space className='btn'>
                    <FaEye />
                    See more
                  </Space>
                </Button>
              )}
            </div>
          </div>
        </BoxType1>

        {item.files?.map((file, index) => (
          <BoxType1
            key={index}
            title={
              <>
                <Space className='btn'>
                  <FaFile />
                  {file.title ?? file.name}
                </Space>
              </>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: file.desc }}></div>
            <div className='mt-4'>
              <DownloadButtonComponent
                type='primary'
                onClick={async () => {
                  try {
                    // Here should we display modal (only for features.download.watermark.edit permission holders)

                    if (canSetCustomWatermark() && !file?.skip_watermark) {
                      setDownloadDialog_item(item);
                      setDownloadDialog_files([file]);
                      setDownloadDialogOpen(true);
                    } else if (!canSetCustomWatermark()) {
                      setDownloadDialog_item(item);
                      setDownloadDialog_files([file]);
                      setDisclaimerOkHandler(() => handleOkConfirmation);
                      setDownloadDisclaimerDialogOpen(true);
                    } else await downloadWatermarkedFiles(item, [file]);
                  } catch (error) {
                    message.error({ message: 'Error', description: error?.message ?? 'An error occurred while downloading the file' });
                  }
                }}
              >
                <Space className='btn'>
                  <FaFileDownload />
                  Download
                </Space>
              </DownloadButtonComponent>
              {file?.skip_watermark && canSetCustomWatermark() && (
                <div className='text-xs opacity-60 mr-2 mt-2'>
                  <WarningOutlined /> The watermark is not enabled for this file.
                </div>
              )}
            </div>
          </BoxType1>
        ))}

        {item.files?.length > 1 && (
          <div className='mt-6'>
            <DownloadButtonComponent
              type='primary'
              onClick={async () => {
                if (canSetCustomWatermark()) {
                  setDownloadDialog_item(item);
                  setDownloadDialog_files(item.files);
                  setDownloadDialogOpen(true);
                } else if (userInfo.is_own_org && !canSetCustomWatermark()) {
                  setDownloadDialog_item(item);
                  setDownloadDialog_files(item.files);
                  setDisclaimerOkHandler(() => handleOkConfirmation);
                  setDownloadDisclaimerDialogOpen(true);
                } else await downloadWatermarkedFiles(item, item.files);
              }}
            >
              <Space className='btn'>
                <FaArrowAltCircleDown />
                Download all {item.files?.length} files
              </Space>
            </DownloadButtonComponent>
          </div>
        )}
      </div>
    );
  };

  const handleBulkDownload = async () => {
    if (true === notArrayOrEmpty(selectedDocsModal)) return;

    for await (const doc of selectedDocsModal) {
      const fileUrl = await getWatermarkedFile(doc.section_id, doc.item_id, doc.id, doc.url, doc.sp_path ?? 'SPC Portal/AI-approved Documents');

      let anchor = document.createElement('a');
      anchor.href = fileUrl.url;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
    setOpenModal(false);
  };

  const getAllFiles = (sectionId) => {
    // console.log('getAllFiles() for SectionId', sectionId);
    const currentSection = sections.find((x) => x.id === sectionId);
    const itemList = currentSection?.download_items ?? [];

    let result = [];
    itemList?.forEach((option) => {
      if (option.files) {
        result = result.concat(option.files.map((file) => ({ ...file, section_id: option.section_id, item_id: option.id })));
      }
    });

    return result;
  };

  if (true === notArrayOrEmpty(sections)) return null;

  const subscribeForChanges = async (section) => {
    console.log('subscribeForLiveEvents()', section);
    executeAsyncProcess(async () => {
      try {
        if (section?.subscribed) {
          await deleteSubscriber(section.id);
          message.success({ message: 'Unsubscribed', description: 'You will no longer receive updates for this section' });
        } else {
          await postSubscriber(section.id);
          message.success({ message: 'Subscribed', description: 'You will receive updates for this section' });
        }

        setSections((sections) =>
          sections.map((x) => {
            if (x.id === section.id) x.subscribed = !x.subscribed;
            return x;
          })
        );
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div className='flex flex-col gap-6'>
      {contextHolder}
      {busy && (
        <div className='flex justify-center items-center h-full'>
          <Spin tip='Loading'>{content}</Spin>
        </div>
      )}

      <Modal
        closable={false}
        onCancel={() => setOpenModal(false)}
        title={
          <div className='flex flex-row gap-1'>
            <div className='flex-auto'>
              <Space className='btn text-xl'>
                <FaArrowAltCircleDown />
                Bulk Download
              </Space>
            </div>
            <div className='flex flex-row gap-1'>
              <Tooltip title='Select all'>
                <Button type='text' onClick={() => setSelectedDocsModal(getAllFiles(openModal))}>
                  <RiCheckboxMultipleFill className='text-xl' />
                </Button>
              </Tooltip>
              <Tooltip title='Deselect all'>
                <Button type='text' onClick={() => setSelectedDocsModal([])}>
                  <RiCheckboxMultipleBlankFill className='text-xl' />
                </Button>
              </Tooltip>
              <Tooltip title='Close'>
                <Button type='text' onClick={() => setOpenModal(false)}>
                  <CloseOutlined className='text-xl' />
                </Button>
              </Tooltip>
            </div>
          </div>
        }
        open={openModal ? true : false}
        footer={[
          <Button size='large' key='back' onClick={() => setOpenModal(false)}>
            <Space className='btn'>
              <CloseOutlined />
              Cancel
            </Space>
          </Button>,
          <DownloadButtonComponent size='large' className='font-bold' key='submit' type='primary' onClick={async () => await handleBulkDownload()} disabled={true === notArrayOrEmpty(selectedDocsModal)}>
            <Space className='btn'>
              <FaFileDownload />
              Download {true === notEmptyArray(selectedDocsModal) ? selectedDocsModal.length : '0'}
              {selectedDocsModal.length > 1 ? 'documents' : 'document'}
            </Space>
          </DownloadButtonComponent>,
        ]}
        width={800}
      >
        <div className='py-4 h-[60vh] overflow-auto'>
          <DocumentsCheckboxGroup options={getAllFiles(openModal)} value={selectedDocsModal} onChange={(v) => setSelectedDocsModal(v)} />
        </div>
      </Modal>

      {/* Download Dialog */}

      <Modal
        disabled={busy}
        okButtonProps={{
          disabled: downloadCustomWatermarkChecked && !downloadCustomWatermark,
        }}
        confirmLoading={busy}
        title='Watermark'
        open={downloadDialogOpen}
        onOk={handleOkConfirmation}
        onCancel={() => setDownloadDialogOpen(false)}
      >
        <div className='flex flex-row gap-4 mt-4'>
          <div className='flex-initial'>
            <SignatureOutlined style={{ fontSize: '56px', color: '#faad14' }} />
          </div>
          <div>
            <Checkbox className='mb-2' checked={downloadCustomWatermarkChecked} onChange={() => setDownloadCustomWatermarkChecked(!downloadCustomWatermarkChecked)}>
              Apply custom Watermark
            </Checkbox>

            <Input disabled={busy || !downloadCustomWatermarkChecked} className='w-full mb-4' default='' value={downloadCustomWatermark} onChange={(e) => setDownloadCustomWatermark(e.target.value)} maxLength={30} showCount />
          </div>
        </div>
        <div className='text-center text-xs opacity-60'>
          You can apply a custom watermark to the downloaded files. <br />
          <br />
          Default watermark contains{' '}
          <strong>
            <i>NDA PROTECTED [mail address] [Date] [Org Id]</i>
          </strong>
          <br />
          <br />
          Custom watermark will contain{' '}
          <strong>
            <i>NDA PROTECTED [Date] [Your custom watermark]</i>
          </strong>
        </div>
      </Modal>

      {/* /Download Dialog */}

      {/* Download Dialog Disclaimer */}

      <Modal
        zIndex={3000}
        disabled={busy}
        okButtonProps={{
          disabled: !downloadDisclaimerChecked,
        }}
        confirmLoading={busy}
        title={userInfo.is_own_org ? 'Attention Genesys Employees' : 'Dislaimer'}
        open={downloadDisclaimerDialogOpen}
        onOk={disclaimerOkHandler}
        onCancel={() => setDownloadDisclaimerDialogOpen(false)}
      >
        <div className='mt-4 clearfix'>
          <FaCloudDownloadAlt
            style={{
              float: 'left',
              fontSize: '56px',
              color: '#faad14',
              marginRight: '16px',
            }}
          />

          {userInfo.is_own_org ? (
            <div className='text-left text-sm opacity-80'>
              A ticket and NDA are not required when retrieving attestations on behalf of existing direct customers or partners, as confidentiality terms are already established.
              <p>Please confirm an NDA is in place before proceeding with any requests involving prospects, indirect end users/customer, or any other parties. Note: In the case of a customer purchasing through a partner, Genesys does not rely on indirect confidentiality terms through the partner—such customers must have a direct NDA in place to receive attestations.</p>
            </div>
          ) : (
            <div className='text-left text-sm opacity-80'>
              You acknowledge and agree your access to this Genesys Security Portal and its content is subject to the confidentiality provisions of your agreement with Genesys for the products and services to which the access is relevant. All content contained on this portal is considered Genesys Confidential Information.
              <p>If you are a Genesys partner, reseller or a consultant, all content on this portal is only for you and your organization internal use and due diligence. Unauthorized access, sharing, or misuse of portal content is strictly prohibited. Genesys disclaims all liability for any breach arising from your actions. Continued use of the Security Portal constitutes acceptance of these terms.</p>
            </div>
          )}
        </div>
        <div>
          <Checkbox className='flex mb-4 mt-4' checked={downloadDisclaimerChecked} onChange={() => setDownloadDisclaimerChecked(!downloadDisclaimerChecked)}>
            Accept Disclaimer
          </Checkbox>
        </div>
      </Modal>

      {/* /Download Dialog Disclaimer */}

      {!busy &&
        sections
          .filter((section) => section.is_active)
          .map((section, index) => (
            <BoxType2
              key={index}
              title={
                <Space className='btn'>
                  <PiCertificateFill />
                  <div>
                    {section.title}
                    <span className='font-thin opacity-60'> | {section.subtitle ? section.subtitle : 'downloads'}</span>
                  </div>
                </Space>
              }
              extras2={
                <>
                  <div className='mr-2'>
                    <Tooltip title={`${!section?.subscribed ? 'Subscribe for updates' : 'Unsubscribe from updates'}`} placement='left'>
                      <Button type='text' onClick={() => subscribeForChanges(section)}>
                        {section?.subscribed ? <PiBellSimpleRingingFill /> : <PiBellSimpleLight />}
                      </Button>
                    </Tooltip>
                  </div>
                  <Button
                    onClick={() => {
                      if (userInfo.is_own_org && !canSetCustomWatermark()) {
                        setDisclaimerOkHandler(() => () => {
                          setDownloadDisclaimerDialogOpen(false);
                          setOpenModal(section.id);
                        });
                        setDownloadDisclaimerDialogOpen(true);
                      } else setOpenModal(section.id);
                    }}
                  >
                    <Space className='btn'>
                      <FaArrowAltCircleDown />
                      Bulk Download
                    </Space>
                  </Button>
                </>
              }
            >
              <div className='flex flex-col justify-center gap-6 my-4'>
                <div className='grid grid-cols-2 md:grid-cols-4 lg:md:grid-cols-6 gap-6 text-center'>
                  {section?.download_items
                    .filter((item) => item.is_active)
                    .map((item, index) => (
                      <DownloadItem
                        key={index}
                        image={item.icon}
                        title={item.title}
                        hasFiles={notEmptyArray(item.files)}
                        onClick={() => {
                          handleItemOnClick(item);
                        }}
                      />
                    ))}
                </div>
              </div>
            </BoxType2>
          ))}
    </div>
  );
};

SectionDownload.propTypes = {};

export default SectionDownload;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import { Input, Space, Tag, Select, Tooltip, Button, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getInvitationLinks, patchResendInvitationLink, patchInvalidateInvitationLink } from 'src/api/accesscontrol';
import dayjs from 'dayjs';
import Highlighter from 'react-highlight-words';
import useTheme from 'src/hooks/useTheme';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import Space2 from 'src/components/layout/Space2';
import { MdCancel, MdSend } from 'react-icons/md';
import { uuid } from 'short-uuid';
import { EXPIRATION_HOURS } from '../misc';

const InvitationLinkSelector = ({ onSelect, selected, reload, onResend, onInvalidate }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const [messageApi, contextHolder] = message.useMessage();

  const pageSizeOptions = [20, 50, 100];
  const filterOptions = ['active', 'expired', 'used'];

  const [query, setQuery] = useState(null);
  const [filter, setFilter] = useState(null);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [entites, setEntities] = useState([]);

  const columns = [
    {
      title: 'Email / ID',
      key: 'email',
      render: (record) => {
        const NEW_ROW_PERIOD = 60 * 1000; // 1 minute in milliseconds
        const diff = dayjs().diff(dayjs(record.created_at));
        const newRow = diff <= NEW_ROW_PERIOD;
        return (
          <div>
            <Space>
              <span className={record.id === selected ? 'font-bold' : null}>
                <Highlighter textToHighlight={record?.email ?? ''} searchWords={[`${query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
              </span>
              {newRow && (
                <Tag color='blue' size='small'>
                  just created
                </Tag>
              )}
            </Space>
            <div className='text-[0.6rem] font-light opacity-60'>
              <Highlighter textToHighlight={record?.id ?? ''} searchWords={[`${query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
            </div>
          </div>
        );
      },
    },
    {
      responsive: ['xxl'],
      title: 'Tenant',
      key: 'tenant',
      align: 'center',
      render: (record) => (
        <div>
          <div>
            <Highlighter textToHighlight={record?.tenant_name ?? ''} searchWords={[`${query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
          <div className='text-thin font-light opacity-60'>
            <Highlighter textToHighlight={record?.env ?? ''} searchWords={[`${query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        </div>
      ),
    },
    {
      responsive: ['xxl'],
      title: 'State',
      key: 'state',
      align: 'center',
      render: (record) => {
        const state = getState(record);
        return <Tag color={state === 'active' ? 'green' : state === 'expired' ? 'red' : 'blue'}>{state}</Tag>;
      },
    },
    {
      responsive: ['xl'],
      title: 'Created',
      key: 'created',
      align: 'center',
      render: (record) =>
        record.created_at ? (
          <div>
            <div>{dayjs(record.created_at).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(record.created_at).format('lll')}</div>
          </div>
        ) : (
          <>n/a</>
        ),
    },
    {
      responsive: ['xl'],
      title: 'Used',
      key: 'used_at',
      align: 'center',
      render: (record) =>
        record.used_at ? (
          <div>
            <div>{dayjs(record.used_at).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(record.used_at).format('lll')}</div>
          </div>
        ) : (
          <>n/a</>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (record) => (
        <Space2 style={{ gap: '2px' }}>
          <Tooltip title='Re-send'>
            <Button type='text' size='small' onClick={() => handleDetailsOnResend(record?.id)}>
              <MdSend style={{ color: theme.primary }} />
            </Button>
          </Tooltip>
          {!record?.used_at && dayjs(record?.expires_at).isAfter(dayjs()) && (
            <Tooltip title='Invalidate'>
              <Button type='text' size='small' onClick={() => handleDetailsOnInvalidate(record?.id)}>
                <MdCancel style={{ color: theme.red }} />
              </Button>
            </Tooltip>
          )}
        </Space2>
      ),
    },
  ];

  useEffect(() => {
    setEntities(null);
    if (onSelect) onSelect(null);
    loadData();
  }, [page]);

  useEffect(() => {
    setEntities(null);
    setPage(1);
    if (onSelect) onSelect(null);
    loadData();
  }, [filter, query, pageSize, reload]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getInvitationLinks(filter, query, page, pageSize);
        setTotal(resp?.total ?? 0);
        setEntities(resp?.entites ?? null);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleDetailsOnResend = (invitationId) => {
    console.log('handleDetailsOnResend');
    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        messageApi.loading({
          key,
          content: 'Re-sending...',
          duration: 0,
        });
        const result = await patchResendInvitationLink(invitationId, { expires_at: dayjs().add(EXPIRATION_HOURS, 'hour').toISOString(), used_at: null });
        messageApi.open({
          key,
          type: 'success',
          content: 'Re-sent',
          duration: 3,
        });
        if (onResend) onResend(invitationId);
        setEntities((prev) => prev.map((x) => (x.id === invitationId ? { ...x, ...result } : x)));
      } catch (error) {
        console.error('error', error);
        messageApi.destroy(key);
      }
    });
  };

  const handleDetailsOnInvalidate = (invitationId) =>
    executeAsyncProcess(async () => {
      console.log('handleDetailsOnInvalidate');
      const key = uuid();
      try {
        messageApi.loading({
          key,
          content: 'Invalidating...',
          duration: 0,
        });
        const result = await patchInvalidateInvitationLink(invitationId);
        messageApi.open({
          key,
          type: 'success',
          content: 'Invalidated',
          duration: 3,
        });
        if (onInvalidate) onInvalidate(invitationId);
        setEntities((prev) => prev.map((x) => (x.id === invitationId ? { ...x, ...result } : x)));
      } catch (error) {
        console.error('error', error);
        messageApi.destroy(key);
      }
    });

  const getState = (record) => {
    if (record.used_at) return 'used';
    if (dayjs(record.expires_at).isBefore(dayjs())) return 'expired';
    return 'active';
  };

  return (
    <DraggableLayoutItem
      title='Invitation link list'
      titleExtras={
        <Space>
          <Select size='small' allowClear className='w-24' options={filterOptions.map((x) => ({ label: x, value: x }))} value={filter} onChange={(x) => setFilter(x)} placeholder='[All]' />
          <Input
            size='small'
            allowClear
            className='w-44'
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            placeholder='Search...'
            prefix={<SearchOutlined />}
          />
        </Space>
      }
    >
      {contextHolder}
      <CondensedTableLegacy
        pagination={{
          showSizeChanger: true,
          pageSizeOptions,
          size: 'small',
          onShowSizeChange: (current, size) => {
            console.log('onShowSizeChange', current, size);
            setPageSize(size);
          },
          current: page,
          pageSize,
          total,
          onChange: (page) => setPage(page),
        }}
        columns={columns}
        dataSource={entites}
        rowKey={'id'}
        onRow={(record) => {
          return {
            style: { cursor: 'pointer', background: record.id === selected ? theme.backgroundLight : null },
            onClick: async () => {
              if (!onSelect) return;
              onSelect(record.id);
            },
          };
        }}
      />
    </DraggableLayoutItem>
  );
};

InvitationLinkSelector.propTypes = {
  onSelect: PropTypes.func,
  selected: PropTypes.string,
  reload: PropTypes.bool,
  onResend: PropTypes.func,
  onInvalidate: PropTypes.func,
};

export default InvitationLinkSelector;

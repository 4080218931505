import React, { useContext, useState } from 'react';
import DataNexusContext from 'src/context/DataNexusContext';
import { notArrayOrEmpty, selectFilterLabel } from 'src/misc/Misc';
import Na from 'src/components/layout/Na';
import PropTypes from 'prop-types';
import { Select, Tag, Modal, Input, Form } from 'antd';
import { patchServiceRelation, deleteServiceRelation, postServiceRelation } from 'src/api/datanexus';

const PickerSubcontractors = ({ width = '100%', onPick, placeholder = 'select a subcontractor', initialValue = [], relationDetails = [], mode = 'multiple', relationParent = true, mySubcontractor, busy }) => {
  const { subcontractorsList } = useContext(DataNexusContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [dropdownVisible, setDropdownVisible] = useState(true); // Control dropdown visibility
  const [selectedValues, setSelectedValues] = useState(initialValue); // Track selected values

  const getOptions = () =>
    subcontractorsList.map((d) => {
      return { label: d.name, value: d.id, country_of_registration: d.country_of_registration, lei: d.lei };
    });

  // Centralized function to handle item removal
  const handleRemoveItem = async (id) => {
    try {
      console.log('Removing item with id:', id);
      busy && busy(true); // Show loading state

      // Call the API to delete the relation
      const body = { parent_id: relationParent ? parseInt(id) : parseInt(mySubcontractor), child_id: relationParent ? parseInt(mySubcontractor) : parseInt(id) };
      console.log('Deleting relation:', body);
      await deleteServiceRelation(body);
    } catch (error) {
      console.error('Error removing item:', error);
    } finally {
      // Remove the item from the selected values
      const updatedValues = selectedValues.filter((value) => value !== id);
      setSelectedValues(updatedValues); // Update the selected values
      onPick && onPick(true); // Notify parent component
      busy && busy(false); // Show loading state
    }
  };

  const handleOnChange = async (values) => {
    if (values.length < selectedValues.length) {
      // An item was removed
      const removedId = selectedValues.find((id) => !values.includes(id));
      if (removedId) {
        handleRemoveItem(removedId); // Use the centralized remove logic
      }
    } else {
      // An item was added
      const selected = subcontractorsList.find((item) => item.id === values[values.length - 1]);
      console.log('New item:', selected);
      if (selected) {
        setSelectedItem(selected);
        setIsModalVisible(true);
        setDropdownVisible(false); // Close dropdown when modal is shown
      }
    }
  };

  const handleModalOk = async () => {
    try {
      busy && busy(true); // Show loading state
      form.validateFields().then(async (formValues) => {
        console.log('Form values:', formValues);
        setIsModalVisible(false);
        form.resetFields();
        setDropdownVisible(true); // Re-enable dropdown after modal is closed

        if (selectedItem) {
          const existingIndex = selectedValues.findIndex((id) => (id === relationParent ? selectedItem.parent_id : selectedItem.child_id));

          if (existingIndex !== -1) {
            // Update the existing item in the list

            const body = {
              parent_id: relationParent ? parseInt(selectedItem.parent_id) : parseInt(mySubcontractor),
              child_id: relationParent ? parseInt(mySubcontractor) : parseInt(selectedItem.child_id),
              data: formValues,
            };
            console.log('Updating existing item:', body);

            await patchServiceRelation(body);

            onPick && onPick(true); // Notify parent component
          } else {
            // Add the selected item to the list if it doesn't exist
            const updatedValues = [...selectedValues, selectedItem.id];

            const body = { ...formValues, parent_id: relationParent ? parseInt(selectedItem.id) : parseInt(mySubcontractor), child_id: relationParent ? parseInt(mySubcontractor) : parseInt(selectedItem.id) };

            console.log('@@ New', body);

            await postServiceRelation(body);

            setSelectedValues(updatedValues); // Update the selected values
            onPick && onPick(true); // Notify parent component
          }

          setSelectedItem(null); // Clear the selected item
        }
      });
    } catch (error) {
      console.error('Validation failed:', error);
    } finally {
      busy && busy(false); // Hide loading state
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setDropdownVisible(true); // Re-enable dropdown after modal is closed

    // Do not add the item to the list if the modal is canceled
    setSelectedItem(null); // Clear the selected item
  };

  if (true === notArrayOrEmpty(subcontractorsList)) return <Na />;

  const optionRender = (v) => {
    return (
      <div>
        <div>{v.label}</div>
        <div className='text-xs font-light opacity-60'>{v.country_of_registration}</div>
        <div className='text-xs font-light opacity-60'>{v.lei}</div>
      </div>
    );
  };

  const tagRender = (props) => {
    const { label, closable, value } = props || {};

    const handleTagClick = () => {
      const selected = relationDetails.find((item) => (relationParent ? item.parent_id : item.child_id) === value);
      console.log('Selected item:', selected);

      if (selected) {
        setSelectedItem(selected);
        setIsModalVisible(true);
        setDropdownVisible(false); // Close dropdown when modal is shown
        form.setFieldsValue({
          description: selected.description || '', // Pre-fill with existing values if available
          country_of_service_provision: selected.country_of_service_provision || '',
        });
      } else {
        console.warn('No matching item found for value:', value);
      }
    };

    const handleTagClose = (e) => {
      e.stopPropagation(); // Prevent dropdown from opening
      handleRemoveItem(value); // Use the centralized remove logic
    };

    return (
      <Tag
        color='blue'
        closable={closable}
        onClose={handleTagClose} // Handle deletion logic here
        onClick={handleTagClick} // Handle click to show modal
        onMouseDown={(e) => e.stopPropagation()} // Prevent dropdown from opening
        style={{ margin: 4, marginTop: 4, marginBottom: 4, cursor: 'pointer' }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <>
      <Select
        value={selectedValues} // Bind the selected values to the Select component
        mode={mode}
        options={getOptions()}
        placeholder={placeholder}
        tagRender={tagRender}
        onChange={handleOnChange}
        style={{ width }}
        autoComplete='off'
        allowClear={false}
        optionRender={optionRender}
        showSearch
        filterOption={selectFilterLabel}
        dropdownVisible={dropdownVisible} // Control dropdown visibility
        onDropdownVisibleChange={(open) => setDropdownVisible(open && !isModalVisible)} // Prevent dropdown from opening when modal is visible
      />

      <Modal title={`Details for ${selectedItem?.name ? selectedItem.name : selectedItem?.parents?.name || ''}`} open={isModalVisible} onOk={async () => await handleModalOk()} onCancel={handleModalCancel}>
        <Form form={form} layout='vertical'>
          <Form.Item label='Description' name='description' rules={[{ required: false, message: 'Please input Custom Field 1!' }]}>
            <Input placeholder='Enter value for Custom Field 1' />
          </Form.Item>
          <Form.Item label='Country of service provision' name='country_of_service_provision' rules={[{ required: false, message: 'Please input Custom Field 2!' }]}>
            <Input placeholder='Enter value for Custom Field 2' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
PickerSubcontractors.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
  busy: PropTypes.func,
  placeholder: PropTypes.string,
  initialValue: PropTypes.array,
  relationDetails: PropTypes.array,
  mode: PropTypes.string,
  allowClear: PropTypes.bool,
  tagRender: PropTypes.func,
  relationParent: PropTypes.bool,
  mySubcontractor: PropTypes.string,
};

export default PickerSubcontractors;

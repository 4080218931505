import React, { useEffect, useState } from 'react';
//import PropTypes from 'prop-types';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import useScreen from 'src/hooks/useScreen';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { Space, Table, message, Tag } from 'antd';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import { getSections, postSection, pachSection, deleteSection } from 'src/api/download';
import SectionForm from './Components/SectionForm';
import { uuid } from 'short-uuid';
import queryString from 'query-string';
import StyledButton from 'src/components/layout/StyledButton';
import { FaPlus } from 'react-icons/fa6';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { FaFileDownload } from 'react-icons/fa';
import SectionCreate from './Components/SectionCreate';

const Downloads = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { theme } = useTheme();
  const { isDesktop } = useScreen();
  const { questionId } = queryString.parse(window.location.search);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'id',
      key: 'id',
      hidden: true,
      render: (record) => <>{record.id}</>,
    },
    {
      title: 'Section Name',
      key: 'title',
      width: 320,
      render: (record) => (
        <>
          {record.title}{' '}
          {!record.is_active && (
            <Tag className='ml-4' color='orange'>
              Disabled
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Objects',
      key: 'items',
      width: 128,
      render: (record) => <>{record._count?.download_items}</>,
    },
    {
      title: 'Subscribers',
      key: 'subscribers',
      width: 128,
      render: (record) => <>{record?.subscribers} </>,
    },
  ];

  const [layoutHorizontal] = useState(isDesktop);
  const [sections, setSections] = useState(null);
  const [newSectionOpen, setNewSectionOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  //const [editOrder, setEditOrder] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Downloads',
      },
    ]);

    loadData();

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = (await getSections()) ?? [];
        setSections(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const updateSection = async (values) => {
    console.log('updateSection', values);
    await executeAsyncProcess(async () => {
      try {
        const resp = await pachSection(selectedSection, values);
        setSections((sections) => {
          const newSections = [...sections];
          const index = newSections.findIndex((section) => section.id === resp.id);
          newSections[index] = resp;
          newSections[index]._count = sections[index]._count;
          return newSections;
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleDeleteSection = async (sectionId) => {
    console.log('handleDeleteSection', sectionId);
    executeAsyncProcess(async () => {
      try {
        await deleteSection(sectionId);

        setSections((sections) => {
          return sections.filter((section) => section.id !== sectionId);
        });
        setSelectedSection(null);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Section deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSection(selectedRowKeys[0] ?? null);
    },
    selectedRowKeys: [selectedSection],
    type: 'radio',
  };

  const handleOnRow = (record) => {
    return {
      onClick: () => {
        setSelectedSection(record.id);
      },
    };
  };

  const handleSectionCreateOnSubmit = (values) => {
    console.log('handleSectionCreateOnSubmit', values);
    setNewSectionOpen(false);

    executeAsyncProcess(async () => {
      try {
        const resp = await postSection(values);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Section added',
          duration: 3,
        });
        await loadData();
        setSelectedSection(resp.id);
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.downloads']}>
      {contextHolder}
      <SectionCreate open={newSectionOpen} onClose={() => setNewSectionOpen(false)} onSubmit={handleSectionCreateOnSubmit} />
      <TitleBar
        title={
          <Space className='btn'>
            <FaFileDownload />
            Downloads
            <span className='font-extralight'>Settings</span>
          </Space>
        }
        afterTitleExtras={
          <>
            {!questionId && (
              <StyledButton color={theme.textBase} onClick={() => setNewSectionOpen(true)} size='small'>
                <Space className='btn'>
                  <FaPlus />
                  <WhenDesktop>Create new section</WhenDesktop>
                </Space>
              </StyledButton>
            )}
          </>
        }
      />
      <div className='p-4 h-full w-full'>
        <Splitter initialSizes={[30, 70]} direction={layoutHorizontal ? SplitDirection.Horizontal : SplitDirection.Vertical}>
          <div className={'overflow-scroll h-full w-full'} style={{ backgroundColor: theme.backgroundBase }}>
            <Table size='small' pagination={false} columns={columns} dataSource={sections} rowKey={'id'} rowSelection={rowSelection} onRow={handleOnRow} />
          </div>
          <div className={'overflow-scroll h-full w-full'} style={{ backgroundColor: theme.backgroundBase }}>
            {selectedSection && <SectionForm sectionId={selectedSection} onSubmit={updateSection} onDelete={handleDeleteSection} onRefresh={loadData} />}
          </div>
        </Splitter>
      </div>
    </PermissionsProvider>
  );
};

export default Downloads;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CondensedTabs from 'src/components/layout/CondensedTabs';
import Space2 from 'src/components/layout/Space2';
import { LiaSalesforce } from 'react-icons/lia';
import { PiUsersBold } from 'react-icons/pi';
import DashboardDataPeriods from './DashboardDataPeriods';
import DashboardDataAccounts from './DashboardDataAccounts';
import DashboardDataByUser from './DashboardDataUsers';
import useTheme from 'src/hooks/useTheme';
// import FuncKeys from 'src/components/misc/FuncKeys';

const DashboardData = ({ selectedFilters }) => {
  const { theme } = useTheme();

  const tabItems = [
    {
      key: 'by-accounts',
      label: (
        <Space2>
          <LiaSalesforce />
          By Accounts
        </Space2>
      ),
    },
    {
      key: 'by-users',
      label: (
        <Space2>
          <PiUsersBold />
          By Users
        </Space2>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(tabItems[0].key);

  return (
    // <FuncKeys>
    <>
      <div className='p-2' style={{ backgroundColor: theme.backgroundBase }}>
        <CondensedTabs items={tabItems} onTabClick={(key) => setActiveTab(key)} activeKey={activeTab} type='card' />
        {activeTab === 'by-accounts' && (
          <DashboardDataPeriods selectedFilters={selectedFilters}>
            <DashboardDataAccounts />
          </DashboardDataPeriods>
        )}
        {activeTab === 'by-users' && <DashboardDataByUser /> && (
          <DashboardDataPeriods selectedFilters={selectedFilters}>
            <DashboardDataByUser />
          </DashboardDataPeriods>
        )}
      </div>
    </>
    // </FuncKeys>
  );
};

DashboardData.propTypes = {
  selectedFilters: PropTypes.object,
};

export default DashboardData;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import CurrentUserPicture from '../UserPicture/CurrentUserPicture';
import GcPicture from '../UserPicture/GcPicture';
import styled from 'styled-components';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
// Possible themes: a11yDark, atomDark, base16AteliersulphurpoolLight, cb, coldarkCold, coldarkDark, coy, coyWithoutShadows, darcula, dark, dracula, duotoneDark, duotoneEarth, duotoneForest, duotoneLight, duotoneSea, duotoneSpace, funky, ghcolors, gruvboxDark, gruvboxLight, holiTheme, hopscotch, lucario, materialDark, materialLight, materialOceanic, nightOwl, nord, okaidia, oneDark, oneLight, pojoaque, prism, shadesOfPurple, solarizedDarkAtom, solarizedlight, synthwave84, tomorrow, twilight, vs, vscDarkPlus, xonokai, zTouch
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { notification, Popover, Tooltip, Input, Button, Space } from 'antd';
import { MdOutlineThumbDown, MdOutlineThumbUp } from 'react-icons/md';
import { FiClipboard } from 'react-icons/fi';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import mermaid from 'mermaid';
import d3ToPng from 'd3-svg-to-png';
import { FaDownload } from 'react-icons/fa';
import short from 'short-uuid';
import { theme } from 'antd';

const { TextArea } = Input;

const StyledDiv = styled.div`
  table {
    margin: 0;
    border: 1px solid #999999;
    border-collapse: collapse;
    font-size: 0.8rem;
  }

  tr,
  td,
  th {
    margin: 0;
    border: 1px solid #999999;
    border-collapse: collapse;
  }
  td {
    padding: 1px 8px;
  }

  @keyframes cursor-blink {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  .blinkingcursor span::after {
    content: '';
    margin-left: 4px;
    width: 9px;
    height: 18px;
    background: #aaaaaa;
    display: inline-block;
    animation: cursor-blink 1s steps(2) infinite;
  }
`;

//#region Mermaid Diagrams

class Mermaid extends React.Component {
  componentDidMount() {
    mermaid.contentLoaded();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chart !== this.props.chart) {
      document.getElementById(`mermaid-chart-${this.props.id}`).removeAttribute('data-processed');
      mermaid.contentLoaded();
    }
  }

  render() {
    return (
      <>
        <div id={`mermaid-chart-${this.props.id}`} className='mermaid'>
          {this.props.chart}
        </div>
        <div className='text-right mt-2'>
          <Button
            onClick={() => {
              d3ToPng(document.getElementById(`mermaid-chart-${this.props.id}`).querySelector('svg'), `diagram-${this.props.id}`);
            }}>
            <FaDownload />
          </Button>
        </div>
      </>
    );
  }
}

Mermaid.propTypes = {
  chart: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

//#endregion

//#region Code Component

const CodeComponent = ({ className, children }) => {
  if (!className) return null;
  const language = className?.replace('lang-', '') || 'javascript';
  if (language === 'mermaid') {
    // Not supported within the Mermaid diagram
    children = children.replace('<br/>', '<br>');

    console.log('Mermaid diagram:', children);
  }
  return (
    <div className='relative'>
      {language === 'mermaid' ? (
        // Mermaid diagram
        <div className='bg-gray-200 p-4 rounded-md'>
          <Mermaid id={short.generate()} chart={children} />
        </div>
      ) : (
        // Regular code block
        <div className='bg-gray-900 p-4 rounded-md'>
          <div className='flex justify-between items-center mb-2'>
            <span className='text-gray-400'>{language}</span>
            <button
              className='code bg-gray-800 hover:bg-gray-700 text-gray-300 px-3 py-1 rounded-md'
              data-clipboard-target='#code'
              onClick={() => {
                navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                  if (result.state === 'granted' || result.state === 'prompt') {
                    navigator.clipboard.writeText(children).then(
                      function () {
                        console.log('copied');
                        notification.success({ message: 'Code copied to clipboard' });
                      },
                      function () {
                        console.error('not copied');
                      }
                    );
                  }
                });
              }}>
              Copy
            </button>
          </div>
          <div className='overflow-x-auto'>
            <pre id='code'>
              <SyntaxHighlighter style={coldarkDark} language={language}>
                {children}
              </SyntaxHighlighter>
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};

CodeComponent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

//#endregion

//#region Message

const Message = ({ content, isTyping, reportBadResponse, reportGreatResponse }) => {
  const { sender, message, badResponse } = content;

  const [displayMessage, setDisplayMessage] = React.useState('');
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);
  const [valueFeedback, setValueFeedback] = React.useState('');
  const [animateFeedback, setAnimateFeedback] = React.useState(false);
  const [greatResponseSent, setGreatResponseSent] = useState(false);

  React.useEffect(() => {
    // console.log('Message:', message);
    if (isUser) {
      // User message
      setDisplayMessage(message);
    } else {
      // System message
      setDisplayMessage(message || '');
    }
  }, [message]);

  const isUser = sender === 'user';

  const handleOpenChange = (newOpen) => {
    setFeedbackOpen(newOpen);
  };

  const feedbackContent = (
    <div className={`w-96 p-2 pb-2 ${animateFeedback ? 'disabled' : ''}`}>
      <p className='pl-2 mt-2'>Could you be more precise?</p>
      <div className='relative text-right'>
        <TextArea placeholder='Give us some details, this will help a lot!' autoSize={{ minRows: 2, maxRows: 6 }} value={valueFeedback} onChange={(e) => setValueFeedback(e.target.value)} />
        <Button className='mt-4' onClick={() => sendFeedback(valueFeedback)} disabled={!valueFeedback}>
          Submit
        </Button>
      </div>
    </div>
  );

  const sendFeedback = async (notes) => {
    console.log('sendFeedback()');
    try {
      setAnimateFeedback(true);
      setFeedbackOpen(false);
      await reportBadResponse(notes);
    } catch (error) {
      console.error(error);
    } finally {
      setAnimateFeedback(false);
    }
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div className={'group w-full'}>
      <div className='text-base gap-4 md:gap-6 md:max-w-2xl lg:max-w-xl xl:max-w-3xl flex lg:px-0 m-auto w-full'>
        <div className={`flex flex-row gap-4 md:gap-6 md:max-w-2xl lg:max-w-xl xl:max-w-3xl p-4 md:py-6 lg:px-0 m-auto w-full ${isUser ? 'justify-end' : ''}`}>
          {/* SYSTEM PIC */}
          {!isUser && (
            <div className='w-8 flex flex-col relative items-end'>
              <div className='my-4'>
                <GcPicture color='#ffffff' bgColor='#fc492b' />
              </div>
            </div>
          )}

          {/* MESSAGE */}
          <StyledDiv className={`relative flex w-[calc(100%-50px)] flex-col lg:w-[calc(100%-215px)] self-center ${isUser ? 'bg-genesys-orange-500 font-medium text-white rounded-2xl rounded-tr-none shadow-md' : 'dark:text-white text-black rounded-md'} p-4 max-w-[80%]`} style={{ background: !isUser && colorBgContainer }}>
            <div className={isTyping ? 'blinkingcursor' : ''}>
              <Markdown
                options={{
                  overrides: {
                    code: {
                      component: CodeComponent,
                    },
                  },
                }}>
                {displayMessage}
              </Markdown>
              {sender !== 'user' && !isTyping && (
                <div className='text-left text-gray-400 pt-4 -mb-4'>
                  <Space>
                    {greatResponseSent && (
                      <Tooltip title='Thank you for your feedback!' arrow={true} color='black'>
                        <MdOutlineThumbUp className='cursor-not-allowed text-green-500' />
                      </Tooltip>
                    )}
                    {/* THUMBS UP */}
                    {!greatResponseSent && (
                      <Tooltip title='Mark as a great response' arrow={true} color='black'>
                        <MdOutlineThumbUp
                          className='hover:scale-125 cursor-pointer'
                          onClick={async () => {
                            setGreatResponseSent(true);
                            reportGreatResponse();
                          }}
                        />
                      </Tooltip>
                    )}

                    {/* THUMBS DOWN */}
                    <Tooltip placement='bottom' title='Bad response' arrow={true} color='black'>
                      <Popover content={feedbackContent} title='Feedback' trigger='click' open={feedbackOpen} onOpenChange={handleOpenChange}>
                        {animateFeedback ? (
                          <AiOutlineLoading3Quarters className='animate-spin' />
                        ) : (
                          <MdOutlineThumbDown
                            className={`${badResponse ? 'fill-blue-500' : ''} hover:scale-125 ${animateFeedback ? 'animate-spin' : ''} `}
                            disabled={true}
                            onClick={async () => {
                              if (!badResponse) {
                                setAnimateFeedback(true);
                                await reportBadResponse();
                                setAnimateFeedback(false);
                              }
                            }}
                          />
                        )}
                      </Popover>
                    </Tooltip>
                    {/* CLIPBOARD */}
                    <Tooltip placement='bottom' title='Copy' arrow={true} color='black'>
                      <FiClipboard
                        className='hover:scale-125'
                        onClick={() => {
                          navigator.clipboard.writeText(message).then(
                            function () {
                              notification.success({ message: 'Message copied to clipboard' });
                            },
                            function (error) {
                              console.error(error);
                            }
                          );
                        }}
                      />
                    </Tooltip>
                  </Space>
                </div>
              )}
            </div>
          </StyledDiv>

          {/* USER PIC */}
          {isUser && (
            <div className='w-8 flex flex-col relative items-start'>
              <div className='my-4'>
                <CurrentUserPicture />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Message.propTypes = {
  content: PropTypes.object,
  isTyping: PropTypes.bool,
  reportBadResponse: PropTypes.func,
  reportGreatResponse: PropTypes.func,
};

export default Message;

//#endregion

import React, { useEffect, useState, useContext } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageTitle, pageList } from '../Misc/misc';
import ServiceHostingData from '../Admin/ServiceHosting/ServiceHostingData';
import LeafletMap from 'src/components/controls/LeafletMap/LeafletMap';
import GridLayout from 'src/components/layout/GridLayout';
import DrawIoViewer from 'src/components/controls/DrawIoViewer/DrawIoViewer';
import { LOCAL_STORAGE_KEYS, gcRegionList } from 'src/misc/Config';
import GridLayoutItemsSelector from 'src/components/layout/GridLayoutItemsSelector';
import Space2 from 'src/components/layout/Space2';
import ServiceHostingFilters from '../Admin/ServiceHosting/ServiceHostingFilters';
import DataNexusContext from 'src/context/DataNexusContext';
import LoaderDict from 'src/components/pages/DataNexus/Loaders/DictLoader';
import { useParams } from 'react-router-dom';
import { PiBellSimpleLight, PiBellSimpleRingingFill } from 'react-icons/pi';
import { Button, Tooltip /*notification*/ } from 'antd';
//import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
// import { postSubscriber, deleteSubscriber, getSubscriptionStatus } from 'src/api/datanexus';

const DataNexusHome = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { adminServiceHostingFilters } = useContext(DataNexusContext);
  const { projectId } = useParams();
  // const { executeAsyncProcess } = useAsyncProcesses();

  const [forceDefaultLayout, setForceDefaultLayout] = useState(false);
  const [layoutKey, setLayoutKey] = useState('layout-1');
  const [hiddenIds, setHiddenIds] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [subscribed /*, setSubscribed*/] = useState(false);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Data Nexus',
      },
      {
        title: 'Projects',
      },
      {
        title: projectId,
      },
    ]);

    //loadSubscriptionStatus();

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const handleItemSelect = (item) => {
    console.log('Selected item:', item);
    setSelectedItem(item);
  };

  const components = [
    {
      id: 'Service Hosting',
      component: (
        <>
          <ServiceHostingFilters />
          <ServiceHostingData hideId={true} filters={adminServiceHostingFilters} onRowSelect={handleItemSelect} />
        </>
      ),
      title: 'Service Hosting',
      subtitle: 'Services hosted by region',
      layout: {
        // These are all grid units, not pixels. Unit is 150px by default.
        x: 0, // Distance from the left edge of the grid
        y: 0, // Distance from the top edge of the grid
        w: 15, // Width of the component in grid units
        h: 17, // Height of the component in grid units
        minW: 5, // Minimum width of the component in grid units
        minH: 15, // Minimum height of the component in grid units
        maxW: 20, // Can be set to Infinity
        maxH: 17, // Can be set to Infinity
        //static: true, // If true, equal to `isDraggable: false, isResizable: false`
        //isResizable: false, // If false, will not be resizable. Overrides `static`
        //isDraggable: false, // If false, will not be draggable. Overrides `static`
        //isBounded: true, // If true and draggable, item will be moved only within grid
        //resizeHandles: ['ne'], // By default, a handle is only shown on the bottom-right (southeast, se) corner
      },
    },
    {
      id: 'Map',
      component: (
        <LeafletMap
          selectedRegion={selectedItem?.region}
          targetRegion={selectedItem?.use_other_region}
          serviceType={selectedItem?.type_display_name || selectedItem?.feature_display_name}
          regionCoordinates={Object.fromEntries(
            gcRegionList.map((region) => [
              region.region,
              {
                0: region.coordinates[0],
                1: region.coordinates[1],
                zoomLevel: region.zoomLevel,
                boundaries: region.boundaries,
              },
            ])
          )}
        />
      ),
      title: 'Map',
      subtitle: 'Services Map',
      layout: {
        x: 0,
        y: 17,
        w: 7,
        h: 10,
        minW: 5,
        minH: 9,
        maxW: 20,
        maxH: 13,
      },
    },
    {
      id: 'Diagram',
      title: 'Diagram',
      subtitle: 'Services Diagram',
      component: (
        <DrawIoViewer
          xml={`
      <mxfile host="app.diagrams.net" modified="2025-03-25T12:00:00.000Z" agent="5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36" etag="your-etag" version="14.9.0" type="device">
  <diagram id="genesys-byoc-p-inbound" name="Genesys Cloud - VI01 Inbound Delivery (BYOC-P)">
    <mxGraphModel dx="1422" dy="762" grid="1" gridSize="10" guides="1" tooltips="1" connect="1" arrows="1" fold="1" page="1" pageScale="1" pageWidth="1169" pageHeight="827" math="0" shadow="0">
      <root>
        <mxCell id="0" />
        <mxCell id="1" parent="0" />

        <!-- Title and Header -->
        <mxCell id="header" value="Genesys Cloud Data Flows - VI01 - Inbound Delivery (BYOC-P)" style="text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=20;fontStyle=1" vertex="1" parent="1">
          <mxGeometry x="240" y="20" width="680" height="30" as="geometry" />
        </mxCell>
        <mxCell id="lastUpdated" value="Last updated: Mar 25, 2025" style="text;html=1;strokeColor=none;fillColor=none;align=right;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=12;" vertex="1" parent="1">
          <mxGeometry x="900" y="50" width="200" height="20" as="geometry" />
        </mxCell>
        <mxCell id="disclaimer" value="Genesys Cloud Data Flows show a representation of the platform architecture to illustrate how data is transmitted, processed and stored by Genesys Cloud. All Data Flows are provided with certain assumptions and caveats as detailed in the document titled &quot;Genesys Cloud Data Flows Guide.pdf&quot;. This document must be read in full. No data flow is considered valid or should be supplied without this document." style="text;html=1;strokeColor=#666666;fillColor=#f5f5f5;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=1;fontSize=10;fontColor=#333333;spacingLeft=5;spacingRight=5;spacingTop=5;spacingBottom=5;" vertex="1" parent="1">
          <mxGeometry x="80" y="70" width="1000" height="40" as="geometry" />
        </mxCell>

        <!-- Security Zones -->
        <mxCell id="securityZone1" value="Security" style="swimlane;horizontal=0;fillColor=#d5e8d4;strokeColor=#82b366;startSize=30;fontSize=12;verticalAlign=middle;align=center;" vertex="1" parent="1">
          <mxGeometry x="40" y="120" width="1080" height="120" as="geometry" />
        </mxCell>
        <mxCell id="securityNote1" value="PSTN typically considered secure for voice communications" style="text;html=1;strokeColor=none;fillColor=#d5e8d4;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="securityZone1">
          <mxGeometry x="40" y="10" width="280" height="30" as="geometry" />
        </mxCell>
        <mxCell id="securityNote2" value="Security controlled by customer, Genesys Cloud Edge devices provide security and encryption" style="text;html=1;strokeColor=none;fillColor=#d5e8d4;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="securityZone1">
          <mxGeometry x="400" y="10" width="280" height="30" as="geometry" />
        </mxCell>
        <mxCell id="securityNote3" value="All data sent via HTTPS / TCP 443" style="text;html=1;strokeColor=none;fillColor=#d5e8d4;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="securityZone1">
          <mxGeometry x="700" y="10" width="280" height="30" as="geometry" />
        </mxCell>

        <!-- Voice Inbound Flow Zone -->
        <mxCell id="voiceInboundZone" value="Voice Inbound (BYOC-P) Data Flow" style="swimlane;horizontal=0;fillColor=#dae8fc;strokeColor=#6c8ebf;startSize=30;fontSize=12;verticalAlign=middle;align=center;" vertex="1" parent="1">
          <mxGeometry x="40" y="240" width="1080" height="160" as="geometry" />
        </mxCell>

        <!-- Step 1: Caller Device -->
        <mxCell id="callerDevice" value="" style="shape=mxgraph.aws4.mobile;fillColor=#FF8000;strokeColor=#d79b00;aspect=fixed;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="120" y="60" width="48" height="80" as="geometry" />
        </mxCell>
        <mxCell id="callerDeviceLabel" value="Caller Device" style="text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="104" y="140" width="80" height="20" as="geometry" />
        </mxCell>
        <mxCell id="step1Circle" value="1" style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#FF8000;strokeColor=#d79b00;fontColor=#FFFFFF;fontStyle=1;fontSize=14;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="124" y="20" width="40" height="40" as="geometry" />
        </mxCell>

        <!-- Step 2: Public PSTN -->
        <mxCell id="publicPSTN" value="" style="shape=mxgraph.aws4.traditional_server;fillColor=#FF8000;strokeColor=#d79b00;aspect=fixed;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="280" y="60" width="48" height="80" as="geometry" />
        </mxCell>
        <mxCell id="publicPSTNLabel" value="Public PSTN" style="text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="264" y="140" width="80" height="20" as="geometry" />
        </mxCell>
        <mxCell id="step2Circle" value="2" style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#FF8000;strokeColor=#d79b00;fontColor=#FFFFFF;fontStyle=1;fontSize=14;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="284" y="20" width="40" height="40" as="geometry" />
        </mxCell>

        <!-- Step 3: Customer Premise Equipment -->
        <mxCell id="customerPremise" value="" style="shape=mxgraph.aws4.router;fillColor=#FF8000;strokeColor=#d79b00;aspect=fixed;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="440" y="60" width="48" height="80" as="geometry" />
        </mxCell>
        <mxCell id="customerPremiseLabel" value="Customer Premise Equipment" style="text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="404" y="140" width="120" height="20" as="geometry" />
        </mxCell>
        <mxCell id="step3Circle" value="3" style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#FF8000;strokeColor=#d79b00;fontColor=#FFFFFF;fontStyle=1;fontSize=14;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="444" y="20" width="40" height="40" as="geometry" />
        </mxCell>

        <!-- Step 4: Genesys Cloud Edge -->
        <mxCell id="genesysCloudEdge" value="" style="shape=mxgraph.aws4.gateway;fillColor=#FF8000;strokeColor=#d79b00;aspect=fixed;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="600" y="60" width="48" height="80" as="geometry" />
        </mxCell>
        <mxCell id="genesysCloudEdgeLabel" value="Genesys Cloud Edge" style="text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="564" y="140" width="120" height="20" as="geometry" />
        </mxCell>
        <mxCell id="step4Circle" value="4" style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#FF8000;strokeColor=#d79b00;fontColor=#FFFFFF;fontStyle=1;fontSize=14;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="604" y="20" width="40" height="40" as="geometry" />
        </mxCell>

        <!-- Step 5: Genesys Cloud -->
        <mxCell id="genesysCloud" value="" style="shape=mxgraph.aws4.resourceIcon;resIcon=mxgraph.aws4.cloud;fillColor=#FF8000;strokeColor=#d79b00;aspect=fixed;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="760" y="60" width="48" height="80" as="geometry" />
        </mxCell>
        <mxCell id="genesysCloudLabel" value="Genesys Cloud" style="text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="724" y="140" width="120" height="20" as="geometry" />
        </mxCell>
        <mxCell id="step5Circle" value="5" style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#FF8000;strokeColor=#d79b00;fontColor=#FFFFFF;fontStyle=1;fontSize=14;" vertex="1" parent="voiceInboundZone">
          <mxGeometry x="764" y="20" width="40" height="40" as="geometry" />
        </mxCell>

        <!-- Flow Arrows -->
        <mxCell id="arrow1to2" value="" style="endArrow=classic;html=1;rounded=0;exitX=1;exitY=0.5;exitDx=0;exitDy=0;exitPerimeter=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;entryPerimeter=0;strokeColor=#0066CC;strokeWidth=2;" edge="1" parent="voiceInboundZone" source="callerDevice" target="publicPSTN">
          <mxGeometry width="50" height="50" relative="1" as="geometry">
            <mxPoint x="180" y="100" as="sourcePoint" />
            <mxPoint x="230" y="50" as="targetPoint" />
          </mxGeometry>
        </mxCell>
        <mxCell id="arrow2to3" value="" style="endArrow=classic;html=1;rounded=0;exitX=1;exitY=0.5;exitDx=0;exitDy=0;exitPerimeter=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;entryPerimeter=0;strokeColor=#0066CC;strokeWidth=2;" edge="1" parent="voiceInboundZone" source="publicPSTN" target="customerPremise">
          <mxGeometry width="50" height="50" relative="1" as="geometry">
            <mxPoint x="340" y="100" as="sourcePoint" />
            <mxPoint x="390" y="50" as="targetPoint" />
          </mxGeometry>
        </mxCell>
        <mxCell id="arrow3to4" value="" style="endArrow=classic;html=1;rounded=0;exitX=1;exitY=0.5;exitDx=0;exitDy=0;exitPerimeter=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;entryPerimeter=0;strokeColor=#0066CC;strokeWidth=2;" edge="1" parent="voiceInboundZone" source="customerPremise" target="genesysCloudEdge">
          <mxGeometry width="50" height="50" relative="1" as="geometry">
            <mxPoint x="500" y="100" as="sourcePoint" />
            <mxPoint x="550" y="50" as="targetPoint" />
          </mxGeometry>
        </mxCell>
        <mxCell id="arrow4to5" value="" style="endArrow=classic;html=1;rounded=0;exitX=1;exitY=0.5;exitDx=0;exitDy=0;exitPerimeter=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;entryPerimeter=0;strokeColor=#0066CC;strokeWidth=2;" edge="1" parent="voiceInboundZone" source="genesysCloudEdge" target="genesysCloud">
          <mxGeometry width="50" height="50" relative="1" as="geometry">
            <mxPoint x="660" y="100" as="sourcePoint" />
            <mxPoint x="710" y="50" as="targetPoint" />
          </mxGeometry>
        </mxCell>

        <!-- Interaction Metadata Zone -->
        <mxCell id="interactionMetadataZone" value="Interaction Metadata" style="swimlane;horizontal=0;fillColor=#fff2cc;strokeColor=#d6b656;startSize=30;fontSize=12;verticalAlign=middle;align=center;" vertex="1" parent="1">
          <mxGeometry x="40" y="400" width="1080" height="160" as="geometry" />
        </mxCell>

        <!-- Data Encryption Note -->
        <mxCell id="encryptionNote" value="Data encrypted at rest (AES256)" style="text;html=1;strokeColor=none;fillColor=#d5e8d4;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="interactionMetadataZone">
          <mxGeometry x="800" y="10" width="200" height="20" as="geometry" />
        </mxCell>

        <!-- Step 6: AWS Storage -->
        <mxCell id="awsStorage" value="" style="shape=mxgraph.aws4.resourceIcon;resIcon=mxgraph.aws4.database;fillColor=#FF8000;strokeColor=#d79b00;aspect=fixed;" vertex="1" parent="interactionMetadataZone">
          <mxGeometry x="860" y="60" width="48" height="80" as="geometry" />
        </mxCell>
        <mxCell id="awsStorageLabel" value="AWS Storage" style="text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="interactionMetadataZone">
          <mxGeometry x="824" y="140" width="120" height="20" as="geometry" />
        </mxCell>
        <mxCell id="step6Circle" value="6" style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#FF8000;strokeColor=#d79b00;fontColor=#FFFFFF;fontStyle=1;fontSize=14;" vertex="1" parent="interactionMetadataZone">
          <mxGeometry x="864" y="20" width="40" height="40" as="geometry" />
        </mxCell>

        <!-- Arrow to AWS Storage -->
        <mxCell id="arrow5to6" value="" style="endArrow=classic;html=1;rounded=0;exitX=0.5;exitY=1;exitDx=0;exitDy=0;exitPerimeter=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;entryPerimeter=0;strokeColor=#0066CC;strokeWidth=2;" edge="1" parent="1" source="genesysCloud" target="awsStorage">
          <mxGeometry width="50" height="50" relative="1" as="geometry">
            <mxPoint x="800" y="340" as="sourcePoint" />
            <mxPoint x="850" y="290" as="targetPoint" />
            <Array as="points">
              <mxPoint x="824" y="500" />
            </Array>
          </mxGeometry>
        </mxCell>

        <!-- Call Recording Storage Zone -->
        <mxCell id="callRecordingZone" value="Call Recording Storage" style="swimlane;horizontal=0;fillColor=#e1d5e7;strokeColor=#9673a6;startSize=30;fontSize=12;verticalAlign=middle;align=center;" vertex="1" parent="1">
          <mxGeometry x="40" y="560" width="1080" height="160" as="geometry" />
        </mxCell>

        <!-- HTTPS Note -->
        <mxCell id="httpsNote" value="All data sent via HTTPS / TCP 443" style="text;html=1;strokeColor=none;fillColor=#d5e8d4;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="callRecordingZone">
          <mxGeometry x="700" y="10" width="280" height="20" as="geometry" />
        </mxCell>

        <!-- Step 7: AWS S3 -->
        <mxCell id="awsS3" value="" style="shape=mxgraph.aws4.resourceIcon;resIcon=mxgraph.aws4.s3;fillColor=#FF8000;strokeColor=#d79b00;aspect=fixed;" vertex="1" parent="callRecordingZone">
          <mxGeometry x="700" y="60" width="48" height="80" as="geometry" />
        </mxCell>
        <mxCell id="awsS3Label" value="AWS S3" style="text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontSize=10;" vertex="1" parent="callRecordingZone">
          <mxGeometry x="664" y="140" width="120" height="20" as="geometry" />
        </mxCell>
        <mxCell id="step7Circle" value="7" style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#FF8000;strokeColor=#d79b00;fontColor=#FFFFFF;fontStyle=1;fontSize=14;" vertex="1" parent="callRecordingZone">
          <mxGeometry x="704" y="20" width="40" height="40" as="geometry" />
        </mxCell>

        <!-- Arrow to AWS S3 -->
        <mxCell id="arrow6to7" value="" style="endArrow=classic;html=1;rounded=0;exitX=0.5;exitY=1;exitDx=0;exitDy=0;exitPerimeter=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;entryPerimeter=0;strokeColor=#0066CC;strokeWidth=2;" edge="1" parent="1" source="genesysCloudEdge" target="awsS3">
          <mxGeometry width="50" height="50" relative="1" as="geometry">
            <mxPoint x="640" y="380" as="sourcePoint" />
            <mxPoint x="690" y="330" as="targetPoint" />
            <Array as="points">
              <mxPoint x="664" y="660" />
            </Array>
          </mxGeometry>
        </mxCell>

        <!-- Description Table -->
        <mxCell id="descriptionTable" value="" style="shape=table;startSize=0;container=1;collapsible=0;childLayout=tableLayout;fontSize=12;strokeColor=#666666;fillColor=#f5f5f5;fontColor=#333333;" vertex="1" parent="1">
          <mxGeometry x="40" y="740" width="1080" height="240" as="geometry" />
        </mxCell>
        <mxCell id="tableHeader" style="shape=tableRow;horizontal=0;startSize=0;swimlaneHead=0;swimlaneBody=0;top=0;left=0;bottom=0;right=0;collapsible=0;dropTarget=0;fillColor=#f5f5f5;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;strokeColor=#666666;fontSize=12;fontStyle=1;fontColor=#333333;" vertex="1" parent="descriptionTable">
          <mxGeometry width="1080" height="40" as="geometry" />
        </mxCell>
        <mxCell id="stepHeader" value="STEP" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=#f5f5f5;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;fontStyle=1;fontColor=#333333;" vertex="1" parent="tableHeader">
          <mxGeometry width="80" height="40" as="geometry">
            <mxRectangle width="80" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="descriptionHeader" value="DESCRIPTION" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=#f5f5f5;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;fontStyle=1;fontColor=#333333;" vertex="1" parent="tableHeader">
          <mxGeometry x="80" width="600" height="40" as="geometry">
            <mxRectangle width="600" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="securityHeader" value="SECURITY" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=#f5f5f5;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;fontStyle=1;fontColor=#333333;" vertex="1" parent="tableHeader">
          <mxGeometry x="680" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="processorHeader" value="GENESYS SUB-PROCESSOR" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=#f5f5f5;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;fontStyle=1;fontColor=#333333;" vertex="1" parent="tableHeader">
          <mxGeometry x="880" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>

        <!-- Row 1 -->
        <mxCell id="row1" style="shape=tableRow;horizontal=0;startSize=0;swimlaneHead=0;swimlaneBody=0;top=0;left=0;bottom=0;right=0;collapsible=0;dropTarget=0;fillColor=none;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;strokeColor=#666666;fontSize=12;" vertex="1" parent="descriptionTable">
          <mxGeometry y="40" width="1080" height="40" as="geometry" />
        </mxCell>
        <mxCell id="step1" value="1" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row1">
          <mxGeometry width="80" height="40" as="geometry">
            <mxRectangle width="80" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="desc1" value="Caller dials phone number associated with service configured on Genesys Cloud." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row1">
          <mxGeometry x="80" width="600" height="40" as="geometry">
            <mxRectangle width="600" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="security1" value="Call devices and the PSTN are typically considered secure." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row1">
          <mxGeometry x="680" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="processor1" value="N/A" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row1">
          <mxGeometry x="880" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>

        <!-- Row 2 -->
        <mxCell id="row2" style="shape=tableRow;horizontal=0;startSize=0;swimlaneHead=0;swimlaneBody=0;top=0;left=0;bottom=0;right=0;collapsible=0;dropTarget=0;fillColor=none;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;strokeColor=#666666;fontSize=12;" vertex="1" parent="descriptionTable">
          <mxGeometry y="80" width="1080" height="40" as="geometry" />
        </mxCell>
        <mxCell id="step2" value="2" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row2">
          <mxGeometry width="80" height="40" as="geometry">
            <mxRectangle width="80" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="desc2" value="The call is delivered via the public PSTN." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row2">
          <mxGeometry x="80" width="600" height="40" as="geometry">
            <mxRectangle width="600" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="security2" value="Call devices and the PSTN are typically considered secure." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row2">
          <mxGeometry x="680" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="processor2" value="N/A" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row2">
          <mxGeometry x="880" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>

        <!-- Row 3 -->
        <mxCell id="row3" style="shape=tableRow;horizontal=0;startSize=0;swimlaneHead=0;swimlaneBody=0;top=0;left=0;bottom=0;right=0;collapsible=0;dropTarget=0;fillColor=none;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;strokeColor=#666666;fontSize=12;" vertex="1" parent="descriptionTable">
          <mxGeometry y="120" width="1080" height="40" as="geometry" />
        </mxCell>
        <mxCell id="step3" value="3" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row3">
          <mxGeometry width="80" height="40" as="geometry">
            <mxRectangle width="80" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="desc3" value="Call may traverse Customer Premise Equipment such as a Media Gateway or SBC. Customer may choose to implement security." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row3">
          <mxGeometry x="80" width="600" height="40" as="geometry">
            <mxRectangle width="600" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="security3" value="TLS and SRTP are optional for security and configured by the customer." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row3">
          <mxGeometry x="680" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="processor3" value="N/A" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row3">
          <mxGeometry x="880" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>

        <!-- Row 4 -->
        <mxCell id="row4" style="shape=tableRow;horizontal=0;startSize=0;swimlaneHead=0;swimlaneBody=0;top=0;left=0;bottom=0;right=0;collapsible=0;dropTarget=0;fillColor=none;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;strokeColor=#666666;fontSize=12;" vertex="1" parent="descriptionTable">
          <mxGeometry y="160" width="1080" height="40" as="geometry" />
        </mxCell>
        <mxCell id="step4" value="4" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row4">
          <mxGeometry width="80" height="40" as="geometry">
            <mxRectangle width="80" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="desc4" value="The Genesys Edge (in this model a physical hardware device or virtual device hosted on physical hardware) provides media services including IVR flows, message playback and call recording." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row4">
          <mxGeometry x="80" width="600" height="40" as="geometry">
            <mxRectangle width="600" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="security4" value="Call recordings encrypted at rest on the Edge using AES 256." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row4">
          <mxGeometry x="680" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="processor4" value="AWS" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row4">
          <mxGeometry x="880" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>

        <!-- Row 5 -->
        <mxCell id="row5" style="shape=tableRow;horizontal=0;startSize=0;swimlaneHead=0;swimlaneBody=0;top=0;left=0;bottom=0;right=0;collapsible=0;dropTarget=0;fillColor=none;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;strokeColor=#666666;fontSize=12;" vertex="1" parent="descriptionTable">
          <mxGeometry y="200" width="1080" height="40" as="geometry" />
        </mxCell>
        <mxCell id="step5" value="5" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row5">
          <mxGeometry width="80" height="40" as="geometry">
            <mxRectangle width="80" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="desc5" value="Genesys Edge communicates with Genesys Cloud in AWS Virtual Private Cloud (Core) to determine call processing requirements, including whether or not the call should be recorded." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row5">
          <mxGeometry x="80" width="600" height="40" as="geometry">
            <mxRectangle width="600" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="security5" value="Connection to Genesys Cloud is secured via HTTPS." style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;align=left;spacingLeft=5;" vertex="1" parent="row5">
          <mxGeometry x="680" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
        <mxCell id="processor5" value="AWS" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=1;strokeColor=#666666;fontSize=12;" vertex="1" parent="row5">
          <mxGeometry x="880" width="200" height="40" as="geometry">
            <mxRectangle width="200" height="40" as="alternateBounds" />
          </mxGeometry>
        </mxCell>
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>
          `}
        />
      ),
      layout: {
        x: 7, // Start at grid unit 7 to show besides the Map component
        y: 17,
        w: 7,
        h: 10,
        minW: 5,
        minH: 9,
        maxW: 20,
        maxH: 13,
      },
    },
  ];

  const resetLayout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.layoutDataNexusHome);
    setForceDefaultLayout((prev) => !prev); // Toggle to trigger useEffect in GridLayout
    setLayoutKey('layout-' + Date.now()); // Generate new key to force remount
  };

  const handleOnShownComponentsIdsChange = (v) => {
    console.log('handleOnShownComponentsIdsChange', v);

    const hIds = components.map((x) => x.id).filter((x) => !v.includes(x));
    setHiddenIds(hIds);

    // Update local storage
    // localStorage.setItem(layoutHiddenKey, JSON.stringify(hIds));
  };

  // const loadSubscriptionStatus = async () => {
  //   const status = await getSubscriptionStatus();
  //   setSubscribed(status.subscribed);
  // };

  // const subscribeForChanges = async () => {
  //   console.log('subscribeForChanges()');
  //   executeAsyncProcess(async () => {
  //     try {
  //       if (subscribed) {
  //         await deleteSubscriber();
  //         notification.success({ message: 'Unsubscribed', description: 'You will no longer receive updates for this section' });
  //         setSubscribed(false);
  //       } else {
  //         await postSubscriber();
  //         notification.success({ message: 'Subscribed', description: 'You will receive updates for this section' });
  //         setSubscribed(true);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   });
  // };

  return (
    <PermissionsProvider requiredPermissions={['apps.datanexus.view']}>
      <LoaderDict>
        <TitleBar
          title={pageTitle}
          afterTitleExtras={
            <Space2>
              <PageSwitch pageList={pageList} label={`Project ${projectId ?? '...'}`} />
            </Space2>
          }
        >
          <GridLayoutItemsSelector allComponentIds={components.map((c) => c.id)} hiddenIds={hiddenIds} onLayoutReset={resetLayout} onShownComponentsIdsChange={handleOnShownComponentsIdsChange} />
        </TitleBar>
        <div className='mr-2'>
          <Tooltip title={`${!subscribed ? 'Subscribe for updates' : 'Unsubscribe from updates'}`} placement='left'>
            <Button type='text' /*onClick={() => subscribeForChanges()} */>{subscribed ? <PiBellSimpleRingingFill /> : <PiBellSimpleLight />}</Button>
          </Tooltip>
        </div>

        <GridLayout key={layoutKey} components={components} hiddenComponentIds={hiddenIds} force={forceDefaultLayout} />
      </LoaderDict>
    </PermissionsProvider>
  );
};

export default DataNexusHome;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import useTheme from 'src/hooks/useTheme';
import { Space, Popover, Tooltip, Checkbox } from 'antd';
import TicketListWithGrouping from 'src/components/pages/Teacket/Components/TicketListWithGrouping';
import { getTickets2 } from 'src/api/teacket';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import Highlighter from 'react-highlight-words';
import UserImage from 'src/components/layout/UserImage';
import BarLoader from 'react-bar-loader';
import TicketStatusColor from '../../Components/TicketStatusColor';
import TicketPriorityColor from '../../Components/TicketPriorityColor';
import { FaTableColumns } from 'react-icons/fa6';
import TicketTypeTag from '../../Components/TicketTypeTag';
import CmdBtn from 'src/components/layout/CmdBtn';
import TicketDrawer from '../../Components/TicketDrawer';
import { isRequestCancelled, localDownload, notEmptyArray, notEmptyString } from 'src/misc/Misc';
import TicketStage from '../../Components/TicketStage';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import StyledButton from 'src/components/layout/StyledButton';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import Space2 from 'src/components/layout/Space2';
import FuncKeys from 'src/components/misc/FuncKeys';
import Papa from 'papaparse';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const TicketListControl2 = ({ allowSorting = false, getColumnsSelector }) => {
  const { theme } = useTheme();
  const context = useContext(TeacketContext);
  const { ticketList2selectedFilters, ticketList2LoadData, setTicketList2LoadData, ticketList2Refresh, setTicketList2Refresh, ticketList2Export, setTicketList2Export, visibleColumns } = context;
  const { executeAsyncProcess } = useAsyncProcesses();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [ticketListQuerying, setTicketListQuerying] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsTotal, setTicketsTotal] = useState(0);
  const [pageSize] = useState(parseInt(25));
  const [initiated, setInitiated] = useState(false);

  const { ticketListSorter, ticketListQuery } = useContext(TeacketContext);

  useEffect(() => {
    if (getColumnsSelector)
      getColumnsSelector(
        <Tooltip title='Show/Hide columns'>
          <Popover content={columnsContent} title='Show/Hide Columns' trigger='click'>
            <StyledButton color={theme.textBase} size='small'>
              <Space2>
                <FaTableColumns />
                <WhenDesktop>Columns</WhenDesktop>
              </Space2>
            </StyledButton>
          </Popover>
        </Tooltip>
      );
  }, [visibleColumns, theme]);

  useEffect(() => {
    if (!ticketList2LoadData) return;
    setTicketList2LoadData(false);
    loadData();
  }, [ticketList2LoadData]);

  useEffect(() => {
    if (!ticketList2Refresh) return;
    setTicketList2Refresh(false);
    loadData();
  }, [ticketList2Refresh]);

  useEffect(() => {
    if (!ticketList2Export) return;
    setTicketList2Export(false);
    exportData();
  }, [ticketList2Export]);

  useEffect(() => {
    console.log('ticketListSorter', ticketListSorter);
    if (!ticketListSorter) return;
    loadData();
  }, [ticketListSorter]);

  useEffect(() => {
    if (!initiated) return;
    console.log('=== ticketListQuery:', ticketListQuery);
    loadData();
  }, [ticketListQuery]);

  useEffect(() => {
    console.log('aaaa 1');
    if (!initiated && ticketList2selectedFilters?.from) {
      console.log('aaaa 2');
      setInitiated(true);
      loadData();
    }
  }, [ticketList2selectedFilters]);

  const loadData = async () => {
    console.log('loadData', ticketList2selectedFilters);
    try {
      setIsLoading(true);
      setTicketListQuerying(true);
      setTickets([]);

      const resp = await getTickets2(currentPage, pageSize, ticketListSorter?.field ?? null, ticketListSorter?.order ?? null, ticketListQuery, ticketList2selectedFilters, null, true);

      setTickets(resp?.entities ?? []);
      setTicketsTotal(resp?.total ?? 0);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.error(error);
    } finally {
      setTicketListQuerying(false);
      setIsLoading(false);
    }
  };

  const exportData = () => {
    console.log('exportData()', ticketList2selectedFilters);
    executeAsyncProcess(async () => {
      try {
        const resp = await getTickets2(1, 1000, ticketListSorter?.field ?? null, ticketListSorter?.order ?? null, ticketListQuery, ticketList2selectedFilters, null, true);
        let tickets = resp?.entities ?? [];
        // console.log('Tickets received from backend:', tickets);

        const EXCLUDED_COLUMNS = ['props', 'ticket_values', 'members', 'owner_id', 'assignee_id', 'time_tracking', 'checklist', 'checklist_progress'];

        //#region "INITIAL COLUMNS ORDER"
        // This is an initial column order.
        // Other columns, that not exist in the below list will be added at the end of the list w/o guaranteeing the order.
        // If you need the fixed order, please add the column to the list below.
        let columns1 = [
          'id',
          'title',
          'content',
          'status',
          'created_at',
          'updated_at',
          'service_id',
          'category',
          'priority',
          'sub_category',
          'wrap_up_code',
          'justification',
          'closed_at',
          'acc_id',
          'acc_name',
          'opp_id',
          'opp_name',
          'dep_id',
          'dep_name',
          'type',
          'Requester',
          'Program Manager',
          'Assigned TAM',
          'Need completed by',
          'account_name',
          'org_count',
          'product',
          'region',
          'Forecasted date',
          'sub_region',
          'sales_segment',
          'direct_indirect_sale',
          'Subject Matter Expert',
          'deal_type',
          'time_tracking_users',
          'time_tracking_total_hours',
          'seats',
          'Justification',
        ];
        //#endregion

        for (let item of tickets) {
          //#region "TURN MEMBERS INTO COLUMNS"
          item?.members?.forEach((m) => {
            item[m.role] = m.name;
          });
          //#endregion

          //#region "TURN VALUES INTO COLUMNS"
          item?.ticket_values?.forEach((v) => {
            item[v.field_name] = v.valueString ?? v.valueInt ?? v.valueDateTime ?? v.valueBoolean ?? v.valueNumber ?? '';
          });
          //#endregion

          //#region "TURN VALUES PROPS INTO COLUMNS"
          if (item?.props) {
            for (const [key, value] of Object.entries(item.props)) {
              item[key] = value;
            }
          }
          //#endregion

          //#region "TURN TIME TRACKING INTO COLUMNS"
          if (true === notEmptyArray(item?.time_tracking)) {
            item['time_tracking_users'] = item.time_tracking.map((x) => x.user_name).join('|');
            item['time_tracking_total_hours'] = item.time_tracking.map((x) => x.total_hours).join('|');
          }
          //#endregion

          for (const [key, value] of Object.entries(item)) {
            // remove end-lines
            if (true === notEmptyString(value)) item[key] = value.replace(/(\r\n|\n|\r)/gm, ' ');
            // remove excluded columns
            if (EXCLUDED_COLUMNS.includes(key)) delete item[key];
            else if (!columns1.includes(key)) columns1.push(key);
          }
        }

        // console.log('Tickets to export:', tickets);

        const csv = Papa.unparse(tickets, {
          quotes: true,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ',',
          header: true,
          newline: '\r\n',
          skipEmptyLines: false,
          columns: columns1,
        });

        localDownload(csv, `export_${dayjs().format('YYYYMMDD_hhmmss')}.csv`);
      } catch (error) {
        console.error(error);
      }
    });
  };

  // To add a column, add it here and in the following files:
  // frontend/src/contexxt/TeacketProvider.js in the visibleColumns and columnOrder arrays
  // If sorting is required, add it to getTickets2() function in backend/docker/db/endpoints/teacket.js in the getTickets2() function and the "Order By" section
  const columns = [
    {
      title: 'Id',
      showInGroupBy: false,
      key: 'id',
      dataIndex: 'id',
      width: 140,
      align: 'center',
      sorter: true,
      render: (text, record) => (
        <Space>
          <CmdBtn
            type='link'
            style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis' }}
            onClick={async () => {
              context.setTicketListSelectedTicket(text);
            }}
            onClickCommandShiftPressed={() => {
              navigate(`/teacket/tickets/${record.id}`);
            }}
            onClickCommandPressed={() => {
              window.open(`/teacket/tickets/${record.id}`, '_blank');
            }}
          >
            <strong>{record.id}</strong>
          </CmdBtn>
          {record?.type && <TicketTypeTag type={record.type} styling='-ml-4 -mr-4' />}
        </Space>
      ),
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      width: 420,
      align: 'center',
      sorter: true,
      render: (text) => (
        <div style={{ maxWidth: '400px', wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </div>
      ),
    },
    {
      title: 'Account',
      showInGroupBy: true,
      key: 'account',
      dataIndex: 'acc_name',
      align: 'center',
      sorter: true,
      render: (text) => (
        <div>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </div>
      ),
    },
    {
      title: 'Opportunity',
      showInGroupBy: true,
      key: 'opportunity',
      dataIndex: 'opp_name',
      align: 'center',
      sorter: true,
      render: (text) => (
        <div>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </div>
      ),
    },
    {
      title: 'ACV ($)',
      showInGroupBy: true,
      key: 'gross_acv_booking_usd',
      dataIndex: 'gross_acv_booking_usd',
      align: 'center',
      sorter: true,
      render: (text, record) => {
        const acv = record?.props?.gross_acv_booking_usd;

        if (acv) {
          return <div>{`$${Number(acv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      title: 'Region',
      showInGroupBy: false,
      key: 'region',
      sorter: false,
      render: (text, record) => <div>{record?.props?.region === 'NORTH AMERICA' ? 'NA' : record?.props?.region}</div>,
    },
    {
      title: 'Members',
      key: 'members',
      dataIndex: 'members',
      align: 'center',
      width: 220,
      showInGroupBy: false,
      render: (text) => {
        return (
          <div className='flex flex-col gap-1 m-2 flex-wrap'>
            {text?.map((x, i) => {
              return (
                <div key={i} className='flex flex-row gap-2 items-center'>
                  <UserImage image={x?.user_pic} size='25px' />
                  <div style={{ lineHeight: '0.5rem' }}>
                    <div className='text-sm'>{x?.name}</div>
                    <div className='text-[0.6rem] font-light opacity-60'>{x?.role}</div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: 'Category',
      showInGroupBy: true,
      key: 'category',
      dataIndex: 'category',
      align: 'center',
      sorter: true,
      render: (text, record) => (
        <div style={{ wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          <div className='text-xs font-light opacity-60'>
            <Highlighter textToHighlight={record?.sub_category ?? ''} searchWords={[`${context.ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        </div>
      ),
    },
    {
      title: 'Priority',
      showInGroupBy: true,
      key: 'priority',
      dataIndex: 'priority',
      align: 'center',
      sorter: true,
      render: (text, record) => (
        <div className='flex flex-row gap-2 items-center justify-center'>
          <TicketPriorityColor serviceId={record?.service_id} priority={text} />
          {text}
        </div>
      ),
    },
    {
      title: 'Status',
      showInGroupBy: true,
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <div className='flex flex-row gap-2 items-center justify-center'>
              <TicketStatusColor serviceId={record?.service_id} status={text} />
              {text}
            </div>
            <TicketStage serviceId={record?.service_id} status={text} />
          </>
        );
      },
    },
    {
      title: 'Progress',
      showInGroupBy: true,
      key: 'checklist_progress',
      dataIndex: 'checklist_progress',
      align: 'center',
      sorter: true,
      render: (text, record) => {
        const value = record?.props?.checklist_progress;

        if (value) {
          return <div>{Number(value)} %</div>;
        }
      },
    },
    {
      title: 'Created',
      showInGroupBy: false,
      key: 'created_at',
      dataIndex: 'created_at',
      align: 'center',
      sorter: true,
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>{dayjs(text).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
    {
      title: 'Closed',
      showInGroupBy: false,
      key: 'closed_at',
      dataIndex: 'closed_at',
      align: 'center',
      sorter: true,
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(text).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
    {
      title: 'Need Completed By',
      showInGroupBy: false,
      key: 'need_completed_by',
      dataIndex: 'need_completed_by',
      align: 'center',
      sorter: true,
      render: (text, record) => {
        const needCompletedBy = record.ticket_values.find((x) => x.field_name === 'Need completed by');
        if (needCompletedBy) {
          return (
            <>
              <div>{dayjs(needCompletedBy.valueDateTime).fromNow()}</div>
              <div className='font-thin text-xs'>{dayjs(needCompletedBy.valueDateTime).format('LL')}</div>
            </>
          );
        } else {
          return <>n/a</>;
        }
      },
    },
    {
      title: 'Forecasted',
      showInGroupBy: false,
      key: 'forecasted_date',
      dataIndex: 'forecasted_date',
      align: 'center',
      sorter: true,
      render: (text, record) => {
        const forecastedDate = record.ticket_values.find((x) => x.field_name === 'Forecasted date');
        if (forecastedDate) {
          return (
            <>
              <div>{dayjs(forecastedDate.valueDateTime).fromNow()}</div>
              <div className='font-thin text-xs'>{dayjs(forecastedDate.valueDateTime).format('LL')}</div>
            </>
          );
        } else {
          return <>n/a</>;
        }
      },
    },
    {
      title: 'Last Updated',
      showInGroupBy: false,
      key: 'updated_at',
      dataIndex: 'updated_at',
      align: 'center',
      sorter: true,
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(text).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
    {
      title: 'Time Entries',
      showInGroupBy: false,
      key: 'time_tracking',
      dataIndex: 'time_tracking',
      align: 'center',
      sorter: false,
      render: (text, record) => {
        return record?.time_tracking?.map((time_tracking_entry) => {
          const member = record.members.find((x) => x.user_id === time_tracking_entry.user_id);
          return (
            <>
              <Tooltip title={`${member?.name} - ${time_tracking_entry.total_hours} ${time_tracking_entry.total_hours > 1 ? 'hours' : 'hour'}`}>
                <div key={time_tracking_entry.user_id} className='flex flex-row gap-2 mb-2 items-center'>
                  <UserImage image={member?.user_pic} size='25px' />
                  <div style={{ lineHeight: '0.5rem' }}>
                    <div className='font-thin text-xs'>{time_tracking_entry.total_hours}</div>
                  </div>
                </div>
              </Tooltip>
            </>
          );
        });
      },
    },
  ].map((col) => ({ ...col, sorter: allowSorting ? col.sorter : null }));

  const columnsContent = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {columns.map((column) => {
        const isInColumnOrder = context.columnOrder.includes(column.key);
        return (
          <div key={column.key} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={isInColumnOrder ? context.visibleColumns[column.key] : context.visibleColumns[column.key] !== false}
              onChange={() => {
                // If column is not in columnOrder, add it first
                if (!isInColumnOrder) {
                  context.columnOrder.push(column.key);
                }
                context.toggleColumnVisibility(column.key);
              }}
            >
              {column.title}
            </Checkbox>
          </div>
        );
      })}
    </div>
  );

  // Also update the filteredColumns logic:
  const filteredColumns = columns
    .filter((col) => {
      // If the column is not in columnOrder or doesn't have an explicit visibility setting,
      // consider it visible
      if (!context.columnOrder.includes(col.key) && context.visibleColumns[col.key] !== false) {
        return true;
      }
      // Otherwise use the visibility setting
      return context.visibleColumns[col.key];
    })
    .sort((a, b) => {
      // If either column is not in columnOrder, put it at the end
      if (!context.columnOrder.includes(a.key)) return 1;
      if (!context.columnOrder.includes(b.key)) return -1;
      // Otherwise use the original sorting
      return context.columnOrder.indexOf(a.key) - context.columnOrder.indexOf(b.key);
    });

  const renderShowTotal = (total, range) => (
    <div className='inline-flex items-center text-sm dark:text-gray-100 dark:bg-gray-800 text-gray-600 bg-gray-50 px-2 py-1 rounded-md'>
      <span>Showing&nbsp;</span>
      <span>
        {range[0]}-{range[1]}
      </span>
      <span className='mx-1'>of</span>
      <span className='font-medium dark:text-blue-400 text-blue-600'>{total}</span>
      <span className='ml-1'>{total > 1 ? 'tickets' : 'ticket'}</span>
    </div>
  );

  const paginationConfig = {
    current: currentPage,
    showSizeChanger: false,
    pageSize,
    showTotal: context.ticketListGroupBy ? undefined : renderShowTotal,
    size: 'small',
    total: ticketsTotal,
    onChange: (page) => {
      setCurrentPage(page);
    },
    position: ['topRight', 'bottomRight'],
  };

  return (
    <>
      <TicketDrawer />
      <div className={`relative ${ticketListQuerying ? 'disabled' : null}`}>
        {!tickets ? (
          <SkeletonTable />
        ) : (
          <>
            {ticketListQuerying ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '2px' }} />}
            <FuncKeys>
              <TicketListWithGrouping loading={isLoading} pagination={paginationConfig} columns={filteredColumns} dataSource={tickets} rowKey='id' />
            </FuncKeys>
          </>
        )}
      </div>
      {/* </div> */}
    </>
  );
};

TicketListControl2.propTypes = {
  userId: PropTypes.string,
  column: PropTypes.object,
  index: PropTypes.number,
  moveColumn: PropTypes.func,
  tickets: PropTypes.array,
  showRequesterAndUserTickets: PropTypes.bool,
  accountId: PropTypes.string,
  opportunityId: PropTypes.string,
  allowSorting: PropTypes.bool,
  getColumnsSelector: PropTypes.func,
};

export default TicketListControl2;

import React, { useContext, useEffect, useState } from 'react';
import FsiReportContext from 'src/context/FsiReportContext';
// import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useTheme from 'src/hooks/useTheme';
import { getReportList, deleteReportFile } from 'src/api/fsireport';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import CondensedTable from 'src/components/layout/CondensedTable';
import { bytesToSize, dateTimeSorter } from 'src/misc/Misc';
import { FilePdfOutlined } from '@ant-design/icons';
import { FaRegTrashCan } from 'react-icons/fa6';
import dayjs from 'dayjs';
import { Tag, Space, Popconfirm, Button, message } from 'antd';
import UserImage from 'src/components/layout/UserImage';

//import PropTypes from 'prop-types';

const ReportsWidget = () => {
  const { currentProject, currentProjectReportList, setCurrentProjectReportList } = useContext(FsiReportContext);
  // const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const [busy, setBusy] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'Name',
      key: 'Name',
      render: (record) => {
        const NEW_ROW_PERIOD = 60 * 1000; // 1 minute in milliseconds
        const diff = dayjs().diff(dayjs(record.lastModifiedDateTime));
        const newRow = diff <= NEW_ROW_PERIOD;
        return (
          <div className='flex flex-row gap-2'>
            <div className='flex flex-row justify-center items-center text-red-800 dark:text-red-200'>
              <FilePdfOutlined className='text-xl' />
            </div>
            <div>
              <Space>
                {/* {record.name} */}
                FSI Report
                {newRow && (
                  <Tag color='blue' size='small'>
                    just created
                  </Tag>
                )}
              </Space>
              <div className='text-xs font-light'>
                <span className='opacity-80'>{dayjs(record.lastModifiedDateTime).fromNow()}</span> <span className='opacity-40'>on {dayjs(record.lastModifiedDateTime).format('llll')}</span>
              </div>
            </div>
          </div>
        );
      },
      //sorter: (a, b) => textSorter(a, b, 'name'),
    },
    {
      title: 'Created By',
      key: 'createdBy',
      dataIndex: 'createdBy',
      align: 'right',
      width: 100,
      render: (record) =>
        record ? (
          <div className='flex flex-row gap-2 items-center justify-end'>
            <UserImage image={record?.user_pic} size='28px' />
            <div className='text-sm'>{record?.name}</div>
          </div>
        ) : (
          ''
        ),
      // sorter: (a, b) => numberSorter(a, b, 'size'),
    },
    {
      title: 'Size',
      key: 'Size',
      align: 'right',
      width: 100,
      render: (record) => <>{bytesToSize(record.size ?? 0)}</>,
      // sorter: (a, b) => numberSorter(a, b, 'size'),
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space className='btn' data-ignore-click='true'>
          <Popconfirm
            data-ignore-click='true'
            placement='topLeft'
            title='Delete'
            description='Do you want to delete this file?'
            okText='Yes'
            cancelText='No'
            onConfirm={(event) => {
              event.stopPropagation(); // Prevent the row click event
              deleteFileHandle(record.name);
            }}
            onCancel={(event) => {
              event.stopPropagation(); // Prevent the row click event on cancel
            }}
            onPopupClick={(event) => {
              event.stopPropagation(); // Prevent the row click event when clicking anywhere on the Popconfirm
            }}
          >
            <Button data-ignore-click='true' disabled={busy} type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }} className='hover:scale-125'>
              <FaRegTrashCan data-ignore-click='true' />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const deleteFileHandle = async (fileName) => {
    console.log('deleteFileHandle()', fileName);

    try {
      setBusy(true);
      // Find ID for the file to be deleted (fileName) from currentProjectReportList
      const file = currentProjectReportList.find((f) => f.name === fileName);
      console.log('fileId:', file.id);

      await deleteReportFile(currentProject.id, file.id);

      // Remove the file from the currentProjectReportList
      setCurrentProjectReportList(currentProjectReportList.filter((f) => f.id !== file.id));
      messageApi.open({
        key: file.id,
        type: 'success',
        content: 'Report deleted',
        duration: 3,
      });
    } catch (error) {
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    if (!currentProject?.id) return;
    if (currentProjectReportList) return;
    loadData();
  }, []);

  const loadData = async () => {
    // await executeAsyncProcess(async () => {
    try {
      const resp = ((await getReportList(currentProject.id)) ?? []).sort((a, b) => dateTimeSorter(b, a, 'lastModifiedDateTime'));

      setCurrentProjectReportList(resp);
    } catch (error) {
      console.error(error);
    }
    // });
  };

  if (!currentProject) return <SkeletonTable />;
  if (currentProjectReportList === null) return <SkeletonTable />;

  return (
    <div className={`flex flex-col gap-2 ${busy ? 'disabled' : ''} `}>
      {contextHolder}
      <CondensedTable
        loading={busy}
        columns={columns}
        dataSource={currentProjectReportList}
        size='small'
        pagination={{ size: 'small', pageSize: 5 }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              const ignoreClick = event.target?.closest('[data-ignore-click]');
              if (ignoreClick) return;

              window.open(record.webUrl, '_blank');
            }, // click row
          };
        }}
        rowClassName='cursor-pointer'
      />
    </div>
  );
};

ReportsWidget.propTypes = {};

export default ReportsWidget;

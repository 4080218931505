import React, { useState } from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import Space2 from '../Space2';
import { FaFileSignature } from 'react-icons/fa';
import PropertySection from '../PropertySection';
import { Button, Checkbox } from 'antd';
import { logout } from 'src/misc/Session';
import { postUsersMeDisclaimerAccept, postUsersMeDisclaimerDecline } from 'src/api/users';

//import PropTypes from 'prop-types';

const AdminDisclaimerContent = () => {
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);

  const handleAccept = async () => {
    setLoadingAccept(true);
    try {
      await postUsersMeDisclaimerAccept();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
    setLoadingAccept(false);
  };

  const handleDecline = async () => {
    setLoadingDecline(true);
    try {
      await postUsersMeDisclaimerDecline();
      await logout();
    } catch (error) {
      console.error(error);
    }
    setLoadingDecline(false);
  };

  return (
    <>
      <div className='fixed z-50'>
        <Header hideSignInBtn={true} />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 w-full xl:w-[1200px]'>
          <PropertySection px={8} py={8}>
            <div className='flex flex-col gap-4'>
              <Space2 className='font-bold text-3xl'>
                <FaFileSignature className={'text-4xl'} />
                Genesys Security Portal Access Agreement
              </Space2>

              <div className='text-justify opacity-80 text-lg'>You acknowledge and agree your access to this Genesys Security Portal as a designated Genesys Cloud Organization (instance) administrator ("Administrator") will be subject to the confidentiality provisions of your agreement with Genesys for the products and services to which the access is relevant. All content contained on this Portal is considered Genesys Confidential Information.</div>
              <div className='text-justify opacity-80 text-lg'>The Administrator is authorized to invite up to five (5) additional users from their Genesys Cloud organization. All invited users shall be bound by the same terms, conditions, and confidentiality obligations as the Administrator. The Administrator is responsible for ensuring that all invited users comply with these obligations.</div>
              <div className='text-justify opacity-80 text-lg'>If you are a Genesys partner, reseller or a consultant, all content on this portal is only for you and your organization internal use and due diligence. Unauthorized access, sharing, or misuse of portal content is strictly prohibited. Genesys disclaims all liability for any breach arising from Administrator or user actions. Continued use of the Security Portal constitutes acceptance of these terms.</div>
              <div>
                <Checkbox checked={agreeChecked} onChange={() => setAgreeChecked(!agreeChecked)} disabled={loadingAccept || loadingDecline}>
                  <span className='font-bold text-lg'>I have read and I agree to the terms</span>
                </Checkbox>
              </div>
              <Space2>
                <Button size='large' type='primary' disabled={loadingAccept || loadingDecline || !agreeChecked} loading={loadingAccept} onClick={handleAccept}>
                  <span className='font-bold'>Accept</span>
                </Button>
                <Button size='large' className='opacity-60 hover:opacity-100' type='default' disabled={loadingAccept || loadingDecline} loading={loadingDecline} onClick={handleDecline}>
                  Decline
                </Button>
              </Space2>
            </div>
          </PropertySection>
        </div>
      </div>
    </>
  );
};

AdminDisclaimerContent.propTypes = {};

export default AdminDisclaimerContent;

import React from 'react';
import { Alert } from 'antd';

//import PropTypes from 'prop-types';

const UserAccountDisabled = () => {
  return (
    <>
      <div className='mt-8'>
        <Alert message={<span className='font-bold text-lg'>Your user account is disabled in the portal.</span>} type='warning' showIcon />
        <div className='my-4 font-bold text-2xl'>What should you do?</div>
        <div>
          <ul className='list-disc space-y-4'>
            <li>Please contact your Administrator</li>
          </ul>
        </div>
      </div>
    </>
  );
};

UserAccountDisabled.propTypes = {};

export default UserAccountDisabled;

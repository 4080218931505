import React, { useContext, useState, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Input, Select, TreeSelect } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { notArrayOrEmpty, notEmptyArray, getDistinct3, getDistinct1, textSorterStrArray } from 'src/misc/Misc';
//import PropTypes from 'prop-types';

const { SHOW_PARENT } = TreeSelect;

const Filters = () => {
  const { servicesForTenant, myTicketsQuery, myTicketsPriorities, myTicketsRoles, setMyTicketsQuery, setMyTicketsStatuses, setMyTicketsPriorities, setMyTicketsRoles } = useContext(TeacketContext);

  const [statusList, setStatusList] = useState([]);
  const [statusTree, setStatusTree] = useState(null);
  const [selectedStatusTree, setSelectedStatusTree] = useState(null);
  const [priorityList, setPriorityList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    // console.log('servicesForTenant:', servicesForTenant);
    if (true === notArrayOrEmpty(servicesForTenant)) return;
    let sl = [];
    let pl = [];
    let rl = [];
    for (const service of servicesForTenant) {
      if (true === notEmptyArray(service?.props?.statusList)) sl = sl.concat(service.props.statusList.map((x) => x));
      if (true === notEmptyArray(service?.props?.priorityList)) pl = pl.concat(service.props.priorityList.map((x) => x.priority));
      if (true === notEmptyArray(service?.props?.roleList)) rl = rl.concat(service.props.roleList.map((x) => x.role));
    }
    sl = getDistinct3(sl, 'status');
    pl = getDistinct1(pl);
    rl = getDistinct1(rl)?.sort((a, b) => textSorterStrArray(a, b));
    // console.log('sl:', sl);
    // console.log('pl:', pl);
    setStatusList(sl);
    setPriorityList(pl);
    setRoleList(rl);
  }, [servicesForTenant]);

  useEffect(() => buildStatusTree(), [statusList]);
  useEffect(() => handleStatusTreeChange(['all-open']), [statusTree]);

  const buildStatusTree = () => {
    if (true === notArrayOrEmpty(statusList)) return;
    const result = [
      {
        title: '[All open statuses]',
        value: 'all-open',
        key: 'all-open',
        children: statusList
          .filter((x) => !x.isClosed)
          ?.map((x) => {
            return { title: x.status, value: x.status, key: x.status };
          }),
      },
      {
        title: '[All closed statuses]',
        value: 'all-closed',
        key: 'all-closed',
        children: statusList
          .filter((x) => x.isClosed)
          ?.map((x) => {
            return { title: x.status, value: x.status, key: x.status };
          }),
      },
    ];
    setStatusTree(result);
  };

  const handleStatusTreeChange = (v) => {
    console.log('handleStatusTreeChange()', v);
    setSelectedStatusTree(v);
    if (true === notArrayOrEmpty(v) || true === notArrayOrEmpty(statusTree)) {
      setMyTicketsStatuses(null);
      return;
    }
    let result = [];
    for (const item of v) {
      if ('all-open' === item) {
        const sl = statusTree.find((x) => 'all-open' === x.value)?.children;
        if (true === notEmptyArray(sl)) {
          result = result.concat(sl.map((x) => x.value));
        }
      } else if ('all-closed' === item) {
        const sl = statusTree.find((x) => 'all-closed' === x.value)?.children;
        if (true === notEmptyArray(sl)) {
          result = result.concat(sl.map((x) => x.value));
        }
      } else {
        result.push(item);
      }
    }
    console.log('result:', result);
    setMyTicketsStatuses(result);
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-6 gap-4'>
      <div>
        <Input
          className='w-full'
          placeholder='Search...'
          prefix={<SearchOutlined className='mr-2' />}
          allowClear
          value={myTicketsQuery}
          onChange={(e) => {
            setMyTicketsQuery(e.target.value);
          }}
        />
      </div>
      <div className='2xl:col-span-3'>
        <TreeSelect className='w-full' allowClear value={selectedStatusTree} placeholder='[All statuses]' treeData={statusTree} treeCheckable={true} showCheckedStrategy={SHOW_PARENT} onChange={handleStatusTreeChange} />
      </div>
      <Select
        allowClear
        className='w-full'
        placeholder='[All priorities]'
        value={myTicketsPriorities}
        onChange={(v) => setMyTicketsPriorities(v)}
        options={priorityList?.map((x) => {
          return { value: x, label: x };
        })}
      />
      <Select
        allowClear
        className='w-full'
        placeholder='[All roles]'
        value={myTicketsRoles}
        onChange={(v) => setMyTicketsRoles(v)}
        options={roleList?.map((x) => {
          return { value: x, label: x };
        })}
      />
    </div>
  );
};

Filters.propTypes = {};

export default Filters;

import React, { useContext, useEffect } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import NoData from 'src/components/layout/NoData';
import MasterAdminContext from 'src/context/MasterAdminContext';
import { Tabs } from 'antd';
import BreachNotificationsEditorData from './BreachNotificationsEditorData';
import BreachNotificationsEditorTargets from './BreachNotificationsEditorTargets';
import BreachNotificationsEditorPost from './BreachNotificationsEditorPost';
import { getBreachNotificationMessage } from 'src/api/notifications';

const BreachNotificationsEditor = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { breachNotificationsSelectedId, breachNotificationsSelected, setBreachNotificationsSelected, breachNotificationsTabsDisabled } = useContext(MasterAdminContext);

  useEffect(() => {
    if (!breachNotificationsSelectedId) {
      setBreachNotificationsSelected(null);
      return;
    }
    loadData();
  }, [breachNotificationsSelectedId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getBreachNotificationMessage(breachNotificationsSelectedId);
        setBreachNotificationsSelected(resp);
      } catch (error) {
        console.error('error', error);
      }
    });
  };

  if (!breachNotificationsSelectedId || !breachNotificationsSelected) return <NoData />;

  return (
    <Tabs
      type='card'
      size='small'
      defaultActiveKey='1'
      items={[
        {
          key: '1',
          disabled: breachNotificationsTabsDisabled,
          label: 'Content',
          children: <BreachNotificationsEditorData />,
        },
        {
          key: '2',
          disabled: breachNotificationsTabsDisabled,
          label: 'Affected Emails',
          children: <BreachNotificationsEditorTargets />,
        },
        {
          key: '3',
          disabled: breachNotificationsTabsDisabled,
          label: 'Post',
          children: <BreachNotificationsEditorPost />,
        },
      ]}
    />
  );
};

BreachNotificationsEditor.propTypes = {};

export default BreachNotificationsEditor;

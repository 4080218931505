import React, { useContext, useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import Droppable from 'src/components/controls/Droppable';
import { postTeacketCreateUploadSession, patchTeacketUpdateFields, patchTeacketUpdatePermission, getTeacketFiles, postTeacketMoveFiles, postTeacketUploadComplete, getTeacketFolderName } from 'src/api/microsoft';
import { postTicketHistory } from 'src/api/teacket';
import TeacketContext from 'src/context/TeacketContext';
import NoData from 'src/components/layout/NoData';
import * as dayjs from 'dayjs';
import { splitBufferIntoBatches, notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { putUploadFile } from 'src/api/common';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import { bytesToSize, numberSorter, textSorter } from 'src/misc/Misc';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import useTheme from 'src/hooks/useTheme';
import { Alert, message, Tooltip } from 'antd';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { v4 as uuid } from 'uuid';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';

const TicketSharepointFiles = () => {
  const WIDGET_ID = 'Sharepoint Files';
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { currentTicketSharepointFilesOppAccUpdated, setCurrentTicketSharepointFilesOppAccUpdated, currentTicket, currentTicketSharepointFilesFiltered, currentTicketSharepointFiles, setCurrentTicketSharepointFiles, currentTicketSharepointFilesRequestIds, determineIsWidgetReadOnly } = useContext(TeacketContext);
  const { id: ticket_id, created_at, acc_name, acc_id, opp_name, opp_id } = currentTicket;
  const { theme } = useTheme();

  const columns = [
    {
      title: 'Name',
      key: 'Name',
      render: (record) => {
        return (
          <div>
            <div
              className='cursor-pointer'
              style={{ maxWidth: '400px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'left', color: theme.primary }}
              onClick={() => {
                window.open(record.webUrl, '_blank');
              }}
            >
              <strong>{record.name}</strong>
            </div>
            {currentTicketSharepointFilesRequestIds && <div style={{ fontSize: '0.6rem', lineHeight: '0.6rem', fontWeight: '300', opacity: '0.5' }}>{record?.listItem?.fields?.RequestID ?? 'n/a'}</div>}
          </div>
        );
      },
      sorter: (a, b) => textSorter(a, b, 'name'),
    },
    {
      title: 'Size',
      key: 'Size',
      align: 'right',
      width: 100,
      render: (record) => <>{bytesToSize(record.size ?? 0)}</>,
      sorter: (a, b) => numberSorter(a, b, 'size'),
    },
  ];

  const [loading, setLoading] = useState(null);
  const [folderName, setFolderName] = useState(null);

  useEffect(() => {
    if (!currentTicket?.id) return;
    if (!acc_name) return;
    loadFileList();
    loadFolderName();
  }, [currentTicket?.id]);

  useEffect(() => {
    if (!currentTicket) return;
    if (!currentTicketSharepointFilesOppAccUpdated) return;
    const { old1, new1 } = currentTicketSharepointFilesOppAccUpdated;
    if (!old1?.acc_id || !old1?.acc_name || !new1?.acc_id || !new1?.acc_name) {
      console.warn("Moving files won't be triggered");
      return;
    }
    console.log('currentTicketSharepointFilesOppAccUpdated:', { old1, new1 });
    if (old1.acc_id === new1.acc_id && old1.opp_id === new1.opp_id && old1.acc_name === new1.acc_name && old1.opp_name === new1.opp_name) return;
    moveFiles({ ...old1 }, { ...new1 });
    setCurrentTicketSharepointFilesOppAccUpdated(null);
  }, [currentTicketSharepointFilesOppAccUpdated]);

  const moveFiles = async (prevAccOppData, currentAccOppData) => {
    console.log('moveFiles()', { prevAccOppData, currentAccOppData });
    const key = uuid();
    executeAsyncProcess(async () => {
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Moving SharePoint files, please wait...',
          duration: 0,
        });
        const body = {
          old_year: dayjs(created_at).year(),
          old_acc_name: prevAccOppData.acc_name,
          old_opp_name: prevAccOppData.opp_name,
          new_year: dayjs(created_at).year(),
          new_acc_name: currentAccOppData.acc_name,
          new_acc_id: currentAccOppData.acc_id,
          new_opp_name: currentAccOppData.opp_name,
          new_opp_id: currentAccOppData.opp_id,
          request_id: ticket_id,
        };
        const result = await postTeacketMoveFiles(body);
        console.log('moveFiles() result:', result);
        await loadFileList();
        await loadFolderName();
        messageApi.open({
          key,
          type: 'success',
          content: 'Files moved',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
        messageApi.destroy(key);
      }
    });
  };

  const handleOnDrop = async (files) => {
    console.log('handleOnDrop()', ticket_id, currentTicket);
    try {
      //#region "create upload session"
      setLoading('Creating an upload session');
      const file = files[0];
      const BATCH_SIZE = 4000000;
      const { uploadUrl } = (await postTeacketCreateUploadSession(dayjs(created_at).year(), acc_name, opp_name, file.name)) ?? {};
      // console.log('uploadUrl:', uploadUrl);
      //#endregion

      //#region "upload file to sharepoint"
      setLoading('Uploading the file');
      const fileData = new Blob([file]);
      const arrayBuffer = await fileData.arrayBuffer();
      const batches = splitBufferIntoBatches(arrayBuffer, BATCH_SIZE);
      if (notArrayOrEmpty(batches)) throw new Error('The buffer is empty');
      for (const i in batches) {
        console.log(`[TicketSharepointFiles] uploading batch ${Number(i) + 1} of ${batches.length}`);
        await putUploadFile(uploadUrl, batches[i].batch, batches[i].from, batches[i].to, arrayBuffer.byteLength);
      }
      //#endregion

      //#region "update fields"
      setLoading('Updating file data');
      const fields = { RequestID: ticket_id, OppName: opp_name, OppID: opp_id, AccName: acc_name, AccID: acc_id };
      console.log(`[TicketSharepointFiles] updating fields:\r\n${JSON.stringify(fields, null, 2)}`);
      await patchTeacketUpdateFields(dayjs(created_at).year(), acc_name, opp_name, file.name, fields);
      //#endregion

      //#region "update permission"
      setLoading('Updating the permission');
      await patchTeacketUpdatePermission(dayjs(created_at).year(), acc_name, opp_name, file.name);
      //#endregion

      //#region "update complete - send out sqs msg"
      setLoading('Completing the upload');
      await postTeacketUploadComplete(ticket_id);
      //#endregion

      //#region "update history"
      setLoading('Updating the history');
      await postTicketHistory(ticket_id, 'SharePoint', 'Upload', { file: file.name });
      //#endregion

      //#region "load file list"
      setLoading('Loading the file list');
      const filesList = await getTeacketFiles(dayjs(created_at).year(), acc_name, opp_name);
      // console.log(`[TicketSharepointFiles] filesList:\r\n${JSON.stringify(filesList, null, 2)}`);
      setCurrentTicketSharepointFiles(filesList?.value || []);
      //#endregion

      // await postRequestHistoryItems(currentTicket.id, { action: 'fileUpload', changes: [{ fileName: file.name, filePath: `${rootFolder}/${accOppFolderName}` }] });
    } catch (error) {
      console.error(error);
    }
    setLoading(null);
  };

  const loadFileList = async () => {
    console.log('loadFileList()', ticket_id, currentTicket);
    setLoading('Loading the file list');
    try {
      const filesList = await getTeacketFiles(dayjs(created_at).year(), acc_name, opp_name);
      // console.log(`[TicketSharepointFiles] filesList:\r\n${JSON.stringify(filesList, null, 2)}`);
      setCurrentTicketSharepointFiles(filesList?.value || []);
    } catch (error) {
      console.error(error);
    }
    setLoading(null);
  };

  const loadFolderName = async () => {
    console.log('loadFolderName()', ticket_id, currentTicket);
    try {
      const resp = await getTeacketFolderName(dayjs(created_at).year(), acc_name, opp_name);
      setFolderName(resp?.folder_name || null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnWsMessageReceived = (e) => {
    console.log('[TicketSharepointFiles] handleOnWsMessageReceived()', e);
    if (e?.data?.ticket_id !== currentTicket?.id) return;
    try {
      if ('TICKET_SHAREPOINT_CHANGED' !== e.action) return;
      loadFileList();
      loadFolderName();
    } catch (error) {
      console.error(error);
    }
  };

  if (!currentTicket) return null;
  if (!acc_name) return <NoData label='No opportunity or account selected' />;
  if (loading) return <SkeletonTable label={loading} />;

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_SHAREPOINT_CHANGED']} />
      <Droppable onDrop={handleOnDrop} droppable={false === determineIsWidgetReadOnly(WIDGET_ID)}>
        {contextHolder}
        <div className='flex flex-col gap-2'>
          {false === determineIsWidgetReadOnly(WIDGET_ID) && <Alert message='Drop a file here' type='info' showIcon className='px-2 py-1' />}
          <CondensedTableLegacy columns={columns} dataSource={currentTicketSharepointFilesFiltered} pagination={{ size: 'small' }} noDataMessage='No Files' />
          {true === notEmptyArray(currentTicketSharepointFiles) && (
            <Tooltip title='Click to open the folder on SharePoint'>
              <div
                className='cursor-pointer text-[0.6rem] font-light opacity-40 hover:underline hover:opacity-60'
                onClick={() => {
                  window.open('https://genesyslab.sharepoint.com/sites/GlobalPSP/Shared%20Documents/Forms/AllItems.aspx?id=' + decodeURIComponent(`/sites/GlobalPSP/Shared Documents/${folderName}`, '_blank'));
                }}
              >
                {folderName}
              </div>
            </Tooltip>
          )}
        </div>
      </Droppable>
    </>
  );
};

TicketSharepointFiles.propTypes = {};

export default TicketSharepointFiles;

import React, { useEffect, useContext } from 'react';
import HomePageContext from 'src/context/HomePageContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
// import SectionNoPermissions from './Components/SectionNoPermissions';
import SectionTrust from './Components/SectionTrust';
import SectionSpeedTickets from './Components/SectionSpeedTickets';
//import SectionCompliance from './Components/SectionCompliance';
import SectionDownload from './Components/SectionDownload';
// import SectionDocuments from './Components/SectionDocuments';
// import SectionProducts from './Components/SectionProducts';
import SectionVideos from './Components/SectionVideos';
import { Drawer, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useTheme from 'src/hooks/useTheme';
import SectionSpcTools from './Components/SectionSpcTools';
import SectionSecurity from './Components/SectionSecurity';
import useUserInfo from 'src/hooks/useUserInfo';
import { hasUserPermission } from 'src/misc/Session';
// import { notArrayOrEmpty } from 'src/misc/Misc';
import SectionWelcome from './Components/SectionWelcome';
//import PropTypes from 'prop-types';

const Home = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { drawerTitle, drawerContent, closeDrawer } = useContext(HomePageContext);
  const { theme } = useTheme();
  const { permissions: userPermissions } = useUserInfo();

  useEffect(() => {
    setBreadcrumb([]);
    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    console.log('drawerTitle', drawerTitle, 'drawerContent', drawerContent);
  }, [drawerTitle, drawerContent]);

  return (
    <>
      <Drawer
        width='800px'
        title={
          <div className='text-2xl' style={{ color: theme.textBase }}>
            {drawerTitle}
          </div>
        }
        placement='right'
        closable={false}
        maskClosable
        extra={
          <Button type='text' onClick={() => closeDrawer()}>
            <CloseOutlined />
          </Button>
        }
        open={drawerTitle !== null && drawerContent !== null}
        onClose={() => closeDrawer()}
      >
        <div style={{ color: theme.textBase }}>{drawerContent}</div>
      </Drawer>
      {/* <TitleBar
        title={
          <Space className='btn'>
            <GenesysLogo style={{ height: '24px', width: '24px' }} />
            Compliance Center
            <span className='font-thin'>Home</span>
          </Space>
        }
      /> */}
      <div className='w-full flex flex-row justify-center p-6'>
        <div className='w-[1100px] flex flex-col gap-6'>
          <SectionWelcome />
          {/* {true === notArrayOrEmpty(userPermissions) && <SectionNoPermissions />} */}

          {(true === hasUserPermission('apps.copilot.view', userPermissions) || true === hasUserPermission('apps.teacket.view', userPermissions)) && (
            <div className='flex flex-col xl:flex-row gap-6'>
              {true === hasUserPermission('apps.copilot.view', userPermissions) && (
                <div className={`${true === hasUserPermission('apps.teacket.view', userPermissions) ? 'xl:w-2/3' : 'xl:w-full'}`}>
                  <SectionTrust />
                </div>
              )}
              {true === hasUserPermission('apps.teacket.view', userPermissions) && (
                <div className={`${true === hasUserPermission('apps.copilot.view', userPermissions) ? 'xl:w-1/3' : 'xl:w-full'}`}>
                  <SectionSpeedTickets />
                </div>
              )}
            </div>
          )}

          <SectionDownload />
          <SectionSpcTools />
          <SectionSecurity />
          <SectionVideos />
        </div>
      </div>
    </>
  );
};

// Compliance.propTypes = {};

export default Home;

import React from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { AiOutlineLogin } from 'react-icons/ai';
import { Alert, Space } from 'antd';
import styled from 'styled-components';
import { IoDocumentAttach } from 'react-icons/io5';

const StyledDiv = styled.div`
  li {
    margin-top: 0.5em;
  }
  li li {
    text-indent: 2.5em;
    margin-top: 0.5em;
  }
  li li li {
    text-indent: 5em;
    margin-top: 0.5em;
  }
  li li li li {
    text-indent: 7.5em;
    margin-top: 0.5em;
  }
`;

const GettingStarted = () => {
  return (
    <StyledDiv>
      <div className='fixed z-50'>
        <Header />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 max-w-7xl p-4'>
          <div className='my-8 font-bold text-2xl text-blue-600 dark:text-blue-400'>
            <Space>
              <AiOutlineLogin />
              Getting Started with the SPC Portal
            </Space>
          </div>
          <div className='my-8 text-lg text-gray-800 dark:text-gray-200'>
            <p>Welcome to the SPC portal. This portal allows you to access tools that have been created by the Genesys Security, Privacy & Compliance COE team.</p>
            <p>
              Access to this portal's features is limited and requires a signed trial agreement. <u>Full access to this portal will not be granted without a signed copy of this agreement</u>.
            </p>
            <p>
              To get started, please follow these <strong>steps</strong>:
              <ol>
                <li>
                  Go to{' '}
                  <a href='https://spc.genesys.com' target='_blank' rel='noreferrer'>
                    https://spc.genesys.com
                  </a>{' '}
                  and click on the
                  <img src='/images/products/GettingStarted/SignInButton.png' alt='Sign In Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg align-middle' />
                  button on the top right.
                </li>
                <li>Select the environment where your Genesys Cloud is hosted and login to the SPC Portal using your own Genesys Cloud organization credentials.</li>
                <p>
                  <div className='bg-yellow-200 text-yellow-800 pl-4 pt-1 pb-4 rounded'>
                    <p>
                      <strong>Important</strong>: The initial user who logs in needs administrator privileges <strong>across all divisions</strong> within Genesys Cloud. If you don't have these permissions, request an organization administrator to log in first. This administrator (or master administrator) requires, at a minimum, the following permission:
                    </p>
                    <div className='ml-2 mb-4'>
                      <ul>
                        <li>General/Admin (All Divisions)</li>
                      </ul>
                    </div>
                    <strong>Why?</strong> The reason for this requirement is that the SPC portal creates an OAuth client within your Genesys Cloud organization. This client allows the portal to access data and deliver services. The SPC Portal only reads data; it will not modify your organization's data.
                  </div>
                </p>
                <li>
                  You should now see a screen similar to this:
                  <div className='my-8'>
                    <div className='flex justify-center'>
                      <img src='/images/products/GettingStarted/HomeScreenWithWelcomeWidget.png' alt='Admin Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                    </div>
                  </div>
                </li>
                <li>
                  To gain full access, there is one more thing you need to do:
                  <ul>
                    <li>
                      Click on <img src='/images/products/GettingStarted/GreenTeacketSidebar.png' alt='Green Teacket Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg align-middle mb-2' /> in the left sidebar
                    </li>
                    <li>
                      Click on the <img src='/images/products/GettingStarted/CreateNewTicketButton.png' alt='Create New Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg align-middle mb-2' /> button
                    </li>
                    <li>
                      Select the <strong>SPC Portal</strong> service
                    </li>
                    <li>
                      Select the <strong>Request Full Access</strong> category
                    </li>
                    <li>
                      Download the <IoDocumentAttach />{' '}
                      <strong>
                        <a href='/documents/Trial Agreement.docx'>SPC Trial Agreement</a>
                      </strong>{' '}
                      document and get it signed by your signatory authority
                    </li>
                    <li>
                      Click on the <strong>Submit</strong> button. You will return to this ticket later on to upload your signed trial agreement.
                    </li>
                  </ul>
                </li>
                <li>Once you have a copy of the signed document, open the ticket and upload it in the "Files" section (drag & drop is supported).</li>
                <li>Specify the email address of one of your Genesys Cloud administrators.</li>
                <li>
                  Once the agreement is received and processed, <strong>the administrator will receive an email with an invitation link</strong> to our portal. Only this user will be allowed to add other users from your Genesys Cloud organization to our SPC portal.
                </li>
                <li>
                  When your administrator logs in for the first time, the <strong>SPC</strong> application will need to be approved. Follow these steps to approve the application
                  <div className='ml-2'>
                    <ul>
                      <li>
                        In your Genesys Cloud instance, open <strong>Admin/Authorized Applications</strong> (in the Integrations section)
                      </li>
                      <li>
                        Open the <strong>SPC</strong> application and approve it. The permissions requested are read-only and will not change anything in your organization.
                      </li>
                      <div className='mt-2 mb-2'>
                        <Alert message='Some specific items do not have a read-only option however they will only be used by the SPC portal to read data.' type='info' showIcon />
                      </div>
                      <li>
                        It should look like this after being approved:
                        <div className='my-8'>
                          <div className='flex justify-center'>
                            <img src='/images/products/GettingStarted/SPCApplicationAuthorization.png' alt='Admin Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  Once the application is approved in your Genesys Cloud organization, it's now time to invite other users from your Genesys Cloud organization (5 users maximum). To do this, follow these steps:
                  <ul>
                    <li>
                      Click on the <strong>Admin</strong> item in the left sidebar
                      <div className='my-8'>
                        <div className='flex justify-center'>
                          <img src='/images/products/GettingStarted/Admin.png' alt='Admin Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                        </div>
                      </div>
                    </li>
                    <li>
                      Click on the <strong>Invitation Links</strong> button
                      <div className='my-8'>
                        <div className='flex justify-center'>
                          <img src='/images/products/GettingStarted/InvitationLinksButton.png' alt='Invitation Links Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                        </div>
                      </div>
                    </li>
                    <li>
                      Click on the <strong>Create Invitation</strong> button on the top bar
                      <div className='my-8'>
                        <div className='flex justify-center'>
                          <img src='/images/products/GettingStarted/CreateInvitationButton.png' alt='Create Invitation Button' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                        </div>
                      </div>
                    </li>
                    <li>
                      Enter the email addresses that will be able to access SPC. <strong>Trials are limited to 5 users.</strong>
                      <div className='my-8'>
                        <div className='flex justify-center'>
                          <img src='/images/products/GettingStarted/UserEmails.png' alt='Enter at least one email' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                        </div>
                      </div>
                    </li>
                    <li>
                      Click on <strong>Next</strong>. This is where roles will be assigned to all of the email addresses listed in the previous step. If you want to give users the ability to add more users, select the <strong>Tenant Admin</strong> role. Otherwise, simply select the appropriate roles. There is a role for each feature.
                      <div className='my-8'>
                        <div className='flex justify-center'>
                          <img src='/images/products/GettingStarted/SelectRole.png' alt='Enter at least one email' className='shadow-sm dark:shadow-genesys-gray-400 rounded-lg' />
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ol>
            </p>
          </div>
          <div className='my-8'>
            <p>If you encounter an error message due to insufficient permissions, make sure you have the required permissions listed at the beginning of this page or ask an admin from your Genesys Cloud organization to log in and grant you the necessary permissions.</p>
          </div>
          <div className='my-8'>
            <p>If you have any issues, please reach out to spc.portal@genesys.com.</p>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

// Copilot.propTypes = {};

export default GettingStarted;

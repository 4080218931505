import React, { useContext, useState, useEffect, useRef } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import { Space, Input, Button, Form, Select, Modal, message, Alert, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postTicket, postTicketHistory, putTicketWidgetsValues } from 'src/api/teacket';
import { getAccountForOrgId, getAccountDeployments } from 'src/api/snowflake';
import useUserInfo from 'src/hooks/useUserInfo';
import { notArrayOrEmpty, notEmptyArray, notEmptyString, splitBufferIntoBatches, sleepMs } from 'src/misc/Misc';
import { getServicesIsMaster } from 'src/api/teacket';
import SnowflakeAccountPicker from 'src/components/controls/SnowflakeAccountPicker/SnowflakeAccountPicker';
import SnowflakeOpportunityPicker from 'src/components/controls/SnowflakeOpportunityPicker/SnowflakeOpportunityPicker';
import PropertySection from 'src/components/layout/PropertySection';
import SearchServiceUser from 'src/components/pages/Teacket/Components/ServiceUserPicker';
import { CONTENT_MAX_LENGTH, TITLE_MAX_LENGTH, isLegalSupportSelected } from '../../Misc/misc';
import { uuid } from 'short-uuid';
import UserImage from 'src/components/layout/UserImage';
import TicketCreateCompletionDates from './TicketCreateCompletionDates';
import CategoryUrls from '../../Components/CategoryUrls';
import TicketCreateSharepointFiles from './TicketCreateSharepointFiles';
import { postTeacketCreateUploadSession, patchTeacketUpdateFields, patchTeacketUpdatePermission } from 'src/api/microsoft';
import { putUploadFile } from 'src/api/common';
import dayjs from 'dayjs';
import StakeholderDetails from '../../Components/StakeholderDetails';
import { FileExclamationOutlined } from '@ant-design/icons';
import TicketCreateLegalSupport from './TicketCreateLegalSupport';

const TicketCreateControl = () => {
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [createTicketForm] = Form.useForm();
  const { servicesForTenant, getServiceSettingForServiceId, setCurrentTicketOppDetails, getWidgetListForServiceId } = useContext(TeacketContext);
  const [messageApi, contextHolder] = message.useMessage();
  const formRef = useRef(null);

  //#region Use States

  const [isMaster, setIsMaster] = useState(null);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [opportunityModalOpen, setOpportunityModalOpen] = useState(false);
  const [opportunity, setOpportunity] = useState(null);
  const [snowflakeOpportunitySupport, setSnowflakeOpportunitySupport] = useState(null);
  const [requesterModalOpen, setRequesterModalOpen] = useState(false);
  const [requester, setRequester] = useState(null);
  const [requesterImage, setRequesterImage] = useState(null);
  const [gcDeploymentSupport, setGcDeploymentSupport] = useState(null);
  const [accOrOppMandatory, setAccOrOppMandatory] = useState(null);
  const [deploymentOptions, setDeploymentOptions] = useState(null);
  const [isOkDisabled, setIsOkDisabled] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [showCompletionDates, setShowCompletionDates] = useState(false);
  const [showSharepointFiles, setShowSharepointFiles] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [notRelatedToAnOpportunity, setNotRelatedToAnOpportunity] = useState(false);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [requiredFileIsMissing, setRequiredFileIsMissing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [legalSupoprtDetails, setLegalSupportDetails] = useState(null);
  const [errors, setErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  //#endregion

  //#region Use Effects

  useEffect(() => {
    createTicketForm.setFieldValue('owner_id', userInfo.id);
    createTicketForm.setFieldValue('owner_name', userInfo.name);
    if (servicesForTenant?.length === 1) {
      console.log('==== Setting service_id to:', servicesForTenant?.[0]?.id);
      createTicketForm.setFieldValue('service_id', servicesForTenant?.[0]?.id);
      handleOnServiceChange(servicesForTenant?.[0]?.id);
    }
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    console.log('deploymentOptions:', deploymentOptions);
  }, [deploymentOptions]);

  useEffect(() => {
    console.log('=== form values:', createTicketForm.getFieldsValue(true));
    setCurrentTicket(convertToObject(createTicketForm));
  }, [createTicketForm]);

  useEffect(() => {
    console.log('Current ticket:', currentTicket);
  }, [currentTicket]);

  //#endregion

  //#region "FORM HANDLERS"

  const handleFormOnFinish = async (values) => {
    console.log('Success:', values);

    //#region "ADDITIONAL VALIDATIONS"

    if (true === accOrOppMandatory) {
      if (!createTicketForm.getFieldValue('acc_id') || !createTicketForm.getFieldValue('acc_name')) {
        messageApi.open({
          type: 'error',
          content: 'Please select an account or an opportunity',
        });
        return;
      }
    }

    if (true === notEmptyArray(fileList) && !createTicketForm.getFieldValue('acc_name')) {
      messageApi.open({
        type: 'error',
        content: 'Please select opportunity or account before uploading files',
      });
      return;
    }

    if (true === isLegalSupportSelected(createTicketForm.getFieldValue('category')) && !legalSupoprtDetails?.terms) {
      messageApi.open({
        type: 'error',
        content: 'Please select legal support terms',
      });
      return;
    }

    //#endregion

    delete values.owner_name; // Not needed

    const body = { ...values, status: createTicketForm.getFieldValue('status') };

    // // Add SF opportunity fields
    // if (snowflakeOpportunitySupport) {
    //   body.props = currentTicketOppDetails;
    // }

    // // Add SF account fields if it's present
    // body.props = { ...currentTicketOppDetails, ...(account || {}) };

    const key = uuid();

    await executeAsyncProcess(async () => {
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Submitting the ticket, please wait...',
          duration: 0,
        });

        // Create ticket
        console.log('Create ticket with body:', body);
        const ticket = await postTicket(body);

        // Process files
        if (true === notEmptyArray(fileList) && ticket) {
          for (const file of fileList) {
            try {
              messageApi.open({
                key,
                type: 'loading',
                content: `Uploading file ${file.name}...`,
                duration: 0,
              });
              await uploadFile(ticket.id, file);
            } catch (error) {
              const ERR_DURATION = 3;
              console.error(error);
              messageApi.open({
                key,
                type: 'error',
                content: `Failed to upload file ${file.name}`,
                duration: ERR_DURATION,
              });
              await sleepMs(ERR_DURATION * 1000); // allow user to see the error message, before redirecting to the ticket page
            }
          }
        }

        // Process legal support details
        if (true === isLegalSupportSelected(createTicketForm.getFieldValue('category')) && ticket) {
          messageApi.open({
            key,
            type: 'loading',
            content: 'Saving legal support details...',
            duration: 0,
          });
          if (legalSupoprtDetails?.legalOwner) await putTicketWidgetsValues(ticket.id, 'Properties', 'Legal Owner', legalSupoprtDetails.legalOwner);
          if (legalSupoprtDetails?.clmTicketUrl) await putTicketWidgetsValues(ticket.id, 'Properties', 'CLM Ticket URL', legalSupoprtDetails.clmTicketUrl);
          if (legalSupoprtDetails?.terms) await putTicketWidgetsValues(ticket.id, 'Properties', 'Terms', legalSupoprtDetails.terms);
        }

        messageApi.open({
          key,
          type: 'success',
          content: 'Ticket submitted',
        });

        // Browse to newly created ticket
        if (ticket) {
          navigate(`/teacket/tickets/${ticket.id}`);
        }
      } catch (error) {
        console.error(error);
        messageApi.open({
          key,
          type: 'error',
          content: 'Failed to submit ticket',
        });
      }
    });
  };

  const handleFormOnFailure = (errorInfo) => {
    console.log('Failed:', errorInfo);
    console.log('Current ticket form values:', createTicketForm.getFieldsValue(true));

    setShowErrors(true);
    setErrors(errorInfo.errorFields.map((x) => x.errors));
  };

  const handleFormReset = () => {
    setSubCategoryList([]);
    setCategoryList([]);
    setPriorityList([]);
    setDeploymentOptions(null);
    setGcDeploymentSupport(null);
    setAccOrOppMandatory(null);
    setShowCompletionDates(false);
    setShowSharepointFiles(false);
    setCategoryUrlList(null);
    setIsMaster(null);

    setErrors([]);
    setShowErrors(false);
  };

  const handleOnServiceChange = async (value) => {
    console.log('handleOnServiceChange()', value);

    // Reset errors
    setShowErrors(false);
    setErrors([]);

    // <reset owner>

    createTicketForm.setFieldValue('owner_id', userInfo.id);
    createTicketForm.setFieldValue('owner_name', userInfo.name);
    setRequesterImage(userInfo.user_pic);

    // </reset owner>

    // <reset form options>
    setDefaultStatus(value);
    setPriorityOptions(value);
    setCategoryOptions(value);
    setShowCompletionDates(false);
    setShowSharepointFiles(false);
    setCategoryUrlList(null);

    // </reset form options>

    // <reset selected acc or opp>
    createTicketForm.setFieldValue('acc_id', undefined);
    createTicketForm.setFieldValue('acc_name', undefined);
    createTicketForm.setFieldValue('category', undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('opp_id', undefined);
    createTicketForm.setFieldValue('opp_name', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);
    createTicketForm.setFieldValue('sub_category', undefined);

    // Set new service id
    createTicketForm.setFieldValue('service_id', value);

    setCurrentTicketOppDetails(null);
    // </reset selected acc or opp>

    // <determine if the current user's tenant is a master one for the selected service>
    setIsMaster(null);
    const im = await isTenantMasterForService(value);
    setIsMaster(im);
    // </determine if the current user's tenant is a master one for the selected service>

    // <determine account>
    if (true !== im) {
      await determineAccount();
    }
    // </determine account>

    // <determine if snowflake opportunity support is enabled>
    const sf = getServiceSettingForServiceId(value, 'SnowflakeOpportunitySupport');
    console.log('sf', sf);
    setSnowflakeOpportunitySupport('1' === sf);
    // </determine if snowflake opportunity support is enabled>

    // <determine if GC deployment support is enabled>
    const gc = getServiceSettingForServiceId(value, 'GcDeploymentSupport');
    setGcDeploymentSupport('1' === gc);
    // </determine if GC deployment support is enabled>

    // <determine if GC acc or opp is mantadory>
    const aom = getServiceSettingForServiceId(value, 'AccOrOppMandatory');
    setAccOrOppMandatory('1' === aom);
    // </determine if GC acc or opp is mantadory>

    // <determine if completion dates should be shown>
    const cd = getWidgetListForServiceId(value)?.find((x) => x.widgetId === 'Completion Dates') ? true : false;
    setShowCompletionDates(cd);
    // </determine if completion dates should be shown>

    // <determine if sharepoint files should be shown>
    const sp = getWidgetListForServiceId(value)?.find((x) => x.widgetId === 'Sharepoint Files') ? true : false;
    setShowSharepointFiles(sp);
    // </determine if sharepoint files should be shown>

    if ('1' !== sf) return;

    // <determine if Opportunity Not Required is set>
    const oppnotreq = getServiceSettingForServiceId(value, 'OpportunityNotRequiredByDefault');
    if (oppnotreq === '1') handleNotRelatedToOpportunityChange({ target: { checked: true } });
    else handleNotRelatedToOpportunityChange({ target: { checked: false } });

    // </determine if Opportunity Not Required is set>
  };

  const handleCategorySelect = (value) => {
    const selectedCategory = categoryList.find((item) => item.value === value);
    console.log('Selected category:', selectedCategory);
    setSelectedCategory(value);
    createTicketForm.setFieldValue('sub_category', undefined);
    setSubCategoryOptions(value);
    setCategoryUrls(value);
    if (selectedCategory?.fileOrLinkRequired) {
      setRequiredFileIsMissing(true);
    } else setRequiredFileIsMissing(false);
  };

  const handleSubCategorySelect = (value) => {
    setSelectedSubCategory(value);
    const selectedSubCat = subCategoryList.find((item) => item.value === value);
    console.log('Selected sub category:', selectedSubCat);
    if (selectedSubCat?.fileOrLinkRequired) {
      setRequiredFileIsMissing(true);
    } else setRequiredFileIsMissing(false);
  };

  const handleOnDeploymentChange = (value) => {
    console.log('Selected deployment:', value);
    const deployment = deploymentOptions.find((item) => item.ORG_ID === value);
    console.log('deployment', deployment?.ORG_NAME__C);
    createTicketForm.setFieldValue('dep_name', deployment?.ORG_NAME__C);
  };

  // Handle new account values
  const handleAccountModalOnOk = () => {
    console.log('handleAccountModalOnOk', account);

    // Clear opportunity when a new account is set
    setOpportunity(null);

    // Update current form values
    createTicketForm.setFieldValue('acc_id', account?.ACCOUNT_ID ?? undefined);
    createTicketForm.setFieldValue('acc_name', account?.ACCOUNT_NAME ?? undefined);
    createTicketForm.setFieldValue('opp_id', undefined);
    createTicketForm.setFieldValue('opp_name', undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);

    // Props
    createTicketForm.setFieldValue('props', {
      account_name: account?.ACCOUNT_NAME ?? undefined,
      org_count: account?.ORG_COUNT ?? undefined,
      region: account?.ACCOUNT_REGION?.toUpperCase() ?? undefined,
      sales_segment: opportunity?.ACCOUNT_SALES_SEGMENT ?? undefined,
    });

    // Update current ticket so that the stakeholder details component values are updated too
    setCurrentTicket(convertToObject(createTicketForm));

    setAccountModalOpen(false);
    setCurrentTicketOppDetails(null);

    if (gcDeploymentSupport && account?.ACCOUNT_ID) {
      getDeploymentsForAccount(account?.ACCOUNT_ID);
    }
  };

  // Handle new opportunity values
  const handleOpportunityModalOnOk = () => {
    console.log('handleOpportunityModalOnOk', opportunity);
    createTicketForm.setFieldValue('acc_id', opportunity?.ACCOUNT_ID ?? undefined);
    createTicketForm.setFieldValue('acc_name', opportunity?.ACCOUNT_NAME ?? undefined);
    createTicketForm.setFieldValue('opp_id', opportunity?.OPPORTUNITY_ID ?? undefined);
    createTicketForm.setFieldValue('opp_name', opportunity?.OPPORTUNITY_NAME ?? undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);

    // Props
    createTicketForm.setFieldValue('props', {
      agents: opportunity?.OPPORTUNITY_AGENTS ?? undefined,
      close_date: opportunity?.OPPORTUNITY_CLOSE_DATE ?? undefined,
      created_date: opportunity?.OPPORTUNITY_CREATED_DATE ?? undefined,
      currency_iso_code: opportunity?.OPPORTUNITY_CURRENCY_ISO_CODE ?? undefined,
      deal_type: opportunity?.OPPORTUNITY_DEAL_TYPE ?? undefined,
      direct_indirect_sale: opportunity?.OPPORTUNITY_DIRECT_INDIRECT_SALE ?? undefined,
      dsr_url: opportunity?.OPPORTUNITY_DSR_URL ?? undefined,
      gross_acv_booking: opportunity?.OPPORTUNITY_GROSS_ACV_BOOKING ?? undefined,
      gross_acv_booking_usd: opportunity?.OPPORTUNITY_GROSS_ACV_BOOKING_USD ?? undefined,
      owner_email: opportunity?.OPPORTUNITY_OWNER_EMAIL ?? undefined,
      owner_name: opportunity?.OPPORTUNITY_OWNER_NAME ?? undefined,
      product: opportunity?.OPPORTUNITY_PRODUCT ?? undefined,
      region: opportunity?.OPPORTUNITY_COUNTRY_REGION?.toUpperCase() ?? undefined,
      sales_segment: opportunity?.OPPORTUNITY_SALES_SEGMENT ?? undefined,
      sc_email: opportunity?.OPPORTUNITY_SC_EMAIL ?? undefined,
      sc_name: opportunity?.OPPORTUNITY_SC_NAME ?? undefined,
      seats: opportunity?.OPPORTUNITY_SEATS ?? undefined,
      stage_name: opportunity?.OPPORTUNITY_STAGE_NAME ?? undefined,
      sub_region: opportunity?.OPPORTUNITY_COUNTRY_SUB_REGION?.toUpperCase() ?? undefined,
    });

    setCurrentTicket(convertToObject(createTicketForm));

    // Other props

    setOpportunityModalOpen(false);
    if (gcDeploymentSupport && account?.ACCOUNT_ID) getDeploymentsForAccount(account?.ACCOUNT_ID);

    setCurrentTicketOppDetails(opportunity);
  };

  const handleAccountModalOnSelect = async (v) => {
    console.log('handleAccountModalOnSelect', v);
    setAccount(v);
  };

  const handleRequesterModalOnOk = () => {
    console.log('handleRequesterModalOnOk');
    createTicketForm.setFieldValue('owner_id', requester?.user_id ?? undefined);
    createTicketForm.setFieldValue('owner_name', requester?.user_name ?? undefined);
    setRequesterImage(requester?.user_pic);
    setRequesterModalOpen(false);
  };

  const handleOpportunityModalOnSelect = (v) => {
    console.log('handleOpportunityModalOnSelect', v);
    setOpportunity(v);
  };

  const handleRequesterModalOnSelect = (v) => {
    console.log('handleRequesterModalOnSelect', v);
    setRequester(v);
  };

  const handleSharepointFilesOnChange = (files) => {
    console.log('handleSharepointFilesOnChange()', files);
    setFileList(files);
  };

  const handleNotRelatedToOpportunityChange = (e) => {
    const isChecked = e.target.checked;
    setNotRelatedToAnOpportunity(isChecked);

    setAccount(null);
    setOpportunity(null);

    createTicketForm.setFieldValue('acc_id', undefined);
    createTicketForm.setFieldValue('acc_name', undefined);
    createTicketForm.setFieldValue('opp_id', undefined);
    createTicketForm.setFieldValue('opp_name', undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);

    createTicketForm.setFieldValue('props', {});
  };

  const handleLegalSupportDetailsOnChange = (details) => {
    console.log('handleLegalSupportDetailsOnChange()', details);
    setLegalSupportDetails(details);
  };

  //#endregion

  //#region "VALUES MANIPULATION"

  const [priorityList, setPriorityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [categoryUrlList, setCategoryUrlList] = useState([]);

  const setPriorityOptions = async (service_id) => {
    try {
      const service = servicesForTenant?.find((service) => service.id === service_id);
      let prioList = null;
      if (true === (await isTenantMasterForService(service_id))) {
        prioList = true === notEmptyArray(service?.props?.priorityList) ? service?.props?.priorityList : [];
      } else {
        prioList = true === notEmptyArray(service?.props?.priorityList) ? service?.props?.priorityList.filter((x) => !x.masterOnly) : [];
      }
      setPriorityList(prioList);
      const defaultPriority = service?.props?.priorityList?.find((status) => true === status.default);
      if (!defaultPriority) return;
      createTicketForm.setFieldValue('priority', defaultPriority.priority);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load priority options',
        duration: 3,
      });
    }
  };

  const setDefaultStatus = (service_id) => {
    try {
      const service = servicesForTenant?.find((service) => service.id === service_id);
      const defaultStatus = service?.props?.statusList?.find((status) => true === status.default);
      if (!defaultStatus) throw new Error('Default status not found');
      createTicketForm.setFieldValue('status', defaultStatus.status);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load status',
        duration: 3,
      });
    }
  };

  const setCategoryOptions = (service_id) => {
    try {
      const service = servicesForTenant?.find((service) => service.id === service_id);
      const cl = (true === notEmptyArray(service?.props?.categoryList) ? service.props?.categoryList : [])
        .filter((i) => {
          if (i.hideForRequester) return false;
          if (i.requiredPermission && !userInfo.permissions.includes(i.requiredPermission)) return false;
          return true;
        })
        .sort((a, b) => a.category.localeCompare(b.category))
        .map((item) => {
          return { value: item.category, label: item.displayName || item.category || 'n/a', description: item.description || '', fileOrLinkRequired: item.fileOrLinkRequired || false };
        });

      setCategoryList(cl);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load category options',
        duration: 3,
      });
    }
  };

  const setSubCategoryOptions = (category) => {
    try {
      const service_id = createTicketForm.getFieldValue('service_id');
      const service = servicesForTenant?.find((service) => service.id === service_id);
      const categoryItem = service?.props?.categoryList?.find((item) => item.category === category);
      const scl = (true === notEmptyArray(categoryItem?.items) ? categoryItem.items : [])
        .filter((i) => !i.requiredPermission || userInfo.permissions.includes(i.requiredPermission))
        .sort((a, b) => a.subCategory.localeCompare(b.subCategory))
        .map((item) => {
          return { value: item.subCategory, label: item.displayName || item.subCategory || 'n/a', description: item.description || '', fileOrLinkRequired: item.fileOrLinkRequired || false };
        });
      setSubCategoryList(scl);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load sub category options',
        duration: 3,
      });
    }
  };

  const setCategoryUrls = (category) => {
    try {
      const service_id = createTicketForm.getFieldValue('service_id');
      const service = servicesForTenant?.find((service) => service.id === service_id);
      const categoryItem = service?.props?.categoryList?.find((item) => item.category === category);
      if (!categoryItem || true === notArrayOrEmpty(categoryItem?.urlList)) {
        setCategoryUrlList(null);
        return;
      }
      setCategoryUrlList(categoryItem.urlList);
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  //#region "MISC"

  const isTenantMasterForService = async (service_id) => {
    let result = null;
    await executeAsyncProcess(async () => {
      try {
        const resp = await getServicesIsMaster(service_id);
        result = resp?.isMaster === true;
      } catch (error) {
        console.error(error);
        return false;
      }
    });
    return result;
  };

  const determineAccount = async () => {
    await executeAsyncProcess(async () => {
      try {
        const { ACCOUNT_ID: acc_id, ACCOUNT_NAME: acc_name, deployments } = await getAccountForOrgId(true);
        console.log('determineAccount', acc_id, acc_name);

        createTicketForm.setFieldValue('acc_id', acc_id);
        createTicketForm.setFieldValue('acc_name', acc_name);

        setCurrentTicket(convertToObject(createTicketForm));

        if (deployments) setDeploymentOptions(deployments);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getDeploymentsForAccount = async (acc_id) =>
    await executeAsyncProcess(async () => {
      try {
        const deployments = await getAccountDeployments(acc_id);
        setDeploymentOptions(deployments);
      } catch (error) {
        console.error(error);
      }
    });

  const uploadFile = async (ticket_id, file) => {
    //#region "create upload session"
    const { uploadUrl } = (await postTeacketCreateUploadSession(dayjs().year(), createTicketForm.getFieldValue('acc_name'), createTicketForm.getFieldValue('opp_name'), file.name)) ?? {};
    const BATCH_SIZE = 4000000;
    //#endregion

    //#region "upload file to sharepoint"
    const fileData = new Blob([file]);
    const arrayBuffer = await fileData.arrayBuffer();
    const batches = splitBufferIntoBatches(arrayBuffer, BATCH_SIZE);
    if (notArrayOrEmpty(batches)) throw new Error('The buffer is empty');
    for (const i in batches) {
      console.log(`[TicketCreateControl] uploading batch ${Number(i) + 1} of ${batches.length}`);
      await putUploadFile(uploadUrl, batches[i].batch, batches[i].from, batches[i].to, arrayBuffer.byteLength);
    }
    //#endregion

    //#region "update fields"
    const fields = { RequestID: ticket_id, OppName: createTicketForm.getFieldValue('opp_name'), OppID: createTicketForm.getFieldValue('opp_id'), AccName: createTicketForm.getFieldValue('acc_name'), AccID: createTicketForm.getFieldValue('acc_id') };
    console.log(`[TicketCreateControl] updating fields:\r\n${JSON.stringify(fields, null, 2)}`);
    await patchTeacketUpdateFields(dayjs().year(), createTicketForm.getFieldValue('acc_name'), createTicketForm.getFieldValue('opp_name'), file.name, fields);
    //#endregion

    //#region "update permission"
    await patchTeacketUpdatePermission(dayjs().year(), createTicketForm.getFieldValue('acc_name'), createTicketForm.getFieldValue('opp_name'), file.name);
    //#endregion

    //#region "update history"
    await postTicketHistory(ticket_id, 'SharePoint', 'Upload', { file: file.name });
    //#endregion
  };

  //#endregion

  const convertToObject = (form, overwrite = false) => {
    console.log('convertToObject()', overwrite);
    console.log('=== Current ticket form values:', form?.getFieldsValue(true));

    if (!form) return {};

    const currentTicketValues = {};
    for (const [key, value] of Object.entries(form.getFieldsValue(true))) {
      currentTicketValues[key] = value;
    }

    console.log('Current ticket values:', currentTicketValues);

    if (overwrite) {
      return currentTicketValues;
    }

    // If you change any of this, also update Stakeholder.js handleTicketChange() function
    // which is called when the account or opportunity is changed within a ticket
    // Also update the <Form.Item> below (end of this page)

    // Map account values
    if (account) {
      currentTicketValues.props = {
        account_grouping: account.ACCOUNT_GROUPING,
        account_name: account.ACCOUNT_NAME,
        org_count: account.ORG_COUNT,
        owner_name: account.OWNER_NAME,
        region: currentTicketValues.props?.region || account.ACCOUNT_REGION?.toUpperCase(),
        sales_segment: currentTicketValues.props?.sales_segment || account.ACCOUNT_SALES_SEGMENT,
        sub_region: null,
      };
    }

    // Opportunity values can override account values
    if (opportunity) {
      currentTicketValues.props = {
        ...currentTicketValues.props,
        agents: opportunity.OPPORTUNITY_AGENTS,
        close_date: opportunity.OPPORTUNITY_CLOSE_DATE,
        created_date: opportunity.OPPORTUNITY_CREATED_DATE,
        currency_iso_code: opportunity.OPPORTUNITY_CURRENCY_ISO_CODE,
        deal_type: currentTicketValues.props?.deal_type || opportunity.OPPORTUNITY_DEAL_TYPE,
        direct_indirect_sale: currentTicketValues.props?.direct_indirect_sale || opportunity.OPPORTUNITY_DIRECT_INDIRECT_SALE,
        dsr_url: opportunity.OPPORTUNITY_DSR_URL,
        gross_acv_booking: opportunity.OPPORTUNITY_GROSS_ACV_BOOKING,
        gross_acv_booking_usd: opportunity.OPPORTUNITY_GROSS_ACV_BOOKING_USD,
        owner_name: opportunity.OPPORTUNITY_OWNER_NAME,
        owner_email: opportunity.OPPORTUNITY_OWNER_EMAIL,
        product: currentTicketValues.props?.product || opportunity.OPPORTUNITY_PRODUCT,
        region: currentTicketValues.props?.region || opportunity.OPPORTUNITY_COUNTRY_REGION?.toUpperCase(),
        sales_segment: currentTicketValues.props?.sales_segment || opportunity.OPPORTUNITY_SALES_SEGMENT,
        sc_name: opportunity.OPPORTUNITY_SC_NAME,
        sc_email: opportunity.OPPORTUNITY_SC_EMAIL,
        seats: currentTicketValues.props?.seats || opportunity.OPPORTUNITY_SEATS,
        stage_name: opportunity.OPPORTUNITY_STAGE_NAME,
        sub_region: currentTicketValues.props?.sub_region || opportunity.OPPORTUNITY_COUNTRY_SUB_REGION?.toUpperCase(),
      };
    }

    console.log('New ticket props', currentTicketValues.props);

    return currentTicketValues;
  };

  // Triggerred when properties are overridden by the user from the stakeholder details component
  const handleTicketPropertiesChange = (properties) => {
    console.log('[TicketCreateControl] properties', properties);

    for (let index = 0; index < Object.keys(properties)?.length; index++) {
      let property = Object.keys(properties)[index];
      console.log('Property:', property);
      console.log('Property value:', properties[property]);

      switch (property) {
        case 'region':
        case 'sub_region':
        case 'direct_indirect_sale':
        case 'deal_type':
        case 'sales_segment':
        case 'product':
        case 'seats':
          console.log(`Updating props.${property} to: ${properties[property]}`);
          createTicketForm.setFieldValue('props', { ...createTicketForm.getFieldValue('props'), [property]: properties[property] });
          break;
        default:
          console.error('Property not found:', property);
          break;
      }
    }

    // Update ticket (also used to update stakeholder details component)
    console.log('Calling convertToObject()');
    setCurrentTicket(convertToObject(createTicketForm, true));
  };

  const isThereUrl = () => {
    let url = false;
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    if (urlPattern.test(createTicketForm.getFieldValue('content'))) {
      url = true;
    }

    if (urlPattern.test(createTicketForm.getFieldValue('title'))) {
      url = true;
    }
    console.log('URL in Title or Content present:', url);
    return url;
  };

  return (
    <div>
      {contextHolder}

      {/* ACCOUNT MODAL */}
      {accountModalOpen && (
        <Modal
          title={'Search for account'}
          open={true}
          onCancel={() => {
            setAccountModalOpen(false);
          }}
          onOk={handleAccountModalOnOk}
        >
          <SnowflakeAccountPicker onSelect={handleAccountModalOnSelect} />
        </Modal>
      )}

      {/* OPPORTUNITY MODAL */}
      {opportunityModalOpen && (
        <Modal
          title={
            <div className='flex flex-col items-center'>
              <span>{account?.ACCOUNT_ID ? 'Select or search for a New Opportunity' : 'Search for Opportunity'}</span>
              <p className='text-xs opacity-60 text-center'>
                You can search by Name or paste full Opportuity URL like <i>https://genesys.lightning.force.com/lightning/r/Opportunity/0000T000.../view</i>
              </p>
            </div>
          }
          open={true}
          onCancel={() => {
            setOpportunityModalOpen(false);
          }}
          onOk={handleOpportunityModalOnOk}
          okButtonProps={{ disabled: isOkDisabled }}
        >
          <SnowflakeOpportunityPicker onSelect={handleOpportunityModalOnSelect} setBusy={setIsOkDisabled} limit={20} accountId={account?.ACCOUNT_ID ?? undefined} />
        </Modal>
      )}

      {/* REQUESTER MODAL */}
      {requesterModalOpen && (
        <Modal
          title={'Search for requester'}
          open={true}
          onCancel={() => {
            setRequesterModalOpen(false);
          }}
          onOk={handleRequesterModalOnOk}
        >
          <SearchServiceUser serviceId={createTicketForm.getFieldValue('service_id')} onSelect={handleRequesterModalOnSelect} />
        </Modal>
      )}

      {/* FORM */}
      <Form ref={formRef} form={createTicketForm} name='ticket-create-form' initialValues={{ remember: true }} onFinish={handleFormOnFinish} onFinishFailed={handleFormOnFailure} onReset={handleFormReset} autoComplete='off' layout='vertical'>
        <div>
          <div>
            {/* SERVICE */}
            <Form.Item label='Service' name='service_id' rules={[{ required: true, message: 'Select a service' }]}>
              <Select
                placeholder='Select a service...'
                onChange={handleOnServiceChange}
                options={servicesForTenant
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((service) => {
                    return { value: service.id, label: service.name };
                  })}
                optionRender={(option) => {
                  const item = servicesForTenant.find((x) => x.id === option.value);
                  // console.log('servicesForTenant:', servicesForTenant);
                  return (
                    <div>
                      <div>{item.name}</div>
                      <div className='text-xs font-light opacity-60 whitespace-normal break-words'>{item.description}</div>
                    </div>
                  );
                }}
              />
            </Form.Item>

            {/* SERVICE DESCRIPTION */}
            {true === notEmptyString(servicesForTenant.find((x) => x.id === createTicketForm.getFieldValue('service_id'))?.description) && <div className='mt-[-22px] mb-6 px-2 text-xs font-light opacity-60 whitespace-normal break-words'>{servicesForTenant.find((x) => x.id === createTicketForm.getFieldValue('service_id'))?.description}</div>}

            {/* REQUESTER */}
            {createTicketForm.getFieldValue('service_id') && true === isMaster && (
              <div className='mb-6'>
                {/* <REQUESTER> */}
                <PropertySection>
                  <div className='mx-2 mt-2'>
                    <div className='flex flex-row gap-2'>
                      <div className='flex-initial w-56'>
                        <Form.Item label='Requester Name' name='owner_name' rules={[{ required: true, message: 'Select a requester' }]}>
                          <Input disabled={true} />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item label=' '>
                          <Button type='primary' icon={<SearchOutlined />} onClick={() => setRequesterModalOpen(true)}></Button>
                        </Form.Item>
                      </div>

                      <div className='flex-auto'>
                        <Form.Item hidden label='Requester ID' name='owner_id' rules={[{ required: true, message: 'Select a requester' }]}>
                          <Input disabled={true} />
                        </Form.Item>
                        <div className='mt-3 text-right'>
                          <UserImage image={requesterImage} size={'48px'} />
                        </div>
                      </div>
                    </div>
                  </div>
                </PropertySection>
                {/* </ REQUESTER> */}
              </div>
            )}

            {/* ACCOUNT & OPPORTUNITY */}
            {createTicketForm.getFieldValue('service_id') && (
              <div className='mb-6'>
                <PropertySection>
                  <div className='mx-2 mt-2'>
                    {/* ACCOUNT ID & NAME */}
                    {(notRelatedToAnOpportunity || false === snowflakeOpportunitySupport) && (
                      <div className='flex flex-row gap-2'>
                        <div className='flex-initial w-56'>
                          <Form.Item label='Account Id' name='acc_id' rules={[{ required: false }]}>
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                        <div className='flex-auto'>
                          <Form.Item label='Account Name' name='acc_name' rules={[{ required: false }]}>
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                        <div>
                          {true === isMaster && (
                            <Form.Item label=' '>
                              <Button type='primary' icon={<SearchOutlined />} onClick={() => setAccountModalOpen(true)}></Button>
                            </Form.Item>
                          )}
                        </div>
                      </div>
                    )}
                    {true === gcDeploymentSupport && deploymentOptions?.length === 0 && <Alert className='mb-4' style={{ width: '100%' }} message='No deployments were found for this account' type='info' showIcon closable />}
                    {/* OPPORTUNITY ID & NAME */}
                    {true === isMaster && true === snowflakeOpportunitySupport && (
                      <>
                        {!notRelatedToAnOpportunity && (
                          <div className='flex flex-row gap-2 items-start'>
                            <div className='flex-initial w-56'>
                              <Form.Item label='Opportunity Id' name='opp_id'>
                                <Input disabled={true} />
                              </Form.Item>
                            </div>
                            <div className='flex-auto'>
                              <Form.Item label='Opportunity Name' name='opp_name'>
                                <Input disabled={true} />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item label=' '>
                                <Button type='primary' icon={<SearchOutlined />} onClick={() => setOpportunityModalOpen(true)}></Button>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        <div>
                          <Checkbox checked={notRelatedToAnOpportunity} onChange={handleNotRelatedToOpportunityChange}>
                            Not related to an opportunity
                          </Checkbox>
                          {/* TODO: Do not show if opportunity is not required */}
                          {notRelatedToAnOpportunity && (
                            <div className='mt-2 mb-2'>
                              <Alert message='Tickets associated with revenue opportunities will be handled with higher priority' type='warning' showIcon />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {/* DETAILS */}
                    {snowflakeOpportunitySupport && <div className='pb-4 mt-2'>{(createTicketForm.getFieldValue('acc_id') || createTicketForm.getFieldValue('acc_name')) && <StakeholderDetails ticket={currentTicket} onPropertiesChange={handleTicketPropertiesChange} noEdit={true} />}</div>}
                  </div>
                </PropertySection>
              </div>
            )}

            {/* GC DEPLOYMENT */}
            {gcDeploymentSupport && true === notEmptyArray(deploymentOptions) && (
              <Form.Item label='GC Deployment' name='dep_id'>
                <Select placeholder='Select a deployment...' onChange={handleOnDeploymentChange}>
                  {deploymentOptions?.map((item) => (
                    <Select.Option key={item.ORG_ID} value={item.ORG_ID}>
                      {item.ORG_NAME__C}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {/* CATEGORY */}
            <Form.Item label='Category' name='category' rules={[{ required: true, message: 'Select a category' }]}>
              <Select
                value={selectedCategory}
                showSearch={true}
                placeholder='Select a category...'
                onSelect={handleCategorySelect}
                disabled={!createTicketForm.getFieldValue('service_id')}
                style={{ width: '100%' }}
                optionRender={(option) => {
                  return (
                    <div className='flex flex-col'>
                      <div className='overflow-hidden'>{option.data.label}</div>
                      {option.data.description && (
                        <div className='text-xs font-light opacity-60' style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                          {option.data.description}
                        </div>
                      )}
                    </div>
                  );
                }}
                options={categoryList}
                autoComplete='off'
              />
            </Form.Item>
            {true === notEmptyArray(categoryUrlList) && (
              <div className='mb-6'>
                <PropertySection>
                  <CategoryUrls urlList={categoryUrlList} />
                </PropertySection>
              </div>
            )}

            {/* SUBCATEGORY */}
            {subCategoryList.length > 0 && (
              <Form.Item label='Sub Category' name='sub_category' rules={[{ required: true, message: 'Select a subcategory' }]}>
                <Select
                  value={selectedSubCategory}
                  showSearch={true}
                  placeholder='Select a sub category...'
                  onSelect={handleSubCategorySelect}
                  disabled={!createTicketForm.getFieldValue('category')}
                  style={{ width: '100%' }}
                  optionRender={(option) => {
                    return (
                      <div className='flex flex-col'>
                        <div className='overflow-hidden'>{option.data.label}</div>
                        {option.data.description && (
                          <div className='text-xs font-light opacity-60' style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                            {option.data.description}
                          </div>
                        )}
                      </div>
                    );
                  }}
                  options={subCategoryList}
                />
              </Form.Item>
            )}

            {/* ALERT FOR MISSING FILE */}
            {requiredFileIsMissing && <Alert className='-mt-2 mb-2 pt-1 pb-1' style={{ width: '100%' }} message='Do not forget to either attach a file or specify the URL in the description.' type='warning' showIcon />}

            {/* LEGAL SUPPORT PROPERTIES */}
            {true === isLegalSupportSelected(createTicketForm.getFieldValue('category')) && <TicketCreateLegalSupport serviceId={createTicketForm.getFieldValue('service_id')} onChange={handleLegalSupportDetailsOnChange} oppAcvUsd={opportunity?.OPPORTUNITY_GROSS_ACV_BOOKING_USD ?? 0} oppRegion={opportunity?.OPPORTUNITY_REGION ?? null} oppSegment={opportunity?.OPPORTUNITY_SALES_SEGMENT} />}

            {/* PRIORITY */}
            <Form.Item label='Priority' name='priority' rules={[{ required: true, message: 'Select a priority' }]}>
              <Select placeholder='Select a priority...' disabled={!createTicketForm.getFieldValue('service_id')}>
                {priorityList?.map((item) => (
                  <Select.Option key={item.priority} value={item.priority}>
                    {item.priority}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* TITLE */}
            <Form.Item
              label='Title'
              name='title'
              rules={[
                { required: true, message: 'Enter a title' },
                { min: 20, message: 'Enter at least 20 characters for the title' },
              ]}
            >
              <Input placeholder='Enter a title for this ticket (20 characters min.)...' maxLength={TITLE_MAX_LENGTH} showCount disabled={!createTicketForm.getFieldValue('service_id')} />
            </Form.Item>

            {/* DESCRIPTION */}
            <Form.Item label='Description' name='content' rules={[{ required: true, message: 'Enter a description' }]}>
              <Input.TextArea placeholder='What is your problem?' autoSize={{ minRows: 4, maxRows: 8 }} maxLength={CONTENT_MAX_LENGTH} showCount disabled={!createTicketForm.getFieldValue('service_id')} />
            </Form.Item>

            {/* COMPLETION DATES */}
            {showCompletionDates && <TicketCreateCompletionDates form={createTicketForm} />}

            {/* SHAREPOINT FILES */}
            {showSharepointFiles && (
              <PropertySection>
                <div className='my-2'>
                  <TicketCreateSharepointFiles onChange={handleSharepointFilesOnChange} />
                </div>
              </PropertySection>
            )}

            <Form.Item name='owner_id' hidden={true} />
            <Form.Item name='dep_name' hidden={true} />
            <Form.Item name='acc_id' hidden={true} rules={[{ required: notRelatedToAnOpportunity || !snowflakeOpportunitySupport, message: 'Select an account' }]} />
            <Form.Item name='acc_name' hidden={true} />
            <Form.Item name='opp_id' hidden={true} rules={[{ required: !notRelatedToAnOpportunity && snowflakeOpportunitySupport, message: 'Select an opportunity' }]} />
            <Form.Item name='opp_name' hidden={true} />
            <Form.Item name={['props', 'account_grouping']} hidden={true} />
            <Form.Item name={['props', 'account_name']} hidden={true} />
            <Form.Item name={['props', 'agents']} hidden={true} />
            <Form.Item name={['props', 'close_date']} hidden={true} />
            <Form.Item name={['props', 'created_date']} hidden={true} />
            <Form.Item name={['props', 'currency_iso_code']} hidden={true} />
            <Form.Item name={['props', 'deal_type']} hidden={true} />
            <Form.Item name={['props', 'dsr_url']} hidden={true} />
            <Form.Item name={['props', 'direct_indirect_sale']} hidden={true} />
            <Form.Item name={['props', 'gross_acv_booking']} hidden={true} />
            <Form.Item name={['props', 'gross_acv_booking_usd']} hidden={true} />
            <Form.Item name={['props', 'org_count']} hidden={true} />
            <Form.Item name={['props', 'owner_id']} hidden={true} />
            <Form.Item name={['props', 'owner_email']} hidden={true} />
            <Form.Item name={['props', 'owner_name']} hidden={true} />
            <Form.Item name={['props', 'product']} hidden={true} rules={[{ required: (createTicketForm.getFieldValue('acc_id') || createTicketForm.getFieldValue('opp_id')) && snowflakeOpportunitySupport, message: 'Select a product' }]} />
            <Form.Item name={['props', 'region']} hidden={true} rules={[{ required: (createTicketForm.getFieldValue('acc_id') || createTicketForm.getFieldValue('opp_id')) && snowflakeOpportunitySupport, message: 'Select a region' }]} />
            <Form.Item name={['props', 'sales_segment']} hidden={true} />
            <Form.Item name={['props', 'sc_id']} hidden={true} />
            <Form.Item name={['props', 'sc_email']} hidden={true} />
            <Form.Item name={['props', 'sc_name']} hidden={true} />
            <Form.Item name={['props', 'seats']} hidden={true} />
            <Form.Item name={['props', 'stage_name']} hidden={true} />
            <Form.Item name={['props', 'sub_region']} hidden={true} />
          </div>
        </div>
        <div className='mt-4'>
          {showErrors && (
            <div className='mb-4'>
              {errors.map((error, index) => (
                <Alert className='mb-2' message={error} type='error' showIcon />
              ))}
            </div>
          )}
          <Form.Item>
            <Space>
              <Button onClick={() => navigate('/teacket')}>Cancel</Button>
              <Button htmlType='reset'>Reset</Button>
              <Button
                type='primary'
                htmlType='submit'
                onClick={(e) => {
                  const hasUrl = isThereUrl();
                  const hasFiles = fileList?.length > 0;
                  if (requiredFileIsMissing && !hasFiles && !hasUrl) {
                    e.preventDefault();
                    setIsModalVisible(true);
                  }
                }}
              >
                Submit
              </Button>
            </Space>
            <Modal
              title='Confirmation'
              open={isModalVisible}
              onOk={() => {
                setIsModalVisible(false);
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
              icon={<FileExclamationOutlined />}
              onCancel={() => setIsModalVisible(false)}
            >
              <div className='flex flex-row gap-4 mt-4'>
                <div className='flex-initial'>
                  <FileExclamationOutlined style={{ fontSize: '36px', color: '#faad14' }} />
                </div>
                A required file or link is missing. Submit anyway?
              </div>
            </Modal>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

TicketCreateControl.propTypes = {};

export default TicketCreateControl;

import React, { useEffect, useContext } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { AiFillNotification } from 'react-icons/ai';
import Space2 from 'src/components/layout/Space2';
import { FaPlus } from 'react-icons/fa6';
import StyledButton from 'src/components/layout/StyledButton';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import useTheme from 'src/hooks/useTheme';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import BreachNotificationsFilter from './Components/BreachNotificationsFilter';
import BreachNotificationsSelector from './Components/BreachNotificationsSelector';
import BreachNotificationsEditor from './Components/BreachNotificationsEditor';
import PageLayoutType2 from 'src/components/layout/PageLayoutType2';
import PermissionsProvider from 'src/providers/PermissionsProvider';

const BreachNotifications = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { breachNotificationsSelectedId, setBreachNotificationsNewCreated } = useContext(MasterAdminContext);
  const { theme } = useTheme();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Breach Notifications',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const handleOnCreateMsg = () => {
    console.log('handleOnCreateMsg()');
    setBreachNotificationsNewCreated(true);
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      <TitleBar
        title={
          <Space2>
            <AiFillNotification />
            Breach Notifications
          </Space2>
        }
        afterTitleExtras={
          <StyledButton color={theme.textBase} onClick={handleOnCreateMsg} size='small'>
            <Space2 className='btn'>
              <FaPlus />
              <WhenDesktop>Create breach notification</WhenDesktop>
            </Space2>
          </StyledButton>
        }
      />
      <PageLayoutType2
        left={
          <DraggableLayoutItem title='List of Breach Notifications' titleExtras={<BreachNotificationsFilter />}>
            <BreachNotificationsSelector />
          </DraggableLayoutItem>
        }
        right={
          <DraggableLayoutItem title={breachNotificationsSelectedId ? 'Details' : null} titleExtras={<span className='text-xs font-light'>{breachNotificationsSelectedId}</span>}>
            <BreachNotificationsEditor />
          </DraggableLayoutItem>
        }
      />
    </PermissionsProvider>
  );
};

export default BreachNotifications;

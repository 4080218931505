import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getTenant, patchTenant } from 'src/api/tenants';
import { Button, Space, Form, Input, InputNumber, Tabs, message, Select } from 'antd';
import RolesEditor from '../../Components/RolesEditor';
import { LuBuilding, LuShieldCheck } from 'react-icons/lu';
import { uuid } from 'short-uuid';
import FeaturesAccessEditor from './FeaturesAccessEditor';
import { TbLockAccess } from 'react-icons/tb';
import NoData from 'src/components/layout/NoData';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import useDictionary from 'src/hooks/useDictionary';

const TenantEditor = ({ tenantId, onSave, initialTab = 'tenant' }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const [form] = Form.useForm();
  const tierList = useDictionary('tenant-tier');
  const [messageApi, contextHolder] = message.useMessage();

  const [tenant, setTenant] = useState(null);
  const [modified, setModified] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (!tenantId) {
      setTenant(null);
      return;
    }
    if (tenant?.id === tenantId) return;
    setTenant(null);
    form.setFieldsValue({});
    loadData();
  }, [tenantId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        setModified(false);
        const resp = await getTenant(tenantId);
        form.setFieldsValue(resp);
        setTenant(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnChange = (/*values*/) => {
    setModified(true);
  };

  const handleOnFinish = (values) => {
    console.log('Success:', values);
    executeAsyncProcess(async () => {
      try {
        const resp = await patchTenant(tenantId, { name: values.name, users_limit: values.users_limit, tier: values.tier ?? null });
        form.setFieldsValue(resp);
        if (onSave) onSave(resp);
        setModified(false);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Data saved',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    messageApi.open({
      key: uuid(),
      type: 'error',
      content: 'Provided data is invalid',
      duration: 3,
    });
  };

  const handleOnReset = () => {
    form.resetFields();
    setModified(false);
  };

  return (
    <DraggableLayoutItem
      title={
        tenant ? (
          <Space className='whitespace-nowrap btn'>
            <LuBuilding className='opacity-60' />
            <div className='overflow-hidden overflow-ellipsis'>{tenant?.name ?? 'unknown'}</div>
          </Space>
        ) : null
      }
      titleExtras={
        tenant ? (
          <Button type='default' size='small' onClick={() => window.open(`/master-admin/users?tenantId=${tenant?.id}`)}>
            Show users
          </Button>
        ) : null
      }
    >
      {contextHolder}
      {!tenant ? (
        <NoData />
      ) : (
        <Tabs
          onChange={(key) => setActiveTab(key)}
          size='small'
          type='card'
          defaultActiveKey={initialTab}
          items={[
            {
              key: 'tenant',
              label: (
                <Space className='btn'>
                  <LuBuilding />
                  Tenant
                </Space>
              ),
              children: (
                <div>
                  <Form form={form} name='tenantEditor' style={{ maxWidth: 600 }} layout='vertical' initialValues={tenant} onChange={handleOnChange} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} autoComplete='off'>
                    <Form.Item label='Id' name='id'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Name' name='name' rules={[{ required: true, message: 'The tenant name is required' }]}>
                      <Input disabled={tenant.third_party_provider} />
                    </Form.Item>
                    <Form.Item
                      label={
                        <Space>
                          <span>Max number of users</span>
                          <span className='font-light opacity-60 text-xs'>(set 0 for unlimted)</span>
                        </Space>
                      }
                      name='users_limit'
                      rules={[{ required: true, message: 'The tenant name is required' }]}
                    >
                      {/* need to handle on change on InputNumber as the form handler do not detect up/down btns */}
                      <InputNumber onChange={handleOnChange} min={0} max={999} />
                    </Form.Item>
                    <Form.Item label='Access Tier' name='tier'>
                      <Select
                        disabled={!tenant.third_party_provider} // not available for cognito
                        allowClear
                        placeholder='Select a tier...'
                        options={tierList?.map((x) => {
                          return { value: x.valueStr, label: x.valueStr };
                        })}
                        optionRender={(option) => {
                          const item = tierList.find((x) => x.valueStr === option.value);
                          return <div>{item?.valueStr ?? 'error'}</div>;
                        }}
                        // onChange={(value) => form.setFieldValue('tier', value)}
                        onChange={handleOnChange}
                      />
                    </Form.Item>
                    <Form.Item label='Account type' name='acc_type'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='3rd party provider' name='third_party_provider'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='3rd party id' name='third_party_id'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='3rd party env' name='third_party_env'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Created at' name='created_at'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Updated at' name='updated_at'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item>
                      <Space>
                        <Button htmlType='reset' disabled={!modified}>
                          Reset
                        </Button>
                        <Button type='primary' htmlType='submit' disabled={!modified}>
                          Submit
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
              ),
            },

            {
              key: 'roles',
              label: (
                <Space className='btn'>
                  <LuShieldCheck />
                  Roles
                </Space>
              ),
              children: 'roles' === activeTab && <RolesEditor objectType='tenant' id={tenantId} />,
            },
            {
              key: 'features-access',
              label: (
                <Space className='btn'>
                  <TbLockAccess />
                  Features Access
                </Space>
              ),
              children: <FeaturesAccessEditor tenantId={tenantId} tenantName={tenant?.name} />,
            },
          ]}
        />
      )}
    </DraggableLayoutItem>
  );
};

TenantEditor.propTypes = {
  tenantId: PropTypes.string,
  initialTab: PropTypes.string,
  onSave: PropTypes.func,
};

export default TenantEditor;

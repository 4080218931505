import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from '../Misc/misc';
import { MdDashboard } from 'react-icons/md';
import { Space, Card, Button } from 'antd';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';
import { notArrayOrEmpty } from 'src/misc/Misc';
import PageLayoutType1 from 'src/components/layout/PageLayoutType1';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getUsersSignedInNow } from 'src/api/users';
import CondensedTable from 'src/components/layout/CondensedTable';
import { uuid } from 'short-uuid';
import dayjs from 'dayjs';
import Na from 'src/components/layout/Na';
import { LuPlug, LuBuilding, LuUsers2 } from 'react-icons/lu';
import CountUp from 'react-countup';
import useTheme from 'src/hooks/useTheme';
import { ReloadOutlined } from '@ant-design/icons';
import Space2 from 'src/components/layout/Space2';

//import PropTypes from 'prop-types';

const FeaturesAccess = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();

  const columns = [
    {
      title: 'User',
      key: 'user',
      render: (record) => (
        <>
          <div style={{ lineHeight: '1rem' }}>{record.user_name}</div>
          <div className='font-light opacity-40' style={{ fontSize: '0.6rem', lineHeight: '0.8rem' }}>
            {record.user_id}
          </div>
        </>
      ),
    },
    {
      title: 'Tenant',
      key: 'tenant',
      render: (record) => (
        <>
          <div style={{ lineHeight: '1rem' }}>{record.tenant_name}</div>
          <div className='font-light opacity-40' style={{ fontSize: '0.6rem', lineHeight: '0.8rem' }}>
            {record.tenant_id}
          </div>
        </>
      ),
    },
    {
      title: 'Connected at',
      key: 'connected_at',
      align: 'center',
      render: (record) =>
        record.connected_at ? (
          <>
            <div style={{ lineHeight: '1rem' }}>{dayjs(record.connected_at).fromNow()}</div>
            <div className='font-light opacity-40' style={{ fontSize: '0.6rem', lineHeight: '0.8rem' }}>
              {dayjs(record.connected_at).format('LLL')}
            </div>
          </>
        ) : (
          <Na />
        ),
    },
    {
      title: 'Last activity at',
      key: 'last_activity_at',
      align: 'center',
      render: (record) =>
        record.last_activity ? (
          <>
            <div style={{ lineHeight: '1rem' }}>{dayjs(record.last_activity).fromNow()}</div>
            <div className='font-light opacity-40' style={{ fontSize: '0.6rem', lineHeight: '0.8rem' }}>
              {dayjs(record.last_activity).format('LLL')}
            </div>
          </>
        ) : (
          <Na />
        ),
    },
  ];

  const [entities, setEntities] = useState(null);

  useEffect(() => {
    loadData();

    setBreadcrumb([
      {
        title: 'Dashboards',
        path: '/dashboards',
      },
      {
        title: 'Who is signed in now?',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getUsersSignedInNow();
        setEntities(resp);
        //setEntities(resp.concat(resp).concat(resp).concat(resp).concat(resp).concat(resp).concat(resp).concat(resp).concat(resp).concat(resp));
      } catch (error) {
        console.error(error);
      }
    });
  };

  const getConnectionsCount = () => {
    if (true === notArrayOrEmpty(entities)) return 0;
    return entities.length;
  };

  const getUsersCount = () => {
    if (true === notArrayOrEmpty(entities)) return 0;
    return entities.map((x) => x.user_id).filter((x, i, a) => a.indexOf(x) === i).length;
  };

  const getTenantsCount = () => {
    if (true === notArrayOrEmpty(entities)) return 0;
    return entities.map((x) => x.tenant_id).filter((x, i, a) => a.indexOf(x) === i).length;
  };

  return (
    <PermissionsProvider requiredPermissions={[MASTER_ADMIN_PERMISSION]}>
      <TitleBar
        title={
          <Space className='btn'>
            <MdDashboard />
            Dashboards
          </Space>
        }
        afterTitleExtras={<PageSwitch pageList={pageList} />}
      />
      <PageLayoutType1
        left={
          <DraggableLayoutItem title='Stats'>
            <div className='flex flex-row gap-4 flex-wrap justify-center'>
              <Card
                size='small'
                title={
                  <Space className='btn' style={{ color: theme.textBase }}>
                    <LuPlug />
                    Connections
                  </Space>
                }
                className='w-36 text-center'
              >
                <div className='text-center text-4xl'>
                  <CountUp end={getConnectionsCount()} separator=',' duration={3} />
                </div>
              </Card>
              <Card
                size='small'
                title={
                  <Space className='btn' style={{ color: theme.textBase }}>
                    <LuUsers2 />
                    Users
                  </Space>
                }
                className='w-36 text-center'
              >
                <div className='text-center text-4xl'>
                  <CountUp end={getUsersCount()} separator=',' duration={3} />
                </div>
              </Card>
              <Card
                size='small'
                title={
                  <Space className='btn' style={{ color: theme.textBase }}>
                    <LuBuilding />
                    Tenants
                  </Space>
                }
                className='w-36 text-center'
              >
                <div className='text-center text-4xl'>
                  <CountUp end={getTenantsCount()} separator=',' duration={3} />
                </div>
              </Card>
            </div>
          </DraggableLayoutItem>
        }
        right={
          <DraggableLayoutItem
            title={
              <>
                Connections <span className='text-xs font-light opacity-60'> (based on web-socket sessions)</span>
              </>
            }
            titleExtras={
              <Button size='small' onClick={loadData}>
                <Space2>
                  <ReloadOutlined />
                  <div>Refresh</div>
                </Space2>
              </Button>
            }
          >
            <CondensedTable
              columns={columns}
              dataSource={entities}
              rowKey={() => uuid()}
              pagination={{
                size: 'small',
                pageSize: 50,
                showSizeChanger: false,
              }}
            />
          </DraggableLayoutItem>
        }
      />
    </PermissionsProvider>
  );
};

// FeaturesAccess.propTypes = {};

export default FeaturesAccess;

import React from 'react';
import PropTypes from 'prop-types';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { Popover } from 'antd';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .ant-popover-inner {
    padding: 4px !important;
  }
`;

const FieldLabel = ({ children, label, help }) => {
  return (
    <div className='flex flex-col gap-1'>
      <div className='flex flex-row justify-between'>
        <div className='text-xs font-semibold opacity-80 overflow-hidden'>{label}</div>
        <div className='flex flex-row items-center justify-end'>
          {help ? (
            <StyledDiv>
              <Popover title={label} content={help} placement='leftTop' trigger={'click'} style={{ margin: '10px', padding: '0px' }} className='hover:scale-110 cursor-pointer  opacity-80'>
                <IoMdInformationCircleOutline />
              </Popover>
            </StyledDiv>
          ) : null}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

FieldLabel.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  help: PropTypes.any,
};

export default FieldLabel;

import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';

//#region "Permissions"

var controllerSearchPermissions;
export const searchPermissions = async (query, page = 1, pageSize = 24) => {
  controllerSearchPermissions?.abort('cancelled');
  controllerSearchPermissions = new AbortController();
  const signal = controllerSearchPermissions.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize });

  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/permissions${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const postPermission = async (body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/permissions`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchPermission = async (permission, body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/permissions/${encodeURIComponent(permission)}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const deletePermission = async (permission) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/permissions/${encodeURIComponent(permission)}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "Tenant Roles"

export const getTenantRoles = async (body) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/tenant-roles`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );

//#endregion

//#region "Roles"

var controllerSearchRoles;
export const searchRoles = async (query, page = 1, pageSize = 24) => {
  controllerSearchRoles?.abort('cancelled');
  controllerSearchRoles = new AbortController();
  const signal = controllerSearchRoles.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize });

  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/roles${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const postRole = async (body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/roles`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchRole = async (role, body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/roles/${encodeURIComponent(role)}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const deleteRole = async (role) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/roles/${encodeURIComponent(role)}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "RolePermissions"

export const getRolePermissions = async (role) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/role-permissions/${encodeURIComponent(role)}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postRolePermission = async (role, permission) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/role-permissions`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ role, permission }),
    })
  );
};

export const deleteRolePermission = async (role, permission) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/role-permissions/${encodeURIComponent(role)}/${encodeURIComponent(permission)}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "RoleAssignments"

export const getRoleAssignments = async (query) => {
  const queryStringParams = objToQueryStringParams(query);
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/role-assignments${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postRoleAssignment = async (body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/role-assignments`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const deleteRoleAssignment = async (assignmentId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/role-assignments/${encodeURIComponent(assignmentId)}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const deleteUserRoleAssignments = async (userId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/role-assignments/users/${encodeURIComponent(userId)}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const patchRoleAssignmentProps = async (assignmentId, changes) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/role-assignments/${encodeURIComponent(assignmentId)}/props`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

//#endregion

//#region "DeterminePermissions"

export const getDeterminePermissions = async (userId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/determine-permissions/${userId}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "Admin Stats"

export const getAdminStats = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/admin-stats`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "Invitation Links"

var controllerInvitationLinks;
export const getInvitationLinks = async (filter, query, page, pageSize) => {
  controllerInvitationLinks?.abort('cancelled');
  controllerInvitationLinks = new AbortController();
  const signal = controllerInvitationLinks.signal;
  const queryStringParams = objToQueryStringParams({ filter, query, page, pageSize });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/invitation-links${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const postInvitationLinks = async (body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/invitation-links`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchResendInvitationLink = async (id, body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/invitation-links/${encodeURIComponent(id)}/resend`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchInvalidateInvitationLink = async (id) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/invitation-links/${encodeURIComponent(id)}/invalidate`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getInvitationLink = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/invitation-links/${encodeURIComponent(id)}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "FEATURES"

export const getFeatureList = async (tenantId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/features`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "FEATURES ACCESS - USER"

export const getFeaturesAccess = async (feature) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/features-access/${encodeURIComponent(feature)}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    }),
    true,
    false
  );
};

export const postFeaturesAccessFreeTrialRequest = async (feature) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/features-access/${encodeURIComponent(feature)}`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "FEATURES ACCESS - ADMIN"

export const getAdminFeaturesAccessList = async (pageSize = 10, page = 1, tenant_id = null, access_type = null, feature = null) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/features-access/admin/${objToQueryStringParams({ pageSize, page, tenant_id, access_type, feature })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getAdminFeaturesAccessListForTenant = async (tenantId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/features-access/admin/${tenantId}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postAdminFeaturesAccess = async (body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/features-access/admin`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchAdminFeaturesAccess = async (tenantId, feature, body) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/features-access/admin/${tenantId}/${feature}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

//#endregion

//#region "REPORTS"

export const getReportsOrphaned = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/reports/orphaned`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getReportsPermissions = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/reports/permissions`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getReportsPermissionsRoles = async (permission) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/reports/permissions/${encodeURIComponent(permission)}/roles`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getReportsRolesAssignments = async (role) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/accesscontrol/reports/roles/${encodeURIComponent(role)}/assignments`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

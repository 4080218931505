import React, { useContext } from 'react';
import DataNexusContext from 'src/context/DataNexusContext';
import FieldLabel from 'src/components/layout/FieldLabel';
import PickerRegions from '../../Controls/PickerRegions';
import PickerFeatures from '../../Controls/PickerFeatures';
import PickerNames from '../../Controls/PickerNames';
import PickerTypes from '../../Controls/PickerTypes';
import { Input } from 'antd';
import { notEmptyArray, notEmptyString } from 'src/misc/Misc';
import { SearchOutlined } from '@ant-design/icons';
//import PropTypes from 'prop-types';

const ServiceHostingFilters = () => {
  const { adminServiceHostingFilters, setAdminServiceHostingFilters } = useContext(DataNexusContext);

  const handleRegionsOnPick = (v) => {
    console.log('handleRegionsOnPick', v);
    setAdminServiceHostingFilters('regions', true === notEmptyArray(v) ? v : null);
  };

  const handleUseOtherRegionsOnPick = (v) => {
    console.log('handleUseOtherRegionsOnPick', v);
    setAdminServiceHostingFilters('useOtherRegions', true === notEmptyArray(v) ? v : null);
  };

  const handleFeaturesOnPick = (v) => {
    console.log('handleFeaturesOnPick', v);
    setAdminServiceHostingFilters('features', true === notEmptyArray(v) ? v : null);
  };

  const handleTypesOnPick = (v) => {
    console.log('handleTypesOnPick', v);
    setAdminServiceHostingFilters('types', true === notEmptyArray(v) ? v : null);
  };

  const handleNamesOnPick = (v) => {
    console.log('handleNamesOnPick', v);
    setAdminServiceHostingFilters('names', true === notEmptyArray(v) ? v : null);
  };

  const handleQueryOnChange = (e) => {
    console.log('handleQueryOnChange', e.target.value);
    setAdminServiceHostingFilters('query', true === notEmptyString(e.target.value) ? e.target.value : null);
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-4 2xl:grid-cols-6 gap-4'>
      <FieldLabel label='Hosting Region'>
        <PickerRegions initialValue={adminServiceHostingFilters?.regions} onPick={handleRegionsOnPick} mode='multiple' allowClear={true} placeholder='[All regions]' />
      </FieldLabel>
      <FieldLabel label='Feature'>
        <PickerFeatures initialValue={adminServiceHostingFilters?.features} onPick={handleFeaturesOnPick} mode='multiple' allowClear={true} placeholder='[All features]' />
      </FieldLabel>
      <FieldLabel label='Type'>
        <PickerTypes initialValue={adminServiceHostingFilters?.types} onPick={handleTypesOnPick} mode='multiple' allowClear={true} placeholder='[All types]' />
      </FieldLabel>
      <FieldLabel label='Name'>
        <PickerNames initialValue={adminServiceHostingFilters?.names} onPick={handleNamesOnPick} mode='multiple' allowClear={true} placeholder='[All names]' />
      </FieldLabel>
      <FieldLabel label='AWS Region'>
        <PickerRegions initialValue={adminServiceHostingFilters?.useOtherRegions} onPick={handleUseOtherRegionsOnPick} mode='multiple' allowClear={true} placeholder='[All regions]' />
      </FieldLabel>
      <FieldLabel
        label='Search'
        help={
          <ul>
            <li>region</li>
            <li>use_other_region</li>
            <li>feature</li>
            <li>type</li>
            <li>name</li>
            <li>third_party_region</li>
            <li>comments</li>
          </ul>
        }>
        <Input placeholder='type to search...' value={adminServiceHostingFilters?.query ?? ''} onChange={handleQueryOnChange} allowClear prefix={<SearchOutlined className='mr-2' />} />
      </FieldLabel>
    </div>
  );
};

ServiceHostingFilters.propTypes = {};

export default ServiceHostingFilters;

import React from 'react';
import useUserInfo from 'src/hooks/useUserInfo';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';

import DownloadStats from './Components/DownloadStats';

const Downloads = () => {
  const { permissions: userPermissions } = useUserInfo();

  const componentsGallery = [
    {
      id: 'Downloads',
      component: <DownloadStats />,
      permissions: ['apps.dashboards.downloads.view'],
    },
  ].filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions));

  return (
    <>
      {componentsGallery.map((item) => (
        <React.Fragment key={item.id}>{item.component}</React.Fragment>
      ))}
    </>
  );
};

export default Downloads;

import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Pagination } from 'antd';
import { notArrayOrEmpty } from 'src/misc/Misc';

//import PropTypes from 'prop-types';

const PaginationComponent = () => {
  const { myTickets, myTicketsPageSize, myTicketsCurrentPage, setMyTicketsCurrentPage, myTicketsTotal } = useContext(TeacketContext);

  const handlePaginationOnChange = (page) => {
    setMyTicketsCurrentPage(page);
  };

  if (notArrayOrEmpty(myTickets)) return null;

  return <Pagination current={myTicketsCurrentPage} pageSize={myTicketsPageSize} total={myTicketsTotal} showSizeChanger={false} showLessItems={true} size='small' onChange={handlePaginationOnChange} />;
};

PaginationComponent.propTypes = {};

export default PaginationComponent;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from 'react-interval-hook';
import dayjs from 'dayjs';

const SecondsCountDown = ({ timeoutAt, onTimeout }) => {
  const [initialized, setInitialized] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(null);

  useEffect(() => {
    if (!initialized) return;
    if (!timeoutAt) return;
    if (!onTimeout) return;
    if (secondsLeft > 0) return;

    //console.log('aaaaa SecondsCountDown: onTimeout', secondsLeft);
    onTimeout();
  }, [secondsLeft]);

  useEffect(() => {
    calcSecondsLeft();
  }, [timeoutAt]);

  useInterval(() => {
    calcSecondsLeft();
  }, 200);

  const calcSecondsLeft = () => {
    let sl = dayjs(timeoutAt).diff(dayjs(), 'seconds');
    if (sl < 0) sl = 0;
    if (sl === secondsLeft) return;
    setSecondsLeft(sl);
    setInitialized(true);
  };

  return <>{secondsLeft}</>;
};

SecondsCountDown.propTypes = {
  timeoutAt: PropTypes.object.isRequired,
  onTimeout: PropTypes.func,
};

export default SecondsCountDown;

import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { message, Space } from 'antd';
import { GiEntryDoor } from 'react-icons/gi';
import { FaPlus } from 'react-icons/fa';
import StyledButton from 'src/components/layout/StyledButton';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import useTheme from 'src/hooks/useTheme';
import InvitationLinkSelector from './Components/InvitationLinkSelector';
import InvitationLinkCreateNew from './Components/InvitationLinkCreateNew';
import { invertColor, notEmptyArray } from 'src/misc/Misc';
import { gcRegionList } from 'src/misc/Config';
import dayjs from 'dayjs';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postInvitationLinks } from 'src/api/accesscontrol';
import InvitationLinkDetails from './Components/InvitationLinkDetails';
import { uuid } from 'short-uuid';
import PageLayoutType3 from 'src/components/layout/PageLayoutType3';
import { EXPIRATION_HOURS } from './misc';
import queryString from 'query-string';
import LoaderTenantRoles from './Components/LoaderTenantRoles';

//import PropTypes from 'prop-types';

const InvitationLinks = () => {
  const invite = queryString.parse(window.location.search)?.invite === 'true';

  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const [newInvitationOpen, setNewInvitationOpen] = useState(false);
  const [selectedInvitationId, setSelectedInvitationId] = useState(null);
  const [reload, setReload] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);

  useEffect(() => {
    if (forceRerender) setForceRerender(false);
  }, [forceRerender]);

  useEffect(() => {
    if (invite) setNewInvitationOpen(true);

    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Invitation Links',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const handleWizardOnAbort = () => setNewInvitationOpen(false);

  const handleWizardOnFinish = async (e) => {
    console.log('handleWizardOnFinish()', e);

    setNewInvitationOpen(false);
    setSelectedInvitationId(null);

    let request = [];

    // <STEP 1 - prepare request>
    try {
      if (true === notEmptyArray(e?.emails)) {
        for (const email of e.emails) {
          const env = gcRegionList.find((x) => x.region === e.deployment.ORG_REGION).baseUrl;
          if (!env) throw new Error('Invalid region: ', e.deployment.ORG_REGION);
          request.push({
            tenant_id: e.deployment.ORG_ID,
            tenant_name: e.deployment.ORG_NAME,
            env,
            email,
            roles: e.roles?.map((x) => x.role) ?? [],
            expires_at: dayjs().add(EXPIRATION_HOURS, 'hour').utc().toISOString(),
          });
        }
      }
    } catch (error) {
      console.error(error);
      message.error('Failed to create invitation link', 5);
    }
    // </STEP 1 - prepare request>

    // <STEP 2 - send request>
    await executeAsyncProcess(async () => {
      try {
        const resp = await postInvitationLinks(request);
        console.log('postInvitationLinks()', resp);
        messageApi.open({
          key: uuid(),
          type: resp?.failures > 0 ? 'error' : 'success',
          content: (
            <div className='text-right'>
              <div>
                <span className='font-thin'>attempts:</span> {resp.attempts ?? 'n/a'}
              </div>
              <div>
                <span className='font-thin'>successes:</span> {resp.successes ?? 'n/a'}
              </div>
              <div>
                <span className='font-thin'>failures:</span> {resp.failures ?? 'n/a'}
              </div>
            </div>
          ),
          duration: 10,
        });
      } catch (error) {
        console.error(error);
      }
    });
    setReload(!reload);
    // </STEP 2 - send request>
  };

  const handleDetailsOnResend = () => setForceRerender(true);

  const handleDetailsOnInvalidate = () => setForceRerender(true);

  return (
    <PermissionsProvider requiredPermissions={['admin.master', 'admin.tenant']}>
      <LoaderTenantRoles>
        {contextHolder}
        <>
          {newInvitationOpen && (
            <div className='fixed top-0 right-0 bottom-0 left-0' style={{ background: invertColor(theme.backgroundBase) + '55', zIndex: 100 }}>
              <InvitationLinkCreateNew onAbort={handleWizardOnAbort} onFinish={handleWizardOnFinish} />
            </div>
          )}
        </>
        <TitleBar
          title={
            <Space className='btn'>
              <GiEntryDoor />
              Invitation Links
            </Space>
          }
          afterTitleExtras={
            <>
              <StyledButton color={theme.textBase} onClick={() => setNewInvitationOpen(true)} size='small'>
                <Space className='btn'>
                  <FaPlus />
                  <WhenDesktop>Create invitation</WhenDesktop>
                </Space>
              </StyledButton>
            </>
          }
        />
        <PageLayoutType3
          left={
            <InvitationLinkSelector
              reload={reload}
              onSelect={(id) => {
                setSelectedInvitationId(id);
              }}
              selected={selectedInvitationId}
              onResend={handleDetailsOnResend}
              onInvalidate={handleDetailsOnInvalidate}
            />
          }
          right={<>{!forceRerender && <InvitationLinkDetails selectedInvitationId={selectedInvitationId} />}</>}
        />
      </LoaderTenantRoles>
    </PermissionsProvider>
  );
};

// InvitationLinks.propTypes = {};

export default InvitationLinks;

import React, { useContext, useEffect, useState } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getBreachNotificationsHistory, postBreachNotificationSend } from 'src/api/notifications';
import CondensedTableLegacy from 'src/components/layout/CondensedTableLegacy';
import dayjs from 'dayjs';
import Na from 'src/components/layout/Na';
import { Button, Space, message as messageAntd } from 'antd';
import { uuid } from 'short-uuid';

const BreachNotificationsEditorPost = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { breachNotificationsSelectedId } = useContext(MasterAdminContext);
  const [messageApi, contextHolder] = messageAntd.useMessage();

  const [history, setHistory] = useState(null);

  const columns = [
    {
      title: 'Posted at',
      key: 'sentAt',
      width: '200px',
      align: 'center',
      render: (record) => {
        const { sent_at } = record;
        if (!sent_at) return <Na />;
        return (
          <>
            <div>{dayjs(sent_at).fromNow()}</div>
            <div className='text-xs font-light opacity-60'>{dayjs(sent_at).format('lll')}</div>
          </>
        );
      },
    },
    {
      title: 'Cancelled at',
      key: 'cancelledAt',
      width: '200px',
      align: 'center',
      render: (record) => {
        const { cancelled_at } = record;
        if (!cancelled_at) return <Na />;
        return (
          <>
            <div>{dayjs(cancelled_at).fromNow()}</div>
            <div className='text-xs font-light opacity-60'>{dayjs(cancelled_at).format('lll')}</div>
          </>
        );
      },
    },
    {
      title: '',
      key: 'placeholder',
      render: () => {
        return <></>;
      },
    },
  ];

  useEffect(() => {
    if (!breachNotificationsSelectedId) {
      setHistory(null);
      return;
    }
    loadData();
  }, [breachNotificationsSelectedId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getBreachNotificationsHistory(breachNotificationsSelectedId);
        console.log('resp', resp?.entities);
        setHistory(resp?.entities ?? null);
      } catch (error) {
        console.error('error', error);
      }
    });
  };

  const handleSend = async () => {
    executeAsyncProcess(async () => {
      try {
        const key = uuid();
        messageApi.open({
          key,
          type: 'loading',
          content: 'Posting...',
          duration: 0,
        });
        await postBreachNotificationSend(breachNotificationsSelectedId);
        await loadData();
        messageApi.open({
          key,
          type: 'success',
          content: 'Message posted',
          duration: 5,
        });
      } catch (error) {
        console.error('error', error);
      }
    });
  };

  return (
    <div className='flex flex-col gap-2'>
      {contextHolder}
      <Space className=' justify-end'>
        <Button size='small' onClick={loadData}>
          Refresh
        </Button>
        <Button size='small' type='primary' onClick={handleSend}>
          Post the message
        </Button>
      </Space>
      <CondensedTableLegacy
        columns={columns}
        dataSource={history}
        pagination={{
          pageSize: 20,
          size: 'small',
        }}
        rowKey='id'
      />
    </div>
  );
};

BreachNotificationsEditorPost.propTypes = {};

export default BreachNotificationsEditorPost;

import React, { useContext } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';

import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//import PropTypes from 'prop-types';

const BreachNotificationsFilter = () => {
  const { adminMessagesQuery, setAdminMessagesQuery } = useContext(MasterAdminContext);
  return (
    <Input
      size='small'
      autoComplete='off'
      className='w-36'
      placeholder='Search...'
      prefix={<SearchOutlined className='mr-2' />}
      allowClear
      value={adminMessagesQuery}
      onChange={(e) => {
        setAdminMessagesQuery(e.target.value);
      }}
    />
  );
};

BreachNotificationsFilter.propTypes = {};

export default BreachNotificationsFilter;

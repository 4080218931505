import React, { useEffect, useState } from 'react';
// import GlobalContext from 'src/context/GlobalContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Space } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from '../Misc/misc';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import DashboardFilters from '../Dashboard/components/DashboardFilters/DashboardFilters';
import DashboardData from './DashboardData';
// import { BsCommand } from 'react-icons/bs';

//import PropTypes from 'prop-types';

const DashboardTimeTracking = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  // const { commandPressed } = useContext(GlobalContext);

  const [selectedFilters, setSelectedFilters] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Dashboards',
      },
      {
        title: 'Time Tracking',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const handleDashboardFiltersOnChange = (e) => {
    let sf = { ...(selectedFilters ? { ...selectedFilters } : {}), ...e };
    setSelectedFilters(sf);
  };

  const handleOnSubmit = (startDate, endDate, services, regions, subRegions, quarters, acvs, platforms, forecasts, stages, types, ticketTypes, statuses, categories, subCategories, marketSegments, accountGroupings, accounts, opportunities, requesters, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers, departments, wrapUpCodes, directIndirects, customerTypes, regionalCareOffers, includeOpportunityFlag, selectedPeriod, periodType) => {
    console.log('Handle on submit:', startDate, endDate, services, regions, subRegions, quarters, acvs, platforms, forecasts, stages, types, ticketTypes, statuses, categories, subCategories, marketSegments, accountGroupings, accounts, opportunities, requesters, projectManagers, primaryTeamMembers, subjectMatterExperts, teamMembers, departments, wrapUpCodes, directIndirects, customerTypes, regionalCareOffers, includeOpportunityFlag, selectedPeriod, periodType);
    setSelectedFilters({
      startDate,
      endDate,
      services,
      regions,
      subRegions,
      quarters,
      acvs,
      platforms,
      forecasts,
      stages,
      types,
      ticketTypes,
      statuses,
      categories,
      subCategories,
      marketSegments,
      accountGroupings,
      accounts,
      opportunities,
      requesters,
      projectManagers,
      primaryTeamMembers,
      subjectMatterExperts,
      teamMembers,
      departments,
      wrapUpCodes,
      directIndirects,
      customerTypes,
      regionalCareOffers,
      includeOpportunityFlag,
      selectedPeriod,
      periodType,
    });
  };

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.dashboards.timetracking.view']}>
      <LoaderServicesForTenant>
        <TitleBar
          title={
            <Space className='btn'>
              <BsCupHotFill />
              <div>
                <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
              </div>
            </Space>
          }
          afterTitleExtras={
            <div>
              <PageSwitch pageList={pageList} />
            </div>
          }
          bottomExtras={<DashboardFilters id='time-tracking-dashboard' collapsible={false} onSubmit={handleOnSubmit} onChange={handleDashboardFiltersOnChange} allowSave servicesMode='single' overwriteOnSubmit={true} includePeriod includeDateRange={false} includeProjectManagers includePrimaryTeamMembers includeSubjectMatterExperts includeTeamMembers />}
          defaultExpanded={true}>
          {/* <Tooltip
            title={
              <>
                Press the <BsCommand /> CMD key to see more
              </>
            }
            placement='topRight'
          >
            <BsCommand className={`${commandPressed ? 'text-genesys-orange-base' : null} opacity-80 hover:opacity-100`} />
          </Tooltip> */}
        </TitleBar>
        <div className='p-4 flex flex-col gap-4 w-full lg:w-[50%]'>
          <DashboardData selectedFilters={selectedFilters} />
        </div>
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// DashboardTimeTracking.propTypes = {};

export default DashboardTimeTracking;

import React, { useContext, useState, useRef, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Button, Input, List, Tooltip, Modal, Progress, Popover, Dropdown, Tag, message, Switch } from 'antd';
import { DeleteOutlined, PlusOutlined, EditOutlined, CheckOutlined, CloseOutlined, LinkOutlined, WarningOutlined } from '@ant-design/icons';
import { uuid } from 'short-uuid';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { patchTicketProperties } from 'src/api/teacket';
import { IoPersonAdd } from 'react-icons/io5';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import useUserInfo from 'src/hooks/useUserInfo';
import { hasSupervisorRights } from '../Misc/misc';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities'; // Test Log
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import PropTypes from 'prop-types';

const SortableItem = ({ id, children, dragHandle, canDrag }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={{ ...style, display: 'flex', alignItems: 'center' }} {...(canDrag ? attributes : {})}>
      {/* Only show drag handle and apply listeners if user can drag */}
      {canDrag && (
        <div {...listeners} style={{ cursor: 'grab', marginRight: '8px', display: 'flex', alignItems: 'center' }}>
          {dragHandle}
        </div>
      )}
      <div style={{ flexGrow: 1 }}>{children}</div>
    </div>
  );
};

// Update PropTypes validation
SortableItem.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  dragHandle: PropTypes.node.isRequired,
  canDrag: PropTypes.bool,
};

const TicketCheckItems = () => {
  const { currentTicket, setCurrentTicket, currentTicketMembers } = useContext(TeacketContext);

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(null);

  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [editingItemId, setEditingItemId] = useState(null);
  const [editText, setEditText] = useState('');
  const [urlEditingItemId, setUrlEditingItemId] = useState(null);
  const [urlInput, setUrlInput] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [progressPopoverOpen, setProgressPopoverOpen] = useState(null); // Track which item has open progress popover
  const { executeAsyncProcess } = useAsyncProcesses();
  const [score, setScore] = useState(0);
  const { id: userId, permissions: userPermissions } = useUserInfo();
  const [messageApi, contextHolder] = message.useMessage();
  const [editMode, setEditMode] = useState(false);

  // ResizeObserver to track container width
  useEffect(() => {
    try {
      // Load existing checklist items from ticket properties
      if (currentTicket?.props?.checklist) {
        setItems(currentTicket.props.checklist);
      }

      if (!containerRef.current) return;

      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0]) {
          setContainerWidth(entries[0].contentRect.width);
        }
      });

      resizeObserver.observe(containerRef.current);

      return () => resizeObserver.disconnect();
    } catch (error) {
      console.error('Error in TicketCheckItems.js:', error);
    }
  }, []);

  const calculateProgress = (checklist) => {
    if (!checklist) return 0;
    return Math.round(checklist.reduce((acc, item) => acc + item.progress, 0) / checklist.length);
  };

  useEffect(() => {
    if (!currentTicket?.props?.checklist) return;
    // const items = currentTicket?.props?.checklist;
    // if (!items) setScore(0);

    // const totalProgress = Math.round(items.reduce((acc, item) => acc + item.progress, 0) / items.length);

    // setScore(totalProgress);
    setScore(calculateProgress(currentTicket?.props?.checklist));
  }, [currentTicket?.props?.checklist]);

  // Determine if we should show compact progress (just text) based on container width
  const showCompactProgress = containerWidth && containerWidth < 300;

  const handleUpdateChecklist = async (properties) => {
    executeAsyncProcess(async () => {
      try {
        console.log('[Checklist] Updated properties', JSON.stringify(properties, null, 2));
        const iProgress = calculateProgress(properties.checklist);
        properties.checklist_progress = iProgress;

        if (currentTicket) {
          const resp = await patchTicketProperties(currentTicket.id, properties);
          setCurrentTicket({ ...currentTicket, props: { ...currentTicket.props, checklist: resp?.props?.checklist, checklist_progress: iProgress } });
          setItems(resp?.props?.checklist);
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const addItem = async () => {
    if (newItem.trim() !== '') {
      const item = {
        id: uuid(),
        text: newItem,
        checked: false,
        progress: 0,
        url: '',
      };

      // Update ticket properties
      await handleUpdateChecklist({ checklist: [...items, item] });
      setNewItem('');
    }
  };

  // const toggleItem = (id) => {
  //   setItems(items.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item)));
  // };

  const removeItem = (id) => {
    setItemToDelete(id);
  };

  const confirmDelete = async () => {
    await handleUpdateChecklist({ checklist: items.filter((item) => item.id !== itemToDelete) });
    setItemToDelete(null);
  };

  const cancelDelete = () => {
    setItemToDelete(null);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      addItem();
    }
  };

  // Progress handling functions
  const updateProgress = async (id, progress) => {
    await handleUpdateChecklist({ checklist: items.map((item) => (item.id === id ? { ...item, progress, checked: progress === 100 ? true : false } : item)) });
    setProgressPopoverOpen(null); // Close popover after selection
  };

  const handleProgressClick = (id) => {
    const item = items.find((item) => item.id === id);
    const bCanHandleProgress = hasSupervisorRights(userPermissions);
    if (!item) return;
    if (item.assigned_user_id && item.assigned_user_id !== userId && !bCanHandleProgress) {
      messageApi.open({
        key: uuid(),
        type: 'warning',
        content: 'You are not allowed to change progress of this item',
        duration: 3,
      });

      return;
    }
    setProgressPopoverOpen(id);
  };

  const closeProgressPopover = () => {
    setProgressPopoverOpen(null);
  };

  // Progress option content for popover
  const progressContent = (id, currentProgress) => (
    <div className='flex flex-col gap-2'>
      <div className='text-center font-medium mb-1'>Set Progress</div>
      <div className='flex gap-1'>
        {[0, 25, 50, 75, 100].map((value) => (
          <Button key={value} type={currentProgress === value ? 'primary' : 'default'} size='small' onClick={() => updateProgress(id, value)} className={`min-w-[40px] ${currentProgress === value ? '' : 'hover:text-blue-600'}`}>
            {value}%
          </Button>
        ))}
      </div>
    </div>
  );

  // Text and URL editing functions (unchanged)
  const startEditing = (id, text) => {
    setEditingItemId(id);
    setEditText(text);
    // Close other editing modes
    setUrlEditingItemId(null);
    setUrlInput('');
    setProgressPopoverOpen(null);
  };

  const saveEdit = async (id) => {
    if (editText.trim() !== '') {
      await handleUpdateChecklist({ checklist: items.map((item) => (item.id === id ? { ...item, text: editText } : item)) });
      setEditingItemId(null);
      setEditText('');
    }
  };

  const cancelEdit = () => {
    setEditingItemId(null);
    setEditText('');
  };

  const handleEditKeyPress = (e, id) => {
    if (e.key === 'Enter') {
      saveEdit(id);
    } else if (e.key === 'Escape') {
      cancelEdit();
    }
  };

  const startEditingUrl = (id, url) => {
    setUrlEditingItemId(id);
    setUrlInput(url || '');
    // Close other editing modes
    setEditingItemId(null);
    setEditText('');
    setProgressPopoverOpen(null);
  };

  const saveUrlEdit = async (id) => {
    let processedUrl = urlInput.trim();

    if (processedUrl) {
      // Ensure URL has protocol
      if (!processedUrl.startsWith('http://') && !processedUrl.startsWith('https://')) {
        processedUrl = 'https://' + processedUrl;
      }
      await handleUpdateChecklist({ checklist: items.map((item) => (item.id === id ? { ...item, url: processedUrl } : item)) });
    } else {
      // If empty, remove the URL
      await handleUpdateChecklist({ checklist: items.map((item) => (item.id === id ? { ...item, url: '' } : item)) });
    }

    setUrlEditingItemId(null);
    setUrlInput('');
  };

  const cancelUrlEdit = () => {
    setUrlEditingItemId(null);
    setUrlInput('');
  };

  const handleUrlKeyPress = (e, id) => {
    if (e.key === 'Enter') {
      saveUrlEdit(id);
    } else if (e.key === 'Escape') {
      cancelUrlEdit();
    }
  };

  // Find the item being deleted (if any)
  const itemBeingDeleted = items.find((item) => item.id === itemToDelete);

  const assignMember = async (itemId, memberId) => {
    console.log('assignMember', itemId, memberId);
    await handleUpdateChecklist({
      checklist: items.map((item) => (item.id === itemId ? { ...item, assigned_user_id: memberId } : item)),
    });
  };

  const handleOnWsMessageReceived = (e) => {
    if (e?.data?.ticket_id !== currentTicket?.id) return;
    if (!e?.data?.details?.checklist) return;
    console.log('[TicketCheckItems] handleOnWsMessageReceived()', e);
    try {
      const items = e?.data?.details?.checklist;
      setItems(items);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      const reorderedItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
        ...item,
        pos: index, // Update position
      }));

      setItems(reorderedItems);

      // Save the updated positions to the backend
      await handleUpdateChecklist({ checklist: reorderedItems });
    }
  };

  return (
    <div className='p-4 border rounded-md shadow-sm' ref={containerRef}>
      {contextHolder}
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_UPDATED_PROPS']} />
      <div className='flex flex-row justify-between mb-4'>
        <span className='opacity-60'>Project checklist items</span>
        {/* Edit mode toggle - only visible to users with supervisor rights */}
        {hasSupervisorRights(userPermissions) && (
          <div className='flex justify-end mb-3'>
            <Switch size='small' checked={editMode} onChange={setEditMode} checkedChildren='Edit' unCheckedChildren='View' className='mr-2' />
          </div>
        )}
      </div>

      {/* Delete confirmation modal */}
      <Modal title='Confirm Deletion' open={itemToDelete !== null} onOk={confirmDelete} onCancel={cancelDelete} okText='Delete' cancelText='Cancel' okButtonProps={{ danger: true }}>
        <p>Are you sure you want to delete "{itemBeingDeleted?.text}"?</p>
      </Modal>

      {/* Add item input - now requires both supervisor rights AND edit mode */}
      {hasSupervisorRights(userPermissions) && editMode && (
        <div className='flex mb-4 gap-2'>
          <Input value={newItem} onChange={(e) => setNewItem(e.target.value)} onKeyPress={handleKeyPress} placeholder='Add new item' className='flex-grow' />
          <Button type='primary' onClick={addItem} icon={<PlusOutlined />}>
            Add
          </Button>
        </div>
      )}

      {/* Drag-and-Drop List */}
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items.map((item) => item.id)} strategy={verticalListSortingStrategy}>
          <List
            size='small'
            dataSource={items}
            className='ant-list-no-margins'
            renderItem={(item) => (
              <SortableItem
                key={item.id}
                id={item.id}
                canDrag={hasSupervisorRights(userPermissions) && editMode}
                dragHandle={
                  <PiDotsSixVerticalBold
                    style={{
                      fontSize: '14px',
                      color: '#555',
                      cursor: 'grab',
                    }}
                    title='Drag to reorder'
                  />
                }
              >
                <List.Item className='flex items-center py-2'>
                  {/* Text editing mode */}
                  {editingItemId === item.id ? (
                    <div className='flex flex-grow gap-2'>
                      <Input value={editText} onChange={(e) => setEditText(e.target.value)} onKeyDown={(e) => handleEditKeyPress(e, item.id)} autoFocus className='flex-grow' />
                      <Button type='text' icon={<CheckOutlined />} onClick={() => saveEdit(item.id)} className='text-green-600' size='small' />
                      <Button type='text' icon={<CloseOutlined />} onClick={cancelEdit} size='small' />
                    </div>
                  ) : /* URL editing mode */
                  urlEditingItemId === item.id ? (
                    <div className='flex flex-grow gap-2'>
                      <Input value={urlInput} onChange={(e) => setUrlInput(e.target.value)} onKeyDown={(e) => handleUrlKeyPress(e, item.id)} placeholder='Enter URL' autoFocus className='flex-grow' />
                      <Button type='text' icon={<CheckOutlined />} onClick={() => saveUrlEdit(item.id)} className='text-green-600' size='small' />
                      <Button type='text' icon={<CloseOutlined />} onClick={cancelUrlEdit} size='small' />
                    </div>
                  ) : (
                    /* Normal display mode */
                    <>
                      <div className='flex-grow flex items-center justify-between pt-1 pb-1'>
                        {item?.assigned_user_id ? (
                          <>
                            {(() => {
                              const username = currentTicketMembers?.find((member) => member.user_id === item.assigned_user_id)?.user_name;
                              if (!username) {
                                return (
                                  <Tooltip title='User no longer assigned to the ticket'>
                                    <span className='mr-8 -ml-2'>
                                      <WarningOutlined style={{ color: 'orange' }} />
                                    </span>
                                  </Tooltip>
                                );
                              }
                              const firstLetter = username.charAt(0).toUpperCase();
                              const lastLetter = username.split(' ')[1]?.charAt(0).toUpperCase() || '';

                              // Check if the assigned user is the current user
                              const isCurrentUser = item.assigned_user_id === userId;

                              return (
                                <Tooltip title={`${username}${isCurrentUser ? ' (You)' : ''}`}>
                                  <Tag
                                    size='small'
                                    style={{
                                      transform: 'rotate(-90deg) translateY(-75%) ',
                                      ...(isCurrentUser && {
                                        background: '#52c164',
                                        color: 'black',
                                        borderColor: '#52c41a',
                                      }),
                                    }}
                                  >
                                    {`${firstLetter}${lastLetter}`}
                                  </Tag>
                                </Tooltip>
                              );
                            })()}
                          </>
                        ) : (
                          <div className='w-10 h-5'></div>
                        )}
                        <div className='flex flex-row items-center'>
                          <span className={`${item.checked ? 'line-through text-gray-400' : ''}`}>{item.text}</span>
                          {item.url && (
                            <Tooltip title={item.url} placement='top'>
                              <a href={item.url} target='_blank' rel='noopener noreferrer' className='text-blue-500 text-sm ml-2'>
                                <LinkOutlined />
                              </a>
                            </Tooltip>
                          )}
                        </div>

                        {/* Progress with popover */}
                        <div className='ml-auto mr-4'>
                          <Popover
                            content={progressContent(item.id, item.progress)}
                            trigger='click'
                            open={progressPopoverOpen === item.id}
                            onOpenChange={(visible) => {
                              if (visible) {
                                handleProgressClick(item.id);
                              } else {
                                closeProgressPopover();
                              }
                            }}
                          >
                            <div
                              className='cursor-pointer ml-2'
                              onClick={(e) => e.stopPropagation()} // Prevent drag-and-drop
                            >
                              {showCompactProgress ? (
                                <Tooltip title='Click to set progress'>
                                  <span className={`font-medium ${item.progress === 100 ? 'text-green-600' : 'text-blue-600'}`}>{item.progress}%</span>
                                </Tooltip>
                              ) : (
                                <Progress percent={item.progress} steps={4} size={[10, 6]} showInfo={true} format={(percent) => <span className='opacity-90 text-xs'>{percent}%</span>} />
                              )}
                            </div>
                          </Popover>
                        </div>
                      </div>
                      {/* Edit buttons - require both supervisor rights AND edit mode */}
                      {hasSupervisorRights(userPermissions) && editMode && (
                        <div className='flex'>
                          {/* Member assignment dropdown */}
                          <Dropdown
                            menu={{
                              items: [
                                ...Array.from(new Set(currentTicketMembers?.map((member) => member.user_id))).map((user_id) => {
                                  const member = currentTicketMembers.find((m) => m.user_id === user_id);
                                  return {
                                    key: member.user_id,
                                    label: member.user_name,
                                    onClick: () => assignMember(item.id, member.user_id),
                                  };
                                }),
                                // Only include the unassign option if there's a user assigned
                                ...(item.assigned_user_id
                                  ? [
                                      {
                                        key: 'unassign',
                                        label: <div style={{ textAlign: 'center', width: '100%', color: 'rgba(255, 0, 0, 0.8)' }}>-- Unassign current User --</div>,
                                        onClick: () => assignMember(item.id, null),
                                        style: { color: '#d9d9d9' },
                                      },
                                    ]
                                  : []),
                              ],
                            }}
                            placement='bottomRight'
                            trigger={['click']}
                          >
                            <Button
                              type='text'
                              icon={<IoPersonAdd />}
                              size='small'
                              className='mr-1 text-gray-500'
                              title='Assign member'
                              onClick={(e) => e.stopPropagation()} // Prevent drag-and-drop
                            />
                          </Dropdown>

                          {/* URL add/edit button */}
                          <Button
                            type='text'
                            icon={<LinkOutlined />}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent drag-and-drop
                              startEditingUrl(item.id, item.url);
                            }}
                            size='small'
                            className='mr-1 text-gray-500'
                          />

                          {/* Edit text button */}
                          <Button
                            type='text'
                            icon={<EditOutlined />}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent drag-and-drop
                              startEditing(item.id, item.text);
                            }}
                            size='small'
                            className='mr-1 text-blue-600'
                          />

                          {/* Delete button */}
                          <Button
                            type='text'
                            danger
                            icon={<DeleteOutlined />}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent drag-and-drop
                              removeItem(item.id);
                            }}
                            size='small'
                          />
                        </div>
                      )}
                    </>
                  )}
                </List.Item>
              </SortableItem>
            )}
          />
        </SortableContext>
      </DndContext>

      {items.length > 0 && (
        <div className='mt-6 pt-2'>
          <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-col items-center'>
              <span className='text-sm font-medium mt-2 opacity-90'>overall progress {score}%</span>
            </div>
          </div>
        </div>
      )}
      {items.length === 0 && <div className='text-center text-gray-400 my-4'>No items added yet</div>}
    </div>
  );
};

export default TicketCheckItems;
